import React, { Component } from 'react';
import { Link } from 'react-router-dom';
//import imgTraining4 from '../../assets/img/sebastian-herrmann-NbtIDoFKGO8-unsplash.png';
import imgTraining4 from '../../assets/img/Group 2197.png';
import actions from '../../js/actions';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { MemoryRouter, Route } from 'react-router';
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import moment from 'moment';
import { host } from '../../utils/constants';
import Headers from '../../layouts/headers';

const mapStateToProps = (state) => ({
  isLoadingTrainings: state.AppReducer.isLoadingTrainings,
  trainings: state.AppReducer.trainings,
  params: state.AppReducer.params,
  isAuthorized: state.AppReducer.isAuthorized,
});

const mapDispatchToProps = (dispatch) => ({
  changeSearchedString: (params, type) => {
    dispatch(actions.changeSearchedString(params));
    dispatch(actions.getAllTrainings(params, type));
  },
  getAllTrainings: (params, type) => dispatch(actions.getAllTrainings(params, type)),
});

class DirectTraining extends Component {
  constructor(props) {
    super(props);
    document.title = 'تدريب مباشر';
    props.getAllTrainings(props.params, 3);
    this.makeHttpRequestWithPage = this.makeHttpRequestWithPage.bind(this);
  }
  makeHttpRequestWithPage = (event, page) => {
    this.props.changeSearchedString(
      {
        ...this.props.params,
        currentPage: page ? page : this.props.params.currentPage - 1,
        limit: this.props.params.limit,
      },
      3,
    );
  };
  render() {
    const { isLoadingTrainings, isAuthorized, trainings } = this.props;
    return (
      <section className="body-section">
        <Headers isAuthorized={isAuthorized} />
        <div
          style={{ backgroundColor: '#EDEDED' }}
          className="bs-main-container  zoom-training-body"
        >
          <div className="bs-main-content">
            <div className="training-program-section-elem header--subSect">
              <div className="training-program-section-header-title">
                <span>خطة التدريب السنوية</span>
              </div>
              <div className="training-program-section-header-desc">
                <span>
                  {' '}
                  <p>                  نقدم مجموعة متنوعة من البرامج التدريبية في مجالات العمل المؤسسي (التخصصية والعامة) بما يتماشى مع احتياجات موظفي حكومة ام القيوين لعام 2021م.. 
</p>
عزيزي الموظف يرجى التسجيل في البرنامج المطلوب ليصلنا طلبكم و سوف يتم اعلامكم بتفاصيل انعقاده
                </span>
              </div>
            </div>
            {isLoadingTrainings ? (
              <div className="cards-container">
                <div className="cards-wrapper">
                  <div className="cardes-wrapper-content">isLoading</div>
                </div>
              </div>
            ) : (
              <>
                <div className="cards-container">
                  <div className="cards-wrapper">
                    <div className="cardes-wrapper-content">
                      {trainings &&
                        trainings.data &&
                        trainings.data.length > 0 &&
                        trainings.data.map((training) => {
                          return (
                            <Link
                              key={training.id}
                              style={{ color: 'black', textDecoration: 'none' }}
                              to={`/direct-trainings/details/${training.id}`}
                            >
                              <div className="training-program-card">
                                <div className="training-program-card-content">
                                  <div className="training-program-card-miniature">
                                    <img src={`${host}${training.picture}`} alt="" />
                                  </div>
                                </div>
                              </div>
                            </Link>
                          );
                        })}
                    </div>
                  </div>
                  <div className="pagination-container"></div>
                  {trainings && trainings.total > 20 ? (
                    <div className="pagination-content">
                      <MemoryRouter initialEntries={['/inbox']}>
                        <Route>
                          {() => {
                            const page = parseInt(
                              trainings.current_page || 1,
                              Math.ceil(trainings.total / trainings.per_page),
                            );
                            return (
                              <Pagination
                                // color="secondary"
                                page={page}
                                //defaultPage={trainings.current_page}
                                // borderCount={5}
                                hidePrevButton
                                hideNextButton
                                count={Math.ceil(trainings.total / trainings.per_page)}
                                onChange={this.makeHttpRequestWithPage}
                                renderItem={(item) => (
                                  <PaginationItem
                                    className="pagination-content-elem last-pagination-number"
                                    component={Link}
                                    page={trainings.current_page}
                                    to={`/inbox${
                                      item.page === 1 ? '' : `?page=${item.page}`
                                    }`}
                                    {...item}
                                  />
                                )}
                              />
                            );
                          }}
                        </Route>
                      </MemoryRouter>
                    </div>
                  ) : (
                    ''
                  )}{' '}
                </div>
              </>
            )}
          </div>
        </div>
      </section>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DirectTraining));
