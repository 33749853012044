import React, { Component } from 'react';
import actions from '../../../../../js/actions';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import { host, itemCourses } from '../../../../../utils/constants';
import Content from './content';
import HeadersAuth from '../../../../../layouts/auth/headers';
import SideBar from '../../../../../layouts/auth/sidebar';
import { getTimeFromMins } from '../../../../../utils/helpers';

const mapStateToProps = (state) => ({
  isLoadingUserSmartTraining: state.AppReducer.isLoadingUserSmartTraining,
  userSmartTraining: state.AppReducer.userSmartTraining,
  isAuthorized: state.AppReducer.isAuthorized,
});

const mapDispatchToProps = (dispatch) => ({
  getUserTrainingById: (id) => dispatch(actions.getUserTrainingById(id)),
});

class SmartTrainingCatalogue extends Component {
  constructor(props) {
    super(props);
    props.getUserTrainingById(props.match.params.id);
    this.state = {
      content: false,
      sections: [],
    };
    this.handleNextSection = this.handleNextSection.bind(this);
  }
  handleNextSection(id) {
    const { sections } = this.state;
    const data = [...sections, id];
    this.setState({
      sections: data,
    });
  }
  render() {
    const { userSmartTraining } = this.props;
    const { sections } = this.state;
    let i = -2;
    let ij = 0;
    const imgRatings = [
      {
        id: 1,
      },
      {
        id: 2,
      },
      {
        id: 3,
      },
      {
        id: 4,
      },
    ];
    if (!userSmartTraining)
      return (
        <section className="body-section registered-user-view ctlg-courses-cpy">
          <div className="bs-main-container">
            <div className="bs-main-content">
              <div className="registered-user-view-container">
                <div className="registered-user-view-content">
                  <CircularProgress
                    style={{
                      textAlign: 'center',
                      marginTop: '6%',
                      width: '40px',
                      color: '#d85555',
                      marginRight: '50%',
                      marginBottom: '20%',
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      );
    return (
      <>
        <HeadersAuth />
        <section className="sidebar-body-container registered-user-view-main-cont">
          <SideBar
            path={this.props.location.pathname}
            changeActiveItemSideBar={this.props.changeActiveItemSideBar}
            activeItemSideBar={this.props.activeItemSideBar}
            account={this.props.account}
          />
          <section className="body-section registered-user-view">
            <div className="bs-main-container">
              <div className="bs-main-content">
                <div className="registered-user-view-container">
                  <div className="SideNavMenu-btn--Mob">
                    <div className="SideNavMenu-btn--Mob-content">
                      <button
                        type="button"
                        data-toggle="modal"
                        data-target=".sidenavbar-modal"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="60.171"
                          height="60.171"
                          viewBox="0 0 60.171 60.171"
                        >
                          <g
                            id="Group_1515"
                            data-name="Group 1515"
                            transform="translate(-1777.986 -170.986)"
                          >
                            <path
                              id="Path_799"
                              data-name="Path 799"
                              d="M1808.072,166.157h0a30.086,30.086,0,0,1-30.086-30.085h0a30.086,30.086,0,0,1,30.086-30.085h0a30.085,30.085,0,0,1,30.085,30.085h0A30.085,30.085,0,0,1,1808.072,166.157Z"
                              transform="translate(0 65)"
                              fill="#f0f0f0"
                              style={{ mixBlendMode: 'multiply', isolation: 'isolate' }}
                            ></path>
                            <path
                              id="Path_800"
                              data-name="Path 800"
                              d="M1812.421,163.205h0a22.785,22.785,0,0,1-22.785-22.785h0a22.785,22.785,0,0,1,22.785-22.785h0a22.785,22.785,0,0,1,22.785,22.785h0A22.785,22.785,0,0,1,1812.421,163.205Z"
                              transform="translate(-4.349 60.651)"
                              fill="#fff"
                            ></path>
                            <g
                              id="Group_1082"
                              data-name="Group 1082"
                              transform="translate(1796.957 189.169)"
                            >
                              <g
                                id="Group_1080"
                                data-name="Group 1080"
                                transform="translate(4.41)"
                              >
                                <path
                                  id="Path_805"
                                  data-name="Path 805"
                                  d="M1821.8,147.965a6.46,6.46,0,0,1-4.308-1.641,6.338,6.338,0,0,1-2.2-4.819,6.506,6.506,0,0,1,13.012,0,6.338,6.338,0,0,1-2.208,4.824A6.443,6.443,0,0,1,1821.8,147.965Zm0-10.315a3.858,3.858,0,0,0-3.855,3.855,3.718,3.718,0,0,0,1.3,2.831,3.9,3.9,0,0,0,5.092.01,3.736,3.736,0,0,0,1.315-2.841A3.906,3.906,0,0,0,1821.8,137.65Z"
                                  transform="translate(-1815.294 -135)"
                                  fill="#ea0029"
                                ></path>
                              </g>
                              <g
                                id="Group_1081"
                                data-name="Group 1081"
                                transform="translate(0 9.015)"
                              >
                                <path
                                  id="Path_806"
                                  data-name="Path 806"
                                  d="M1824.17,162.931h-9.943a5.862,5.862,0,0,1-5.475-3.618,5.943,5.943,0,0,1,1.208-6.542,12.739,12.739,0,0,1,5.4-3.333,1.373,1.373,0,0,1,1.276.28,3.9,3.9,0,0,0,5.079,0,1.37,1.37,0,0,1,1.273-.28,12.7,12.7,0,0,1,5.9,3.892,5.586,5.586,0,0,1,.784,6.04A6.1,6.1,0,0,1,1824.17,162.931Zm-8.669-10.74a10.14,10.14,0,0,0-3.631,2.417,3.342,3.342,0,0,0-.681,3.669,3.214,3.214,0,0,0,3.038,2h9.943a3.414,3.414,0,0,0,3.1-2.024,2.935,2.935,0,0,0-.393-3.209,10.121,10.121,0,0,0-4.032-2.857A6.591,6.591,0,0,1,1815.5,152.192Z"
                                  transform="translate(-1808.258 -149.386)"
                                  fill="#ea0029"
                                ></path>
                              </g>
                            </g>
                          </g>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="29"
                          height="40"
                          viewBox="0 0 29 40"
                        >
                          <defs>
                            <filter
                              id="Polygon_2"
                              x="0"
                              y="0"
                              width="29"
                              height="40"
                              filterUnits="userSpaceOnUse"
                            >
                              <feOffset dy="3" input="SourceAlpha"></feOffset>
                              <feGaussianBlur
                                stdDeviation="3"
                                result="blur"
                              ></feGaussianBlur>
                              <feFlood flood-opacity="0.161"></feFlood>
                              <feComposite operator="in" in2="blur"></feComposite>
                              <feComposite in="SourceGraphic"></feComposite>
                            </filter>
                          </defs>
                          <g
                            transform="matrix(1, 0, 0, 1, 0, 0)"
                            filter="url(#Polygon_2)"
                          >
                            <path
                              id="Polygon_2-2"
                              data-name="Polygon 2"
                              d="M10.293.707a1,1,0,0,1,1.414,0l8.586,8.586A1,1,0,0,1,19.586,11H2.414a1,1,0,0,1-.707-1.707Z"
                              transform="translate(9 28) rotate(-90)"
                              fill="#fff"
                            ></path>
                          </g>
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div className="registered-user-view-content">
                    <div className="registered-user-view-column">
                      <div className="registered-user-view-column-header">
                        <div className="registered-user-view-column-header-content">
                          <div className="registered-user-view-column-header-elem">
                            <div className="registered-user-view-column-header-elem-img-container">
                              <img src={`${host}${userSmartTraining.picture}`} alt="" />
                            </div>
                            <span>{userSmartTraining.name}</span>
                          </div>
                          <div className="header-vert-spr"></div>
                          <div className="registered-user-view-column-header-elem">
                            <div className="registered-user-view-column-header-elem-icon-container">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="22"
                                height="22"
                                viewBox="0 0 22 22"
                              >
                                <g
                                  id="Group_4"
                                  data-name="Group 4"
                                  transform="translate(-1703 -517.519)"
                                >
                                  <g
                                    id="Ellipse_40"
                                    data-name="Ellipse 40"
                                    transform="translate(1703 517.519)"
                                    fill="none"
                                    stroke="#000"
                                    strokeWidth="2"
                                  >
                                    <circle cx="11" cy="11" r="11" stroke="none" />
                                    <circle cx="11" cy="11" r="10" fill="none" />
                                  </g>
                                  <path
                                    id="Path_6"
                                    data-name="Path 6"
                                    d="M-6296-7514.979v6.927h5.139"
                                    transform="translate(8009.593 8036.893)"
                                    fill="#fff"
                                    stroke="#000"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1"
                                  />
                                </g>
                              </svg>
                            </div>
                            <span>{getTimeFromMins(userSmartTraining.period)}</span>
                          </div>
                          <div className="header-vert-spr"></div>
                          <div className="registered-user-view-column-header-elem">
                            <div className="registered-user-view-column-header-elem-course-rating">
                              <div className="registered-user-view-column-header-elem-course-rating-content">
                                {imgRatings.map((somme) => {
                                  ij++;
                                  if (ij <= userSmartTraining.sommeRating)
                                    return (
                                      <svg
                                        height="20px"
                                        viewBox="0 -10 511.98685 511"
                                        width="20px"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="m510.652344 185.902344c-3.351563-10.367188-12.546875-17.730469-23.425782-18.710938l-147.773437-13.417968-58.433594-136.769532c-4.308593-10.023437-14.121093-16.511718-25.023437-16.511718s-20.714844 6.488281-25.023438 16.535156l-58.433594 136.746094-147.796874 13.417968c-10.859376 1.003906-20.03125 8.34375-23.402344 18.710938-3.371094 10.367187-.257813 21.738281 7.957031 28.90625l111.699219 97.960937-32.9375 145.089844c-2.410156 10.667969 1.730468 21.695313 10.582031 28.09375 4.757813 3.4375 10.324219 5.1875 15.9375 5.1875 4.839844 0 9.640625-1.304687 13.949219-3.882813l127.46875-76.183593 127.421875 76.183593c9.324219 5.609376 21.078125 5.097657 29.910156-1.304687 8.855469-6.417969 12.992187-17.449219 10.582031-28.09375l-32.9375-145.089844 111.699219-97.941406c8.214844-7.1875 11.351563-18.539063 7.980469-28.925781zm0 0"
                                          fill="#ffc107"
                                        />
                                      </svg>
                                    );
                                  else
                                    return (
                                      <svg
                                        height="20px"
                                        viewBox="0 -10 511.98685 511"
                                        width="20px"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="m114.59375 491.140625c-5.609375 0-11.179688-1.75-15.933594-5.1875-8.855468-6.417969-12.992187-17.449219-10.582031-28.09375l32.9375-145.089844-111.703125-97.960937c-8.210938-7.167969-11.347656-18.519532-7.976562-28.90625 3.371093-10.367188 12.542968-17.707032 23.402343-18.710938l147.796875-13.417968 58.433594-136.746094c4.308594-10.046875 14.121094-16.535156 25.023438-16.535156 10.902343 0 20.714843 6.488281 25.023437 16.511718l58.433594 136.769532 147.773437 13.417968c10.882813.980469 20.054688 8.34375 23.425782 18.710938 3.371093 10.367187.253906 21.738281-7.957032 28.90625l-111.703125 97.941406 32.9375 145.085938c2.414063 10.667968-1.726562 21.699218-10.578125 28.097656-8.832031 6.398437-20.609375 6.890625-29.910156 1.300781l-127.445312-76.160156-127.445313 76.203125c-4.308594 2.558594-9.109375 3.863281-13.953125 3.863281zm141.398438-112.875c4.84375 0 9.640624 1.300781 13.953124 3.859375l120.277344 71.9375-31.085937-136.941406c-2.21875-9.746094 1.089843-19.921875 8.621093-26.515625l105.472657-92.5-139.542969-12.671875c-10.046875-.917969-18.6875-7.234375-22.613281-16.492188l-55.082031-129.046875-55.148438 129.066407c-3.882812 9.195312-12.523438 15.511718-22.546875 16.429687l-139.5625 12.671875 105.46875 92.5c7.554687 6.613281 10.859375 16.769531 8.621094 26.539062l-31.0625 136.9375 120.277343-71.914062c4.308594-2.558594 9.109376-3.859375 13.953126-3.859375zm-84.585938-221.847656s0 .023437-.023438.042969zm169.128906-.0625.023438.042969c0-.023438 0-.023438-.023438-.042969zm0 0"
                                          fill="#DCDBDB"
                                        />
                                      </svg>
                                    );
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="registered-user-view-column-content">
                        <div className="registered-user-view-column-elem">
                          <div className="registered-user-view-column-elem-content">
                            <div className="registered-user-view-column-elem-row">
                              <Content
                                handleNextSection={this.handleNextSection}
                                training={userSmartTraining}
                                sections={userSmartTraining.sections}
                              />
                              <div className="course-summary">
                                <div className="course-summary-content">
                                  <div className="course-summary-elem  summary-title">
                                    <div className="course-chapter-name">
                                      <span>المحتوى</span>
                                    </div>
                                    <div className="course-chapter-content">
                                      {userSmartTraining &&
                                        userSmartTraining.sections &&
                                        userSmartTraining.sections
                                          .sort((a, b) =>
                                            a.type > b.type
                                              ? 1
                                              : b.type > a.type
                                              ? -1
                                              : 0,
                                          )
                                          .map((item) => {
                                            // item.sectio_items.
                                            if (item.type === 0)
                                              return item.section_items
                                                .sort((a, b) =>
                                                  a.order > b.order
                                                    ? 1
                                                    : b.order > a.order
                                                    ? -1
                                                    : 0,
                                                )
                                                .map((section_item) => {
                                                  return (
                                                    <div
                                                      key={section_item.id}
                                                      className="course-chapter-elem"
                                                    >
                                                      <span>
                                                        <i
                                                          style={{
                                                            marginRight: '2%',
                                                            marginLeft: '2%',
                                                            color: 'gray',
                                                          }}
                                                          className="far fa-file-alt"
                                                        ></i>
                                                        {section_item.doc_name}
                                                      </span>
                                                    </div>
                                                  );
                                                });
                                          })}
                                    </div>
                                  </div>
                                  {userSmartTraining &&
                                    userSmartTraining.sections &&
                                    userSmartTraining.sections
                                      .sort((a, b) =>
                                        a.type > b.type ? 1 : b.type > a.type ? -1 : 0,
                                      )
                                      .map((item) => {
                                        i++;
                                        // completed-chap
                                        //sections
                                        if (item.type !== 0)
                                          return (
                                            <div
                                              key={item.id}
                                              // className={"course-summary-elem"}
                                              className="course-summary-elem completed-chap"
                                            >
                                              <div className="course-chapter-name">
                                                <span>{itemCourses[i]} :</span>
                                                <span> {item.name}</span>
                                              </div>
                                              <div
                                                /*style={
                                                  sections.find(
                                                    (section) => section === item.id,
                                                  )
                                                    ? 'course-summary-elem completed-chap'
                                                    : 'course-summary-elem completed-chap'
                                                }*/
                                                className="course-chapter-content"
                                              >
                                                {item.section_items &&
                                                  item.section_items
                                                    .sort((a, b) =>
                                                      a.order > b.order
                                                        ? 1
                                                        : b.order > a.order
                                                        ? -1
                                                        : 0,
                                                    )
                                                    .map((section_item) => {
                                                      if (
                                                        section_item.doc_name !==
                                                          'تأكيد استيعاب' &&
                                                        item.name !== 'الشهادة' &&
                                                        item.name !==
                                                          'ملخص الدورة التدريبية'
                                                      )
                                                        return (
                                                          <div
                                                            style={{
                                                              backgroundColor:
                                                                userSmartTraining.sections
                                                                  .length ===
                                                                userSmartTraining.section_pause
                                                                  ? 'white'
                                                                  : sections.find(
                                                                      (section) =>
                                                                        section ===
                                                                        section_item.id,
                                                                    )
                                                                  ? 'white'
                                                                  : '#eaeaea',
                                                              //'#eaeaea'
                                                            }}
                                                            /* style={{
                                                              backgroundColor: 'white',
                                                            }}*/
                                                            className="course-chapter-elem"
                                                            key={section_item.id}
                                                          >
                                                            {section_item.type ===
                                                            'video' ? (
                                                              <>
                                                                <i
                                                                  style={{
                                                                    marginRight: '2%',
                                                                    marginLeft: '2%',
                                                                    color: 'gray',
                                                                  }}
                                                                  className={
                                                                    section_item.type ===
                                                                    'video'
                                                                      ? 'fas fa-play'
                                                                      : 'far fa-file-pdf'
                                                                  }
                                                                ></i>
                                                                <span
                                                                  style={{
                                                                    color: 'black',
                                                                  }}
                                                                >
                                                                  {section_item.doc_name}
                                                                </span>
                                                              </>
                                                            ) : (
                                                              <>
                                                                <i
                                                                  style={{
                                                                    marginRight: '2%',
                                                                    marginLeft: '2%',
                                                                    color: 'gray',
                                                                  }}
                                                                  className={
                                                                    section_item.type ===
                                                                    'video'
                                                                      ? 'fas fa-play'
                                                                      : 'far fa-file-pdf'
                                                                  }
                                                                ></i>
                                                                <span
                                                                  style={{
                                                                    color: 'black',
                                                                  }}
                                                                >
                                                                  {section_item.doc_name}
                                                                </span>
                                                                {/* </a>*/}
                                                              </>
                                                            )}
                                                          </div>
                                                        );
                                                      if (
                                                        item.name !== 'الشهادة' &&
                                                        item.name !==
                                                          'ملخص الدورة التدريبية'
                                                      )
                                                        return (
                                                          <div className="course-chapter-elem">
                                                            <span>
                                                              {section_item.doc_name}
                                                            </span>
                                                          </div>
                                                        );
                                                      else
                                                        return (
                                                          <div
                                                            style={{
                                                              background:
                                                                userSmartTraining.sections
                                                                  .length ===
                                                                userSmartTraining.section_pause
                                                                  ? 'white'
                                                                  : '#DEE0E6',
                                                            }}
                                                            className="course-chapter-elem"
                                                          >
                                                            <span
                                                              style={{ color: 'black' }}
                                                            >
                                                              {section_item.doc_name}
                                                            </span>
                                                          </div>
                                                        );
                                                    })}
                                              </div>
                                            </div>
                                          );
                                      })}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
      </>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SmartTrainingCatalogue),
);
