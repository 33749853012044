import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import update from 'immutability-helper';
import actions from '../../../../js/actions';
import { connect } from 'react-redux';
import Headers from '../../../../layouts/headers';
import { CircularProgress } from '@material-ui/core';

const mapStateToProps = (state) => {
  return {
    isLoadingContact: state.AppReducer.isLoadingContact,
    contact: state.AppReducer.contact,
    isAuthorized: state.AppReducer.isAuthorized,
    isLoadingTrainings: state.AppReducer.isLoadingTrainings,
    trainings: state.AppReducer.trainings,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAllCourses: () => dispatch(actions.getAllCourses()),
  submitTraining: (values, docs) => dispatch(actions.submitTraining(values, docs)),
});

class FormTraining extends Component {
  constructor(props) {
    super(props);
    document.title = 'إضافة دورة';
    props.getAllCourses();
    this.state = {
      docs: [],
      course_id: null,
      name: '',
      type: 2,
      description: '',
      training_goal: '',
      file: '',
      question_count: 1,
      proposition_count: 1,
      section_item_count: 1,
      section_count: 1,
      initialValues: {
        name: '',
        type: 2,
        description: '',
        training_goal: '',
        sections: [
          {
            id: 1,
            name: '',
            type: '',
            order: '',
            section_items: [
              {
                id: 1,
                doc_name: '',
                order: '',
                text: '',
                doc: '',
                type: '',
                questions: [
                  {
                    id: 1,
                    content: '',
                    score: '',
                    order: '',
                    answer: '',
                    propositions: [
                      {
                        id: 1,
                        content: '',
                        answer: 0,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    };
    this.validator = new SimpleReactValidator({
      messages: {
        email: 'Adresse e-mail invalide',
        min: 'Saisissez un mot de passe comprenant au moins 6 caractères.',
        max: 'Le mot de passe doit comporter de 6 à 20 caractères. ',
        // OR
        default: 'Champ requise.', // will override all messenger
      },
    });
    this.handleAddProposition = this.handleAddProposition.bind(this);
    this.handleChangeInputProposition = this.handleChangeInputProposition.bind(this);
    this.handleAddQuestion = this.handleAddQuestion.bind(this);
    this.handleAddSectionItem = this.handleAddSectionItem.bind(this);
    this.handleChangeInputSectionItem = this.handleChangeInputSectionItem.bind(this);
    this.handleAddSection = this.handleAddSection.bind(this);
    this.handleChangeInputSection = this.handleChangeInputSection.bind(this);
    this.handleChangeFilesElem = this.handleChangeFilesElem.bind(this);
    this.handleDeletedFilesElem = this.handleDeletedFilesElem.bind(this);
    this.handleDeleteSection = this.handleDeleteSection.bind(this);
    this.handleDeleteSectionItem = this.handleDeleteSectionItem.bind(this);
    this.handleDeleteQuestion = this.handleDeleteQuestion.bind(this);
    this.handleDeleteProposition = this.handleDeleteProposition.bind(this);
  }
  handleDeleteProposition(id, section_item_id, question_id, proposition_id) {
    const { initialValues } = this.state;
    this.setState((prevState) => {
      let data = [...prevState.initialValues.sections];
      var commentIndexSections = data.findIndex(function (c) {
        return c.id === id;
      });
      var commentIndexSectionsItem = data[commentIndexSections].section_items.findIndex(
        function (c) {
          return c.id === section_item_id;
        },
      );
      var commentIndexQuestion = data[commentIndexSections].section_items[
        commentIndexSectionsItem
      ].questions.findIndex(function (c) {
        return c.id === question_id;
      });
      data[commentIndexSections].section_items[commentIndexSectionsItem].questions[
        commentIndexQuestion
      ].propositions = data[commentIndexSections].section_items[
        commentIndexSectionsItem
      ].questions[commentIndexSectionsItem].propositions.filter(
        (item) => item.id !== proposition_id,
      );
      this.setState({
        initialValues: {
          ...initialValues,
          sections: data,
        },
      });
    });
  }
  handleDeleteQuestion(id, section_item_id, question_id) {
    const { initialValues } = this.state;
    this.setState((prevState) => {
      let data = [...prevState.initialValues.sections];
      var commentIndexSections = data.findIndex(function (c) {
        return c.id === id;
      });
      var commentIndexSectionsItem = data[commentIndexSections].section_items.findIndex(
        function (c) {
          return c.id === section_item_id;
        },
      );
      data[commentIndexSections].section_items[commentIndexSectionsItem].questions = data[
        commentIndexSections
      ].section_items[commentIndexSectionsItem].questions.filter(
        (item) => item.id !== question_id,
      );
      this.setState({
        initialValues: {
          ...initialValues,
          sections: data,
        },
      });
    });
  }
  handleDeleteSectionItem(id, section_item_id) {
    const { initialValues } = this.state;
    this.setState((prevState) => {
      let data = [...prevState.initialValues.sections];
      var commentIndexSections = data.findIndex(function (c) {
        return c.id === id;
      });
      data[commentIndexSections].section_items = data[
        commentIndexSections
      ].section_items.filter((item) => item.id !== section_item_id);
      this.setState({
        initialValues: {
          ...initialValues,
          sections: data,
        },
        isOpenModalDeleteSectionItem: false,
      });
    });
  }
  handleDeleteSection(id) {
    const { initialValues } = this.state;
    this.setState((prevState) => {
      let data = [...prevState.initialValues.sections];
      data = data.filter((item) => item.id !== id);

      this.setState({
        initialValues: {
          ...initialValues,
          sections: data,
        },
      });
    });
  }
  submitForm(initialValues, docs) {
    if (
      !initialValues.name ||
      !initialValues.type ||
      !initialValues.description ||
      !initialValues.training_goal ||
      !initialValues.sections[0].name
    ) {
      this.setState({
        ...initialValues,
        hasError: true,
      });
    } else {
      this.setState({
        ...initialValues,
        hasError: false,
      });
      this.props.submitTraining(initialValues, docs);
    }
  }
  handleAddProposition(section_id, section_item_id, question_id) {
    const { initialValues, proposition_count } = this.state;
    const dataSections = [...initialValues.sections];
    var commentIndexSections = dataSections.findIndex(function (c) {
      return c.id === section_id;
    });
    //const dataSectionItem[commentIndexSections] = [...dataSections.section_items];
    var commentIndexSectionItems = dataSections[
      commentIndexSections
    ].section_items.findIndex(function (c) {
      return c.id === section_item_id;
    });
    var commentIndexQuestions = dataSections[commentIndexSections].section_items[
      commentIndexSectionItems
    ].questions.findIndex(function (c) {
      return c.id === question_id;
    });
    let propositions =
      dataSections[commentIndexSections].section_items[commentIndexSectionItems]
        .questions[commentIndexQuestions].propositions;
    dataSections[commentIndexSections].section_items[commentIndexSectionItems].questions[
      commentIndexQuestions
    ].propositions = [
      ...propositions,
      {
        id: propositions.length > 0 ? propositions[propositions.length - 1].id + 1 : 1,
        content: '',
        answer: 0,
      },
    ];
    this.setState({
      proposition_count: proposition_count + 1,
      initialValues: {
        ...initialValues,
        sections: dataSections,
      },
    });
  }
  handleChangeInputProposition(
    section_id,
    section_item_id,
    question_id,
    proposition_id,
    value,
    name,
  ) {
    const { initialValues } = this.state;
    const dataSections = [...initialValues.sections];
    var commentIndexSections = dataSections.findIndex(function (c) {
      return c.id === section_id;
    });
    //const dataSectionItem[commentIndexSections] = [...dataSections.section_items];
    var commentIndexSectionItems = dataSections[
      commentIndexSections
    ].section_items.findIndex(function (c) {
      return c.id === section_item_id;
    });
    var commentIndexQuestions = dataSections[commentIndexSections].section_items[
      commentIndexSectionItems
    ].questions.findIndex(function (c) {
      return c.id === question_id;
    });
    var commentIndexProposition = dataSections[commentIndexSections].section_items[
      commentIndexSectionItems
    ].questions[commentIndexQuestions].propositions.findIndex(function (c) {
      return c.id === proposition_id;
    });
    let proposition =
      dataSections[commentIndexSections].section_items[commentIndexSectionItems]
        .questions[commentIndexQuestions].propositions[commentIndexProposition];
    dataSections[commentIndexSections].section_items[commentIndexSectionItems].questions[
      commentIndexQuestions
    ].propositions[commentIndexProposition] = update(proposition, {
      [name]: { $set: value },
    });

    this.setState({
      initialValues: {
        ...initialValues,
        sections: dataSections,
      },
    });
  }
  handleAddQuestion(section_id, section_item_id) {
    const { initialValues, question_count } = this.state;
    const dataSections = [...initialValues.sections];
    var commentIndexSections = dataSections.findIndex(function (c) {
      return c.id === section_id;
    });
    //const dataSectionItem[commentIndexSections] = [...dataSections.section_items];
    var commentIndexSectionItems = dataSections[
      commentIndexSections
    ].section_items.findIndex(function (c) {
      return c.id === section_item_id;
    });

    let questions =
      dataSections[commentIndexSections].section_items[commentIndexSectionItems]
        .questions;
    dataSections[commentIndexSections].section_items[
      commentIndexSectionItems
    ].questions = [
      ...questions,
      {
        id: questions.length > 0 ? questions[questions.length - 1].id + 1 : 1,
        content: '',
        score: '',
        order: '',
        answer: '',
        propositions: [
          {
            id: 1,
            content: '',
            answer: '',
          },
        ],
      },
    ];
    this.setState({
      question_count: question_count + 1,
      initialValues: {
        ...initialValues,
        sections: dataSections,
      },
    });
  }
  handleChangeInputQuestion(section_id, section_item_id, question_id, value, name) {
    const { initialValues } = this.state;
    const dataSections = [...initialValues.sections];
    var commentIndexSections = dataSections.findIndex(function (c) {
      return c.id === section_id;
    });
    //const dataSectionItem[commentIndexSections] = [...dataSections.section_items];
    var commentIndexSectionItems = dataSections[
      commentIndexSections
    ].section_items.findIndex(function (c) {
      return c.id === section_item_id;
    });
    var commentIndexQuestions = dataSections[commentIndexSections].section_items[
      commentIndexSectionItems
    ].questions.findIndex(function (c) {
      return c.id === question_id;
    });
    let questions =
      dataSections[commentIndexSections].section_items[commentIndexSectionItems]
        .questions[commentIndexQuestions];
    dataSections[commentIndexSections].section_items[commentIndexSectionItems].questions[
      commentIndexQuestions
    ] = update(questions, {
      [name]: { $set: value },
    });

    this.setState({
      initialValues: {
        ...initialValues,
        sections: dataSections,
      },
    });
  }
  handleAddSectionItem(section_id) {
    const { initialValues, section_item_count } = this.state;
    const dataSections = [...initialValues.sections];
    var commentIndexSections = dataSections.findIndex(function (c) {
      return c.id === section_id;
    });
    //const dataSectionItem[commentIndexSections] = [...dataSections.section_items];

    let sectionItems = dataSections[commentIndexSections].section_items;
    dataSections[commentIndexSections].section_items = [
      ...sectionItems,
      {
        id: sectionItems.length > 0 ? sectionItems[sectionItems.length - 1].id + 1 : 1,
        doc_name: '',
        type: '',
        questions: [
          {
            id: 1,
            content: '',
            score: '',
            order: '',
            answer: '',
            propositions: [
              {
                id: 1,
                content: '',
                answer: 0,
              },
            ],
          },
        ],
      },
    ];
    this.setState({
      [`section_item_count_${section_id}`]:
        this.state[`section_item_count_${section_id}`] + 1,
      initialValues: {
        ...initialValues,
        sections: dataSections,
      },
    });
  }
  handleChangeInputSectionItem(section_id, section_item_id, value, name) {
    const { initialValues } = this.state;
    const dataSections = [...initialValues.sections];
    var commentIndexSections = dataSections.findIndex(function (c) {
      return c.id === section_id;
    });
    //const dataSectionItem[commentIndexSections] = [...dataSections.section_items];
    var commentIndexSectionItems = dataSections[
      commentIndexSections
    ].section_items.findIndex(function (c) {
      return c.id === section_item_id;
    });
    let section_items =
      dataSections[commentIndexSections].section_items[commentIndexSectionItems];
    dataSections[commentIndexSections].section_items[commentIndexSectionItems] = update(
      section_items,
      {
        [name]: { $set: value },
      },
    );

    this.setState({
      initialValues: {
        ...initialValues,
        sections: dataSections,
      },
    });
  }
  handleAddSection() {
    const { initialValues, section_count } = this.state;
    const dataSections = [...initialValues.sections];
    //const dataSectionItem[commentIndexSections] = [...dataSections.section_items];
    this.setState({
      section_count: section_count + 1,
      initialValues: {
        ...initialValues,
        sections: [
          ...dataSections,
          {
            id:
              dataSections.length > 0 ? dataSections[dataSections.length - 1].id + 1 : 1,
            name: '',
            doc: '',
            type: '',
            order: '',
            section_items: [
              {
                id: 1,
                doc_name: '',
                type: '',
                questions: [
                  {
                    id: 1,
                    content: '',
                    score: '',
                    order: '',
                    answer: '',
                    propositions: [
                      {
                        id: 1,
                        content: '',
                        answer: 0,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    });
  }
  handleChangeInputSection(section_id, value, name) {
    const { initialValues } = this.state;
    const dataSections = [...initialValues.sections];
    var commentIndexSections = dataSections.findIndex(function (c) {
      return c.id === section_id;
    });
    //const dataSectionItem[commentIndexSections] = [...dataSections.section_items];
    let sections = dataSections[commentIndexSections];
    dataSections[commentIndexSections] = update(sections, {
      [name]: { $set: value },
    });

    this.setState({
      initialValues: {
        ...initialValues,
        sections: dataSections,
      },
    });
  }
  handleChangeFilesElem = (value, name, id) => {
    const { docs } = this.state;
    this.setState({
      docs: [...docs, { id: id, [name]: value }],
    });
  };
  handleDeletedFilesElem = (id) => {
    const { docs } = this.state;
    this.setState({
      docs: docs.filter(function (c) {
        return c.id !== id;
      }),
    });
  };

  render() {
    const {
      initialValues,
      hasError,
      section_item_count,
      question_count,
      proposition_count,
      docs,
      section_count,
    } = this.state;
    const { isAuthorized, isLoadingTrainings, trainings } = this.props;
    let countSection = 0;
    let countSectionItem = 0;
    if (isLoadingTrainings) {
      return (
        <CircularProgress
          style={{
            textAlign: 'center',
            marginTop: '25%',
            width: '40px',
            color: '#d85555',
            marginRight: '50%',
          }}
        />
      );
    }
    return (
      <section className="body-section">
        <Headers isAuthorized={isAuthorized} />
        <div className="bs-main-container e-lib-body">
          <div className="bs-main-content">
            <div className="bs-main-content-header-sm-tr">
              <div className="bs-main-content-header-title-sm-tr">
                <span>إضافة دورة</span>
              </div>
            </div>
            <div className="contact-us-form-loca-container">
              <div className="contact-us-form-loca-content">
                <div className="contact-us-form-container" style={{ width: '100%' }}>
                  <div className="contact-us-form-content">
                    <div className="contact-us-form-icon-placeholder">
                      {initialValues.file &&
                      initialValues.file.length > 0 &&
                      initialValues.file[0].name ? (
                        <img src={URL.createObjectURL(initialValues.file[0])} />
                      ) : (
                        ''
                      )}
                      {/*<img src={Group31} alt="" />*/}
                    </div>
                    <div className="contact-us-form-body">
                      <div className="user-profil-contact-form-container">
                        <div className="contact-us-form-content">
                          <form action="">
                            <div className="contact-us-form-row">
                              <div className="input-field-label-container">
                                <div className="input-field-label-content">
                                  <label htmlFor="">الدورة </label>
                                </div>
                              </div>
                              <div
                                className={
                                  hasError && !initialValues.name
                                    ? 'input-field-container input-error'
                                    : 'input-field-container'
                                }
                              >
                                <div
                                  className="input-field-content"
                                  style={{ height: '3rem' }}
                                >
                                  <input
                                    name="name"
                                    onChange={(e) => {
                                      this.setState({
                                        initialValues: {
                                          ...initialValues,
                                          name: e.target.value,
                                        },
                                      });
                                    }}
                                    value={initialValues.name}
                                    required={this.validator.message(
                                      'name',
                                      initialValues.name,
                                      'required|string',
                                    )}
                                    className="form-control"
                                    id="exampleFormControlTextarea1"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="contact-us-form-row">
                              <div className="input-field-label-container">
                                <div className="input-field-label-content">
                                  <label htmlFor="">المدة </label>
                                </div>
                              </div>
                              <div
                                className={
                                  hasError && !initialValues.period
                                    ? 'input-field-container input-error'
                                    : 'input-field-container'
                                }
                              >
                                <div
                                  className="input-field-content"
                                  style={{ height: '3rem' }}
                                >
                                  <input
                                    name="period"
                                    onChange={(e) => {
                                      this.setState({
                                        initialValues: {
                                          ...initialValues,
                                          period: e.target.value,
                                        },
                                      });
                                    }}
                                    value={initialValues.period}
                                    required={this.validator.message(
                                      'period',
                                      initialValues.period,
                                      'required|string',
                                    )}
                                    className="form-control"
                                    id="exampleFormControlTextarea1"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="contact-us-form-row">
                              <div className="input-field-label-container">
                                <div className="input-field-label-content">
                                  <label htmlFor="">النوع </label>
                                </div>
                              </div>
                              <div
                                className={
                                  hasError && !initialValues.type
                                    ? 'input-field-container input-error'
                                    : 'input-field-container'
                                }
                              >
                                <div
                                  className="input-field-content"
                                  style={{ height: '3rem' }}
                                >
                                  <select
                                    style={{
                                      background: '#FBFBFB',
                                      border: '0',
                                    }}
                                    name="type"
                                    value={initialValues.type}
                                    onChange={(e) => {
                                      this.setState({
                                        initialValues: {
                                          ...initialValues,
                                          type: e.target.value,
                                        },
                                      });
                                    }}
                                  >
                                    <option value={3}>مقترحات برامج تدريبية</option>
                                    <option value={1}>خطة التدريب السنوية</option>
                                    <option selected value={2}>
                                      التدريب الذكي
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div
                              className="contact-us-form-row"
                              style={{ alignItems: 'center', padding: '6px' }}
                            >
                              <div className="input-field-label-container">
                                <div className="input-field-label-content">
                                  <label htmlFor="">الصورة </label>
                                </div>
                              </div>
                              <div
                                className={
                                  hasError && !initialValues.file
                                    ? 'input-field-container input-error'
                                    : 'input-field-container'
                                }
                              >
                                <div className="input-field-content">
                                  <div className="input-field-content">
                                    <label
                                      className="input-field-content"
                                      htmlFor="img_course"
                                      style={{
                                        cursor: 'pointer',
                                        width: '100%',
                                        height: '100%',
                                        padding: '7px',
                                      }}
                                    >
                                      {initialValues.file
                                        ? initialValues.file[0].name
                                        : 'الصورة'}
                                    </label>
                                  </div>
                                  <input
                                    accept="image/png, image/jpeg, image/png, image/gif"
                                    onChange={(e) =>
                                      this.setState({
                                        initialValues: {
                                          ...initialValues,
                                          file: e.target.files,
                                        },
                                      })
                                    }
                                    type="file"
                                    name="file"
                                    id="img_course"
                                    hidden
                                  />
                                  {/*<input
                                    name="file"
                                    accept=".png,.jpg"
                                    onChange={(e) =>
                                      this.setState({
                                        initialValues: {
                                          ...initialValues,
                                          file: e.target.files,
                                        },
                                      })
                                    }
                                    type="file"
                                  />*/}
                                </div>
                              </div>
                            </div>
                            <div className="contact-us-form-row">
                              <div className="input-field-label-container">
                                <div className="input-field-label-content">
                                  <label htmlFor="">الوصف</label>
                                </div>
                              </div>
                              <div
                                className={
                                  hasError && !initialValues.description
                                    ? 'input-field-container input-error'
                                    : 'input-field-container'
                                }
                              >
                                <div className="input-field-content">
                                  <textarea
                                    name="description"
                                    onChange={(e) => {
                                      this.setState({
                                        initialValues: {
                                          ...initialValues,
                                          description: e.target.value,
                                        },
                                      });
                                    }}
                                    required={this.validator.message(
                                      'description',
                                      initialValues.message,
                                      'required|string',
                                    )}
                                    className="form-control"
                                    id="exampleFormControlTextarea1"
                                    rows="5"
                                  ></textarea>
                                </div>
                              </div>
                            </div>
                            <div className="contact-us-form-row">
                              <div className="input-field-label-container">
                                <div className="input-field-label-content">
                                  <label htmlFor="">الهدف</label>
                                </div>
                              </div>
                              <div className="input-field-container">
                                <div className="input-field-content">
                                  <input
                                    name="training_goal"
                                    onChange={(e) => {
                                      this.setState({
                                        initialValues: {
                                          ...initialValues,
                                          training_goal: e.target.value,
                                        },
                                      });
                                    }}
                                    value={initialValues.training_goal}
                                    required={this.validator.message(
                                      'training_goal',
                                      initialValues.message,
                                      'required|string',
                                    )}
                                    className="form-control"
                                    id="exampleFormControlTextarea1"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="contact-us-form-row">
                              <div
                                style={{
                                  display: 'flex',
                                }}
                                className="input-field-label-container"
                              >
                                <div
                                  style={{
                                    width: '80%',
                                  }}
                                  className="input-field-label-content"
                                >
                                  <label htmlFor="">المحاور</label>
                                </div>
                                <button
                                  type="button"
                                  onClick={() => this.handleAddSection()}
                                  style={{
                                    borderRadius: '10px',
                                    padding: '0',
                                    width: '30%',
                                    height: '26px',
                                    marginTop: '0',
                                  }}
                                  className="btn btn-success"
                                >
                                  +
                                </button>
                              </div>
                              <div
                                style={{
                                  display: 'bloc',
                                  width: '100%',
                                }}
                              >
                                {initialValues.sections.map((section) => {
                                  countSection++;
                                  return (
                                    <div style={{ width: '100%' }}>
                                      <strong style={{ color: 'red' }}>
                                        المحور رقم {countSection}{' '}
                                      </strong>
                                      <button
                                        type="button"
                                        onClick={() =>
                                          this.handleDeleteSection(section.id)
                                        }
                                        style={{
                                          borderRadius: '10px',
                                          padding: '0',
                                          width: '10%',
                                          height: '26px',
                                          marginTop: '0',
                                          marginRight: '8%',
                                        }}
                                        className="btn btn-red"
                                      >
                                        X
                                      </button>
                                      <div
                                        style={{ marginTop: '2%' }}
                                        className={
                                          hasError && !section.name
                                            ? 'input-field-container input-error'
                                            : 'input-field-container'
                                        }
                                      >
                                        <div className="input-field-content">
                                          <input
                                            name="name"
                                            onChange={(e) =>
                                              this.handleChangeInputSection(
                                                section.id,
                                                e.target.value,
                                                'name',
                                              )
                                            }
                                            required={this.validator.message(
                                              'name',
                                              initialValues.name,
                                              'required|string',
                                            )}
                                            placeholder="الأسم"
                                            type="text"
                                          />
                                        </div>
                                      </div>
                                      <br />
                                      <div className="input-field-container">
                                        <div className="input-field-content">
                                          <input
                                            name="type"
                                            onChange={(e) =>
                                              this.handleChangeInputSection(
                                                section.id,
                                                e.target.value,
                                                'type',
                                              )
                                            }
                                            required={this.validator.message(
                                              'type',
                                              initialValues.type,
                                              'required|string',
                                            )}
                                            placeholder="نوع "
                                            type="number"
                                          />
                                        </div>
                                      </div>
                                      <br />
                                      <div className="input-field-container">
                                        <div className="input-field-content">
                                          <input
                                            onChange={(e) =>
                                              this.handleChangeInputSection(
                                                section.id,
                                                e.target.value,
                                                'order',
                                              )
                                            }
                                            name="order"
                                            required={this.validator.message(
                                              'order',
                                              initialValues.order,
                                              'required|string',
                                            )}
                                            placeholder="ترتيب المحور"
                                            type="number"
                                          />
                                        </div>
                                      </div>

                                      <br />
                                      <div
                                        style={{ display: 'flex' }}
                                        className="input-field-label-container"
                                      >
                                        <div
                                          style={{ width: '80%' }}
                                          className="input-field-label-content"
                                        >
                                          <label htmlFor="">عناصر المحور</label>
                                        </div>
                                        <button
                                          type="button"
                                          onClick={() =>
                                            this.handleAddSectionItem(section.id)
                                          }
                                          style={{
                                            borderRadius: '10px',
                                            padding: '0',
                                            width: '30%',
                                            height: '26px',
                                            marginTop: '0',
                                          }}
                                          className="btn btn-success"
                                        >
                                          +
                                        </button>
                                      </div>
                                      <br />
                                      <div style={{ marginRight: '5%' }}>
                                        {section.section_items.map((section_item) => {
                                          countSectionItem++;
                                          return (
                                            <>
                                              <strong style={{ color: 'red' }}>
                                                العنصر رقم {section_item.id}{' '}
                                              </strong>
                                              <button
                                                type="button"
                                                onClick={() =>
                                                  this.handleDeleteSectionItem(
                                                    section.id,
                                                    section_item.id,
                                                  )
                                                }
                                                style={{
                                                  borderRadius: '10px',
                                                  padding: '0',
                                                  width: '10%',
                                                  height: '26px',
                                                  marginTop: '0',
                                                  marginRight: '8%',
                                                }}
                                                className="btn btn-red"
                                              >
                                                X
                                              </button>
                                              <div
                                                className={
                                                  hasError && !section_item.doc_name
                                                    ? 'input-field-container input-error'
                                                    : 'input-field-container'
                                                }
                                              >
                                                <div className="input-field-content">
                                                  <input
                                                    name="doc_name"
                                                    onChange={(e) =>
                                                      this.handleChangeInputSectionItem(
                                                        section.id,
                                                        section_item.id,
                                                        e.target.value,
                                                        'doc_name',
                                                      )
                                                    }
                                                    required={this.validator.message(
                                                      'doc_name',
                                                      initialValues.doc_name,
                                                      'required|string',
                                                    )}
                                                    placeholder="الاسم"
                                                    type="text"
                                                  />
                                                </div>
                                              </div>
                                              <br />
                                              <div
                                                className={
                                                  hasError && !section_item.order
                                                    ? 'input-field-container input-error'
                                                    : 'input-field-container'
                                                }
                                              >
                                                <div className="input-field-content">
                                                  <input
                                                    name="order"
                                                    onChange={(e) =>
                                                      this.handleChangeInputSectionItem(
                                                        section.id,
                                                        section_item.id,
                                                        e.target.value,
                                                        'order',
                                                      )
                                                    }
                                                    required={this.validator.message(
                                                      'order',
                                                      initialValues.doc_name,
                                                      'required|string',
                                                    )}
                                                    placeholder="ترتيب العنصر "
                                                    min="1"
                                                    type="number"
                                                  />
                                                </div>
                                              </div>
                                              <br />
                                              <div className="input-field-container">
                                                <div className="input-field-content">
                                                  <select
                                                    style={{
                                                      background: '#FBFBFB',
                                                      border: '0',
                                                    }}
                                                    name="type"
                                                    onChange={(e) =>
                                                      this.handleChangeInputSectionItem(
                                                        section.id,
                                                        section_item.id,
                                                        e.target.value,
                                                        'type',
                                                      )
                                                    }
                                                  >
                                                    <option value="video">Video</option>
                                                    <option value="pdf">PDF</option>
                                                    <option value="text">Text</option>
                                                    <option selected value="">
                                                      Autre
                                                    </option>
                                                  </select>
                                                  {/*<input
                                                  name="type"

                                                  required={this.validator.message(
                                                    'type',
                                                    initialValues.type,
                                                    'required|string',
                                                  )}
                                                  placeholder="type"
                                                  type="number"
                                                />*/}
                                                </div>
                                              </div>
                                              <br />
                                              {section_item.type === 'video' ? (
                                                <div className="input-field-container">
                                                  <div className="input-field-content">
                                                    <input
                                                      style={{ width: '100%' }}
                                                      name="doc"
                                                      onChange={(e) =>
                                                        this.handleChangeInputSectionItem(
                                                          section.id,
                                                          section_item.id,
                                                          e.target.value,
                                                          'doc',
                                                        )
                                                      }
                                                      required={this.validator.message(
                                                        'doc',
                                                        initialValues.type,
                                                        'required|string',
                                                      )}
                                                      placeholder="الاسم"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                              ) : section_item.type === 'pdf' ? (
                                                <div className="input-field-container">
                                                  <div className="input-field-container">
                                                    <div
                                                      style={{
                                                        display: initialValues.copy_institution_profile
                                                          ? 'none'
                                                          : '',
                                                      }}
                                                      className="input-field-content"
                                                    >
                                                      <label
                                                        className="input-field-content"
                                                        htmlFor={`copy_institution_profile-${section.id}_${section_item.id}`}
                                                        style={{
                                                          cursor: 'pointer',
                                                          width: '100%',
                                                          height: '100%',
                                                          padding: '7px',
                                                        }}
                                                      >
                                                        Img
                                                      </label>
                                                    </div>
                                                    <input
                                                      accept="image/png, image/jpeg, image/png, image/gif,.pdf"
                                                      onChange={(e) =>
                                                        this.handleChangeFilesElem(
                                                          e.target.files[0],
                                                          [`doc_${section_item.id}`],
                                                          section.id,
                                                          section_item.id,
                                                        )
                                                      }
                                                      type="file"
                                                      name="doc"
                                                      id={`copy_institution_profile-${section.id}_${section_item.id}`}
                                                      hidden
                                                    />
                                                    <div
                                                      style={{
                                                        display: !docs.find(
                                                          (doc) =>
                                                            doc.id === section_item.id,
                                                        )
                                                          ? 'none'
                                                          : 'flex',
                                                      }}
                                                      className="input-field-content"
                                                    >
                                                      <input
                                                        disabled={true}
                                                        value={
                                                          docs.find(
                                                            (doc) =>
                                                              doc.id === section_item.id,
                                                          )
                                                            ? docs.find(
                                                                (doc) =>
                                                                  doc.id ===
                                                                  section_item.id,
                                                              )[`doc_${section_item.id}`]
                                                                .name
                                                            : ''
                                                        }
                                                        type="text"
                                                      />
                                                      <span
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => {
                                                          this.handleDeletedFilesElem(
                                                            section_item.id,
                                                          );
                                                        }}
                                                      >
                                                        X
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>
                                              ) : section_item.type === '' ? (
                                                ''
                                              ) : (
                                                <div className="input-field-container">
                                                  <div className="input-field-content">
                                                    <textarea
                                                      style={{ width: '100%' }}
                                                      name="doc"
                                                      onChange={(e) =>
                                                        this.handleChangeInputSectionItem(
                                                          section.id,
                                                          section_item.id,
                                                          e.target.value,
                                                          'doc',
                                                        )
                                                      }
                                                      required={this.validator.message(
                                                        'doc',
                                                        initialValues.type,
                                                        'required|string',
                                                      )}
                                                    ></textarea>
                                                  </div>
                                                </div>
                                              )}
                                              <br />
                                              <div
                                                style={{ display: 'flex' }}
                                                className="input-field-label-container"
                                              >
                                                <div
                                                  style={{ width: '80%' }}
                                                  className="input-field-label-content"
                                                >
                                                  <label htmlFor="">الاسئلة</label>
                                                </div>
                                                <button
                                                  type="button"
                                                  onClick={() =>
                                                    this.handleAddQuestion(
                                                      section.id,
                                                      section_item.id,
                                                    )
                                                  }
                                                  style={{
                                                    borderRadius: '10px',
                                                    padding: '0',
                                                    width: '30%',
                                                    height: '26px',
                                                    marginTop: '0',
                                                  }}
                                                  className="btn btn-success"
                                                >
                                                  +
                                                </button>
                                              </div>
                                              <br />
                                              <div style={{ marginRight: '5%' }}>
                                                {section_item.questions.map(
                                                  (question) => {
                                                    return (
                                                      <>
                                                        <strong style={{ color: 'red' }}>
                                                          السؤال رقم {question.id}{' '}
                                                        </strong>
                                                        <button
                                                          type="button"
                                                          onClick={() =>
                                                            this.handleDeleteQuestion(
                                                              section.id,
                                                              section_item.id,
                                                              question.id,
                                                            )
                                                          }
                                                          style={{
                                                            borderRadius: '10px',
                                                            padding: '0',
                                                            width: '10%',
                                                            height: '26px',
                                                            marginTop: '0',
                                                            marginRight: '8%',
                                                          }}
                                                          className="btn btn-red"
                                                        >
                                                          X
                                                        </button>
                                                        <br />
                                                        <div className="input-field-container">
                                                          <div className="input-field-content">
                                                            <input
                                                              name="content"
                                                              onChange={(e) =>
                                                                this.handleChangeInputQuestion(
                                                                  section.id,
                                                                  section_item.id,
                                                                  question.id,
                                                                  e.target.value,
                                                                  'content',
                                                                )
                                                              }
                                                              required={this.validator.message(
                                                                'content',
                                                                initialValues.content,
                                                                'required|string',
                                                              )}
                                                              placeholder="السؤال"
                                                              type="text"
                                                            />
                                                          </div>
                                                        </div>
                                                        <br />
                                                        <div className="input-field-container">
                                                          <div className="input-field-content">
                                                            <input
                                                              name="score"
                                                              onChange={(e) =>
                                                                this.handleChangeInputQuestion(
                                                                  section.id,
                                                                  section_item.id,
                                                                  question.id,
                                                                  e.target.value,
                                                                  'score',
                                                                )
                                                              }
                                                              required={this.validator.message(
                                                                'score',
                                                                initialValues.score,
                                                                'required|string',
                                                              )}
                                                              placeholder="درجة"
                                                              type="number"
                                                            />
                                                          </div>
                                                        </div>
                                                        <br />
                                                        <div className="input-field-container">
                                                          <div className="input-field-content">
                                                            <input
                                                              name="order"
                                                              onChange={(e) =>
                                                                this.handleChangeInputQuestion(
                                                                  section.id,
                                                                  section_item.id,
                                                                  question.id,
                                                                  e.target.value,
                                                                  'order',
                                                                )
                                                              }
                                                              required={this.validator.message(
                                                                'order',
                                                                initialValues.order,
                                                                'required|string',
                                                              )}
                                                              placeholder="ترتيب السؤال"
                                                              type="number"
                                                            />
                                                          </div>
                                                        </div>
                                                        <br />
                                                        <div className="input-field-container">
                                                          <div className="input-field-content">
                                                            <input
                                                              name="answer"
                                                              onChange={(e) =>
                                                                this.handleChangeInputQuestion(
                                                                  section.id,
                                                                  section_item.id,
                                                                  question.id,
                                                                  e.target.value,
                                                                  'answer',
                                                                )
                                                              }
                                                              required={this.validator.message(
                                                                'answer',
                                                                initialValues.answer,
                                                                'required|string',
                                                              )}
                                                              placeholder="الاجابة الصحيحة"
                                                              type="text"
                                                            />
                                                          </div>
                                                        </div>
                                                        <br />
                                                        <div
                                                          className="input-field-label-container"
                                                          style={{ display: 'flex' }}
                                                        >
                                                          <div
                                                            style={{
                                                              width: '70%',
                                                            }}
                                                            className="input-field-label-content"
                                                          >
                                                            <label htmlFor="">
                                                              المقترحات
                                                            </label>
                                                          </div>
                                                          <button
                                                            type="button"
                                                            onClick={() =>
                                                              this.handleAddProposition(
                                                                section.id,
                                                                section_item.id,
                                                                question.id,
                                                              )
                                                            }
                                                            style={{
                                                              borderRadius: '10px',
                                                              padding: '0',
                                                              width: '30%',
                                                              height: '26px',
                                                              marginTop: '0',
                                                            }}
                                                            className="btn btn-success"
                                                          >
                                                            +
                                                          </button>
                                                        </div>
                                                        <br />

                                                        <div style={{ display: 'block' }}>
                                                          {question.propositions.map(
                                                            (proposition) => {
                                                              return (
                                                                <>
                                                                  <strong
                                                                    style={{
                                                                      color: 'red',
                                                                    }}
                                                                  >
                                                                    المقترح رقم{' '}
                                                                    {proposition.id}{' '}
                                                                  </strong>
                                                                  <button
                                                                    type="button"
                                                                    onClick={() =>
                                                                      this.handleDeleteProposition(
                                                                        section.id,
                                                                        section_item.id,
                                                                        question.id,
                                                                        proposition.id,
                                                                      )
                                                                    }
                                                                    style={{
                                                                      borderRadius:
                                                                        '10px',
                                                                      padding: '0',
                                                                      width: '10%',
                                                                      height: '26px',
                                                                      marginTop: '0',
                                                                      marginRight: '8%',
                                                                    }}
                                                                    className="btn btn-red"
                                                                  >
                                                                    X
                                                                  </button>
                                                                  <div
                                                                    style={{
                                                                      display: 'flex',
                                                                    }}
                                                                  >
                                                                    <div
                                                                      style={{
                                                                        width: '60%',
                                                                      }}
                                                                      className="input-field-container"
                                                                    >
                                                                      <div className="input-field-content">
                                                                        <input
                                                                          name="content"
                                                                          onChange={(e) =>
                                                                            this.handleChangeInputProposition(
                                                                              section.id,
                                                                              section_item.id,
                                                                              question.id,
                                                                              proposition.id,
                                                                              e.target
                                                                                .value,
                                                                              'content',
                                                                            )
                                                                          }
                                                                          required={this.validator.message(
                                                                            'content',
                                                                            initialValues.content,
                                                                            'required|string',
                                                                          )}
                                                                          placeholder="المحتوى"
                                                                          type="text"
                                                                        />
                                                                      </div>
                                                                    </div>
                                                                    <div
                                                                      style={{
                                                                        marginRight: '2%',
                                                                        width: '20%',
                                                                      }}
                                                                      className="input-field-container"
                                                                    >
                                                                      <div className="input-field-content">
                                                                        <select
                                                                          name="answer"
                                                                          onChange={(e) =>
                                                                            this.handleChangeInputProposition(
                                                                              section.id,
                                                                              section_item.id,
                                                                              question.id,
                                                                              proposition.id,
                                                                              e.target
                                                                                .value,
                                                                              'answer',
                                                                            )
                                                                          }
                                                                          style={{
                                                                            background:
                                                                              '#FBFBFB',
                                                                            border: '0',
                                                                          }}
                                                                        >
                                                                          <option value="">
                                                                            اختر
                                                                          </option>
                                                                          <option
                                                                            value={0}
                                                                            selected
                                                                          >
                                                                            اجابة خاطئة
                                                                          </option>
                                                                          <option
                                                                            value={1}
                                                                          >
                                                                            اجابة صحيحة
                                                                          </option>
                                                                        </select>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                  <br />
                                                                  {section_item.questions
                                                                    .length > 1 ? (
                                                                    <hr
                                                                      style={{
                                                                        border:
                                                                          '2px solid black',
                                                                      }}
                                                                    />
                                                                  ) : (
                                                                    ''
                                                                  )}
                                                                </>
                                                              );
                                                            },
                                                          )}
                                                        </div>
                                                      </>
                                                    );
                                                  },
                                                )}
                                              </div>

                                              <hr style={{ border: '2px solid black' }} />
                                            </>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                            <div className="contact-us-form-row">
                              <button
                                type="button"
                                onClick={() => this.submitForm(initialValues, docs)}
                                style={{
                                  borderRadius: '10px',
                                }}
                                className="btn btn-red"
                              >
                                إرسال
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(FormTraining);
