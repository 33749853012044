import React, { Component } from 'react';
import SliderHeader from './composents/slider-header';
import actions from '../../js/actions';
import actionsMember from '../members/store/actions';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import Training from './composents/training';
import { Link } from 'react-router-dom';
import Group2191 from '../../assets/img/Group 2191.png';
import Group2194 from '../../assets/img/Group 2194.png';
import Group2197 from '../../assets/img/Group 2197.png';
import Headers from '../../layouts/headers';
import UAQ_Infographic_vision from '../../assets/img/UAQ Vision image.jpg';
import UAQ_Infographic_country from '../../assets/img/uaq_country_banner.jpeg';

const mapStateToProps = (state) => ({
  isLoadingTrainings: state.AppReducer.isLoadingTrainings,
  trainings: state.AppReducer.trainings,
  isLoadingTrainingsType: state.AppReducer.isLoadingTrainingsType,
  trainingsType: state.AppReducer.trainingsType,
  params: state.AppReducer.params,
  isLoadingCertifiedMembers: state.CertifiedMemberReducer.isLoadingCertifiedMembers,
  certifiedMembers: state.CertifiedMemberReducer.certifiedMembers,
  paramsCertifiedMembers: state.CertifiedMemberReducer.params,
  isAuthorized: state.AppReducer.isAuthorized,
});

const mapDispatchToProps = (dispatch) => ({
  getAllTrainings: (params, type) => dispatch(actions.getAllTrainings(params, type)),
  getAllTrainingsType: (params, type) =>
    dispatch(actions.getAllTrainingsType(params, type)),
  getAllCertifiedMembers: (params) =>
    dispatch(actionsMember.getAllCertifiedMembers(params)),
});

class Dashboard extends Component {
  constructor(props) {
    super(props);
    document.title = 'الرئيسية';
    props.getAllTrainings(props.params, 2);
    props.getAllTrainingsType(props.params, 1);
    props.getAllCertifiedMembers(props.paramsCertifiedMembers);
  }
  render() {
    const { trainings, trainingsType, isAuthorized, certifiedMembers } = this.props;
    let i = 0;
    let i2 = 0;
    return (
      <section className="body-section">
        <Headers isAuthorized={isAuthorized} />
        <SliderHeader />
        <div className="bs-main-container">
          <div className="bs-main-content">
          <div className="bs-subsection subsection--white">
              <div className="bs-subesction-content">
                <div className="bs-subsection-elem-wrapper" style={{ width: '70%' }}>
                <img  
                            id="landingVisionPic"
                            src={UAQ_Infographic_vision}
                            style={{width:"100%"}}
                          ></img>
                </div>
              </div>
            </div>
            <div className="bs-subsection subsection--gray">
              <div className="bs-subesction-content">
                <div className="bs-subsection-elem">
                  <div className="bs-subsection-elem-title">
                    <span>مقترحات برامج تدريبية</span>
                  </div>

                  <div className="bsSliderInter">
                    <div
                      id="bsSliderInter"
                      className="carousel slide"
                      data-ride="carousel"
                    >
                      <a
                        className="carousel-control-next"
                        href="#bsSliderInter"
                        role="button"
                        data-slide="next"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="29.437"
                          height="50.216"
                          viewBox="0 0 29.437 50.216"
                        >
                          <defs>
                            <filter
                              id="Path_2"
                              x="0"
                              y="0"
                              width="29.437"
                              height="50.216"
                              filterUnits="userSpaceOnUse"
                            >
                              <feOffset dy="3" input="SourceAlpha" />
                              <feGaussianBlur stdDeviation="3" result="blur" />
                              <feFlood floodOpacity="0.161" />
                              <feComposite operator="in" in2="blur" />
                              <feComposite in="SourceGraphic" />
                            </filter>
                          </defs>
                          <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Path_2)">
                            <path
                              id="Path_2-2"
                              data-name="Path 2"
                              d="M-12300.5-6205.5l9.059,14.366-8,15.12"
                              transform="translate(12310.88 6212.88)"
                              fill="none"
                              stroke="#ea0029"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                            />
                          </g>
                        </svg>
                      </a>
                      <div className="carousel-inner">
                        <div className="carousel-item active">
                          <div className="container">
                            {trainingsType &&
                            trainingsType.data &&
                            trainingsType.data.length > 0 &&
                            trainingsType.data.map((training) => {
                              i2++;
                              if (i2 <= 4)
                                return (
                                  <Training key={training.id} training={training} />
                                );
                            })}
                          </div>
                        </div>
                        {trainingsType && trainingsType.length >= 4 ? (
                          <div className="carousel-item">
                            <div className="container">
                              {trainingsType &&
                              trainingsType.data &&
                              trainingsType.data.length > 0 &&
                              trainingsType.data.map((training) => {
                                i2++;
                                if (i2 > 4)
                                  return (
                                    <Training key={training.id} training={training} />
                                  );
                              })}
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                      <a
                        className="carousel-control-prev"
                        href="#bsSliderInter"
                        role="button"
                        data-slide="prev"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="29.437"
                          height="50.216"
                          viewBox="0 0 29.437 50.216"
                        >
                          <defs>
                            <filter
                              id="Path_2"
                              x="0"
                              y="0"
                              width="29.437"
                              height="50.216"
                              filterUnits="userSpaceOnUse"
                            >
                              <feOffset dy="3" input="SourceAlpha" />
                              <feGaussianBlur stdDeviation="3" result="blur" />
                              <feFlood floodOpacity="0.161" />
                              <feComposite operator="in" in2="blur" />
                              <feComposite in="SourceGraphic" />
                            </filter>
                          </defs>
                          <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Path_2)">
                            <path
                              id="Path_2-2"
                              data-name="Path 2"
                              d="M-12300.5-6205.5l9.059,14.366-8,15.12"
                              transform="translate(-12281.44 -6168.66) rotate(180)"
                              fill="none"
                              stroke="#ea0029"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                            />
                          </g>
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="bs-subsection-elem">
                  <div className="bs-subsection-elem-title">
                    <span>التدريب الذكي</span>
                  </div>

                  <div className="bsSliderInter">
                    <div
                      id="bsSliderInterSmart"
                      className="carousel slide"
                      data-ride="carousel"
                    >
                      <a
                        className="carousel-control-next"
                        href="#bsSliderInterSmart"
                        role="button"
                        data-slide="next"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="29.437"
                          height="50.216"
                          viewBox="0 0 29.437 50.216"
                        >
                          <defs>
                            <filter
                              id="Path_2"
                              x="0"
                              y="0"
                              width="29.437"
                              height="50.216"
                              filterUnits="userSpaceOnUse"
                            >
                              <feOffset dy="3" input="SourceAlpha" />
                              <feGaussianBlur stdDeviation="3" result="blur" />
                              <feFlood floodOpacity="0.161" />
                              <feComposite operator="in" in2="blur" />
                              <feComposite in="SourceGraphic" />
                            </filter>
                          </defs>
                          <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Path_2)">
                            <path
                              id="Path_2-2"
                              data-name="Path 2"
                              d="M-12300.5-6205.5l9.059,14.366-8,15.12"
                              transform="translate(12310.88 6212.88)"
                              fill="none"
                              stroke="#ea0029"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                            />
                          </g>
                        </svg>
                      </a>
                      <div className="carousel-inner">
                        <div className="carousel-item active">
                          <div className="container">
                            {trainings &&
                              trainings.data &&
                              trainings.data.length > 0 &&
                              trainings.data.map((training) => {
                                i++;
                                if (i <= 4)
                                  return (
                                    <Training key={training.id} training={training} />
                                  );
                              })}
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="container">
                            {trainings &&
                              trainings.data &&
                              trainings.data.length > 0 &&
                              trainings.data.map((training) => {
                                i++;
                                if (i > 4)
                                  return (
                                    <Training key={training.id} training={training} />
                                  );
                              })}
                          </div>
                        </div>
                      </div>
                      <a
                        className="carousel-control-prev"
                        href="#bsSliderInterSmart"
                        role="button"
                        data-slide="prev"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="29.437"
                          height="50.216"
                          viewBox="0 0 29.437 50.216"
                        >
                          <defs>
                            <filter
                              id="Path_2"
                              x="0"
                              y="0"
                              width="29.437"
                              height="50.216"
                              filterUnits="userSpaceOnUse"
                            >
                              <feOffset dy="3" input="SourceAlpha" />
                              <feGaussianBlur stdDeviation="3" result="blur" />
                              <feFlood floodOpacity="0.161" />
                              <feComposite operator="in" in2="blur" />
                              <feComposite in="SourceGraphic" />
                            </filter>
                          </defs>
                          <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Path_2)">
                            <path
                              id="Path_2-2"
                              data-name="Path 2"
                              d="M-12300.5-6205.5l9.059,14.366-8,15.12"
                              transform="translate(-12281.44 -6168.66) rotate(180)"
                              fill="none"
                              stroke="#ea0029"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                            />
                          </g>
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>{/*
                <div className="bs-subsection-elem">
                  <div className="bs-subsection-elem-title">
                    <span>العضويات المعتمدة</span>
                  </div>
                  <div className="bs-subsection-elem-cards-container">
                    <div className="bs-subsection-elem-cards-content">
                      {certifiedMembers &&
                        certifiedMembers.length > 0 &&
                        certifiedMembers.map((certified_member) => {
                          return (
                            <Link
                              style={{
                                color: 'black',
                                textDecoration: 'none',
                              }}
                              to={`/registered-members/${certified_member.type}`}
                            >
                              <div
                                key={certified_member.id}
                                className="bs-subsection-elem-card"
                              >
                                <div className="bs-cards-container">
                                  <div className="bs-cards-content">
                                    <img
                                      src={
                                        certified_member.type === 1
                                          ? Group2191
                                          : certified_member.type === 2
                                          ? Group2194
                                          : Group2197
                                      }
                                      alt=""
                                    />
                                  </div>
                                </div>
                                <div className="bs-card-text">
                                  <span>{certified_member.name}</span>
                                </div>
                              </div>
                            </Link>
                          );
                        })}
                    </div>
                  </div>
                </div>*/}
              </div>
            </div>
            <div className="bs-subsection subsection--white">
              <div className="bs-subesction-content">
                <div className="bs-subsection-elem-wrapper" style={{ width: '100%' }}>
                <img
                            style={{ width: '100%' }}
                            src={UAQ_Infographic_country}
                          ></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard));
