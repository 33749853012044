import React, { Component } from 'react';
import actions from '../../js/actions';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import Path5 from '../../assets/img/Path 5.png';
import { host} from '../../utils/constants';
import Modal from '../../componenets/modal';
import { CircularProgress } from '@material-ui/core';
import Headers from '../../layouts/headers';
import { getTimeFromMins } from "../../utils/helpers";
const mapStateToProps = (state) => ({
  training: state.AppReducer.training,
  isAuthorized: state.AppReducer.isAuthorized,
  isLoadingTraining: state.AppReducer.isLoadingTraining,
  account: state.AppReducer.account,
});

const mapDispatchToProps = (dispatch) => ({
  getTrainingById: (id) => dispatch(actions.getTrainingById(id)),
  submitUserDirectTraining: (values) =>
    dispatch(actions.submitUserDirectTraining(values)),
});
class DirectTrainingDetails extends Component {
  constructor(props) {
    super(props);
    props.getTrainingById(props.match.params.id);
    this.state = {
      video: '',
    };
  }
  render() {
    const { training, isAuthorized, account } = this.props;
    let trainingDescription;
    if (training) {
      trainingDescription = (training.description + '').split('N__L').map((i) => (
        <span>
          {i}
          <br></br>
        </span>
      ));
    }
    let i = -2;
    if (!training)
      return (
        <section className="body-section registered-user-view ctlg-courses-cpy">
          <div className="bs-main-container">
            <div className="bs-main-content">
              <div className="registered-user-view-container">
                <div className="registered-user-view-content">
                  <CircularProgress
                    style={{
                      textAlign: 'center',
                      marginTop: '25%',
                      width: '40px',
                      color: '#d85555',
                      marginRight: '277px',
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      );
    return (
      <section className="body-section">
        {' '}
        <Headers isAuthorized={isAuthorized} />
        <section className="sidebar-body-container registered-user-view-main-cont">
          <Modal
            id="confirmation"
            size="modal-md"
            data={
              <>
                <div className="modal-header">
                  <h5 className="modal-title">تسجيل في الدورة</h5>
                </div>
                <div className="modal-body">
                  <p>هل ترغب فعلاً في التسجيل في هذه الدورة ؟</p>
                </div>
                <div className="modal-footer" style={{ display: 'block' }}>
                  <button
                    onClick={() => {
                      document.getElementById('close-modal-confirmation').click();
                      this.props.submitUserDirectTraining({
                        course_id: training.id,
                      });
                    }}
                    style={{ width: '50%', fontSize: '15px' }}
                    type="button"
                    className="btn btn-success"
                  >
                    نعم
                  </button>
                  <button
                    id="close-modal-confirmation"
                    style={{ width: '40%', fontSize: '15px' }}
                    type="button"
                    className="btn btn-info"
                    data-dismiss="modal"
                  >
                    لا
                  </button>
                </div>
              </>
            }
          />
          <section className="body-section registered-user-view ctlg-courses-cpy">
            <div className="bs-main-container">
              <div className="bs-main-content">
                <div className="registered-user-view-container">
                  <div className="SideNavMenu-btn--Mob">
                    <div className="SideNavMenu-btn--Mob-content">
                      <button
                        type="button"
                        data-toggle="modal"
                        data-target=".sidenavbar-modal"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="60.171"
                          height="60.171"
                          viewBox="0 0 60.171 60.171"
                        >
                          <g
                            id="Group_1515"
                            data-name="Group 1515"
                            transform="translate(-1777.986 -170.986)"
                          >
                            <path
                              id="Path_799"
                              data-name="Path 799"
                              d="M1808.072,166.157h0a30.086,30.086,0,0,1-30.086-30.085h0a30.086,30.086,0,0,1,30.086-30.085h0a30.085,30.085,0,0,1,30.085,30.085h0A30.085,30.085,0,0,1,1808.072,166.157Z"
                              transform="translate(0 65)"
                              fill="#f0f0f0"
                              style={{ mixBlendMode: 'multiply', isolation: 'isolate' }}
                            ></path>
                            <path
                              id="Path_800"
                              data-name="Path 800"
                              d="M1812.421,163.205h0a22.785,22.785,0,0,1-22.785-22.785h0a22.785,22.785,0,0,1,22.785-22.785h0a22.785,22.785,0,0,1,22.785,22.785h0A22.785,22.785,0,0,1,1812.421,163.205Z"
                              transform="translate(-4.349 60.651)"
                              fill="#fff"
                            ></path>
                            <g
                              id="Group_1082"
                              data-name="Group 1082"
                              transform="translate(1796.957 189.169)"
                            >
                              <g
                                id="Group_1080"
                                data-name="Group 1080"
                                transform="translate(4.41)"
                              >
                                <path
                                  id="Path_805"
                                  data-name="Path 805"
                                  d="M1821.8,147.965a6.46,6.46,0,0,1-4.308-1.641,6.338,6.338,0,0,1-2.2-4.819,6.506,6.506,0,0,1,13.012,0,6.338,6.338,0,0,1-2.208,4.824A6.443,6.443,0,0,1,1821.8,147.965Zm0-10.315a3.858,3.858,0,0,0-3.855,3.855,3.718,3.718,0,0,0,1.3,2.831,3.9,3.9,0,0,0,5.092.01,3.736,3.736,0,0,0,1.315-2.841A3.906,3.906,0,0,0,1821.8,137.65Z"
                                  transform="translate(-1815.294 -135)"
                                  fill="#ea0029"
                                ></path>
                              </g>
                              <g
                                id="Group_1081"
                                data-name="Group 1081"
                                transform="translate(0 9.015)"
                              >
                                <path
                                  id="Path_806"
                                  data-name="Path 806"
                                  d="M1824.17,162.931h-9.943a5.862,5.862,0,0,1-5.475-3.618,5.943,5.943,0,0,1,1.208-6.542,12.739,12.739,0,0,1,5.4-3.333,1.373,1.373,0,0,1,1.276.28,3.9,3.9,0,0,0,5.079,0,1.37,1.37,0,0,1,1.273-.28,12.7,12.7,0,0,1,5.9,3.892,5.586,5.586,0,0,1,.784,6.04A6.1,6.1,0,0,1,1824.17,162.931Zm-8.669-10.74a10.14,10.14,0,0,0-3.631,2.417,3.342,3.342,0,0,0-.681,3.669,3.214,3.214,0,0,0,3.038,2h9.943a3.414,3.414,0,0,0,3.1-2.024,2.935,2.935,0,0,0-.393-3.209,10.121,10.121,0,0,0-4.032-2.857A6.591,6.591,0,0,1,1815.5,152.192Z"
                                  transform="translate(-1808.258 -149.386)"
                                  fill="#ea0029"
                                ></path>
                              </g>
                            </g>
                          </g>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="29"
                          height="40"
                          viewBox="0 0 29 40"
                        >
                          <defs>
                            <filter
                              id="Polygon_2"
                              x="0"
                              y="0"
                              width="29"
                              height="40"
                              filterUnits="userSpaceOnUse"
                            >
                              <feOffset dy="3" input="SourceAlpha"></feOffset>
                              <feGaussianBlur
                                stdDeviation="3"
                                result="blur"
                              ></feGaussianBlur>
                              <feFlood flood-opacity="0.161"></feFlood>
                              <feComposite operator="in" in2="blur"></feComposite>
                              <feComposite in="SourceGraphic"></feComposite>
                            </filter>
                          </defs>
                          <g
                            transform="matrix(1, 0, 0, 1, 0, 0)"
                            filter="url(#Polygon_2)"
                          >
                            <path
                              id="Polygon_2-2"
                              data-name="Polygon 2"
                              d="M10.293.707a1,1,0,0,1,1.414,0l8.586,8.586A1,1,0,0,1,19.586,11H2.414a1,1,0,0,1-.707-1.707Z"
                              transform="translate(9 28) rotate(-90)"
                              fill="#fff"
                            ></path>
                          </g>
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div className="registered-user-view-content">
                    <div className="course-catalogue-container">
                      <div className="course-catalogue-content">
                        <div className="course-catalogue-elem">
                          <div className="course-info-desc">
                            <div className="course-general-info">
                              <div className="course-general-info-content">
                                <div className="course-miniature">
                                  <img src={`${host}${training.picture}`} alt="" />
                                </div>
                                <div className="course-info-regist-container">
                                  <div className="course-info-regist-content">
                                    {training.period ? (
                                      <div className="course-length">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          viewBox="0 0 22 22"
                                        >
                                          <g
                                            id="Group_4"
                                            data-name="Group 4"
                                            transform="translate(-1703 -517.519)"
                                          >
                                            <g
                                              id="Ellipse_40"
                                              data-name="Ellipse 40"
                                              transform="translate(1703 517.519)"
                                              fill="none"
                                              stroke="#000"
                                              strokeWidth="2"
                                            >
                                              <circle
                                                cx="11"
                                                cy="11"
                                                r="11"
                                                stroke="none"
                                              />
                                              <circle
                                                cx="11"
                                                cy="11"
                                                r="10"
                                                fill="none"
                                              />
                                            </g>
                                            <path
                                              id="Path_6"
                                              data-name="Path 6"
                                              d="M-6296-7514.979v6.927h5.139"
                                              transform="translate(8009.593 8036.893)"
                                              fill="#fff"
                                              stroke="#000"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              strokeWidth="1"
                                            />
                                          </g>
                                        </svg>
                                        <span>{getTimeFromMins(training.period)}</span>
                                      </div>
                                    ) : (
                                      ''
                                    )}
                                    <div className="course-info-ratings">
                                      <div className="course-rating">
                                        <img src={Path5} alt="" />
                                        <img src={Path5} alt="" />
                                        <img src={Path5} alt="" />
                                        <img src={Path5} alt="" />
                                        <img src={Path5} alt="" />
                                      </div>
                                    </div>
                                    <div className="register-course-btn">
                                      <button
                                        data-toggle="modal"
                                        data-target="#confirmation"
                                        style={{
                                          border: '1px solid green',
                                          borderRadius: '10px',
                                        }}
                                        /* onClick={() => {
                                          this.props.submitUserDirectTraining({
                                            course_id: training.id,
                                          });
                                        }}*/
                                      >
                                        <button className="btn btn-green">تسجيل</button>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="course-description-container">
                              <div
                                className="course-description-content"
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                }}
                              >
                                <span>
                                  {training.description
                                    ? trainingDescription
                                    : '...قيد الانجاز\n'}
                                </span>
                                {/*<button className="btn btn-green">اقرا المزيد</button>*/}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
      </section>
    );
  }
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DirectTrainingDetails),
);
