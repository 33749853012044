const initialAppState = {
  isLoadingCertifiedMembers: false,
  certifiedMembers: [],
  isLoadingCertifiedMember: false,
  certifiedMember: null,
  params: {
    currentPage: 1,
    limit: 10,
  },
};
const CertifiedMemberReducer = (state = initialAppState, action) => {
  switch (action.type) {
    case 'GET_CERTIFIED_MEMBER_SHIP_REQUESTING':
      return {
        ...state,
        isLoadingCertifiedMember: true,
      };
    case 'GET_CERTIFIED_MEMBER_SHIP_SUCCESS':
      return {
        ...state,
        isLoadingCertifiedMember: false,
        certifiedMember: action.data,
      };
    case 'GET_CERTIFIED_MEMBER_SHIP_FAILURE':
      return {
        ...state,
        isLoadingCertifiedMember: false,
        certifiedMember: null,
      };

    case 'GET_ALL_CERTIFIED_MEMBER_SHIP_REQUESTING':
      return {
        ...state,
        isLoadingCertifiedMembers: true,
      };
    case 'GET_ALL_CERTIFIED_MEMBER_SHIP_SUCCESS':
      return {
        ...state,
        isLoadingCertifiedMembers: false,
        certifiedMembers: action.data,
      };
    case 'GET_ALL_CERTIFIED_MEMBER_SHIP_FAILURE':
      return {
        ...state,
        isLoadingCertifiedMembers: false,
        certifiedMembers: [],
      };

    case 'PAGINATION_CERTIFIED_MEMBER_SHIP_PARAMS': {
      return {
        ...state,
        params: action.params,
      };
    }
    default: {
      return state;
    }
  }
};

export default CertifiedMemberReducer;
