import React, { Component } from 'react';
import imgTraining2 from '../../../assets/img/headway-5QgIuuBxKwM-unsplash.png';
import { Link } from 'react-router-dom';
import { host } from '../../../utils/constants';

class Training extends Component {
  render() {
    const { training } = this.props;
    return (
      <div key={training} className="carousel-elem-box">
        <Link
          style={{
            color: 'black',
            textDecoration: 'none',
          }}
          to={
            training.sections && training.sections.length > 0
              ? `/intelligent/training/${training.id}`
              : '#'
          }
        >
          <div className="carousel-elem-box-img">
            <img src={`${host}${training.picture}`} alt="" />
          </div>
        </Link>
      </div>
    );
  }
}

export default Training;
