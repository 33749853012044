import React, { Component } from 'react';
import actions from './store/actions';
import actionsCertifiedMember from '../members/store/actions';
import { MemoryRouter, Route, withRouter } from 'react-router';
import { connect } from 'react-redux';
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import { Link } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import Path113 from '../../assets/img/memebers-icons/Path 113.png';
import Path111 from '../../assets/img/memebers-icons/Path 111.png';
import Path112 from '../../assets/img/memebers-icons/Path 112.png';
import Path79 from '../../assets/img/memebers-icons/Path 79.png';
import Headers from '../../layouts/headers';

const mapStateToProps = (state) => ({
  isLoadingMembers: state.MemberReducer.isLoadingMembers,
  members: state.MemberReducer.members,
  isLoadingCertifiedMember: state.CertifiedMemberReducer.isLoadingCertifiedMember,
  certifiedMember: state.CertifiedMemberReducer.certifiedMember,
  params: state.MemberReducer.params,
  isAuthorized: state.AppReducer.isAuthorized,
});

const mapDispatchToProps = (dispatch) => ({
  getAllMembers: (params, type) => dispatch(actions.getAllMembers(params, type)),
  getCertifiedMember: (id) => dispatch(actionsCertifiedMember.getCertifiedMember(id)),
  changeSearchedString: (params, type) => {
    dispatch(actions.changeSearchedString(params));
    dispatch(actions.getAllMembers(params, type));
  },
});
class RegisteredMembers extends Component {
  constructor(props) {
    super(props);
    props.getAllMembers(props.params, props.match.params.type);
    props.getCertifiedMember(props.match.params.type);
  }
  makeHttpRequestWithPage = (event, page) => {
    this.props.changeSearchedString(
      {
        ...this.props.params,
        currentPage: page ? page : this.props.params.currentPage - 1,
        limit: this.props.params.limit,
      },
      this.props.match.params.type,
    );
  };
  render() {
    const {
      members,
      certifiedMember,
      isAuthorized,
      isLoadingMembers,
      params,
    } = this.props;
    if (!certifiedMember) {
      return (
        <div className="bs-main-container  e-lib-body">
          <div className="bs-main-content">
            <div className="profil-container">
              <CircularProgress
                style={{
                  textAlign: 'center',
                  marginTop: '9%',
                  marginBottom: '18%',
                  width: '40px',
                  color: '#d85555',
                  marginRight: '47%',
                }}
              />
            </div>
          </div>
        </div>
      );
    }
    return (
      <section className="body-section">
        <Headers isAuthorized={isAuthorized} />
        <div className="bs-main-container registered-memebers-body">
          <div className="bs-main-content">
            <div className="bs-main-content-header-sm-tr">
              <div className="bs-main-content-header-title-sm-tr">
                <span>{certifiedMember.name}</span>
              </div>
            </div>
            <div className="cards-container">
              <div className="cards-wrapper">
                <div className="cardes-wrapper-content">
                  <div className="search-sort-container">
                    <div className="search-sort-content">
                      <button
                        onClick={() =>
                          this.props.history.push({
                            pathname: '/member-form',
                            state: {
                              certified_member: certifiedMember,
                            },
                          })
                        }
                        type="button"
                        className="btn btn-dark search-btn-mem"
                      >
                        {certifiedMember.type === 1
                          ? 'طلب عضو معتمد'
                          : certifiedMember.type === 2
                          ? 'طلب مؤسسة معتمدة'
                          : 'طلب مدرب معتمد'}
                      </button>
                      <div className="search-input-field-container">
                        <div className="search-input-field-content">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="19.573"
                            height="19.57"
                            viewBox="0 0 19.573 19.57"
                          >
                            <g
                              id="Group_1071"
                              data-name="Group 1071"
                              transform="translate(-1067.851 -67.83)"
                            >
                              <g id="Group_1070" data-name="Group 1070">
                                <g id="Group_1069" data-name="Group 1069">
                                  <g id="Group_1067" data-name="Group 1067">
                                    <path
                                      id="Path_801"
                                      data-name="Path 801"
                                      d="M1075.74,83.605a7.89,7.89,0,1,1,5.58-13.468h0a7.888,7.888,0,0,1-5.58,13.468Zm0-12.705a4.817,4.817,0,1,0,3.409,1.408A4.812,4.812,0,0,0,1075.74,70.9Z"
                                      fill="#ea0029"
                                    />
                                  </g>
                                  <g id="Group_1068" data-name="Group 1068">
                                    <rect
                                      id="Rectangle_127"
                                      data-name="Rectangle 127"
                                      width="3.071"
                                      height="8.631"
                                      transform="translate(1079.149 81.297) rotate(-44.994)"
                                      fill="#ea0029"
                                    />
                                  </g>
                                </g>
                              </g>
                            </g>
                          </svg>
                          <input
                            onChange={(e) => {
                              this.props.changeSearchedString(
                                {
                                  ...params,
                                  search: e.target.value,
                                },
                                this.props.match.params.type,
                              );
                            }}
                            value={params.search}
                            type="text"
                            placeholder="إكتب للبحث"
                          />
                        </div>
                      </div>
                      <div className="dropdown">
                        <button
                          className="btn btn-secondary dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          تطبيق المرشحات
                        </button>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <a className="dropdown-item" href="#">
                            Action
                          </a>
                          <a className="dropdown-item" href="#">
                            Another action
                          </a>
                          <a className="dropdown-item" href="#">
                            Something else here
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  {isLoadingMembers ? (
                    <CircularProgress
                      style={{
                        textAlign: 'center',
                        marginTop: '9%',
                        marginBottom: '18%',
                        width: '40px',
                        color: '#d85555',
                        marginRight: '47%',
                      }}
                    />
                  ) : (
                    members &&
                    members.data &&
                    members.data.map((member) => {
                      return (
                        <Link
                          key={member.id}
                          style={{
                            color: 'black',
                            textDecoration: 'none',
                          }}
                          to={`/member/${member.id}`}
                        >
                          <div key={member.id} className="cards-wrapper-elem">
                            <div className="card-wrapper-elem-content">
                              <div className="cards-wrapper-img">
                                <img
                                  src={
                                    parseInt(member.picture) === 1
                                      ? Path79
                                      : parseInt(member.picture) === 2
                                      ? Path113
                                      : Path112
                                  }
                                  alt=""
                                />
                              </div>
                              <div className="cards-wrapper-text">
                                <span>{`${member.last_name} ${member.first_name}`}</span>
                              </div>
                            </div>
                          </div>
                        </Link>
                      );
                    })
                  )}
                </div>
              </div>
            </div>
            <div className="pagination-container">
              <div className="pagination-content">
                <MemoryRouter initialEntries={['/inbox']}>
                  <Route>
                    {() => {
                      const page = parseInt(
                        members.current_page || 1,
                        Math.ceil(members.total / members.per_page),
                      );
                      return (
                        <Pagination
                          // color="secondary"
                          page={page}
                          //defaultPage={trainings.current_page}
                          // borderCount={5}
                          hidePrevButton
                          hideNextButton
                          count={Math.ceil(members.total / members.per_page)}
                          onChange={this.makeHttpRequestWithPage}
                          renderItem={(item) => (
                            <PaginationItem
                              className="pagination-content-elem last-pagination-number"
                              component={Link}
                              page={members.current_page}
                              to={`/inbox${item.page === 1 ? '' : `?page=${item.page}`}`}
                              {...item}
                            />
                          )}
                        />
                      );
                    }}
                  </Route>
                </MemoryRouter>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RegisteredMembers),
);
