const initialAppState = {
  isLoadingLibraries: false,
  libraries: [],
  isLoadingItemLibraries: false,
  itemLibraries: [],
  isLoadingLibrary: false,
  library: null,
  params: {
    currentPage: 1,
    limit: 100,
  },
};

const LibraryReducer = (state = initialAppState, action) => {
  switch (action.type) {
    case 'GET_LIBRARY_REQUESTING':
      return {
        ...state,
        isLoadingLibrary: true,
      };
    case 'GET_LIBRARY_SUCCESS':
      return {
        ...state,
        isLoadingLibrary: false,
        library: action.data,
      };
    case 'GET_LIBRARY_FAILURE':
      return {
        ...state,
        isLoadingLibrary: false,
        library: null,
      };

    case 'GET_ALL_ITEM_LIBRARIES_REQUESTING':
      return {
        ...state,
        isLoadingItemLibraries: true,
      };
    case 'GET_ALL_ITEM_LIBRARIES_SUCCESS':
      return {
        ...state,
        isLoadingItemLibraries: false,
        itemLibraries: action.data,
      };
    case 'GET_ALL_ITEM_LIBRARIES_FAILURE':
      return {
        ...state,
        isLoadingItemLibraries: false,
        itemLibraries: [],
      };

    case 'GET_ALL_LIBRARIES_REQUESTING':
      return {
        ...state,
        isLoadingLibraries: true,
      };
    case 'GET_ALL_LIBRARIES_SUCCESS':
      return {
        ...state,
        isLoadingLibraries: false,
        libraries: action.data,
      };
    case 'GET_ALL_LIBRARIES_FAILURE':
      return {
        ...state,
        isLoadingLibraries: false,
        libraries: [],
      };
    case 'PAGINATION_LIBRARIES_PARAMS': {
      return {
        ...state,
        params: action.params,
      };
    }
    default: {
      return state;
    }
  }
};

export default LibraryReducer;
