import React, { Component } from 'react';
import HeadersAuth from '../../../../layouts/auth/headers';
import SidebarAdmin from '../../layouts/sidebar';
import { Link } from 'react-router-dom';
import actionsAdmin from '../../store/actions';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import ReportsTest from './pages/reports-test';
import ReportsUser from './pages/reports-user';
import actions from '../../../../js/actions';
import ReportsRating from './pages/reports-rating';

const mapStateToProps = (state) => ({
  account: state.AppReducer.account,
  mostAttendedCourses: state.AdvancementUserCourseReducer.mostAttendedCourses,
  isLoadingMostAttendedCourses:
    state.AdvancementUserCourseReducer.isLoadingMostAttendedCourses,
  countCourse: state.AdvancementUserCourseReducer.countCourse,
  isLoadingCountCourse: state.AdvancementUserCourseReducer.isLoadingCountCourse,
  isLoadingCourseRating: state.AdvancementUserCourseReducer.isLoadingCourseRating,
  courseRating: state.AdvancementUserCourseReducer.courseRating,
  countUser: state.AdvancementUserCourseReducer.countUser,
  isLoadingCountUser: state.AdvancementUserCourseReducer.isLoadingCountUser,
  detailUser: state.AdvancementUserCourseReducer.detailUser,
  isLoadingDetailUser: state.AdvancementUserCourseReducer.isLoadingDetailUser,
  paramsDetailsUser: state.AdvancementUserCourseReducer.paramsDetailsUser,
});

const mapDispatchToProps = (dispatch) => ({
  getMostAttendedCourses: () => dispatch(actionsAdmin.getMostAttendedCourses()),
  getCountCourse: () => dispatch(actionsAdmin.getCountCourse()),
  getCountUser: () => dispatch(actionsAdmin.getCountUser()),
  getCoursesRatings: () => dispatch(actionsAdmin.getCoursesRatings()),
  getDetailsUserCourse: (paramsDetailsUser) =>
    dispatch(actionsAdmin.getDetailsUserCourse(paramsDetailsUser)),
  changeSearchedStringDetailsUser: (params) => {
    dispatch(actionsAdmin.changeSearchedStringDetailsUser(params));
    dispatch(actionsAdmin.getDetailsUserCourse(params));
  },
});

class Reports extends Component {
  constructor(props) {
    super(props);
    props.getMostAttendedCourses();
    props.getCountUser();
    props.getCountCourse();
    this.state = {
      is_active_item: 0,
    };
    this.makeHttpRequestWithPage = this.makeHttpRequestWithPage.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }
  makeHttpRequestWithPage = (event, page) => {
    this.props.changeSearchedStringDetailsUser({
      ...this.props.paramsDetailsUser,
      currentPage: page ? page : this.props.paramsDetailsUser.currentPage - 1,
      limit: this.props.paramsDetailsUser.limit,
    });
  };
  handleClick = (id) => {
    if (id === 1) {
      this.props.getDetailsUserCourse(this.props.paramsDetailsUser);
    }
    if (id === 2) {
      this.props.getCoursesRatings();
    }
    this.setState({
      is_active_item: id,
    });
  };
  render() {
    const {
      activeItemSideBarAdmin,
      account,
      mostAttendedCourses,
      isLoadingMostAttendedCourses,
      detailUser,
      isLoadingDetailUser,
      countCourse,
      isLoadingCourseRating,
      courseRating,
      isLoadingCountUser,
    } = this.props;
    const { is_active_item } = this.state;
    if (isLoadingMostAttendedCourses)
      return (
        <CircularProgress
          style={{
            textAlign: 'center',
            marginTop: '14%',
            width: '40px',
            color: '#d85555',
            marginRight: '50%',
          }}
        />
      );
    return (
      <>
        <HeadersAuth type={true} />
        <section className="sidebar-body-container admin-view-dshbd-main-sect">
          <SidebarAdmin
            user={account}
            activeItemSideBarAdmin={activeItemSideBarAdmin}
            changeActiveItemSideBarAdmin={this.props.changeActiveItemSideBarAdmin}
          />
          <section className="body-section dashboard-body admin-view-dashboard">
            <div className="bs-main-container">
              <div className="bs-main-content">
                <div className="dashboard-container admin-dshb-courses-manager-gest">
                  <div className="SideNavMenu-btn--Mob">
                    <div className="SideNavMenu-btn--Mob-content">
                      <button
                        type="button"
                        data-toggle="modal"
                        data-target=".sidenavbar-admin-modal"
                      >
                        <div className="SideNavMenu-btn--Mob-content">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="60.171"
                            height="60.171"
                            viewBox="0 0 60.171 60.171"
                          >
                            <g
                              id="Group_1515"
                              data-name="Group 1515"
                              transform="translate(-1777.986 -170.986)"
                            >
                              <path
                                id="Path_799"
                                data-name="Path 799"
                                d="M1808.072,166.157h0a30.086,30.086,0,0,1-30.086-30.085h0a30.086,30.086,0,0,1,30.086-30.085h0a30.085,30.085,0,0,1,30.085,30.085h0A30.085,30.085,0,0,1,1808.072,166.157Z"
                                transform="translate(0 65)"
                                fill="#f0f0f0"
                                style={{
                                  mixBlendMode: 'multiply',
                                  isolation: 'isolate',
                                }}
                              />
                              <path
                                id="Path_800"
                                data-name="Path 800"
                                d="M1812.421,163.205h0a22.785,22.785,0,0,1-22.785-22.785h0a22.785,22.785,0,0,1,22.785-22.785h0a22.785,22.785,0,0,1,22.785,22.785h0A22.785,22.785,0,0,1,1812.421,163.205Z"
                                transform="translate(-4.349 60.651)"
                                fill="#fff"
                              />
                              <g
                                id="Group_1082"
                                data-name="Group 1082"
                                transform="translate(1796.957 189.169)"
                              >
                                <g
                                  id="Group_1080"
                                  data-name="Group 1080"
                                  transform="translate(4.41)"
                                >
                                  <path
                                    id="Path_805"
                                    data-name="Path 805"
                                    d="M1821.8,147.965a6.46,6.46,0,0,1-4.308-1.641,6.338,6.338,0,0,1-2.2-4.819,6.506,6.506,0,0,1,13.012,0,6.338,6.338,0,0,1-2.208,4.824A6.443,6.443,0,0,1,1821.8,147.965Zm0-10.315a3.858,3.858,0,0,0-3.855,3.855,3.718,3.718,0,0,0,1.3,2.831,3.9,3.9,0,0,0,5.092.01,3.736,3.736,0,0,0,1.315-2.841A3.906,3.906,0,0,0,1821.8,137.65Z"
                                    transform="translate(-1815.294 -135)"
                                    fill="#ea0029"
                                  />
                                </g>
                                <g
                                  id="Group_1081"
                                  data-name="Group 1081"
                                  transform="translate(0 9.015)"
                                >
                                  <path
                                    id="Path_806"
                                    data-name="Path 806"
                                    d="M1824.17,162.931h-9.943a5.862,5.862,0,0,1-5.475-3.618,5.943,5.943,0,0,1,1.208-6.542,12.739,12.739,0,0,1,5.4-3.333,1.373,1.373,0,0,1,1.276.28,3.9,3.9,0,0,0,5.079,0,1.37,1.37,0,0,1,1.273-.28,12.7,12.7,0,0,1,5.9,3.892,5.586,5.586,0,0,1,.784,6.04A6.1,6.1,0,0,1,1824.17,162.931Zm-8.669-10.74a10.14,10.14,0,0,0-3.631,2.417,3.342,3.342,0,0,0-.681,3.669,3.214,3.214,0,0,0,3.038,2h9.943a3.414,3.414,0,0,0,3.1-2.024,2.935,2.935,0,0,0-.393-3.209,10.121,10.121,0,0,0-4.032-2.857A6.591,6.591,0,0,1,1815.5,152.192Z"
                                    transform="translate(-1808.258 -149.386)"
                                    fill="#ea0029"
                                  />
                                </g>
                              </g>
                            </g>
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="29"
                            height="40"
                            viewBox="0 0 29 40"
                          >
                            <defs>
                              <filter
                                id="Polygon_2"
                                x="0"
                                y="0"
                                width="29"
                                height="40"
                                filterUnits="userSpaceOnUse"
                              >
                                <feOffset dy="3" input="SourceAlpha" />
                                <feGaussianBlur stdDeviation="3" result="blur" />
                                <feFlood flood-opacity="0.161" />
                                <feComposite operator="in" in2="blur" />
                                <feComposite in="SourceGraphic" />
                              </filter>
                            </defs>
                            <g
                              transform="matrix(1, 0, 0, 1, 0, 0)"
                              filter="url(#Polygon_2)"
                            >
                              <path
                                id="Polygon_2-2"
                                data-name="Polygon 2"
                                d="M10.293.707a1,1,0,0,1,1.414,0l8.586,8.586A1,1,0,0,1,19.586,11H2.414a1,1,0,0,1-.707-1.707Z"
                                transform="translate(9 28) rotate(-90)"
                                fill="#fff"
                              />
                            </g>
                          </svg>
                        </div>
                      </button>
                    </div>
                  </div>
                  <div className="dashboard-content--ad admin-dashboard-view-content admin-dashboard-view-MultiRow">
                    <div className="dashboard-row">
                      <div className="admin-dashboard--nav">
                        <div className="admin-dashboard--nav-content">
                          <Link onClick={() => this.handleClick(0)}>
                            <div
                              className={
                                is_active_item === 0
                                  ? 'admin-dashboard--nav-elem active-dshb-nav-elem'
                                  : 'admin-dashboard--nav-elem'
                              }
                            >
                              <span>تقارير الإختبارات</span>
                            </div>
                          </Link>
                          <Link onClick={() => this.handleClick(1)}>
                            <div
                              className={
                                is_active_item === 1
                                  ? 'admin-dashboard--nav-elem active-dshb-nav-elem'
                                  : 'admin-dashboard--nav-elem'
                              }
                            >
                              <span>تقارير المشاركون</span>
                            </div>
                          </Link>
                          <Link onClick={() => this.handleClick(2)}>
                            <div
                              className={
                                is_active_item === 2
                                  ? 'admin-dashboard--nav-elem active-dshb-nav-elem'
                                  : 'admin-dashboard--nav-elem'
                              }
                            >
                              <span>تقييم البرامج</span>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="dashboard-row ">
                      {is_active_item === 0 ? (
                        <ReportsTest
                          countCourse={countCourse}
                          mostAttendedCourses={mostAttendedCourses}
                          isLoadingCountUser={isLoadingCountUser}
                        />
                      ) : is_active_item === 1 ? (
                        <ReportsUser
                          makeHttpRequestWithPage={this.makeHttpRequestWithPage}
                          detailUser={detailUser}
                          isLoadingDetailUser={isLoadingDetailUser}
                          countCourse={countCourse}
                        />
                      ) : (
                        <ReportsRating
                          countCourse={countCourse}
                          courseRating={courseRating}
                          isLoadingCourseRating={isLoadingCourseRating}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
      </>
    );
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Reports));
