import React, { Component } from 'react';
import SidebarAdmin from './layouts/sidebar';
import { Link, Redirect, Route, Switch } from 'react-router-dom';
import Users from './pages/user';
import HeadersAuth from '../../layouts/auth/headers';
import { withRouter } from "react-router";
import { connect } from "react-redux";

class Admin extends Component {
  render() {
    return (
      <>
        <HeadersAuth />
        <section className="sidebar-body-container">
          <SidebarAdmin />
          {/*<Users/>*/}
        </section>
      </>
    );
  }
}
export default withRouter(Admin);
