import React, { Component } from 'react';
import actions from '../../js/actions';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import Path5 from '../../assets/img/Path 5.png';
import { Link } from 'react-router-dom';
import { host, itemCourses } from '../../utils/constants';
import ReactPlayer from 'react-player/youtube';
import Modal from '../../componenets/modal';
import { CircularProgress } from '@material-ui/core';
import Headers from '../../layouts/headers';
import HeadersAuth from '../../layouts/auth/headers';
import SideBar from '../../layouts/auth/sidebar';
const mapStateToProps = (state) => ({
  isAuthorized: state.AppReducer.isAuthorized,
  training: state.AppReducer.training,
  isLoadingTraining: state.AppReducer.isLoadingTraining,
  account: state.AppReducer.account,
});

const mapDispatchToProps = (dispatch) => ({
  getTrainingById: (id) => dispatch(actions.getTrainingById(id)),
  submitFirstUserTraining: (values) => dispatch(actions.submitFirstUserTraining(values)),
});
class TrainingDetails extends Component {
  constructor(props) {
    super(props);
    props.getTrainingById(props.match.params.id);
    this.state = {
      video: '',
    };
  }
  render() {
    const { training, isAuthorized, account } = this.props;
    let trainingDescription;
    if (training) {
      trainingDescription = (training.description + '').split('N__L').map((i) => (
        <span>
          {i}
          <br></br>
        </span>
      ));
    }
    const imgRatings = [
      {
        id: 1,
      },
      {
        id: 2,
      },
      {
        id: 3,
      },
      {
        id: 4,
      },
    ];
    let i = -2;
    let ij = 0;
    if (!training)
      return (
        <section className="body-section registered-user-view ctlg-courses-cpy">
          <div className="bs-main-container">
            <div className="bs-main-content">
              <div className="registered-user-view-container">
                <div className="registered-user-view-content">
                  <CircularProgress
                    style={{
                      textAlign: 'center',
                      marginTop: '6%',
                      width: '40px',
                      color: '#d85555',
                      marginRight: '50%',
                      marginBottom: '20%',
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      );
    return (
      <>
        {isAuthorized ? <HeadersAuth /> : <Headers />}
        <section className="sidebar-body-container registered-user-view-main-cont">
          {isAuthorized ? (
            <SideBar
              path={this.props.location.pathname}
              changeActiveItemSideBar={this.props.changeActiveItemSideBar}
              activeItemSideBar={this.props.activeItemSideBar}
              account={this.props.account}
            />
          ) : (
            ''
          )}

          <section className="body-section registered-user-view ctlg-courses-cpy">
            <div className="bs-main-container">
              <div className="bs-main-content">
                <div className="registered-user-view-container">
                  <div className="SideNavMenu-btn--Mob">
                    <div className="SideNavMenu-btn--Mob-content">
                      <button
                        type="button"
                        data-toggle="modal"
                        data-target=".sidenavbar-modal"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="60.171"
                          height="60.171"
                          viewBox="0 0 60.171 60.171"
                        >
                          <g
                            id="Group_1515"
                            data-name="Group 1515"
                            transform="translate(-1777.986 -170.986)"
                          >
                            <path
                              id="Path_799"
                              data-name="Path 799"
                              d="M1808.072,166.157h0a30.086,30.086,0,0,1-30.086-30.085h0a30.086,30.086,0,0,1,30.086-30.085h0a30.085,30.085,0,0,1,30.085,30.085h0A30.085,30.085,0,0,1,1808.072,166.157Z"
                              transform="translate(0 65)"
                              fill="#f0f0f0"
                              style={{ mixBlendMode: 'multiply', isolation: 'isolate' }}
                            ></path>
                            <path
                              id="Path_800"
                              data-name="Path 800"
                              d="M1812.421,163.205h0a22.785,22.785,0,0,1-22.785-22.785h0a22.785,22.785,0,0,1,22.785-22.785h0a22.785,22.785,0,0,1,22.785,22.785h0A22.785,22.785,0,0,1,1812.421,163.205Z"
                              transform="translate(-4.349 60.651)"
                              fill="#fff"
                            ></path>
                            <g
                              id="Group_1082"
                              data-name="Group 1082"
                              transform="translate(1796.957 189.169)"
                            >
                              <g
                                id="Group_1080"
                                data-name="Group 1080"
                                transform="translate(4.41)"
                              >
                                <path
                                  id="Path_805"
                                  data-name="Path 805"
                                  d="M1821.8,147.965a6.46,6.46,0,0,1-4.308-1.641,6.338,6.338,0,0,1-2.2-4.819,6.506,6.506,0,0,1,13.012,0,6.338,6.338,0,0,1-2.208,4.824A6.443,6.443,0,0,1,1821.8,147.965Zm0-10.315a3.858,3.858,0,0,0-3.855,3.855,3.718,3.718,0,0,0,1.3,2.831,3.9,3.9,0,0,0,5.092.01,3.736,3.736,0,0,0,1.315-2.841A3.906,3.906,0,0,0,1821.8,137.65Z"
                                  transform="translate(-1815.294 -135)"
                                  fill="#ea0029"
                                ></path>
                              </g>
                              <g
                                id="Group_1081"
                                data-name="Group 1081"
                                transform="translate(0 9.015)"
                              >
                                <path
                                  id="Path_806"
                                  data-name="Path 806"
                                  d="M1824.17,162.931h-9.943a5.862,5.862,0,0,1-5.475-3.618,5.943,5.943,0,0,1,1.208-6.542,12.739,12.739,0,0,1,5.4-3.333,1.373,1.373,0,0,1,1.276.28,3.9,3.9,0,0,0,5.079,0,1.37,1.37,0,0,1,1.273-.28,12.7,12.7,0,0,1,5.9,3.892,5.586,5.586,0,0,1,.784,6.04A6.1,6.1,0,0,1,1824.17,162.931Zm-8.669-10.74a10.14,10.14,0,0,0-3.631,2.417,3.342,3.342,0,0,0-.681,3.669,3.214,3.214,0,0,0,3.038,2h9.943a3.414,3.414,0,0,0,3.1-2.024,2.935,2.935,0,0,0-.393-3.209,10.121,10.121,0,0,0-4.032-2.857A6.591,6.591,0,0,1,1815.5,152.192Z"
                                  transform="translate(-1808.258 -149.386)"
                                  fill="#ea0029"
                                ></path>
                              </g>
                            </g>
                          </g>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="29"
                          height="40"
                          viewBox="0 0 29 40"
                        >
                          <defs>
                            <filter
                              id="Polygon_2"
                              x="0"
                              y="0"
                              width="29"
                              height="40"
                              filterUnits="userSpaceOnUse"
                            >
                              <feOffset dy="3" input="SourceAlpha"></feOffset>
                              <feGaussianBlur
                                stdDeviation="3"
                                result="blur"
                              ></feGaussianBlur>
                              <feFlood flood-opacity="0.161"></feFlood>
                              <feComposite operator="in" in2="blur"></feComposite>
                              <feComposite in="SourceGraphic"></feComposite>
                            </filter>
                          </defs>
                          <g
                            transform="matrix(1, 0, 0, 1, 0, 0)"
                            filter="url(#Polygon_2)"
                          >
                            <path
                              id="Polygon_2-2"
                              data-name="Polygon 2"
                              d="M10.293.707a1,1,0,0,1,1.414,0l8.586,8.586A1,1,0,0,1,19.586,11H2.414a1,1,0,0,1-.707-1.707Z"
                              transform="translate(9 28) rotate(-90)"
                              fill="#fff"
                            ></path>
                          </g>
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div className="registered-user-view-content">
                    <div className="course-catalogue-container">
                      <div className="course-catalogue-content">
                        <div className="course-catalogue-elem">
                          <div className="course-info-desc">
                            <div className="course-general-info">
                              <div className="course-general-info-content">
                                <div className="course-miniature">
                                  <img src={`${host}${training.picture}`} alt="" />
                                </div>
                                <div className="course-info-regist-container">
                                  <div className="course-info-regist-content">
                                    <div className="course-info-ratings">
                                      <div className="course-rating">
                                        {imgRatings.map((somme) => {
                                          ij++;
                                          if (ij <= training.sommeRating)
                                            return (
                                              <svg
                                                height="20px"
                                                viewBox="0 -10 511.98685 511"
                                                width="20px"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  d="m510.652344 185.902344c-3.351563-10.367188-12.546875-17.730469-23.425782-18.710938l-147.773437-13.417968-58.433594-136.769532c-4.308593-10.023437-14.121093-16.511718-25.023437-16.511718s-20.714844 6.488281-25.023438 16.535156l-58.433594 136.746094-147.796874 13.417968c-10.859376 1.003906-20.03125 8.34375-23.402344 18.710938-3.371094 10.367187-.257813 21.738281 7.957031 28.90625l111.699219 97.960937-32.9375 145.089844c-2.410156 10.667969 1.730468 21.695313 10.582031 28.09375 4.757813 3.4375 10.324219 5.1875 15.9375 5.1875 4.839844 0 9.640625-1.304687 13.949219-3.882813l127.46875-76.183593 127.421875 76.183593c9.324219 5.609376 21.078125 5.097657 29.910156-1.304687 8.855469-6.417969 12.992187-17.449219 10.582031-28.09375l-32.9375-145.089844 111.699219-97.941406c8.214844-7.1875 11.351563-18.539063 7.980469-28.925781zm0 0"
                                                  fill="#ffc107"
                                                />
                                              </svg>
                                            );
                                          else
                                            return (
                                              <svg
                                                height="20px"
                                                viewBox="0 -10 511.98685 511"
                                                width="20px"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path d="m114.59375 491.140625c-5.609375 0-11.179688-1.75-15.933594-5.1875-8.855468-6.417969-12.992187-17.449219-10.582031-28.09375l32.9375-145.089844-111.703125-97.960937c-8.210938-7.167969-11.347656-18.519532-7.976562-28.90625 3.371093-10.367188 12.542968-17.707032 23.402343-18.710938l147.796875-13.417968 58.433594-136.746094c4.308594-10.046875 14.121094-16.535156 25.023438-16.535156 10.902343 0 20.714843 6.488281 25.023437 16.511718l58.433594 136.769532 147.773437 13.417968c10.882813.980469 20.054688 8.34375 23.425782 18.710938 3.371093 10.367187.253906 21.738281-7.957032 28.90625l-111.703125 97.941406 32.9375 145.085938c2.414063 10.667968-1.726562 21.699218-10.578125 28.097656-8.832031 6.398437-20.609375 6.890625-29.910156 1.300781l-127.445312-76.160156-127.445313 76.203125c-4.308594 2.558594-9.109375 3.863281-13.953125 3.863281zm141.398438-112.875c4.84375 0 9.640624 1.300781 13.953124 3.859375l120.277344 71.9375-31.085937-136.941406c-2.21875-9.746094 1.089843-19.921875 8.621093-26.515625l105.472657-92.5-139.542969-12.671875c-10.046875-.917969-18.6875-7.234375-22.613281-16.492188l-55.082031-129.046875-55.148438 129.066407c-3.882812 9.195312-12.523438 15.511718-22.546875 16.429687l-139.5625 12.671875 105.46875 92.5c7.554687 6.613281 10.859375 16.769531 8.621094 26.539062l-31.0625 136.9375 120.277343-71.914062c4.308594-2.558594 9.109376-3.859375 13.953126-3.859375zm-84.585938-221.847656s0 .023437-.023438.042969zm169.128906-.0625.023438.042969c0-.023438 0-.023438-.023438-.042969zm0 0" />
                                              </svg>
                                            );
                                        })}
                                      </div>
                                    </div>
                                    {/*<div className="register-course-btn">
                                      <button
                                        data-toggle="modal"
                                        data-target="#confirmation"
                                        style={{
                                          border: '1px solid green',
                                          borderRadius: '10px',
                                        }}
<<<<<<<
                                         onClick={() => {
                                          this.props.submitUserDirectTraining({
                                            course_id: training.id,
                                          });
                                        }}
=======
                                        onClick={async () => {
                                          if (training.type != 3) {
                                            await this.props.submitFirstUserTraining({
                                              course_id: training.id,
                                            });
                                            this.props.history.push(
                                              `/intelligent/training/${training.id}/catalogue`,
                                            );
                                          }
                                        }}
>>>>>>>
                                      >
                                        <button className="btn btn-green">تسجيل</button>
                                      </button>
                                    </div>*/}
                                    <div className="register-course-btn">
                                      {account.user_smart_trainings.find(
                                        (item) =>
                                          item.course_id === training.id &&
                                          item.status === training.sections.length,
                                      ) ? (
                                        <button className="btn btn-green">
                                          لقد اكملت الدرس
                                        </button>
                                      ) : account.user_smart_trainings.find(
                                        (item) =>
                                          item.course_id === training.id &&
                                          (item.status === 'start' ||
                                            item.status === 'in progress'),
                                      ) ? (
                                        <Link
                                          to={
                                            training.sections &&
                                            training.sections.length > 0
                                              ? `/intelligent/training/${training.id}/catalogue`
                                              : '#'
                                          }
                                        >
                                          <button className="btn btn-green">
                                            ابدا او اكمل الدرس
                                          </button>
                                        </Link>
                                      ) : (
                                        <button
                                          style={{
                                            border: '1px solid green',
                                            borderRadius: '10px',
                                          }}
                                          onClick={async () => {
                                            if (training.type != 3) {
                                              await this.props.submitFirstUserTraining({
                                                course_id: training.id,
                                              });
                                              this.props.history.push(
                                                `/intelligent/training/${training.id}/catalogue`,
                                              );
                                            }
                                          }}
                                        >
                                          <button className="btn btn-green">تسجيل</button>
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="course-description-container">
                              <div
                                className="course-description-content"
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                }}
                              >
                                {training.training_goal ? (
                                  <>
                                    <span
                                      style={{
                                        backgroundColor: 'grey',
                                        padding: '5px',
                                        color: 'white',
                                        width: 'fit-content',
                                        fontSize: '35px',
                                      }}
                                    >
                                      الهدف العام للبرنامج
                                    </span>
                                    <span>{training.training_goal}</span>
                                  </>
                                ) : (
                                  ''
                                )}
                                <span
                                  style={{
                                    color: 'red',
                                    fontWeight: 'bolder',
                                    fontSize: '25px',
                                  }}
                                >
                                  {' '}
                                  بنهاية هذا البرنامج التدريبي سيكون المتدرب قادراً على{' '}
                                </span>

                                <span>
                                  {training.description
                                    ? trainingDescription
                                    : 'قيد الانجاز'}
                                </span>
                                {/*<button className="btn btn-green">اقرا المزيد</button>*/}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="course-catalogue-elem">
                          <div className="course-summary-container">
                            {training.type !== 3 ? (
                              <div className="course-summary-content">
                                <div className="course-summary-header">
                                  <div className="course-summary-header-elem course-summary-header-title">
                                    <span>المحتوى</span>
                                  </div>

                                  {training &&
                                    training.sections &&
                                    training.sections
                                      .sort((a, b) =>
                                        a.type > b.type ? 1 : b.type > a.type ? -1 : 0,
                                      )
                                      .map((item) => {
                                        if (item.type === 0) {
                                          return item.section_items
                                            .sort((a, b) =>
                                              a.order > b.order
                                                ? 1
                                                : b.order > a.order
                                                ? -1
                                                : 0,
                                            )
                                            .map((section_item) => {
                                              return (
                                                <div className="course-chapter-elem">
                                                  <i
                                                    style={{
                                                      marginRight: '2%',
                                                      marginLeft: '2%',
                                                      color: 'gray',
                                                    }}
                                                    className="far fa-file-alt"
                                                  ></i>
                                                  <span>{section_item.doc_name}</span>
                                                </div>
                                              );
                                            });
                                        }
                                      })}
                                </div>
                                <div className="course-summary-body">
                                  <div className="course-summary-body-content">
                                    {training &&
                                      training.sections &&
                                      training.sections
                                        .sort((a, b) =>
                                          a.type > b.type ? 1 : b.type > a.type ? -1 : 0,
                                        )
                                        .map((item) => {
                                          i++;
                                          if (item.type !== 0) {
                                            return (
                                              <div className="course-summary-body-elem">
                                                <div className="course-summary-body-elem-title">
                                                  <div className="course-summary-body-elem-title-content">
                                                    <span>{itemCourses[i]}</span>
                                                  </div>
                                                </div>
                                                <div className="course-summary-body-elem-body">
                                                  <div className="course-summary-subsections-elem">
                                                    <span>{item.name}</span>
                                                  </div>
                                                  {item.section_items &&
                                                    item.section_items
                                                      .sort((a, b) =>
                                                        a.order > b.order
                                                          ? 1
                                                          : b.order > a.order
                                                          ? -1
                                                          : 0,
                                                      )
                                                      .map((section_item) => {
                                                        if (
                                                          section_item.doc_name !==
                                                            'تأكيد الاستيعاب' &&
                                                          item.name !== 'الشهادة' &&
                                                          item.name !==
                                                            'ملخص الدورة التدريبية'
                                                        )
                                                          return (
                                                            <>
                                                              {section_item.type ===
                                                              'video' ? (
                                                                <div
                                                                  style={{
                                                                    backgroundColor:
                                                                      'white',
                                                                  }}
                                                                  className="course-summary-subsections-elem"
                                                                >
                                                                  <i
                                                                    style={{
                                                                      marginRight: '2%',
                                                                      color: 'gray',
                                                                      marginLeft: '2%',
                                                                    }}
                                                                    className={
                                                                      section_item.type ===
                                                                      'video'
                                                                        ? 'fas fa-play'
                                                                        : 'far fa-file-pdf'
                                                                    }
                                                                  ></i>
                                                                  <span
                                                                    style={{
                                                                      color: 'black',
                                                                    }}
                                                                  >
                                                                    {
                                                                      section_item.doc_name
                                                                    }
                                                                  </span>
                                                                  {/*</a>*/}
                                                                </div>
                                                              ) : (
                                                                <div
                                                                  style={{
                                                                    backgroundColor:
                                                                      'white',
                                                                  }}
                                                                  className="course-summary-subsections-elem"
                                                                >
                                                                  <i
                                                                    style={{
                                                                      marginRight: '2%',
                                                                      color: 'gray',
                                                                      marginLeft: '2%',
                                                                    }}
                                                                    className={
                                                                      section_item.type ===
                                                                      'video'
                                                                        ? 'fas fa-play'
                                                                        : 'far fa-file-pdf'
                                                                    }
                                                                  ></i>
                                                                  <span
                                                                    style={{
                                                                      color: 'black',
                                                                    }}
                                                                  >
                                                                    {
                                                                      section_item.doc_name
                                                                    }
                                                                  </span>
                                                                  {/*</a>*/}
                                                                </div>
                                                              )}
                                                            </>
                                                          );
                                                        if (
                                                          item.name !== 'الشهادة' &&
                                                          item.name !==
                                                            'ملخص الدورة التدريبية'
                                                        )
                                                          return (
                                                            <div className="course-summary-subsections-elem">
                                                              <span>
                                                                {section_item.doc_name}
                                                              </span>
                                                            </div>
                                                          );
                                                        else
                                                          return (
                                                            <div
                                                              style={{
                                                                background: '#ffffff',
                                                              }}
                                                              className="course-summary-subsections-elem"
                                                            >
                                                              <span
                                                                style={{ color: 'black' }}
                                                              >
                                                                {section_item.doc_name}
                                                              </span>
                                                            </div>
                                                          );
                                                      })}
                                                </div>
                                              </div>
                                            );
                                          }
                                        })}
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
      </>
    );
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TrainingDetails));
