import React, { Component } from 'react';
import Modal from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    padding: '20px',
    overflow: 'hidden',
    borderRadius: '15px',
  },
};
class Modals extends Component {
  render() {
    const { id, data, size } = this.props;
    return (
      <div
        className="modal fade"
        id={id}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">X</span>
        </button>
        <div
          className={`modal-dialog modal-dialog-centered ${size || 'modal-lg'}`}
          role="document"
        >
          <div className="modal-content">{data}</div>
        </div>
      </div>
      /*<Modal
        isOpen={setIsOpen}
        onRequestClose={openModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        {data}
      </Modal>*/
    );
  }
}

export default Modals;
