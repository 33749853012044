import React, { Component } from 'react';
import books from '../../assets/img/e lib/books.png';
import photos from '../../assets/img/e lib/photos.png';
import vids from '../../assets/img/e lib/vids.png';
import { Link } from 'react-router-dom';
import Headers from '../../layouts/headers';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
const mapStateToProps = (state) => ({
  isAuthorized: state.AppReducer.isAuthorized,
});
class Libraries extends Component {
  constructor(props) {
    super(props);
    document.title = 'المكتبة الالكترونية';
  }
  render() {
    const { isAuthorized } = this.props;
    return (
      <section className="body-section e-lib-body">
        <Headers isAuthorized={isAuthorized} />
        <div className="bs-main-container">
          <div className="bs-main-content">
            <div className="bs-main-content-header-sm-tr">
              <div className="bs-main-content-header-title-sm-tr">
                <span>المكتبة الالكترونية</span>
              </div>
              <div className="bs-main-content-header-desc-sm-tr">
                <span>
                  تتضمن معلومات و صور و فيديوهات و مواد علمية عن الدورات التدريبية التي تم
                  تنفيذها سابقاً لموظفي حكومة أم القيوين
                </span>
              </div>
            </div>
            <div className="cards-container">
              <div className="cards-wrapper">
                <div className="cardes-wrapper-content">
                  <div className="bs-subsection-elem-cards-container">
                    <div className="bs-subsection-elem-cards-content">
                      <Link to={`/library/photos`}>
                        <div className="bs-subsection-elem-card">
                          <div className="bs-cards-container">
                            <div className="bs-cards-content">
                              <img src={photos} alt="" />
                            </div>
                          </div>
                          <div className="bs-card-text">
                            <span>بانوراما</span>
                          </div>
                        </div>
                      </Link>
                      <Link to={`/library/videos`}>
                        <div className="bs-subsection-elem-card">
                          <div className="bs-cards-container">
                            <div className="bs-cards-content">
                              <img src={vids} alt="" />
                            </div>
                          </div>
                          <div className="bs-card-text">
                            <span>فيديوهات</span>
                          </div>
                        </div>
                      </Link>
                      <Link to={`/library/books`}>
                        <div className="bs-subsection-elem-card">
                          <div className="bs-cards-container">
                            <div className="bs-cards-content">
                              <img src={books} alt="" />
                            </div>
                          </div>
                          <div className="bs-card-text">
                            <span>المواد العلمية للدورات</span>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withRouter(connect(mapStateToProps)(Libraries));
