import React, { Component } from 'react';

class ConditionInstructor extends Component {
  render() {
    return (
      <div className="membership-benefits-conditions-container">
        <div className="membership-benefits-conditions-content">
          <div className="membership-benefits-conditions-header">
            <span>طلب مدرب معتمد</span>
          </div>
          <div className="membership-benefits-conditions-body">
            <ul>
              <li>مدة العضوية عام ميلادي تتجدد في حال الرغبة في ذلك وسداد الرسوم</li>
              <li>يمنح المدرب بطاقة عضوية كمدرب معتمد في المركز
                دعم المدرب لوجستيّاً، وتنفيذ برامجه المتميزة بالاشتراك مع المركز
              </li>
              <li>اشتراك المدرب في الملتقيات التدريبية التي ينفذها المركز ومنحه فرصة تقديم برنامج تدريبي في المتقى وفقاً
                لتخصصه التدريبي
              </li>
              <li>يمنح المتدرب المتميز، بحسب تقدير مجلس الإدارة وسام خبير التنمية في التدريب</li>
              <li>يحق للمركز استطلاع عام للمتدربين حول أداء المدرب بعد كل برنامج تدريبي</li>
              <li>يحق للمركز تعليق أو إلغاء عضوية المنتسب، في حال مخالفته لأخلاقيات التدريب المعروفة، أو في حال ضعف
                قدراته العلمية أو المهارية
              </li>
              <li>شروط العضوية: السيرة الذاتية - نسخة عن بطاقة الهوية - نسخة عن جواز السفر - صورة شخصية</li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default ConditionInstructor;
