import React from 'react';
import { Chart } from 'react-charts';

function ChartUser({ countUser }) {
  const data = React.useMemo(() => [countUser], []);
  const axes = React.useMemo(
    () => [
      { primary: true, type: 'ordinal', position: 'bottom' },
      { position: 'left', type: 'linear', stacked: false },
    ],
    [],
  );
  const series = React.useMemo(
    () => ({
      type: 'line',
    }),
    [],
  );

  return (
    <div className="actual-grah-container">
      <div className="actual-grah-content">
        <div className="graph-placeholder">
          <div
            style={{
              width: '100%',
              height: '300px',
            }}
          >
            <Chart series={series} data={data} axes={axes} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChartUser;
