import React, { Component } from 'react';
import Group2197 from '../../assets/img/Group 2197.png';
import Group2194 from '../../assets/img/Group 2194.png';
import Group2191 from '../../assets/img/Group 2191.png';
import actions from './store/actions';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Headers from '../../layouts/headers';
import { toast } from 'react-toastify';
import ToastAction from '../../componenets/ToastAction';
const mapStateToProps = (state) => ({
  isLoadingCertifiedMembers: state.CertifiedMemberReducer.isLoadingCertifiedMembers,
  certifiedMembers: state.CertifiedMemberReducer.certifiedMembers,
  params: state.CertifiedMemberReducer.params,
  isAuthorized: state.AppReducer.isAuthorized,
});

const mapDispatchToProps = (dispatch) => ({
  getAllCertifiedMembers: (params) => dispatch(actions.getAllCertifiedMembers(params)),
});
class CertifiedMemberShip extends Component {
  constructor(props) {
    super(props);
    document.title = 'العضوية';
    props.getAllCertifiedMembers(props.params);
  }
  handleClickMemberItem() {
    toast(({ closeToast }) => <ToastAction type="info" message="تحت التجهيز" />, {
      hideProgressBar: true,
    });
  }
  render() {
    const { isLoadingCertifiedMembers, isAuthorized, certifiedMembers } = this.props;
    return (
      <section className="body-section">
        <Headers isAuthorized={isAuthorized} />
        <div className="bs-main-container membership-body">
          <div className="bs-main-content">
            <div className="bs-main-content-header-sm-tr">
              <div className="bs-main-content-header-title-sm-tr">
                <span>العضويات المعتمدة</span>
              </div>
            </div>
            <div className="cards-container">
              <div className="cards-wrapper">
                <div className="cardes-wrapper-content">
                  <div className="bs-subsection-elem-cards-container">
                    <div className="bs-subsection-elem-cards-content">
                      {isLoadingCertifiedMembers ? (
                        <CircularProgress
                          style={{
                            textAlign: 'center',
                            marginTop: '9%',
                            marginBottom: '18%',
                            width: '40px',
                            color: '#d85555',
                            marginRight: '47%',
                          }}
                        />
                      ) : (
                        certifiedMembers &&
                        certifiedMembers.length > 0 &&
                        certifiedMembers.map((certified_member) => {
                          return (
                            /*<Link
                              key={certified_member.id}
                              to={`/registered-members/${certified_member.type}`}
                            >*/
                            <div
                              onClick={() => this.handleClickMemberItem()}
                              key={certified_member.id}
                              className="bs-subsection-elem-card"
                            >
                              <div className="bs-cards-container">
                                <div className="bs-cards-content">
                                  <img
                                    src={
                                      certified_member.type === 1
                                        ? Group2191
                                        : certified_member.type === 2
                                        ? Group2194
                                        : Group2197
                                    }
                                    alt=""
                                  />
                                </div>
                              </div>
                              <div className="bs-card-text">
                                <span>{certified_member.name}</span>
                              </div>
                            </div>
                            /*</Link>*/
                          );
                        })
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CertifiedMemberShip),
);
