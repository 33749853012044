import axios from 'axios';
import { host } from '../../../utils/constants';

const submitMemberForm = (values) => async (dispatch) => {
  dispatch({
    type: 'CREATE_NEW_MEMBER_REQUESTING',
  });
  const {
    first_name,
    last_name,
    phone,
    email,
    subject,
    certified_member_ship_id,
  } = values;
  try {
    let formData = new FormData();
    formData.append('copy_identity', values.copy_identity);
    formData.append('personal_photo', values.personal_photo);
    formData.append('copy_passport', values.copy_passport);
    formData.append('copy_institution_profile', values.copy_institution_profile);
    formData.append('certified_member_ship_id', certified_member_ship_id);
    formData.append('first_name', first_name);
    formData.append('last_name', last_name);
    formData.append('phone', phone);
    formData.append('email', email);
    formData.append('subject', subject);
    const { data } = await axios.post(`${host}/api/member/create/`, formData);
    const response = data;
    if (!data.error) {
      dispatch({
        type: 'CREATE_NEW_MEMBER_SUCCESS',
        msg: data.message,
        data: data.member,
      });
    } else {
      //toast.error(`User n'est pas ajouté`, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'CREATE_NEW_MEMBER_FAILURE',
        msg: data,
        ErrorMessage: data.message,
      });
    }
  } catch (e) {
    const { response } = e;
    if (response) {
      const { data } = response;
      dispatch({
        type: 'CREATE_NEW_MEMBER_FAILURE',
        msg: 'error server',
        err: data.data || data.type,
        data: values,
      });
    }
  }
};

const getAllMembers = (params, type) => async (dispatch) => {
  dispatch({ type: 'GET_ALL_MEMBERS_REQUESTING' });
  try {
    const { currentPage } = params || {
      currentPage: 1,
    };
    const { limit } = params || {
      limit: '',
    };
    const { search } = params || {
      search: '',
    };
    let url = `${host}/api/member?id=${type}&limit=${limit}&current_page=${currentPage}`;
    if (search !== '') url += `&search=${search}`;
    const { data } = await axios.get(url);
    dispatch({ type: 'GET_ALL_MEMBERS_SUCCESS', data: data });
  } catch (err) {
    const { response } = err;
    if (response)
      dispatch({
        type: 'GET_ALL_MEMBERS_FAILURE',
        err: err.message,
      });
  }
};
const getMember = (id) => async (dispatch) => {
  dispatch({ type: 'GET_MEMBER_REQUESTING' });
  try {
    const { data } = await axios.get(`${host}/api/member/${id}`);
    dispatch({ type: 'GET_MEMBER_SUCCESS', data: data.result });
  } catch (err) {
    const { response } = err;
    if (response)
      dispatch({
        type: 'GET_MEMBER_FAILURE',
        err: err.message,
      });
  }
};

const changeSearchedString = (params) => ({
  type: 'PAGINATION_MEMBERS_PARAMS',
  params: params,
});

export default {
  getMember,
  changeSearchedString,
  submitMemberForm,
  getAllMembers,
};
