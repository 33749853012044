import React, { Component } from 'react';
import { host } from '../../../../../utils/constants';
import moment from 'moment';
import imgCheck from '../../../../../assets/img/Group 2306.png';
class Members extends Component {
  render() {
    const { advancementUserCourse, params } = this.props;
    return (
      <>
        <div className="dashboard-row">
          <div className="admin-dshb-user-container">
            <div className="admin-dshb-user-content">
              <div className="admin-dshb-user-header">
                <div className="admin-dshb-user-header-content">
                  <div className="admin-dshb-user-header-elem">
                    <span>الاسم</span>
                  </div>
                  <div className="admin-dshb-user-header-elem">
                    <span>الدورة</span>
                  </div>
                  <div className="admin-dshb-user-header-elem">
                    <span>التقدم</span>
                  </div>
                  <div className="admin-dshb-user-header-elem">
                    <span>التحق في </span>
                  </div>
                  <div className="admin-dshb-user-header-elem">
                    <span>إكتمل في </span>
                  </div>
                  <div className="admin-dshb-user-header-elem">
                    <span>الشهادة </span>
                  </div>
                </div>
              </div>
              <div className="admin-dshb-user-body--mob-ver">
                <div className="admin-dshb-user-body-content">
                  {advancementUserCourse && advancementUserCourse.data.length > 0
                    ? advancementUserCourse.data.map((item) => {
                        let percentageCourse =
                          (item.section_pause / item.course.sections.length) * 100;
                        return (
                          <div className="admin-dshb-user-elem">
                            <div className="admin-dshb-user-elem-content">
                              <div className="admin-dshb-user-name">
                                <span>{`${item.user.first_name} ${item.user.last_name}`}</span>
                              </div>
                              <div className="admin-dshb-user-name">
                                <div className="admin-dshb-user-name-icon-cont">
                                  <img src={`${host}${item.course.picture}`} alt="" />
                                </div>
                                <span>{item.course.name}</span>
                              </div>
                              <div className="admin-dshb-user-progress">
                                <div className="admin-dshb-user-progress-bar">
                                  <div
                                    style={{
                                      marginRight: item.section_pause === 0 ? '8%' : '0%',
                                      width: `${
                                        percentageCourse ? percentageCourse : 0
                                      }%`,
                                    }}
                                    className="admin-dshb-user-actual-progress"
                                  >
                                    <span>
                                      {' '}
                                      {percentageCourse
                                        ? `${parseInt(percentageCourse)}%`
                                        : 0}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="admin-dshb-user-start-date">
                                <span>
                                  {moment(item.created_at).format('DD/MM/YYYY')}
                                </span>
                              </div>
                              <div className="admin-dshb-user-end-date">
                                <span>
                                  {item.section_pause === item.course.sections.length
                                    ? moment(item.updated_at).format('DD/MM/YYYY')
                                    : '----'}
                                </span>
                              </div>
                              <div className="admin-dshb-user-end-date">
                                <span>
                                  {item.course.certification ? (
                                    <img src={imgCheck} />
                                  ) : (
                                    '----'
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    : ''}
                </div>
                <div className="pagination-container">
                  {advancementUserCourse.total > 10 ? (
                    <div className="pagination-content">
                      <div className="pagination-content-elem">
                        <span>1</span>
                      </div>
                      <div className="pagination-content-elem">
                        <span>2</span>
                      </div>
                      <div className="pagination-content-elem">
                        <span>3</span>
                      </div>
                      <div className="pagination-dot"></div>
                      <div className="pagination-dot"></div>
                      <div className="pagination-dot"></div>
                      <div className="pagination-content-elem last-pagination-number">
                        <span>20</span>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div className="admin-dshb-user-body">
                <div className="admin-dshb-user-body-content">
                  {advancementUserCourse && advancementUserCourse.data.length > 0
                    ? advancementUserCourse.data.map((item) => {
                        let percentageCourse =
                          (item.section_pause / item.course.sections.length) * 100;
                        return (
                          <div className="admin-dshb-user-elem">
                            <div className="admin-dshb-user-elem-content">
                              <div className="admin-dshb-user-name">
                                <span>{`${item.user.first_name} ${item.user.last_name}`}</span>
                              </div>
                              <div className="admin-dshb-user-name">
                                <div className="admin-dshb-user-name-icon-cont">
                                  <img src={`${host}${item.course.picture}`} alt="" />
                                </div>
                                <span>{item.course.name}</span>
                              </div>
                              <div className="admin-dshb-user-progress">
                                <div className="admin-dshb-user-progress-bar">
                                  <div
                                    style={{
                                      marginRight: item.section_pause === 0 ? '8%' : '0%',
                                      width: `${
                                        percentageCourse ? percentageCourse : 0
                                      }%`,
                                    }}
                                    className="admin-dshb-user-actual-progress"
                                  >
                                    <span>
                                      {' '}
                                      {percentageCourse
                                        ? `${parseInt(percentageCourse)}%`
                                        : 0}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="admin-dshb-user-start-date">
                                <span>
                                  {moment(item.created_at).format('DD/MM/YYYY')}
                                </span>
                              </div>
                              <div className="admin-dshb-user-end-date">
                                <span>
                                  {item.section_pause === item.course.sections.length
                                    ? moment(item.updated_at).format('DD/MM/YYYY')
                                    : '----'}
                                </span>
                              </div>
                              <div className="admin-dshb-user-end-date">
                                <span>
                                  {item.course.certification ? (
                                    <img src={imgCheck} />
                                  ) : (
                                    '----'
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    : ''}
                </div>

                {advancementUserCourse.total > 10 ? (
                  <div className="pagination-container">
                    <div className="pagination-content">
                      <div className="pagination-content-elem">
                        <span>1</span>
                      </div>
                      <div className="pagination-content-elem">
                        <span>2</span>
                      </div>
                      <div className="pagination-content-elem">
                        <span>3</span>
                      </div>
                      <div className="pagination-dot"></div>
                      <div className="pagination-dot"></div>
                      <div className="pagination-dot"></div>
                      <div className="pagination-content-elem last-pagination-number">
                        <span>20</span>
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Members;
