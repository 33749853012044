import React, { Component } from 'react';

class UserProgressInfo extends Component {
  render() {
    const { countTraining, countCertification ,score,badge} = this.props;
    return (
      <div className="dashboard-column-elem -p-progr-info">
        <div className="dashboard-user-progress-info">
          <div className="dashboard-user-progress-info-content">
            <div className="dashboard-user-progress-info-row">
              <div className="dashboard-user-progress-text">
                <span>المستوى</span>
              </div>
              <div className="dashboard-user-progress-stats">
                <span>0</span>
              </div>
            </div>
            <div className="dashboard-user-progress-info-row">
              <div className="dashboard-user-progress-text">
                <span>الشارات</span>
              </div>
              <div className="dashboard-user-progress-stats">
                <span>{badge || 0}</span>
              </div>
            </div>
            <div className="dashboard-user-progress-info-row">
              <div className="dashboard-user-progress-text">
                <span>النقاط</span>
              </div>
              <div className="dashboard-user-progress-stats">
                <span>{score ? score : 0}</span>
              </div>
            </div>
            <div className="dashboard-user-progress-info-row">
              <div className="dashboard-user-progress-text">
                <span>الشهادات</span>
              </div>
              <div className="dashboard-user-progress-stats">
                <span>{countCertification ? countCertification : 0}</span>
              </div>
            </div>
            <div className="dashboard-user-progress-info-row">
              <div className="dashboard-user-progress-text">
                <span>الدورات المكتملة</span>
              </div>
              <div className="dashboard-user-progress-stats">
                <span>{countTraining ? countTraining : 0}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UserProgressInfo;
