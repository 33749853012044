import React, { Component } from 'react';
import Group2x from '../../../../assets/img/Group@2x.png';
import Group23552x from '../../../../assets/img/Group 2355@2x.png';
import UserProgressInfo from '../components/user-progress-info';
import UserProgressCprOthers from '../components/user-progress-cpr-others';
import actions from '../../../../js/actions';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import HeadersAuth from '../../../../layouts/auth/headers';
import SideBar from '../../../../layouts/auth/sidebar';

const mapStateToProps = (state) => ({
  isLoadingUser: state.AppReducer.isLoadingUser,
  user: state.AppReducer.user,
  account: state.AppReducer.account,
});

const mapDispatchToProps = (dispatch) => ({
  getUser: (id) => dispatch(actions.getUser(id)),
});
class Progressive extends Component {
  constructor(props) {
    super(props);
    document.title = 'تقدمي';
    props.getUser(props.account.id);
  }

  render() {
    const { countCertification,countTrainingIsFinish, countTraining, account } = this.props;
    return (
      <>
        <HeadersAuth />
        <section className="sidebar-body-container registered-user-view-main-cont">
          <SideBar
            path={this.props.location.pathname}
            changeActiveItemSideBar={this.props.changeActiveItemSideBar}
            activeItemSideBar={this.props.activeItemSideBar}
            account={this.props.account}
          />
          <section className="body-section dashboard-body user-progress-dashboard">
            <div className="bs-main-container">
              <div className="bs-main-content">
                <div className="dashboard-container">
                  <div className="SideNavMenu-btn--Mob">
                    <div className="SideNavMenu-btn--Mob-content">
                      <button
                        type="button"
                        data-toggle="modal"
                        data-target=".sidenavbar-modal"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="60.171"
                          height="60.171"
                          viewBox="0 0 60.171 60.171"
                        >
                          <g
                            id="Group_1515"
                            data-name="Group 1515"
                            transform="translate(-1777.986 -170.986)"
                          >
                            <path
                              id="Path_799"
                              data-name="Path 799"
                              d="M1808.072,166.157h0a30.086,30.086,0,0,1-30.086-30.085h0a30.086,30.086,0,0,1,30.086-30.085h0a30.085,30.085,0,0,1,30.085,30.085h0A30.085,30.085,0,0,1,1808.072,166.157Z"
                              transform="translate(0 65)"
                              fill="#f0f0f0"
                              style={{ mixBlendMode: 'multiply', isolation: 'isolate' }}
                            ></path>
                            <path
                              id="Path_800"
                              data-name="Path 800"
                              d="M1812.421,163.205h0a22.785,22.785,0,0,1-22.785-22.785h0a22.785,22.785,0,0,1,22.785-22.785h0a22.785,22.785,0,0,1,22.785,22.785h0A22.785,22.785,0,0,1,1812.421,163.205Z"
                              transform="translate(-4.349 60.651)"
                              fill="#fff"
                            ></path>
                            <g
                              id="Group_1082"
                              data-name="Group 1082"
                              transform="translate(1796.957 189.169)"
                            >
                              <g
                                id="Group_1080"
                                data-name="Group 1080"
                                transform="translate(4.41)"
                              >
                                <path
                                  id="Path_805"
                                  data-name="Path 805"
                                  d="M1821.8,147.965a6.46,6.46,0,0,1-4.308-1.641,6.338,6.338,0,0,1-2.2-4.819,6.506,6.506,0,0,1,13.012,0,6.338,6.338,0,0,1-2.208,4.824A6.443,6.443,0,0,1,1821.8,147.965Zm0-10.315a3.858,3.858,0,0,0-3.855,3.855,3.718,3.718,0,0,0,1.3,2.831,3.9,3.9,0,0,0,5.092.01,3.736,3.736,0,0,0,1.315-2.841A3.906,3.906,0,0,0,1821.8,137.65Z"
                                  transform="translate(-1815.294 -135)"
                                  fill="#ea0029"
                                ></path>
                              </g>
                              <g
                                id="Group_1081"
                                data-name="Group 1081"
                                transform="translate(0 9.015)"
                              >
                                <path
                                  id="Path_806"
                                  data-name="Path 806"
                                  d="M1824.17,162.931h-9.943a5.862,5.862,0,0,1-5.475-3.618,5.943,5.943,0,0,1,1.208-6.542,12.739,12.739,0,0,1,5.4-3.333,1.373,1.373,0,0,1,1.276.28,3.9,3.9,0,0,0,5.079,0,1.37,1.37,0,0,1,1.273-.28,12.7,12.7,0,0,1,5.9,3.892,5.586,5.586,0,0,1,.784,6.04A6.1,6.1,0,0,1,1824.17,162.931Zm-8.669-10.74a10.14,10.14,0,0,0-3.631,2.417,3.342,3.342,0,0,0-.681,3.669,3.214,3.214,0,0,0,3.038,2h9.943a3.414,3.414,0,0,0,3.1-2.024,2.935,2.935,0,0,0-.393-3.209,10.121,10.121,0,0,0-4.032-2.857A6.591,6.591,0,0,1,1815.5,152.192Z"
                                  transform="translate(-1808.258 -149.386)"
                                  fill="#ea0029"
                                ></path>
                              </g>
                            </g>
                          </g>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="29"
                          height="40"
                          viewBox="0 0 29 40"
                        >
                          <defs>
                            <filter
                              id="Polygon_2"
                              x="0"
                              y="0"
                              width="29"
                              height="40"
                              filterUnits="userSpaceOnUse"
                            >
                              <feOffset dy="3" input="SourceAlpha"></feOffset>
                              <feGaussianBlur
                                stdDeviation="3"
                                result="blur"
                              ></feGaussianBlur>
                              <feFlood flood-opacity="0.161"></feFlood>
                              <feComposite operator="in" in2="blur"></feComposite>
                              <feComposite in="SourceGraphic"></feComposite>
                            </filter>
                          </defs>
                          <g
                            transform="matrix(1, 0, 0, 1, 0, 0)"
                            filter="url(#Polygon_2)"
                          >
                            <path
                              id="Polygon_2-2"
                              data-name="Polygon 2"
                              d="M10.293.707a1,1,0,0,1,1.414,0l8.586,8.586A1,1,0,0,1,19.586,11H2.414a1,1,0,0,1-.707-1.707Z"
                              transform="translate(9 28) rotate(-90)"
                              fill="#fff"
                            ></path>
                          </g>
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div className="dashboard-content">
                    <div className="dashboard-column">
                      <div className="dashboard-column-content">
                        <UserProgressInfo
                          countCertification={countCertification || 0}
                          countTraining={countTrainingIsFinish}
                          score={account.score || 0}
                          badge={account.badge || 0}
                        />
                        <UserProgressCprOthers
                          countCertification={countCertification || 0}
                          countTraining={countTraining}
                          score={account.score || 0}
                          badge={account.badge || 0}
                        />
                      </div>
                    </div>
                    <div className="dashboard-column">
                      <div className="dashboard-column-content">
                        <div className="dashboard-column-elem">
                          <div className="dashboard-column-elem-content">
                            <div className="dashboard-stats-content">
                              <div className="unlocked-badges-container">
                                <div className="unlocked-badges-content">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="138.258"
                                    height="119"
                                    viewBox="0 0 138.258 119"
                                  >
                                    <g
                                      id="Group_719"
                                      data-name="Group 719"
                                      transform="translate(-2805.049 -205)"
                                    >
                                      <g id="Group_703" data-name="Group 703">
                                        <path
                                          id="Path_641"
                                          data-name="Path 641"
                                          d="M2913.3,208.034a13.135,13.135,0,0,1,3.65,7.249,11.371,11.371,0,0,1-11.2-10.218A13.131,13.131,0,0,1,2913.3,208.034Z"
                                          fill="#fff"
                                        />
                                        <g id="Group_701" data-name="Group 701">
                                          <g id="Group_689" data-name="Group 689">
                                            <path
                                              id="Path_642"
                                              data-name="Path 642"
                                              d="M2891.547,312.009a13.761,13.761,0,0,0-2.788,7.465c-.011.052,5.588-1.514,8.1-5.283a9.663,9.663,0,0,0,1.326-7.567A22.826,22.826,0,0,0,2891.547,312.009Z"
                                              fill="#fff"
                                            />
                                            <path
                                              id="Path_643"
                                              data-name="Path 643"
                                              d="M2895.468,323.842a9.5,9.5,0,0,1-6.746-4.517c.058.018,5.324-1.5,9.861-.968a12.551,12.551,0,0,1,7.275,3.434C2905.923,321.806,2900.436,324.7,2895.468,323.842Z"
                                              fill="#fff"
                                            />
                                          </g>
                                          <g id="Group_690" data-name="Group 690">
                                            <path
                                              id="Path_644"
                                              data-name="Path 644"
                                              d="M2901.262,307.741a13.518,13.518,0,0,0-1.139,7.672c-.005.052,4.916-2.391,6.574-6.459a9.681,9.681,0,0,0-.2-7.542A20.972,20.972,0,0,0,2901.262,307.741Z"
                                              fill="#fff"
                                            />
                                            <path
                                              id="Path_645"
                                              data-name="Path 645"
                                              d="M2907.263,318.51a9.369,9.369,0,0,1-7.2-3.235,28.049,28.049,0,0,1,9.074-2.586,12.346,12.346,0,0,1,7.568,2.091C2916.762,314.784,2912.091,318.506,2907.263,318.51Z"
                                              fill="#fff"
                                            />
                                          </g>
                                          <g id="Group_691" data-name="Group 691">
                                            <path
                                              id="Path_646"
                                              data-name="Path 646"
                                              d="M2909.649,301.968a13.425,13.425,0,0,0,.376,7.615c0,.052,4.217-3.144,5.06-7.371a9.681,9.681,0,0,0-1.578-7.278A19.867,19.867,0,0,0,2909.649,301.968Z"
                                              fill="#fff"
                                            />
                                            <path
                                              id="Path_647"
                                              data-name="Path 647"
                                              d="M2917.419,311.367a9.193,9.193,0,0,1-7.481-1.906,26.268,26.268,0,0,1,8.182-4.048,12.16,12.16,0,0,1,7.675.726C2925.852,306.134,2922.028,310.545,2917.419,311.367Z"
                                              fill="#fff"
                                            />
                                          </g>
                                          <g id="Group_692" data-name="Group 692">
                                            <path
                                              id="Path_648"
                                              data-name="Path 648"
                                              d="M2916.65,294.928a13.291,13.291,0,0,0,1.753,7.322c.008.051,3.488-3.774,3.56-8.03a9.6,9.6,0,0,0-2.82-6.8A19.255,19.255,0,0,0,2916.65,294.928Z"
                                              fill="#fff"
                                            />
                                            <path
                                              id="Path_649"
                                              data-name="Path 649"
                                              d="M2925.869,302.7a9.043,9.043,0,0,1-7.572-.558,25.246,25.246,0,0,1,7.182-5.345,12.029,12.029,0,0,1,7.592-.632C2933.124,296.156,2930.175,301.112,2925.869,302.7Z"
                                              fill="#fff"
                                            />
                                          </g>
                                          <g id="Group_693" data-name="Group 693">
                                            <path
                                              id="Path_650"
                                              data-name="Path 650"
                                              d="M2922.205,286.86a13.1,13.1,0,0,0,2.989,6.819c.014.048,2.73-4.279,2.075-8.444a9.462,9.462,0,0,0-3.915-6.143A18.971,18.971,0,0,0,2922.205,286.86Z"
                                              fill="#fff"
                                            />
                                            <path
                                              id="Path_651"
                                              data-name="Path 651"
                                              d="M2932.545,292.816a8.953,8.953,0,0,1-7.472.781,24.835,24.835,0,0,1,6.074-6.465,12,12,0,0,1,7.314-1.957C2938.509,285.153,2936.465,290.512,2932.545,292.816Z"
                                              fill="#fff"
                                            />
                                          </g>
                                          <g id="Group_694" data-name="Group 694">
                                            <path
                                              id="Path_652"
                                              data-name="Path 652"
                                              d="M2926.256,278a12.826,12.826,0,0,0,4.079,6.133c.021.045,1.943-4.661.607-8.625a9.275,9.275,0,0,0-4.859-5.324A18.858,18.858,0,0,0,2926.256,278Z"
                                              fill="#fff"
                                            />
                                            <path
                                              id="Path_653"
                                              data-name="Path 653"
                                              d="M2937.379,281.99a8.925,8.925,0,0,1-7.177,2.087,24.913,24.913,0,0,1,4.855-7.4,12.06,12.06,0,0,1,6.838-3.224C2941.939,273.427,2940.827,279.045,2937.379,281.99Z"
                                              fill="#fff"
                                            />
                                          </g>
                                          <g id="Group_695" data-name="Group 695">
                                            <path
                                              id="Path_654"
                                              data-name="Path 654"
                                              d="M2928.745,268.6a12.523,12.523,0,0,0,5.016,5.289c.027.042,1.128-4.916-.841-8.581a9.1,9.1,0,0,0-5.648-4.374A18.9,18.9,0,0,0,2928.745,268.6Z"
                                              fill="#fff"
                                            />
                                            <path
                                              id="Path_655"
                                              data-name="Path 655"
                                              d="M2940.3,270.523a8.953,8.953,0,0,1-6.684,3.33,25.309,25.309,0,0,1,3.524-8.135,12.169,12.169,0,0,1,6.158-4.4C2943.342,261.28,2943.193,267.016,2940.3,270.523Z"
                                              fill="#fff"
                                            />
                                          </g>
                                          <g id="Group_696" data-name="Group 696">
                                            <path
                                              id="Path_656"
                                              data-name="Path 656"
                                              d="M2929.611,258.885a12.232,12.232,0,0,0,5.8,4.316c.034.037.283-5.047-2.269-8.321a8.931,8.931,0,0,0-6.278-3.319A19.217,19.217,0,0,0,2929.611,258.885Z"
                                              fill="#fff"
                                            />
                                            <path
                                              id="Path_657"
                                              data-name="Path 657"
                                              d="M2941.253,258.708a9,9,0,0,1-5.986,4.485,26.061,26.061,0,0,1,2.079-8.666,12.275,12.275,0,0,1,5.269-5.467C2942.649,249.02,2943.492,254.73,2941.253,258.708Z"
                                              fill="#fff"
                                            />
                                          </g>
                                          <g id="Group_697" data-name="Group 697">
                                            <path
                                              id="Path_658"
                                              data-name="Path 658"
                                              d="M2928.8,249.107a12.013,12.013,0,0,0,6.422,3.241c.041.031-.591-5.053-3.675-7.857a8.816,8.816,0,0,0-6.743-2.187A19.785,19.785,0,0,0,2928.8,249.107Z"
                                              fill="#fff"
                                            />
                                            <path
                                              id="Path_659"
                                              data-name="Path 659"
                                              d="M2940.156,246.841a9.031,9.031,0,0,1-5.079,5.524,27.222,27.222,0,0,1,.517-8.98,12.4,12.4,0,0,1,4.168-6.392C2939.791,236.949,2941.655,242.491,2940.156,246.841Z"
                                              fill="#fff"
                                            />
                                          </g>
                                          <g id="Group_698" data-name="Group 698">
                                            <path
                                              id="Path_660"
                                              data-name="Path 660"
                                              d="M2926.241,239.508a11.838,11.838,0,0,0,6.879,2.088c.048.025-1.493-4.933-5.057-7.2a8.73,8.73,0,0,0-7.039-1A20.87,20.87,0,0,0,2926.241,239.508Z"
                                              fill="#fff"
                                            />
                                            <path
                                              id="Path_661"
                                              data-name="Path 661"
                                              d="M2936.945,235.218a9.039,9.039,0,0,1-3.96,6.42,28.986,28.986,0,0,1-1.16-9.066,12.5,12.5,0,0,1,2.849-7.149C2934.7,225.375,2937.612,230.607,2936.945,235.218Z"
                                              fill="#fff"
                                            />
                                          </g>
                                          <g id="Group_699" data-name="Group 699">
                                            <path
                                              id="Path_662"
                                              data-name="Path 662"
                                              d="M2921.885,230.331a11.682,11.682,0,0,0,7.168.885c.055.018-2.425-4.685-6.414-6.352a8.633,8.633,0,0,0-7.162.207A22.544,22.544,0,0,0,2921.885,230.331Z"
                                              fill="#fff"
                                            />
                                            <path
                                              id="Path_663"
                                              data-name="Path 663"
                                              d="M2931.552,224.135a9.023,9.023,0,0,1-2.623,7.148,31.729,31.729,0,0,1-2.958-8.916,12.723,12.723,0,0,1,1.31-7.711C2927.3,214.606,2931.292,219.385,2931.552,224.135Z"
                                              fill="#fff"
                                            />
                                          </g>
                                          <g id="Group_700" data-name="Group 700">
                                            <path
                                              id="Path_664"
                                              data-name="Path 664"
                                              d="M2915.669,221.82a11.487,11.487,0,0,0,7.284-.34c.062.011-3.387-4.312-7.744-5.33a8.465,8.465,0,0,0-7.108,1.414A24.962,24.962,0,0,0,2915.669,221.82Z"
                                              fill="#fff"
                                            />
                                            <path
                                              id="Path_665"
                                              data-name="Path 665"
                                              d="M2923.908,213.891a9.059,9.059,0,0,1-1.064,7.678,36.057,36.057,0,0,1-4.876-8.517,13.255,13.255,0,0,1-.457-8.052C2917.525,204.948,2922.626,209.132,2923.908,213.891Z"
                                              fill="#fff"
                                            />
                                          </g>
                                        </g>
                                        <g id="Group_702" data-name="Group 702">
                                          <path
                                            id="Path_666"
                                            data-name="Path 666"
                                            d="M2873.008,320.713l.078-.865a53.3,53.3,0,0,0,21.216-2.37,56.207,56.207,0,0,0,18.548-10.156,59.012,59.012,0,0,0,20.979-38.147c1.618-12.369.788-23.392-2.467-32.762a43.7,43.7,0,0,0-18.5-23.4l.459-.737a44.547,44.547,0,0,1,18.859,23.853c3.3,9.5,4.143,20.655,2.508,33.16a59.879,59.879,0,0,1-21.289,38.7,57.032,57.032,0,0,1-18.834,10.312A54.183,54.183,0,0,1,2873.008,320.713Z"
                                            fill="#fff"
                                          />
                                        </g>
                                      </g>
                                      <g id="Group_718" data-name="Group 718">
                                        <path
                                          id="Path_667"
                                          data-name="Path 667"
                                          d="M2835.054,208.034a13.135,13.135,0,0,0-3.65,7.249,11.371,11.371,0,0,0,11.2-10.218A13.135,13.135,0,0,0,2835.054,208.034Z"
                                          fill="#fff"
                                        />
                                        <g id="Group_716" data-name="Group 716">
                                          <g id="Group_704" data-name="Group 704">
                                            <path
                                              id="Path_668"
                                              data-name="Path 668"
                                              d="M2856.81,312.009a13.771,13.771,0,0,1,2.788,7.465c.01.052-5.589-1.514-8.1-5.283a9.653,9.653,0,0,1-1.326-7.567A22.842,22.842,0,0,1,2856.81,312.009Z"
                                              fill="#fff"
                                            />
                                            <path
                                              id="Path_669"
                                              data-name="Path 669"
                                              d="M2852.888,323.842a9.5,9.5,0,0,0,6.746-4.517c-.057.018-5.324-1.5-9.861-.968a12.551,12.551,0,0,0-7.275,3.434C2842.433,321.806,2847.92,324.7,2852.888,323.842Z"
                                              fill="#fff"
                                            />
                                          </g>
                                          <g id="Group_705" data-name="Group 705">
                                            <path
                                              id="Path_670"
                                              data-name="Path 670"
                                              d="M2847.095,307.741a13.525,13.525,0,0,1,1.139,7.672c.005.052-4.917-2.391-6.574-6.459a9.683,9.683,0,0,1,.194-7.542A20.967,20.967,0,0,1,2847.095,307.741Z"
                                              fill="#fff"
                                            />
                                            <path
                                              id="Path_671"
                                              data-name="Path 671"
                                              d="M2841.093,318.51a9.371,9.371,0,0,0,7.205-3.235,28.064,28.064,0,0,0-9.075-2.586,12.344,12.344,0,0,0-7.567,2.091C2831.595,314.784,2836.265,318.506,2841.093,318.51Z"
                                              fill="#fff"
                                            />
                                          </g>
                                          <g id="Group_706" data-name="Group 706">
                                            <path
                                              id="Path_672"
                                              data-name="Path 672"
                                              d="M2838.707,301.968a13.413,13.413,0,0,1-.376,7.615c0,.052-4.216-3.144-5.059-7.371a9.676,9.676,0,0,1,1.578-7.278A19.874,19.874,0,0,1,2838.707,301.968Z"
                                              fill="#fff"
                                            />
                                            <path
                                              id="Path_673"
                                              data-name="Path 673"
                                              d="M2830.937,311.367a9.192,9.192,0,0,0,7.481-1.906,26.245,26.245,0,0,0-8.181-4.048,12.161,12.161,0,0,0-7.675.726C2822.5,306.134,2826.329,310.545,2830.937,311.367Z"
                                              fill="#fff"
                                            />
                                          </g>
                                          <g id="Group_707" data-name="Group 707">
                                            <path
                                              id="Path_674"
                                              data-name="Path 674"
                                              d="M2831.707,294.928a13.287,13.287,0,0,1-1.754,7.322c-.008.051-3.488-3.774-3.559-8.03a9.605,9.605,0,0,1,2.819-6.8A19.248,19.248,0,0,1,2831.707,294.928Z"
                                              fill="#fff"
                                            />
                                            <path
                                              id="Path_675"
                                              data-name="Path 675"
                                              d="M2822.488,302.7a9.041,9.041,0,0,0,7.571-.558,25.253,25.253,0,0,0-7.182-5.345,12.03,12.03,0,0,0-7.592-.632C2815.233,296.156,2818.181,301.112,2822.488,302.7Z"
                                              fill="#fff"
                                            />
                                          </g>
                                          <g id="Group_708" data-name="Group 708">
                                            <path
                                              id="Path_676"
                                              data-name="Path 676"
                                              d="M2826.151,286.86a13.088,13.088,0,0,1-2.989,6.819c-.014.048-2.73-4.279-2.075-8.444a9.467,9.467,0,0,1,3.915-6.143A18.971,18.971,0,0,1,2826.151,286.86Z"
                                              fill="#fff"
                                            />
                                            <path
                                              id="Path_677"
                                              data-name="Path 677"
                                              d="M2815.812,292.816a8.954,8.954,0,0,0,7.472.781,24.874,24.874,0,0,0-6.074-6.465,12,12,0,0,0-7.315-1.957C2809.847,285.153,2811.892,290.512,2815.812,292.816Z"
                                              fill="#fff"
                                            />
                                          </g>
                                          <g id="Group_709" data-name="Group 709">
                                            <path
                                              id="Path_678"
                                              data-name="Path 678"
                                              d="M2822.1,278a12.822,12.822,0,0,1-4.078,6.133c-.021.045-1.944-4.661-.608-8.625a9.275,9.275,0,0,1,4.859-5.324A18.837,18.837,0,0,1,2822.1,278Z"
                                              fill="#fff"
                                            />
                                            <path
                                              id="Path_679"
                                              data-name="Path 679"
                                              d="M2810.977,281.99a8.927,8.927,0,0,0,7.178,2.087,24.926,24.926,0,0,0-4.856-7.4,12.059,12.059,0,0,0-6.837-3.224C2806.418,273.427,2807.529,279.045,2810.977,281.99Z"
                                              fill="#fff"
                                            />
                                          </g>
                                          <g id="Group_710" data-name="Group 710">
                                            <path
                                              id="Path_680"
                                              data-name="Path 680"
                                              d="M2819.612,268.6a12.529,12.529,0,0,1-5.017,5.289c-.027.042-1.128-4.916.842-8.581a9.093,9.093,0,0,1,5.648-4.374A18.917,18.917,0,0,1,2819.612,268.6Z"
                                              fill="#fff"
                                            />
                                            <path
                                              id="Path_681"
                                              data-name="Path 681"
                                              d="M2808.052,270.523a8.951,8.951,0,0,0,6.683,3.33,25.313,25.313,0,0,0-3.524-8.135,12.172,12.172,0,0,0-6.157-4.4C2805.015,261.28,2805.163,267.016,2808.052,270.523Z"
                                              fill="#fff"
                                            />
                                          </g>
                                          <g id="Group_711" data-name="Group 711">
                                            <path
                                              id="Path_682"
                                              data-name="Path 682"
                                              d="M2818.745,258.885a12.232,12.232,0,0,1-5.8,4.316c-.034.037-.283-5.047,2.27-8.321a8.931,8.931,0,0,1,6.277-3.319A19.21,19.21,0,0,1,2818.745,258.885Z"
                                              fill="#fff"
                                            />
                                            <path
                                              id="Path_683"
                                              data-name="Path 683"
                                              d="M2807.1,258.708a9,9,0,0,0,5.985,4.485,26.071,26.071,0,0,0-2.079-8.666,12.263,12.263,0,0,0-5.269-5.467C2805.707,249.02,2804.864,254.73,2807.1,258.708Z"
                                              fill="#fff"
                                            />
                                          </g>
                                          <g id="Group_712" data-name="Group 712">
                                            <path
                                              id="Path_684"
                                              data-name="Path 684"
                                              d="M2819.56,249.107a12.012,12.012,0,0,1-6.421,3.241c-.041.031.59-5.053,3.675-7.857a8.813,8.813,0,0,1,6.742-2.187A19.785,19.785,0,0,1,2819.56,249.107Z"
                                              fill="#fff"
                                            />
                                            <path
                                              id="Path_685"
                                              data-name="Path 685"
                                              d="M2808.2,246.841a9.032,9.032,0,0,0,5.079,5.524,27.206,27.206,0,0,0-.518-8.98,12.393,12.393,0,0,0-4.168-6.392C2808.565,236.949,2806.7,242.491,2808.2,246.841Z"
                                              fill="#fff"
                                            />
                                          </g>
                                          <g id="Group_713" data-name="Group 713">
                                            <path
                                              id="Path_686"
                                              data-name="Path 686"
                                              d="M2822.116,239.508a11.84,11.84,0,0,1-6.88,2.088c-.048.025,1.494-4.933,5.058-7.2a8.73,8.73,0,0,1,7.039-1A20.905,20.905,0,0,1,2822.116,239.508Z"
                                              fill="#fff"
                                            />
                                            <path
                                              id="Path_687"
                                              data-name="Path 687"
                                              d="M2811.412,235.218a9.041,9.041,0,0,0,3.959,6.42,28.951,28.951,0,0,0,1.161-9.066,12.5,12.5,0,0,0-2.85-7.149C2813.658,225.375,2810.744,230.607,2811.412,235.218Z"
                                              fill="#fff"
                                            />
                                          </g>
                                          <g id="Group_714" data-name="Group 714">
                                            <path
                                              id="Path_688"
                                              data-name="Path 688"
                                              d="M2826.472,230.331a11.685,11.685,0,0,1-7.169.885c-.055.018,2.425-4.685,6.414-6.352a8.632,8.632,0,0,1,7.162.207A22.533,22.533,0,0,1,2826.472,230.331Z"
                                              fill="#fff"
                                            />
                                            <path
                                              id="Path_689"
                                              data-name="Path 689"
                                              d="M2816.8,224.135a9.029,9.029,0,0,0,2.622,7.148,31.729,31.729,0,0,0,2.958-8.916,12.727,12.727,0,0,0-1.309-7.711C2821.057,214.606,2817.064,219.385,2816.8,224.135Z"
                                              fill="#fff"
                                            />
                                          </g>
                                          <g id="Group_715" data-name="Group 715">
                                            <path
                                              id="Path_690"
                                              data-name="Path 690"
                                              d="M2832.688,221.82a11.486,11.486,0,0,1-7.284-.34c-.062.011,3.386-4.312,7.743-5.33a8.465,8.465,0,0,1,7.108,1.414A24.947,24.947,0,0,1,2832.688,221.82Z"
                                              fill="#fff"
                                            />
                                            <path
                                              id="Path_691"
                                              data-name="Path 691"
                                              d="M2824.448,213.891a9.068,9.068,0,0,0,1.064,7.678,36.01,36.01,0,0,0,4.876-8.517,13.255,13.255,0,0,0,.457-8.052C2830.832,204.948,2825.731,209.132,2824.448,213.891Z"
                                              fill="#fff"
                                            />
                                          </g>
                                        </g>
                                        <g id="Group_717" data-name="Group 717">
                                          <path
                                            id="Path_692"
                                            data-name="Path 692"
                                            d="M2875.349,320.713l-.079-.865a53.3,53.3,0,0,1-21.216-2.37,56.189,56.189,0,0,1-18.547-10.156,59.011,59.011,0,0,1-20.98-38.147c-1.618-12.369-.788-23.392,2.467-32.762a43.7,43.7,0,0,1,18.5-23.4l-.459-.737a44.558,44.558,0,0,0-18.859,23.853c-3.3,9.5-4.143,20.655-2.507,33.16a59.874,59.874,0,0,0,21.289,38.7,57.02,57.02,0,0,0,18.833,10.312A54.191,54.191,0,0,0,2875.349,320.713Z"
                                            fill="#fff"
                                          />
                                        </g>
                                      </g>
                                    </g>
                                    <path
                                      id="Path_693"
                                      data-name="Path 693"
                                      d="M2885.2,274.8l-11.247-7.5-12.557,5.011,3.661-13.015L2856.41,248.9l13.509-.54,7.214-11.435,4.688,12.681,13.1,3.327-10.612,8.378Z"
                                      transform="translate(-2805.049 -205)"
                                      fill="#fff"
                                    />
                                  </svg>
                                  <div className="unlocked-badges-text-container">
                                    <span className="unlocked-bhadges-in-perc">100%</span>
                                    <span>الشارات المفتوحة</span>
                                  </div>
                                </div>
                              </div>
                              <div className="overall-progress-stats-content">
                                <div className="overall-progress-stats-elem -gray-stats--bar">
                                  <div className="overall-progress-stat-perc-ring">
                                    <div className="overall-progress-stat-perc-ring-content">
                                      <svg
                                        className="pie"
                                        viewBox="0 0 200 200"
                                        data-percent="60"
                                      >
                                        <circle r="90" cx="100" cy="100"></circle>
                                        <circle
                                          className="bar"
                                          r="90"
                                          cx="100"
                                          cy="100"
                                          style={{
                                            strokeDashoffset: '226.195px',
                                            strokeDasharray: '565.487px',
                                          }}
                                        ></circle>
                                      </svg>
                                    </div>
                                    <div className="perc-in-number">
                                      <span>0%</span>
                                    </div>
                                  </div>
                                  <div className="vert-line"></div>
                                  <div className="stats-related-course">
                                    <span>الدورات</span>
                                  </div>
                                </div>
                                <div className="overall-progress-stats-elem -green-stats--bar">
                                  <div className="overall-progress-stat-perc-ring">
                                    <div className="overall-progress-stat-perc-ring-content">
                                      <svg
                                        className="pie"
                                        viewBox="0 0 200 200"
                                        data-percent="85"
                                      >
                                        <circle r="90" cx="100" cy="100"></circle>
                                        <circle
                                          className="bar"
                                          r="90"
                                          cx="100"
                                          cy="100"
                                          style={{
                                            strokeDashoffset: '84.823px',
                                            strokeDasharray: '565.487px',
                                          }}
                                        ></circle>
                                      </svg>
                                    </div>
                                    <div className="perc-in-number">
                                      <span>0%</span>
                                    </div>
                                  </div>
                                  <div className="vert-line"></div>
                                  <div className="stats-related-course">
                                    <span>الاكمال</span>
                                  </div>
                                </div>
                                <div className="overall-progress-stats-elem -red-stats--bar">
                                  <div className="overall-progress-stat-perc-ring">
                                    <div className="overall-progress-stat-perc-ring-content">
                                      <svg
                                        className="pie"
                                        viewBox="0 0 200 200"
                                        data-percent="15"
                                      >
                                        <circle r="90" cx="100" cy="100"></circle>
                                        <circle
                                          className="bar"
                                          r="90"
                                          cx="100"
                                          cy="100"
                                          style={{
                                            strokeDashoffset: '480.664px',
                                            strokeDasharray: '565.487px',
                                          }}
                                        ></circle>
                                      </svg>
                                    </div>
                                    <div className="perc-in-number">
                                      <span>0%</span>
                                    </div>
                                  </div>
                                  <div className="vert-line"></div>
                                  <div className="stats-related-course">
                                    <span>الانجازات</span>
                                  </div>
                                </div>
                                <div className="overall-progress-stats-elem -black-stats--bar">
                                  <div className="overall-progress-stat-perc-ring">
                                    <div className="overall-progress-stat-perc-ring-content">
                                      <svg
                                        className="pie"
                                        viewBox="0 0 200 200"
                                        data-percent="15"
                                      >
                                        <circle r="90" cx="100" cy="100"></circle>
                                        <circle
                                          className="bar"
                                          r="90"
                                          cx="100"
                                          cy="100"
                                          style={{
                                            strokeDashoffset: '480.664px',
                                            strokeDasharray: '565.487px',
                                          }}
                                        ></circle>
                                      </svg>
                                    </div>
                                    <div className="perc-in-number">
                                      <span>0%</span>
                                    </div>
                                  </div>
                                  <div className="vert-line"></div>
                                  <div className="stats-related-course">
                                    <span>متوسط الدرجات</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/*
                        <!--
                        <div className="user-overall-progress-container">
                        <div className="user-overall-progress-content">
                          <div className="overall-progress-stats-content">
                            <div className="overall-progress-stats-elem gray-stats--bar">
                              <div className="overall-progress-stat-perc-ring">
                                <div className="overall-progress-stat-perc-ring-content">
                                  <svg className="pie" viewBox="0 0 200 200" data-percent="60">
                                    <circle r="90" cx="100" cy="100"></circle>
                                    <circle className="bar" r="90" cx="100" cy="100" style="stroke-dashoffset: 226.195px; stroke-dasharray: 565.487px;">
                                    </circle>
                                  </svg>
                                </div>
                                <div className="perc-in-number">
                                  <span>60%</span>
                                </div>

                              </div>
                              <div className="stats-related-course">
                                <span>دورة الذكاء الاصطناعي</span>
                              </div>
                            </div>
                            <div className="overall-progress-stats-elem green-stats--bar">
                              <div className="overall-progress-stat-perc-ring">
                                <div className="overall-progress-stat-perc-ring-content">
                                  <svg className="pie" viewBox="0 0 200 200" data-percent="85">
                                    <circle r="90" cx="100" cy="100"></circle>
                                    <circle className="bar" r="90" cx="100" cy="100" style="stroke-dashoffset: 84.823px; stroke-dasharray: 565.487px;">
                                    </circle>
                                  </svg>
                                </div>
                                <div className="perc-in-number">
                                  <span>85%</span>
                                </div>

                              </div>
                              <div className="stats-related-course">
                                <span>العمل عن بعد </span>
                              </div>
                            </div>
                            <div className="overall-progress-stats-elem red-stats--bar">
                              <div className="overall-progress-stat-perc-ring">
                                <div className="overall-progress-stat-perc-ring-content">
                                  <svg className="pie" viewBox="0 0 200 200" data-percent="15">
                                    <circle r="90" cx="100" cy="100"></circle>
                                    <circle className="bar" r="90" cx="100" cy="100" style="stroke-dashoffset: 480.664px; stroke-dasharray: 565.487px;">
                                    </circle>
                                  </svg>
                                </div>
                                <div className="perc-in-number">
                                  <span>15%</span>
                                </div>

                              </div>
                              <div className="stats-related-course">
                                <span>كورس ممارسة التسامح</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>-->*/}
                          </div>
                        </div>

                        <div className="dashboard-column-elem stats-graph-container">
                          <div className="stats-graph-content">
                            <div className="stats-graph-header">
                              <span>وقت التدريب</span>
                              <div className="graph-control-container">
                                <div className="graph-control-content">
                                  <span>اكثر الدورات حضور</span>
                                  <span>/</span>
                                  <select name="" id="">
                                    <option>الساعة</option>
                                    <option>الساعة</option>
                                    <option>الساعة</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div className="stats-graph-body">
                              <div className="stats-graph-body-content">
                                <div className="art-container">
                                  <img src={Group2x} alt="" />
                                </div>

                                <div className="actual-grah-container">
                                  <div className="actual-grah-content">
                                    <div className="graph-placeholder">
                                      <img src={Group23552x} alt="" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
      </>
    );
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Progressive));
