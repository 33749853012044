import React, { Component } from 'react';

class UserProgressCprOthers extends Component {
  render() {
    const { countTraining, countCertification ,badge,score} = this.props;
    return (
      <div className="dashboard-column-elem -pcr-co">
        <div className="user-progress-cpr-others">
          <div className="user-progress-cpr-others-content">
            <div className="user-progress-cpr-others-title">
              <span>المقارنة مع الاخرين</span>
            </div>
            <div className="user-progress-cpr-others-body">
              <div className="user-progress-cpr-others-body-content">
                <div className="user-progress-cpr-others-stats">
                  <div className="user-progress-cpr-others-stats-column user-lvl-stats">
                    <div className="user-progress-cpr-others-stats-column-cont">
                      <span className="stats-in-num">0</span>
                      <div style={{height:'0%'}} className="ver-progress-bar"></div>
                      <span>المستوى</span>
                    </div>
                  </div>
                  <div className="user-progress-cpr-others-stats-column user-badges-stats">
                    <div className="user-progress-cpr-others-stats-column-cont">
                      <span className="stats-in-num">{badge || 0}</span>
                      <div style={{height:`${(badge || 0)/3}%`}} className="ver-progress-bar"></div>
                      <span>الشارات</span>
                    </div>
                  </div>
                  <div className="user-progress-cpr-others-stats-column user-score-stats">
                    <div className="user-progress-cpr-others-stats-column-cont">
                      <span className="stats-in-num">{score || 0}</span>
                      <div style={{height:`${(score || 0)/3}%`}} className="ver-progress-bar"></div>
                      <span>النقاط</span>
                    </div>
                  </div>
                  <div className="user-progress-cpr-others-stats-column user-certificats-stats">
                    <div className="user-progress-cpr-others-stats-column-cont">
                      <span className="stats-in-num">{countCertification || 0}</span>
                      <div style={{height:`${(countCertification || 0)/3}%`}} className="ver-progress-bar"></div>
                      <span>الشهادات</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UserProgressCprOthers;
