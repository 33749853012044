import React from 'react';
import Chart from 'react-apexcharts';

class ApexChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      series: [
        props.countTrainingIsFinish,
        props.countTrainingIsStart,
        props.countTrainingIsProgress,
      ],
      options: {
        chart: {
          height: 350,
          type: 'radialBar',
        },
        plotOptions: {
          radialBar: {
            dataLabels: {
              name: {
                fontSize: '22px',
              },
              value: {
                fontSize: '16px',
              },
              total: {
                show: true,
                label: '100',
                formatter: function (w) {
                  // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                  return '';
                },
              },
            },
          },
        },
        labels: ['إكمال', 'لم تبدأ', 'في تقدم'],
      },
    };
  }

  render() {
    return (
      <div id="chart">
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="radialBar"
          height={200}
        />
      </div>
    );
  }
}
export default ApexChart;
