import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { validate } from '../../../utils/helpers';
import actions from '../../../js/actions';
import { connect } from 'react-redux';
const mapStateToProps = (state) => {
  return {
    isLoadingContact: state.AppReducer.isLoadingContact,
    contact: state.AppReducer.contact,
  };
};

const mapDispatchToProps = (dispatch) => ({
  submitContact: (values) => dispatch(actions.submitContact(values)),
});
class ContactProfile extends Component {
  constructor(props) {
    super(props);
    document.title = 'تواصل معنا';
    this.state = {
      initialValues: {
        name: '',
        message: '',
        email: '',
        subject: '',
      },
    };
    this.validator = new SimpleReactValidator({
      messages: {
        email: 'Adresse e-mail invalide',
        min: 'Saisissez un mot de passe comprenant au moins 6 caractères.',
        max: 'Le mot de passe doit comporter de 6 à 20 caractères. ',
        // OR
        default: 'Champ requise.', // will override all messenger
      },
    });
  }

  submitForm(initialValues) {
    if (
      !initialValues.name ||
      !initialValues.message ||
      !initialValues.email ||
      !initialValues.subject ||
      !validate(initialValues.name)
    ) {
      this.setState({
        ...initialValues,
        hasError: true,
      });
    } else if (this.validator.allValid()) {
      this.props.submitContact(initialValues);
    } else {
      this.setState({
        ...initialValues,
        hasError: true,
      });
      this.validator.showMessages();
      // rerender to show messenger for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  }
  render() {
    const { initialValues, hasError } = this.state;
    return (
      <div className="user-profil-contact-form-container">
        <div className="user-profil-contact-form-content">
          <div className="user-profil-contact-form-title"></div>
          <form action="">
            <div className="user-profil-contact-form-row">
              <div className="input-field-label-container">
                <div className="input-field-label-content">
                  <label htmlFor="">الأسم </label>
                </div>
              </div>
              <div
                className={
                  (hasError && !initialValues.name) ||
                  (hasError && initialValues.name && !validate(initialValues.name))
                    ? 'input-field-container input-error'
                    : 'input-field-container'
                }
              >
                <div className="input-field-content">
                  <input
                    name="name"
                    onChange={(e) => {
                      this.setState({
                        initialValues: {
                          ...initialValues,
                          name: e.target.value,
                        },
                      });
                    }}
                    required={this.validator.message(
                      'name',
                      initialValues.name,
                      'required|string',
                    )}
                    placeholder="الأسم"
                    type="text"
                  />
                </div>
              </div>
            </div>
            <div className="user-profil-contact-form-row">
              <div className="input-field-label-container">
                <div className="input-field-label-content">
                  <label htmlFor="">البريد الإلكتروني</label>
                </div>
              </div>
              <div
                className={
                  (hasError && !initialValues.email) ||
                  (this.validator.message(
                    'email',
                    initialValues.email,
                    'required|email',
                  ) &&
                    this.validator.message('email', initialValues.email, 'required|email')
                      .type &&
                    initialValues.email)
                    ? 'input-field-container input-error'
                    : 'input-field-container'
                }
              >
                <div className="input-field-content">
                  <input
                    required={this.validator.message(
                      'email',
                      initialValues.email,
                      'required|email',
                    )}
                    name="email"
                    onChange={(e) => {
                      this.setState({
                        initialValues: {
                          ...initialValues,
                          email: e.target.value,
                        },
                      });
                    }}
                    type="text"
                    placeholder="البريد الإلكتروني"
                  />
                </div>
              </div>
            </div>
            <div className="user-profil-contact-form-row">
              <div className="input-field-label-container">
                <div className="input-field-label-content">
                  <label htmlFor="">الموضوع</label>
                </div>
              </div>
              <div
                className={
                  (hasError && !initialValues.subject) ||
                  (hasError && initialValues.subject && !validate(initialValues.subject))
                    ? 'input-field-container input-error'
                    : 'input-field-container'
                }
              >
                <div className="input-field-content">
                  <input
                    name="subject"
                    onChange={(e) => {
                      this.setState({
                        initialValues: {
                          ...initialValues,
                          subject: e.target.value,
                        },
                      });
                    }}
                    required={this.validator.message(
                      'subject',
                      initialValues.subject,
                      'required|string',
                    )}
                    placeholder="الموضوع"
                    type="text"
                  />
                </div>
              </div>
            </div>
            <div className="user-profil-contact-form-row">
              <div className="input-field-label-container">
                <div className="input-field-label-content">
                  <label htmlFor="">رسالتك</label>
                </div>
              </div>
              <div
                className={
                  (hasError && !initialValues.message) ||
                  (hasError && initialValues.message && !validate(initialValues.message))
                    ? 'input-field-container input-error'
                    : 'input-field-container'
                }
              >
                <div className="input-field-content">
                  <textarea
                    name="message"
                    onChange={(e) => {
                      this.setState({
                        initialValues: {
                          ...initialValues,
                          message: e.target.value,
                        },
                      });
                    }}
                    required={this.validator.message(
                      'message',
                      initialValues.message,
                      'required|string',
                    )}
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    rows="5"
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="user-profil-contact-form-row">
              <button
                type="button"
                onClick={() => this.submitForm(initialValues)}
                style={{
                  borderRadius: '10px',
                }}
                className="btn btn-red"
              >
                إرسال
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ContactProfile);
