import axios from 'axios';
import { host } from '../../../../utils/constants';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToastAction from '../../../../componenets/ToastAction';

const InitialState = () => async (dispatch) => {
  dispatch({
    type: 'INITIAL_STATE_SIGN_UP',
  });
};

const submitAccountForm = (values) => async (dispatch) => {
  dispatch({
    type: 'CREATE_NEW_ACCOUNT_REQUESTING',
  });
  const {
    first_name,
    admin,
    address_country,
    course_id,
    last_name,
    phone,
    email,
    password,
    job_title,
    circle,
  } = values;
  let val = {
    first_name,
    address_country,
    last_name,
    course_id,
    email,
    password,
    phone,
    admin,
    job_title,
    circle,
  };
  try {
    const { data } = await axios.post(`${host}/api/user/create/`, val);
    const response = data;

    if (data.id) {
      toast(({ closeToast }) => <ToastAction type="success" message="إضافة المستخدم" />, {
        hideProgressBar: true,
      });
      dispatch({
        type: 'CREATE_NEW_ACCOUNT_SUCCESS',
        msg: response.message,
        data: data,
      });
    } else {
      toast(({ closeToast }) => <ToastAction type="error" message={data.message} />, {
        hideProgressBar: true,
      });
      dispatch({
        type: 'CREATE_NEW_ACCOUNT_FAILURE',
        msg: response.data,
        ErrorMessage: response.message,
        site: values,
      });
    }
  } catch (e) {
    const { response } = e;
    if (response) {
      const { data } = response;
      if (data) {
        toast(({ closeToast }) => <ToastAction type="error" message="خطئ في التحقق" />, {
          hideProgressBar: true,
        });
      }
      dispatch({
        type: 'CREATE_NEW_ACCOUNT_FAILURE',
        msg: 'error server',
        err: data.data || data.type,
        data: values,
      });
    }
  }
};

export default {
  submitAccountForm,
  InitialState,
};
