import React, { Component } from 'react';
import FormMember from './containers/formMember';
import { CircularProgress } from '@material-ui/core';
import ConditionMember from './components/conditionMember';
import ConditionInstitution from './components/conditionInstitution';
import ConditionInstructor from './components/conditionInstructor';
import { connect } from 'react-redux';
import Headers from '../../layouts/headers';
const mapStateToProps = (state, ownProps) => {
  return {
    isLoadingNewMember: state.MemberReducer.isLoadingNewMember,
    newMember: state.MemberReducer.newMember,
    isAuthorized: state.AppReducer.isAuthorized,
  };
};

class AddRegisteredMember extends Component {
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { newMember } = this.props;
    if (newMember !== prevProps.newMember) {
      this.props.history.push(`/member/${newMember.id}`);
    }
  }
  render() {
    const { certified_member, isAuthorized } = this.props.location.state;
    if (!certified_member) {
      return (
        <div className="bs-main-container  e-lib-body">
          <div className="bs-main-content">
            <div className="profil-container">
              <CircularProgress
                style={{
                  textAlign: 'center',
                  marginTop: '9%',
                  marginBottom: '18%',
                  width: '40px',
                  color: '#d85555',
                  marginRight: '47%',
                }}
              />
            </div>
          </div>
        </div>
      );
    }
    return (
      <section className="body-section">
        <Headers isAuthorized={isAuthorized} />
        <div className="bs-main-container  memberApp-form-body">
          <div className="bs-main-content">
            <div className="bs-main-content-header-sm-tr">
              <div className="bs-main-content-header-title-sm-tr">
                <span>
                  {certified_member.type === 1
                    ? 'طلب عضو معتمد'
                    : certified_member.type === 2
                    ? 'طلب مؤسسة معتمدة'
                    : 'طلب مدرب معتمد'}
                </span>
              </div>
            </div>
            <div className="app-form--container">
              <div className="app-form--content">
                {/* {certified_member.type === 1 ? (
                <FormMember certified_member={certified_member} />
              ) : certified_member.type === 2 ? (
                <FormInstitution certified_member={certified_member} />
              ) : (
                <FormInstructor certified_member={certified_member} />
              )}*/}
                <FormMember certified_member={certified_member} />
                {certified_member.type === 1 ? (
                  <ConditionMember />
                ) : certified_member.type === 2 ? (
                  <ConditionInstitution />
                ) : (
                  <ConditionInstructor />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default connect(mapStateToProps)(AddRegisteredMember);
