import React, { Component } from 'react';
import { getTimeFromMins } from '../../../../../utils/helpers';
import { CircularProgress } from '@material-ui/core';
import Modal from 'react-modal';
import actionsAdmin from '../../../store/actions';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import moment from 'moment';
const mapStateToProps = (state) => ({
  ratingUser: state.AdvancementUserCourseReducer.ratingUser,
  isLoadingRatingUser: state.AdvancementUserCourseReducer.isLoadingRatingUser,
});

const mapDispatchToProps = (dispatch) => ({
  getRatingByUser: (id) => dispatch(actionsAdmin.getRatingByUser(id)),
});

const customStyles = {
  content: {
    overflow: 'scroll',
    width: '50%',
    height: '60%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    padding: '20px',
    borderRadius: '15px',
  },
};
class ReportsRating extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenModal: false,
      item: null,
    };
    this.isOpenModal = this.isOpenModal.bind(this);
  }
  async isOpenModal(item) {
    const { isOpenModal } = this.state;
    if (!isOpenModal) await this.props.getRatingByUser(item.id);
    this.setState({
      isOpenModal: !isOpenModal,
      item: item,
    });
  }
  render() {
    const { countCourse, courseRating, isLoadingCourseRating, ratingUser } = this.props;
    const { isOpenModal, item } = this.state;
    const imgRatings = [
      {
        id: 1,
      },
      {
        id: 2,
      },
      {
        id: 3,
      },
      {
        id: 4,
      },
    ];
    let ij1 = 0;
    let ij = 0;
    let ij2 = 0;
    return (
      <div className="admin-dshb-user-container">
        {ratingUser && ratingUser.length > 0 ? (
          <Modal
            isOpen={isOpenModal}
            onRequestClose={this.isOpenModal}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <div
              style={{
                justifyContent: 'flex-end',
              }}
              className="modal-header"
            >
              <button
                onClick={() => this.isOpenModal()}
                style={{ border: '0', backgroundColor: 'white' }}
                className=""
              >
                X
              </button>
            </div>
            <div style={{ height: '86%' }} className="modal-body">
              <div className="admin-dashboard-view-stats-pie-chart-body">
                <div className="dashboard-row" style={{ width: '100%' }}>
                  <div className="admin-dshb-user-container">
                    <div className="admin-dshb-user-content">
                      <div className="admin-dshb-user-header">
                        <div className="admin-dshb-user-header-content">
                          <div className="admin-dshb-user-header-elem">
                            <span>إسم المشترك</span>
                          </div>
                          <div className="admin-dshb-user-header-elem">
                            <span>إسم المستخدم </span>
                          </div>
                          <div className="admin-dshb-user-header-elem">
                            <span>التقييم </span>
                          </div>
                          <div className="admin-dshb-user-header-elem">
                            <span>تاريخ التقييم </span>
                          </div>
                        </div>
                      </div>
                      <div className="admin-dshb-user-body">
                        <div className="admin-dshb-user-body-content">
                          {' '}
                          {ratingUser.length > 0
                            ? ratingUser.map((item) => {
                                ij2 = 0;
                                return (
                                  <div
                                    key={item.id}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => this.isOpenModal(item)}
                                    className="admin-dshb-user-elem"
                                  >
                                    <div className="admin-dshb-user-elem-content">
                                      <div className="admin-dshb-user-name">
                                        <span>{item.course.name}</span>
                                      </div>
                                      <div className="admin-dshb-user-name">
                                        <span>{`${item.user.first_name} ${item.user.last_name}`}</span>
                                      </div>
                                      <div className="admin-dshb-user-name">
                                        <span>
                                          {' '}
                                          {imgRatings.map((somme) => {
                                            ij2++;
                                            if (ij2 <= item.value)
                                              return (
                                                <svg
                                                  height="20px"
                                                  viewBox="0 -10 511.98685 511"
                                                  width="20px"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                >
                                                  <path
                                                    d="m510.652344 185.902344c-3.351563-10.367188-12.546875-17.730469-23.425782-18.710938l-147.773437-13.417968-58.433594-136.769532c-4.308593-10.023437-14.121093-16.511718-25.023437-16.511718s-20.714844 6.488281-25.023438 16.535156l-58.433594 136.746094-147.796874 13.417968c-10.859376 1.003906-20.03125 8.34375-23.402344 18.710938-3.371094 10.367187-.257813 21.738281 7.957031 28.90625l111.699219 97.960937-32.9375 145.089844c-2.410156 10.667969 1.730468 21.695313 10.582031 28.09375 4.757813 3.4375 10.324219 5.1875 15.9375 5.1875 4.839844 0 9.640625-1.304687 13.949219-3.882813l127.46875-76.183593 127.421875 76.183593c9.324219 5.609376 21.078125 5.097657 29.910156-1.304687 8.855469-6.417969 12.992187-17.449219 10.582031-28.09375l-32.9375-145.089844 111.699219-97.941406c8.214844-7.1875 11.351563-18.539063 7.980469-28.925781zm0 0"
                                                    fill="#ffc107"
                                                  />
                                                </svg>
                                              );
                                            else
                                              return (
                                                <svg
                                                  height="20px"
                                                  viewBox="0 -10 511.98685 511"
                                                  width="20px"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                >
                                                  <path d="m114.59375 491.140625c-5.609375 0-11.179688-1.75-15.933594-5.1875-8.855468-6.417969-12.992187-17.449219-10.582031-28.09375l32.9375-145.089844-111.703125-97.960937c-8.210938-7.167969-11.347656-18.519532-7.976562-28.90625 3.371093-10.367188 12.542968-17.707032 23.402343-18.710938l147.796875-13.417968 58.433594-136.746094c4.308594-10.046875 14.121094-16.535156 25.023438-16.535156 10.902343 0 20.714843 6.488281 25.023437 16.511718l58.433594 136.769532 147.773437 13.417968c10.882813.980469 20.054688 8.34375 23.425782 18.710938 3.371093 10.367187.253906 21.738281-7.957032 28.90625l-111.703125 97.941406 32.9375 145.085938c2.414063 10.667968-1.726562 21.699218-10.578125 28.097656-8.832031 6.398437-20.609375 6.890625-29.910156 1.300781l-127.445312-76.160156-127.445313 76.203125c-4.308594 2.558594-9.109375 3.863281-13.953125 3.863281zm141.398438-112.875c4.84375 0 9.640624 1.300781 13.953124 3.859375l120.277344 71.9375-31.085937-136.941406c-2.21875-9.746094 1.089843-19.921875 8.621093-26.515625l105.472657-92.5-139.542969-12.671875c-10.046875-.917969-18.6875-7.234375-22.613281-16.492188l-55.082031-129.046875-55.148438 129.066407c-3.882812 9.195312-12.523438 15.511718-22.546875 16.429687l-139.5625 12.671875 105.46875 92.5c7.554687 6.613281 10.859375 16.769531 8.621094 26.539062l-31.0625 136.9375 120.277343-71.914062c4.308594-2.558594 9.109376-3.859375 13.953126-3.859375zm-84.585938-221.847656s0 .023437-.023438.042969zm169.128906-.0625.023438.042969c0-.023438 0-.023438-.023438-.042969zm0 0" />
                                                </svg>
                                              );
                                          })}
                                        </span>
                                      </div>
                                      <div className="admin-dshb-user-name">
                                        <span>
                                          {moment(item.created_at).format('YYYY-MM-DD')}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })
                            : ''}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                justifyContent: 'flex-start',
              }}
              className="modal-footer"
            ></div>
          </Modal>
        ) : (
          ''
        )}
        <div className="dashboard-row admin-dshb-general-stats">
          <div className="admin-dshb-general-stats-sect">
            <div className="admin-dshb-general-stats-elem">
              <span className="admin-dshb-general-stats-elem-title">
                دورات في قيد التقدم
              </span>
              <span className="user-stat-vals">
                {countCourse.countTrainingIsProgress}
              </span>
            </div>
            <div className="admin-dshb-general-stats--vertSep"></div>
            <div className="admin-dshb-general-stats-elem">
              <span className="admin-dshb-general-stats-elem-title">
                الدورات المكتملة
              </span>
              <span className="user-stat-vals">{countCourse.countTrainingIsFinish}</span>
            </div>
            <div className="admin-dshb-general-stats--vertSep"></div>
            <div className="admin-dshb-general-stats-elem">
              <span className="admin-dshb-general-stats-elem-title">المشاركون</span>
              <span className="user-stat-vals">{countCourse.countUserCourse}</span>
            </div>
            <div className="admin-dshb-general-stats--vertSep"></div>
            <div className="admin-dshb-general-stats-elem">
              <span className="admin-dshb-general-stats-elem-title">وقت التدريب</span>
              <span className="user-stat-vals">
                {countCourse.countPeriodCourse
                  ? getTimeFromMins(countCourse.countPeriodCourse.total_period)
                  : ''}
              </span>
            </div>{' '}
          </div>
        </div>
        <br />
        <div className="dashboard-row ">
          <div className="admin-dashboard-view-stats-pie-chart">
            {isLoadingCourseRating ? (
              <CircularProgress
                style={{
                  textAlign: 'center',
                  marginTop: '14%',
                  width: '40px',
                  color: '#d85555',
                  marginRight: '50%',
                }}
              />
            ) : (
              <>
                <div className="admin-dashboard-view-stats-pie-chart-content">
                  <div className="admin-dashboard-view-stats-pie-chart-header">
                    <span>التقييمات </span>
                  </div>
                  <div className="admin-dshb-user-body--mob-ver">
                    <div className="admin-dshb-user-body-content">
                      {courseRating.length > 0
                        ? courseRating.map((item) => {
                            ij1 = 0;
                            return (
                              <div
                                key={item.id}
                                style={{ cursor: 'pointer' }}
                                onClick={() => this.isOpenModal(item)}
                                className="admin-dshb-user-elem"
                              >
                                <div className="admin-dshb-user-elem-content">
                                  <div className="admin-dshb-user-name">
                                    <span>{item.name}</span>
                                  </div>
                                  <div className="admin-dshb-user-name">
                                    <span>
                                      {' '}
                                      {imgRatings.map((somme) => {
                                        ij1++;
                                        if (ij1 <= item.sommeRating)
                                          return (
                                            <svg
                                              height="20px"
                                              viewBox="0 -10 511.98685 511"
                                              width="20px"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="m510.652344 185.902344c-3.351563-10.367188-12.546875-17.730469-23.425782-18.710938l-147.773437-13.417968-58.433594-136.769532c-4.308593-10.023437-14.121093-16.511718-25.023437-16.511718s-20.714844 6.488281-25.023438 16.535156l-58.433594 136.746094-147.796874 13.417968c-10.859376 1.003906-20.03125 8.34375-23.402344 18.710938-3.371094 10.367187-.257813 21.738281 7.957031 28.90625l111.699219 97.960937-32.9375 145.089844c-2.410156 10.667969 1.730468 21.695313 10.582031 28.09375 4.757813 3.4375 10.324219 5.1875 15.9375 5.1875 4.839844 0 9.640625-1.304687 13.949219-3.882813l127.46875-76.183593 127.421875 76.183593c9.324219 5.609376 21.078125 5.097657 29.910156-1.304687 8.855469-6.417969 12.992187-17.449219 10.582031-28.09375l-32.9375-145.089844 111.699219-97.941406c8.214844-7.1875 11.351563-18.539063 7.980469-28.925781zm0 0"
                                                fill="#ffc107"
                                              />
                                            </svg>
                                          );
                                        else
                                          return (
                                            <svg
                                              height="20px"
                                              viewBox="0 -10 511.98685 511"
                                              width="20px"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path d="m114.59375 491.140625c-5.609375 0-11.179688-1.75-15.933594-5.1875-8.855468-6.417969-12.992187-17.449219-10.582031-28.09375l32.9375-145.089844-111.703125-97.960937c-8.210938-7.167969-11.347656-18.519532-7.976562-28.90625 3.371093-10.367188 12.542968-17.707032 23.402343-18.710938l147.796875-13.417968 58.433594-136.746094c4.308594-10.046875 14.121094-16.535156 25.023438-16.535156 10.902343 0 20.714843 6.488281 25.023437 16.511718l58.433594 136.769532 147.773437 13.417968c10.882813.980469 20.054688 8.34375 23.425782 18.710938 3.371093 10.367187.253906 21.738281-7.957032 28.90625l-111.703125 97.941406 32.9375 145.085938c2.414063 10.667968-1.726562 21.699218-10.578125 28.097656-8.832031 6.398437-20.609375 6.890625-29.910156 1.300781l-127.445312-76.160156-127.445313 76.203125c-4.308594 2.558594-9.109375 3.863281-13.953125 3.863281zm141.398438-112.875c4.84375 0 9.640624 1.300781 13.953124 3.859375l120.277344 71.9375-31.085937-136.941406c-2.21875-9.746094 1.089843-19.921875 8.621093-26.515625l105.472657-92.5-139.542969-12.671875c-10.046875-.917969-18.6875-7.234375-22.613281-16.492188l-55.082031-129.046875-55.148438 129.066407c-3.882812 9.195312-12.523438 15.511718-22.546875 16.429687l-139.5625 12.671875 105.46875 92.5c7.554687 6.613281 10.859375 16.769531 8.621094 26.539062l-31.0625 136.9375 120.277343-71.914062c4.308594-2.558594 9.109376-3.859375 13.953126-3.859375zm-84.585938-221.847656s0 .023437-.023438.042969zm169.128906-.0625.023438.042969c0-.023438 0-.023438-.023438-.042969zm0 0" />
                                            </svg>
                                          );
                                      })}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        : ''}
                    </div>
                  </div>
                </div>
                <div className="admin-dashboard-view-stats-pie-chart-content--desktop-ver">
                  <div className="admin-dashboard-view-stats-pie-chart-header">
                    <span>التقييمات </span>
                  </div>
                  <div className="admin-dashboard-view-stats-pie-chart-body">
                    <div className="dashboard-row">
                      <div className="admin-dshb-user-container">
                        <div className="admin-dshb-user-content">
                          <div className="admin-dshb-user-header">
                            <div className="admin-dshb-user-header-content">
                              <div className="admin-dshb-user-header-elem">
                                <span>إسم الدورة</span>
                              </div>
                              <div className="admin-dshb-user-header-elem">
                                <span>التقييم العام </span>
                              </div>
                            </div>
                          </div>
                          <div className="admin-dshb-user-body">
                            <div className="admin-dshb-user-body-content">
                              {' '}
                              {courseRating.length > 0
                                ? courseRating.map((item) => {
                                    ij = 0;
                                    return (
                                      <div
                                        key={item.id}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => this.isOpenModal(item)}
                                        className="admin-dshb-user-elem"
                                      >
                                        <div className="admin-dshb-user-elem-content">
                                          <div className="admin-dshb-user-name">
                                            <span>{item.name}</span>
                                          </div>
                                          <div className="admin-dshb-user-name">
                                            <span>
                                              {' '}
                                              {imgRatings.map((somme) => {
                                                ij++;
                                                if (ij <= item.sommeRating)
                                                  return (
                                                    <svg
                                                      height="20px"
                                                      viewBox="0 -10 511.98685 511"
                                                      width="20px"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                      <path
                                                        d="m510.652344 185.902344c-3.351563-10.367188-12.546875-17.730469-23.425782-18.710938l-147.773437-13.417968-58.433594-136.769532c-4.308593-10.023437-14.121093-16.511718-25.023437-16.511718s-20.714844 6.488281-25.023438 16.535156l-58.433594 136.746094-147.796874 13.417968c-10.859376 1.003906-20.03125 8.34375-23.402344 18.710938-3.371094 10.367187-.257813 21.738281 7.957031 28.90625l111.699219 97.960937-32.9375 145.089844c-2.410156 10.667969 1.730468 21.695313 10.582031 28.09375 4.757813 3.4375 10.324219 5.1875 15.9375 5.1875 4.839844 0 9.640625-1.304687 13.949219-3.882813l127.46875-76.183593 127.421875 76.183593c9.324219 5.609376 21.078125 5.097657 29.910156-1.304687 8.855469-6.417969 12.992187-17.449219 10.582031-28.09375l-32.9375-145.089844 111.699219-97.941406c8.214844-7.1875 11.351563-18.539063 7.980469-28.925781zm0 0"
                                                        fill="#ffc107"
                                                      />
                                                    </svg>
                                                  );
                                                else
                                                  return (
                                                    <svg
                                                      height="20px"
                                                      viewBox="0 -10 511.98685 511"
                                                      width="20px"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                      <path d="m114.59375 491.140625c-5.609375 0-11.179688-1.75-15.933594-5.1875-8.855468-6.417969-12.992187-17.449219-10.582031-28.09375l32.9375-145.089844-111.703125-97.960937c-8.210938-7.167969-11.347656-18.519532-7.976562-28.90625 3.371093-10.367188 12.542968-17.707032 23.402343-18.710938l147.796875-13.417968 58.433594-136.746094c4.308594-10.046875 14.121094-16.535156 25.023438-16.535156 10.902343 0 20.714843 6.488281 25.023437 16.511718l58.433594 136.769532 147.773437 13.417968c10.882813.980469 20.054688 8.34375 23.425782 18.710938 3.371093 10.367187.253906 21.738281-7.957032 28.90625l-111.703125 97.941406 32.9375 145.085938c2.414063 10.667968-1.726562 21.699218-10.578125 28.097656-8.832031 6.398437-20.609375 6.890625-29.910156 1.300781l-127.445312-76.160156-127.445313 76.203125c-4.308594 2.558594-9.109375 3.863281-13.953125 3.863281zm141.398438-112.875c4.84375 0 9.640624 1.300781 13.953124 3.859375l120.277344 71.9375-31.085937-136.941406c-2.21875-9.746094 1.089843-19.921875 8.621093-26.515625l105.472657-92.5-139.542969-12.671875c-10.046875-.917969-18.6875-7.234375-22.613281-16.492188l-55.082031-129.046875-55.148438 129.066407c-3.882812 9.195312-12.523438 15.511718-22.546875 16.429687l-139.5625 12.671875 105.46875 92.5c7.554687 6.613281 10.859375 16.769531 8.621094 26.539062l-31.0625 136.9375 120.277343-71.914062c4.308594-2.558594 9.109376-3.859375 13.953126-3.859375zm-84.585938-221.847656s0 .023437-.023438.042969zm169.128906-.0625.023438.042969c0-.023438 0-.023438-.023438-.042969zm0 0" />
                                                    </svg>
                                                  );
                                              })}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })
                                : ''}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportsRating));
