import React, { Component } from 'react';
import footerImageWhite from "../assets/img/umAlQuwain_main_logo_white.png";

class Headers extends Component {
  render() {
    return (
      <section className="footer-section">
        <footer>
          <div className="footer-container">
            <div className="footer-content">
              <div className="footer-elem">
                <div className="footer-elem-title">
                  <span>من نحن ؟</span>
                </div>
                <div className="footer-elem-body">
                  <div className="footer-elem-body-unit">
                    <span>
                    نحن أكاديمية أم القيوين للتدريب التابعة للمجلس التنفيذي لحكومة أم القيوين نعمل على توفير دورات تدريبية لجميع أعضاء الحكومة 
                    </span>
                  </div>
                  <div className="footer-elem-body-unit">
                    <div className="footer-elem-body-unit-content">
                      <a href="#">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="45.354"
                          height="45.076"
                          viewBox="0 0 45.354 45.076"
                        >
                          <path
                            id="Subtraction_1"
                            data-name="Subtraction 1"
                            d="M19.145,45.076h0A22.677,22.677,0,0,1,22.66,0c.4,0,.8.011,1.193.031a22.678,22.678,0,0,1,2.379,45.044l0-15.9H31.5l1.012-6.512H32.51l.006-.047H26.23V18.412c-.006-.093-.012-.187-.012-.278a3.292,3.292,0,0,1,1.008-2.377,3.7,3.7,0,0,1,.3-.256l.014-.01.014-.009.027-.021.01-.007.023-.016.021-.015.012-.007.008-.007c.018-.013.035-.024.055-.036l.012-.007a3.263,3.263,0,0,1,2.2-.489h2.855V9.291a33.817,33.817,0,0,0-5.074-.447c-5.361,0-8.561,3.3-8.561,8.823v5H13.387v6.512h5.758v15.9Z"
                            transform="translate(-0.001)"
                            fill="#fff"
                          />
                        </svg>
                      </a>
                      <a href="#">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="45.355"
                          height="45.353"
                          viewBox="0 0 45.355 45.353"
                        >
                          <path
                            id="Subtraction_2"
                            data-name="Subtraction 2"
                            d="M22.68,45.354A22.676,22.676,0,0,1,6.645,6.643,22.677,22.677,0,0,1,38.717,38.711,22.535,22.535,0,0,1,22.68,45.354Zm-5.3-36a8.023,8.023,0,0,0-7.986,7.991V27.97a8.017,8.017,0,0,0,8.006,7.985H28A8.019,8.019,0,0,0,35.99,27.97V17.344A8.023,8.023,0,0,0,28,9.353ZM4.705,14.1a1.551,1.551,0,1,0,1.551,1.551A1.552,1.552,0,0,0,4.705,14.1ZM28,33.443H17.395A5.5,5.5,0,0,1,11.9,27.97V17.344a5.524,5.524,0,0,1,5.494-5.5H28a5.516,5.516,0,0,1,5.488,5.5V27.97A5.493,5.493,0,0,1,28,33.443ZM22.779,16.1a6.55,6.55,0,1,0,.014,0Zm.014,10.749a4.2,4.2,0,1,1,4.2-4.2A4.209,4.209,0,0,1,22.793,26.85Z"
                            transform="translate(-0.002 -0.001)"
                            fill="#fff"
                          />
                        </svg>
                      </a>
                      <a href="#">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="45.355"
                          height="45.353"
                          viewBox="0 0 45.355 45.353"
                        >
                          <path
                            id="Subtraction_3"
                            data-name="Subtraction 3"
                            d="M22.68,45.354A22.676,22.676,0,0,1,6.645,6.643,22.677,22.677,0,0,1,38.717,38.711,22.535,22.535,0,0,1,22.68,45.354ZM10.709,30.295a.029.029,0,0,0-.025.011c-.01.015,0,.045.01.072a.165.165,0,0,1,.016.057,3.632,3.632,0,0,0,.607.37l.025.014.25.14a14.268,14.268,0,0,0,2.8,1.119,14.914,14.914,0,0,0,14.518-3.4A15.274,15.274,0,0,0,32.951,17.56c0-.127.123-.21.23-.283l.025-.02.037-.026a10.465,10.465,0,0,0,1.988-2.06.671.671,0,0,0,.121-.39.03.03,0,0,1,0,.009.4.4,0,0,1-.15.114,9.76,9.76,0,0,1-2.4.7c.344-.1.775-.622,1.031-.935l.014-.016c.037-.044.07-.084.1-.118a4.615,4.615,0,0,0,.822-1.551V12.86a.126.126,0,0,0-.07-.02.123.123,0,0,0-.066.02,12.715,12.715,0,0,1-2.965,1.13.184.184,0,0,1-.1.03.178.178,0,0,1-.1-.03l-.266-.263a4.935,4.935,0,0,0-1.443-.867,4.7,4.7,0,0,0-1.842-.373c-.113,0-.234,0-.355.014a5.012,5.012,0,0,0-2.08.591,5.308,5.308,0,0,0-1.689,1.381,4.969,4.969,0,0,0-1.014,2.014,5.936,5.936,0,0,0,0,2.126c0,.1,0,.128-.049.128a.3.3,0,0,1-.043,0A15.731,15.731,0,0,1,12.49,13.543c-.064-.067-.111-.1-.152-.1s-.08.031-.125.1a5.182,5.182,0,0,0,.879,6.111c.205.194.408.37.621.539a5.334,5.334,0,0,1-1.965-.539h-.012c-.117,0-.162,0-.174.093a2.256,2.256,0,0,0,0,.585,5.205,5.205,0,0,0,3.27,4.176,2.264,2.264,0,0,0,.654.2,5.693,5.693,0,0,1-.973.085,5.586,5.586,0,0,1-.971-.085h-.006c-.062,0-.115,0-.135.027s-.02.07,0,.162a5.433,5.433,0,0,0,4.061,3.38h.545a5.684,5.684,0,0,1-2.541,1.394l-.223.08a9.866,9.866,0,0,1-3.344.581c-.283,0-.574-.012-.857-.037Z"
                            transform="translate(-0.002 -0.001)"
                            fill="#fff"
                          />
                        </svg>
                      </a>
                      <a href="#">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="45.355"
                          height="45.353"
                          viewBox="0 0 45.355 45.353"
                        >
                          <path
                            id="Subtraction_4"
                            data-name="Subtraction 4"
                            d="M22.68,45.354A22.676,22.676,0,0,1,6.645,6.643,22.677,22.677,0,0,1,38.717,38.711,22.535,22.535,0,0,1,22.68,45.354ZM15.422,13.929a5.45,5.45,0,0,0-5.443,5.443v6.486A5.45,5.45,0,0,0,15.422,31.3H29.381a5.45,5.45,0,0,0,5.443-5.443V19.372a5.45,5.45,0,0,0-5.443-5.443Zm4.736,12.315c-.334,0-.8-.128-.8-.37V19.531c0-.218.5-.375.834-.375a.557.557,0,0,1,.25.046l5.973,3.257a.721.721,0,0,1,.318.5.166.166,0,0,1-.1.141L20.43,26.2A.618.618,0,0,1,20.158,26.244Z"
                            transform="translate(-0.002 -0.001)"
                            fill="#fff"
                          />
                        </svg>
                      </a>
                      <a href="#">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="45.355"
                          height="45.353"
                          viewBox="0 0 45.355 45.353"
                        >
                          <path
                            id="Subtraction_5"
                            data-name="Subtraction 5"
                            d="M22.68,45.354A22.676,22.676,0,0,1,6.645,6.643,22.677,22.677,0,0,1,38.717,38.711,22.535,22.535,0,0,1,22.68,45.354ZM27.271,21.98c2.279,0,2.279,2.073,2.279,3.739v6.871h4.375V24.836c0-3.488-.637-6.717-5.258-6.717l-.156,0a4.651,4.651,0,0,0-3.982,2.268V18.458H20.322L20.26,32.591h4.377V25.611C24.637,23.816,24.951,21.98,27.271,21.98ZM13.221,18.457h0V32.575h4.391V18.458H13.221Zm2.2-7.01a2.545,2.545,0,1,0,2.547,2.543A2.544,2.544,0,0,0,15.418,11.447Z"
                            transform="translate(-0.002 -0.001)"
                            fill="#fff"
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer-elem">
                <div className="footer-elem-body">
                  <div className="footer-elem-body-unit">
                    <div className="footer-elem-body-cols-container">
                      <div className="footer-elem-body-col">
                        <div className="footer-elem-title">
                          <span>الصفحات</span>
                        </div>
                        <div className="footer-elem-body-col-elem">
                          <a href="/">الرئيسية</a>
                        </div>
                        <div className="footer-elem-body-col-elem">
                          <a href="/trainings">البرامج التدريبية</a>
                        </div>
                        <div className="footer-elem-body-col-elem">
                          <a href="/intelligent-trainings">التدريب الذكي</a>
                        </div>
                      </div>
                      <div className="footer-elem-body-col">
                        <div className="footer-elem-title">
                          <a href="/direct-trainings">خطة التدريب السنوية</a>
                        </div>
                        <div className="footer-elem-body-col-elem">
                          <a href="/libraries">المكتبة الالكترونية</a>
                        </div>
                        <div className="footer-elem-body-col-elem">
                          <a href="/certified-member-ship">العضوية</a>
                        </div>
                        <div className="footer-elem-body-col-elem">
                          <a href="/contact">تواصل معنا</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer-elem">
                <div className="footer-elem-body">
                  <div className="footer-elem-body-unit -log--container">
                  <img src={footerImageWhite} style={{width:"100%"}} />
                  </div>
                </div>
              </div>
            </div>
            <div className="copyright-terms-container">
              <div style={{width: '100%'}} className="copyright-content">
                <span>جميع الحقوق محفوظة ® 2021</span>
              </div>
            </div>
          </div>
        </footer>
      </section>
    );
  }
}

export default Headers;
