import React, { Component } from 'react';
import headerImageDark from '../../assets/img/umAlQuwain_main_logo_dark.png';
class Headers extends Component {
  render() {
    const { type } = this.props;
    return (
      <section
        style={{ backgroundColor: '#EDEDED' }}
        className="header-section dashboard-header"
      >
        <div className="hs-top-header-container">
          <div className="hs-top-header-content">
            <div style={{ justifyContent: type ? 'center' : '' }} className="hs-navbar">
              <div className="hs-navbar--right-side">
                <div className="hs-logo--container">
                  <img src={headerImageDark} style={{ width: '100%' }} />
                </div>
                {type ? (
                  ''
                ) : (
                  <div className="header-search-box">
                    <div className="header-search-box-content">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="19.323"
                        height="19.32"
                        viewBox="0 0 19.323 19.32"
                      >
                        <g
                          id="Group_1076"
                          data-name="Group 1076"
                          transform="translate(-434.555 -185.983)"
                        >
                          <g id="Group_1075" data-name="Group 1075">
                            <g id="Group_1074" data-name="Group 1074">
                              <g id="Group_1072" data-name="Group 1072">
                                <path
                                  id="Path_802"
                                  data-name="Path 802"
                                  d="M442.343,201.556a7.789,7.789,0,1,1,5.509-13.3h0a7.787,7.787,0,0,1-5.509,13.3Zm0-12.543a4.756,4.756,0,1,0,3.365,1.391A4.747,4.747,0,0,0,442.343,189.013Z"
                                  fill="#ea0029"
                                ></path>
                              </g>
                              <g id="Group_1073" data-name="Group 1073">
                                <rect
                                  id="Rectangle_128"
                                  data-name="Rectangle 128"
                                  width="3.032"
                                  height="8.521"
                                  transform="translate(445.708 199.277) rotate(-44.994)"
                                  fill="#ea0029"
                                ></rect>
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                      <div className="search-input-container">
                        <input type="text" placeholder="إكتب للبحث في الدورات" />
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {type ? (
                ''
              ) : (
                <div className="hs-navbar--left-side">
                  <div className="notification-icon-container">
                    <div className="red-dot"></div>
                    <div className="notification-icon-content">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.825 30.991">
                        <path
                          id="Path_812"
                          data-name="Path 812"
                          d="M1183.4,91.781l-2.218-3.7a10.976,10.976,0,0,1-1.565-5.648V79.2a9.05,9.05,0,0,0-6.456-8.658V68.224a2.582,2.582,0,1,0-5.165,0v2.318a9.05,9.05,0,0,0-6.457,8.658v3.237a10.982,10.982,0,0,1-1.564,5.647l-2.217,3.7a.646.646,0,0,0,.553.978h24.535a.645.645,0,0,0,.554-.977Z"
                          transform="translate(-1157.669 -65.641)"
                          fill="#fff"
                        />
                        <path
                          id="Path_813"
                          data-name="Path 813"
                          d="M1170.581,96.632a4.514,4.514,0,0,0,4.067-2.582h-8.133A4.513,4.513,0,0,0,1170.581,96.632Z"
                          transform="translate(-1157.669 -65.641)"
                          fill="#fff"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="messages-icon-container">
                    <div className="red-dot"></div>
                    <div className="messages-icon-content">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31.721 32.353">
                        <path
                          id="Path_814"
                          data-name="Path 814"
                          d="M1241.007,67.39c-8.759,0-15.86,6.475-15.86,14.462a14,14,0,0,0,5.9,11.253v6.638l5.8-3.933a17.334,17.334,0,0,0,4.156.505c8.76,0,15.861-6.475,15.861-14.463S1249.767,67.39,1241.007,67.39Zm1.917,18.689-4.217-4.464-7.613,4.464,8-8.873,4.218,4.464,7.613-4.464Z"
                          transform="translate(-1225.147 -67.39)"
                          fill="#fff"
                        />
                        <path
                          id="Path_815"
                          data-name="Path 815"
                          d="M1231.049,76.991l12.484-1.04h7.282l1.04,7.282-4.161,5.2-8.323-1.04h-6.242l-2.081-1.04Z"
                          transform="translate(-1225.147 -67.39)"
                          fill="#fff"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Headers;
