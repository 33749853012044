import React, { Component } from 'react';
import ImgGroup1 from '../../../../../assets/img/Group 2355@2x.png';
import ImgGroup2 from '../../../../../assets/img/Group@2x.png';
import { Chart } from 'react-charts';
//import useChartConfig from 'hooks/useChartConfig'

function TrainingTime({ mostAttendedCourses }) {
  const data = React.useMemo(() => [mostAttendedCourses], []);
  const axes = React.useMemo(
    () => [
      { primary: true, type: 'ordinal', position: 'bottom' },
      { position: 'left', type: 'linear', stacked: false },
    ],
    [],
  );
  const series = React.useMemo(
    () => ({
      type: 'line',
    }),
    [],
  );
  return (
    <div className="dashboard-column-elem stats-graph-container">
      <div className="stats-graph-content">
        <div className="stats-graph-header">
          <span>وقت التدريب</span>
          <div className="graph-control-container">
            <div className="graph-control-content">
              <span>اكثر الدورات حضور</span>
              <span>/</span>
              <select name="" id="">
                <option>الساعة </option>
                <option>الساعة </option>
                <option>الساعة </option>
              </select>
            </div>
          </div>
        </div>
        <div className="stats-graph-body">
          <div className="stats-graph-body-content">
            <div className="art-container">
              <img src={ImgGroup1} alt="" />
            </div>

            <div className="actual-grah-container">
              <div className="actual-grah-content">
                <div className="graph-placeholder">
                  <div
                    style={{
                      width: '100%',
                      height: '360px',
                    }}
                  >
                    <Chart series={series} data={data} axes={axes} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TrainingTime;
