import React, { Component } from 'react';
import { getTimeFromMins } from '../../../../../utils/helpers';
import moment from 'moment';
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import { MemoryRouter, Route } from 'react-router';
import { Link } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';

class ReportsUser extends Component {
  render() {
    const { countCourse, detailUser, isLoadingDetailUser } = this.props;
    return (
      <div className="admin-dshb-user-container">
        <div className="dashboard-row admin-dshb-general-stats">
          <div className="admin-dshb-general-stats-sect">
            <div className="admin-dshb-general-stats-elem">
              <span className="admin-dshb-general-stats-elem-title">
                دورات في قيد التقدم
              </span>
              <span className="user-stat-vals">
                {countCourse.countTrainingIsProgress}
              </span>
            </div>
            <div className="admin-dshb-general-stats--vertSep"></div>
            <div className="admin-dshb-general-stats-elem">
              <span className="admin-dshb-general-stats-elem-title">
                الدورات المكتملة
              </span>
              <span className="user-stat-vals">{countCourse.countTrainingIsFinish}</span>
            </div>
            <div className="admin-dshb-general-stats--vertSep"></div>
            <div className="admin-dshb-general-stats-elem">
              <span className="admin-dshb-general-stats-elem-title">المشاركون</span>
              <span className="user-stat-vals">{countCourse.countUserCourse}</span>
            </div>
            <div className="admin-dshb-general-stats--vertSep"></div>
            <div className="admin-dshb-general-stats-elem">
              <span className="admin-dshb-general-stats-elem-title">وقت التدريب</span>
              <span className="user-stat-vals">
                {countCourse.countPeriodCourse
                  ? getTimeFromMins(countCourse.countPeriodCourse.total_period)
                  : ''}
              </span>
            </div>{' '}
          </div>
        </div>
        <br />
        <div className="dashboard-row ">
          <div className="admin-dshb-user-container">
            <div className="admin-dshb-user-content">
              <div className="admin-dshb-user-header">
                <div className="admin-dshb-user-header-content --green-header-vers">
                  <div className="admin-dshb-user-header-elem">
                    <span>إسم المشترك</span>
                  </div>
                  <div className="admin-dshb-user-header-elem">
                    <span>تاريخ الاشتراك</span>
                  </div>
                  <div className="admin-dshb-user-header-elem">
                    <span>النقاط</span>
                  </div>
                  <div className="admin-dshb-user-header-elem">
                    <span>الدورات المكتملة</span>
                  </div>
                  <div className="admin-dshb-user-header-elem">
                    <span>الشهادات</span>
                  </div>
                  <div className="admin-dshb-user-header-elem">
                    <span>البريد</span>
                  </div>
                  <div className="admin-dshb-user-header-elem">
                    <span>الجوال</span>
                  </div>
                </div>
              </div>
              {isLoadingDetailUser ? (
                <CircularProgress
                  style={{
                    textAlign: 'center',
                    marginTop: '14%',
                    width: '40px',
                    color: '#d85555',
                    marginRight: '50%',
                  }}
                />
              ) : (
                <>
                  <div className="admin-dshb-user-body--mob-ver">
                    <div className="admin-dshb-user-body-content">
                      {detailUser && detailUser.data && detailUser.data.length > 0
                        ? detailUser.data.map((user) => {
                            return (
                              <div className="admin-dshb-user-elem">
                                <div className="admin-dshb-user-elem-content">
                                  <div className="admin-dshb-user-name">
                                    <span>{`${user.first_name} ${user.last_name}`}</span>
                                  </div>
                                  <div className="admin-dshb-user-name">
                                    <span>
                                      {moment(user.created_at).format('YYYY-MM-DD')}
                                    </span>
                                  </div>
                                  <div className="admin-dshb-user-score">
                                    <span>{user.score}</span>
                                  </div>
                                  <div className="admin-dshb-user-completed-courses">
                                    <span>{user.user_smart_trainings.length}</span>
                                  </div>
                                  <div className="admin-dshb-user-type">
                                    <span>{user.certifications.length}</span>
                                  </div>
                                  <div className="admin-dshb-user-type">
                                    <span>{user.email}</span>
                                  </div>
                                  <div className="admin-dshb-user-type">
                                    <span>{user.phone}</span>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        : ''}
                    </div>
                    {detailUser && detailUser.total > 5 ? (
                      <div className="pagination-container">
                        <div className="pagination-content">
                          <MemoryRouter initialEntries={['/inbox']}>
                            <Route>
                              {() => {
                                const page = parseInt(
                                  detailUser.current_page || 1,
                                  Math.ceil(detailUser.total / detailUser.per_page),
                                );
                                return (
                                  <Pagination
                                    // color="secondary"
                                    page={page}
                                    //defaultPage={trainings.current_page}
                                    // borderCount={5}
                                    hidePrevButton
                                    hideNextButton
                                    count={Math.ceil(
                                      detailUser.total / detailUser.per_page,
                                    )}
                                    onChange={this.props.makeHttpRequestWithPage}
                                    renderItem={(item) => (
                                      <PaginationItem
                                        className="pagination-content-elem last-pagination-number"
                                        component={Link}
                                        page={detailUser.current_page}
                                        to={`/inbox${
                                          item.page === 1 ? '' : `?page=${item.page}`
                                        }`}
                                        {...item}
                                      />
                                    )}
                                  />
                                );
                              }}
                            </Route>
                          </MemoryRouter>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="admin-dshb-user-body">
                    <div className="admin-dshb-user-body-content">
                      {detailUser && detailUser.data && detailUser.data.length > 0
                        ? detailUser.data.map((user) => {
                            return (
                              <div className="admin-dshb-user-elem">
                                <div className="admin-dshb-user-elem-content">
                                  <div className="admin-dshb-user-name">
                                    <span>{`${user.first_name} ${user.last_name}`}</span>
                                  </div>
                                  <div className="admin-dshb-user-name">
                                    <span>
                                      {moment(user.created_at).format('YYYY-MM-DD')}
                                    </span>
                                  </div>
                                  <div className="admin-dshb-user-score">
                                    <span>{user.score}</span>
                                  </div>
                                  <div className="admin-dshb-user-completed-courses">
                                    <span>{user.user_smart_trainings.length}</span>
                                  </div>
                                  <div className="admin-dshb-user-type">
                                    <span>{user.certifications.length}</span>
                                  </div>
                                  <div className="admin-dshb-user-type">
                                    <span>{user.email}</span>
                                  </div>
                                  <div className="admin-dshb-user-type">
                                    <span>{user.phone}</span>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        : ''}
                    </div>
                    {detailUser && detailUser.total > 5 ? (
                      <div className="pagination-container">
                        <div className="pagination-content">
                          <MemoryRouter initialEntries={['/inbox']}>
                            <Route>
                              {() => {
                                const page = parseInt(
                                  detailUser.current_page || 1,
                                  Math.ceil(detailUser.total / detailUser.per_page),
                                );
                                return (
                                  <Pagination
                                    // color="secondary"
                                    page={page}
                                    //defaultPage={trainings.current_page}
                                    // borderCount={5}
                                    hidePrevButton
                                    hideNextButton
                                    count={Math.ceil(
                                      detailUser.total / detailUser.per_page,
                                    )}
                                    onChange={this.props.makeHttpRequestWithPage}
                                    renderItem={(item) => (
                                      <PaginationItem
                                        className="pagination-content-elem last-pagination-number"
                                        component={Link}
                                        page={detailUser.current_page}
                                        to={`/inbox${
                                          item.page === 1 ? '' : `?page=${item.page}`
                                        }`}
                                        {...item}
                                      />
                                    )}
                                  />
                                );
                              }}
                            </Route>
                          </MemoryRouter>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ReportsUser;
