const initialGlobalState = {
  error: undefined,
  isLoading: true,
  isAuthorized: false,
  isLoadingTrainings: false,
  trainings: [],
  isLoadingTraining: false,
  training: null,
  authLogin: false,
  account: null,
  user: null,
  isLoadingUser: false,
  isLoadingContact: false,
  contact: null,
  isLoadingUpdateUser: false,
  updateUser: false,
  isLoadingcountCertification: false,
  countCertification: null,
  isLoadingCountTraining: false,
  countTraining: null,
  userTraining: null,
  isLoadingUserTraining: false,
  score: 0,
  params: {
    currentPage: 1,
    limit: 30,
  },
  activeItemSideBarAdmin: 0,
  activeItemSideBar: 0,
  isLoadingCountTrainingIsStart: false,
  countTrainingIsStart: null,
  isLoadingCountTrainingIsFinish: false,
  countTrainingIsFinish: null,
  isLoadingCountTrainingInProgress: false,
  countTrainingIsProgress: null,
  isLoadingSavedInfo: false,
  savedInfo: null,
  isLoadingTrainingUser: false,
  trainingsUser: [],
  isLoadingTrainingsType: false,
  trainingsType: [],
  isLoadingUpdateSectionItem: false,
  updateSectionItem: null,
  isLoadingChangePassword: false,
  changePassword: false,
  isLoadingUserSmartTraining: false,
  userSmartTraining: null,
  paramsUser: {
    currentPage: 1,
    limit: 5,
  },
  isLoadingUsers: false,
  users: [],
};

const AppReducer = (state = initialGlobalState, action) => {
  switch (action.type) {
    case 'GET_USERS_REQUESTING':
      return {
        ...state,
        isLoadingUsers: true,
        paramsUser: action.paramsUser,
      };
    case 'GET_USERS_SUCCESS':
      return {
        ...state,
        isLoadingUsers: false,
        users: action.data,
        paramsUser: action.paramsUser,
      };
    case 'GET_USERS_FAILURE':
      return {
        ...state,
        isLoadingUsers: false,
        users: [],
        paramsUser: action.paramsUser,
      };
    case 'PAGINATION_USERS_PARAMS': {
      return {
        ...state,
        paramsUser: action.paramsUser,
      };
    }
    case 'GET_USER_TRAINING_REQUESTING':
      return {
        ...state,
        isLoadingUserSmartTraining: true,
      };
    case 'GET_USER_TRAINING_SUCCESS':
      return {
        ...state,
        isLoadingUserSmartTraining: false,
        userSmartTraining: action.data,
      };
    case 'GET_USER_TRAINING_FAILURE':
      return {
        ...state,
        isLoadingUserSmartTraining: false,
        userSmartTraining: null,
      };
    case 'CHANGE_PASSWORD_REQUESTING':
      return {
        ...state,
        isLoadingChangePassword: true,
      };
    case 'CHANGE_PASSWORD_SUCCESS':
      return {
        ...state,
        isLoadingChangePassword: false,
        changePassword: true,
      };
    case 'CHANGE_PASSWORD_FAILURE':
      return {
        ...state,
        isLoadingChangePassword: false,
        changePassword: false,
      };
    case 'UPDATE_SECTION_ITEM_REQUESTING':
      return {
        ...state,
        isLoadingUpdateSectionItem: true,
      };
    case 'UPDATE_SECTION_ITEM_SUCCESS':
      return {
        ...state,
        isLoadingUpdateSectionItem: false,
        updateSectionItem: action.data,
      };
    case 'UPDATE_SECTION_ITEM_FAILURE':
      return {
        ...state,
        isLoadingUpdateSectionItem: false,
        updateSectionItem: false,
      };
    case 'GET_TRAININGS_TYPE_REQUESTING':
      return {
        ...state,
        isLoadingTrainingsType: true,
      };
    case 'GET_TRAININGS_TYPE_SUCCESS':
      return {
        ...state,
        isLoadingTrainingsType: false,
        trainingsType: action.data,
      };
    case 'GET_TRAININGS_TYPE_FAILURE':
      return {
        ...state,
        isLoadingTrainingsType: false,
        trainingsType: false,
      };

    case 'GET_SAVED_INFO_USER_REQUESTING':
      return {
        ...state,
        isLoadingSavedInfo: true,
      };
    case 'GET_SAVED_INFO_USER_SUCCESS':
      return {
        ...state,
        isLoadingSavedInfo: false,
        savedInfo: action.data,
      };
    case 'GET_SAVED_INFO_USER_FAILURE':
      return {
        ...state,
        isLoadingSavedInfo: false,
        savedInfo: false,
      };

    case 'GET_TRAININGS_BY_USER_REQUESTING':
      return {
        ...state,
        isLoadingTrainingUser: true,
      };
    case 'GET_TRAININGS_BY_USER_SUCCESS':
      return {
        ...state,
        isLoadingTrainingUser: false,
        trainingsUser: action.data.result,
      };
    case 'GET_TRAININGS_BY_USER_FAILURE':
      return {
        ...state,
        isLoadingTrainingUser: false,
        trainingsUser: false,
      };
    case 'GET_COUNT_TRAININGS_IS_FINISH_REQUESTING':
      return {
        ...state,
        isLoadingCountTrainingIsFinish: true,
      };
    case 'GET_COUNT_TRAININGS_IS_FINISH_SUCCESS':
      return {
        ...state,
        isLoadingCountTrainingIsFinish: false,
        countTrainingIsFinish: action.data,
      };
    case 'GET_COUNT_TRAININGS_IS_FINISH_FAILURE':
      return {
        ...state,
        isLoadingCountTrainingIsFinish: false,
        countTrainingIsFinish: false,
      };
    case 'GET_COUNT_TRAININGS_IN_PROGRESS_REQUESTING':
      return {
        ...state,
        isLoadingCountTrainingInProgress: true,
      };
    case 'GET_COUNT_TRAININGS_IN_PROGRESS_SUCCESS':
      return {
        ...state,
        isLoadingCountTrainingInProgress: false,
        countTrainingIsProgress: action.data,
      };
    case 'GET_COUNT_TRAININGS_IN_PROGRESS_FAILURE':
      return {
        ...state,
        isLoadingCountTrainingInProgress: false,
        countTrainingIsProgress: false,
      };

    case 'GET_COUNT_TRAININGS_IS_START_REQUESTING':
      return {
        ...state,
        isLoadingCountTrainingIsStart: true,
      };
    case 'GET_COUNT_TRAININGS_IS_START_SUCCESS':
      return {
        ...state,
        isLoadingCountTrainingIsStart: false,
        countTrainingIsStart: action.data,
      };
    case 'GET_COUNT_TRAININGS_IS_START_FAILURE':
      return {
        ...state,
        isLoadingCountTrainingIsStart: false,
        countTrainingIsStart: false,
      };

    case 'UPDATE_SCORE_SIDE_BAR':
      return {
        ...state,
        score: action.score,
      };

    case 'ADD_USER_COURSE_REQUESTING':
      return {
        ...state,
        isLoadingUserTraining: true,
      };
    case 'ADD_USER_COURSE_SUCCESS':
      return {
        ...state,
        isLoadingUserTraining: false,
        userTraining: action.data,
      };
    case 'ADD_USER_COURSE_FAILURE':
      return {
        ...state,
        isLoadingUserTraining: false,
        userTraining: false,
      };

    case 'GET_COUNT_TRAININGS_REQUESTING':
      return {
        ...state,
        isLoadingCountTraining: true,
      };
    case 'GET_COUNT_TRAININGS_SUCCESS':
      return {
        ...state,
        isLoadingCountTraining: false,
        countTraining: action.data,
      };
    case 'GET_COUNT_TRAININGS_FAILURE':
      return {
        ...state,
        isLoadingCountTraining: false,
        countTraining: false,
      };

    case 'GET_COUNT_CONVERSATION_REQUESTING':
      return {
        ...state,
        isLoadingcountCertification: true,
      };
    case 'GET_COUNT_CONVERSATION_SUCCESS':
      return {
        ...state,
        isLoadingcountCertification: false,
        countCertification: action.data,
      };
    case 'GET_COUNT_CONVERSATION_FAILURE':
      return {
        ...state,
        isLoadingcountCertification: false,
        countCertification: false,
      };

    case 'UPDATE_USER_REQUESTING':
      return {
        ...state,
        isLoadingUpdateUser: true,
      };
    case 'UPDATE_USER_SUCCESS':
      return {
        ...state,
        isLoadingUpdateUser: false,
        updateUser: action.data,
      };
    case 'UPDATE_USER_FAILURE':
      return {
        ...state,
        isLoadingUpdateUser: false,
        updateUser: false,
      };

    case 'GET_USER_REQUESTING':
      return {
        ...state,
        isLoadingUser: true,
      };
    case 'GET_USER_SUCCESS':
      return {
        ...state,
        isLoadingUser: false,
        user: action.data,
      };
    case 'GET_USER_FAILURE':
      return {
        ...state,
        isLoadingUser: false,
        user: null,
      };

    case 'CONTACT_REQUESTING':
      return {
        ...state,
        isLoadingContact: true,
      };
    case 'CONTACT_SUCCESS':
      return {
        ...state,
        isLoadingContact: false,
        contact: action.data,
      };
    case 'CONTACT_FAILURE':
      return {
        ...state,
        isLoadingContact: false,
        contact: null,
      };

    case 'GET_TRAININGS_REQUESTING':
      return {
        ...state,
        isLoadingTrainings: true,
      };
    case 'GET_TRAININGS_SUCCESS':
      return {
        ...state,
        isLoadingTrainings: false,
        trainings: action.data,
      };
    case 'GET_TRAININGS_FAILURE':
      return {
        ...state,
        isLoadingTrainings: false,
        trainings: [],
      };
    case 'GET_TRAINING_REQUESTING':
      return {
        ...state,
        isLoadingTraining: true,
      };
    case 'GET_TRAINING_SUCCESS':
      return {
        ...state,
        isLoadingTraining: false,
        training: action.data,
      };
    case 'GET_TRAINING_FAILURE':
      return {
        ...state,
        isLoadingTraining: false,
        training: null,
      };
    case 'LOGIN_REQUESTING':
      return {
        ...state,
        isLoadingLogin: true,
      };
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        isLoadingLogin: false,
        authLogin: true,
        account: {
          ...action.data,
        },
        message: action.data.message,
      };
    case 'LOGIN_FAILURE':
      return {
        ...state,
        authLogin: false,
        isLoadingLogin: false,
        message: action.err,
      };
    case 'GET_ME_SUCCESS':
      return {
        ...state,
        isAuthorized: true,
        isLoading: false,
        account: {
          ...action.data,
        },
      };
    case 'GET_ME_REQUESTING':
      return {
        ...state,
        isAuthorized: false,
        isLoading: true,
      };
    case 'GET_ME_FAILURE':
      return {
        ...state,
        isLoading: false,
        isAuthorized: false,
        account: null,
        message: '',
      };
    case 'LOGOUT_FAILURE':
      return {
        ...state,
        isLoading: false,
        isAuthorized: false,
        account: '',
        message: '',
      };
    case 'PAGINATION_TRAININGS_PARAMS': {
      return {
        ...state,
        params: action.params,
      };
    }
    case 'UPDATE_ACTIVE_ITEM_SIDE_BAR':
      return {
        ...state,
        activeItemSideBar: action.activeItemSideBar,
      };
    case 'UPDATE_ACTIVE_ITEM_SIDE_BAR_ADMIN':
      return {
        ...state,
        activeItemSideBarAdmin: action.activeItemSideBarAdmin,
      };
    default:
      return state;
  }
};

export default AppReducer;
