import React, { Component } from 'react';
import Circle from 'react-circle';

class ProgressChart extends Component {

  render() {
    const { training, i } = this.props;
    const colorProgressChart = {
      1: '#00732F',
      2: '#EA0029',
      3: 'black',
    };
    let percentageCourse =
      (training.user_smart_training.section_pause / training.sections.length) * 100;
    return (
      <div
        key={training.id}
        //className={`courses-progress-stats-elem ${colorProgressChart[i]}--bar`}
      >
        <Circle
          style={{width:'88%'}}
          animate={true} // Boolean: Animated/Static progress
          responsive={true} // Boolean: Make SVG adapt to parent size
          size={150} // Number: Defines the size of the circle.
          lineWidth={14} // Number: Defines the thickness of the circle's stroke.
          progress={percentageCourse ? parseInt(percentageCourse) : 0} // Number: Update to change the progress and percentage.
          progressColor={colorProgressChart[i]} // String: Color of "progress" portion of circle.
          bgColor="rgb(216, 216, 216)" // String: Color of "empty" portion of circle.
          textColor="black" // String: Color of percentage text color.
          textStyle={{
            font: 'bold 5rem Helvetica, Arial, sans-serif', // CSSProperties: Custom styling for percentage.
          }}
          percentSpacing={10} // Number: Adjust spacing of "%" symbol and number.
          roundedStroke={true} // Boolean: Rounded/Flat line ends
          showPercentage={true} // Boolean: Show/hide percentage.
          showPercentageSymbol={true}
        />
        {/* <div className="courses-progress-stat-perc-ring">
          <div className="courses-progress-stat-perc-ring-content">

            <svg
              preserveAspectRatio="xMinYMin slice"
              height="10%" width="10%"
              className="pie training"
              viewBox="0 0 200 200"
              //data-percent={percentageCourse ? percentageCourse : 0}
            >
              <circle r="90" cx="100" cy="100"></circle>
              <circle
                 style={{
                  //strokeDashoffset: '280.823px',
                  strokeDashoffset:
                    training.user_smart_training.status === 'start'
                      ? '565.823px'
                      : training.user_smart_training.status === 'finish'
                      ? '5.823px'
                      : '280.823px',
                  strokeDasharray: '565.487px',
                }}
                className="bar"
                r="90"
                cx="100"
                cy="100"
              ></circle>
            </svg>
          </div>
           <div className="perc-in-number">
            <span> {percentageCourse ? `${parseInt(percentageCourse)}%` : 0}</span>
          </div>
        </div>*/}
        <div className="stats-related-course">
          <span>{training.name}</span>
        </div>
      </div>
    );
  }
}

export default ProgressChart;
