import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { host } from '../../../../../utils/constants';

class Training extends Component {
  render() {
    const { title, training, user_course } = this.props;
    let percentageCourse = (user_course.section_pause / training.sections.length) * 100;

    return (
      <div key={training} className="carousel-elem-box">
        <a
          style={{
            color: 'black',
            textDecoration: 'none',
          }}
          href={
            training.type === 1
              ? `/training/${training.id}`
              : `/intelligent/training/${training.id}`
          }
        >
          <div className="carousel-elem-box-img">
            <img src={`${host}${training.picture}`} alt="" />
          </div>
          <div className="carousel-elem-box-text">
            {!title ? <span>{training.name}</span> : ''}
            <div className="course--progress-bar">
              <div
                style={{
                  marginRight: user_course.section_pause === 0 ? '8%' : '0%',
                  width: `${percentageCourse ? percentageCourse : 0}%`,
                }}
                className="actual-progress green-prog-bg"
              >
                <span> {percentageCourse ? `${parseInt(percentageCourse)}%` : 0}</span>
              </div>
            </div>
          </div>
        </a>
      </div>
    );
  }
}

export default Training;
