import React, { Component } from 'react';
import { validate } from '../../../../../utils/helpers';
import moment from 'moment';

class FormAutobiography extends Component {
  render() {
    const { initialValues, hasError } = this.props;
    return (
      <div className="user-personal-info-form-content">
        <div className="user-personal-info-form-header">
          <span>السيرة الذاتية</span>
        </div>
        <div className="user-personal-info-form-body">
          <div className="user-personal-info-form-body-row">
            <div
              className={
                (hasError && !initialValues.university) ||
                (initialValues.university && !validate(initialValues.university))
                  ? 'user-info-input-field input-error'
                  : 'user-info-input-field'
              }
            >
              <select
                onChange={(e) => this.props.handleChangeInputUniversity(e)}
                value={initialValues.university}
                style={{ width: '100%' }}
                name="university"
                id=""
              >
                <option value="الجامعة">الجامعة</option>
                <option value="الجامعة">الجامعة</option>
                <option value="الجامعة">الجامعة</option>
              </select>
            </div>
            <div
              className={
                (hasError && !initialValues.section) ||
                (initialValues.section && !validate(initialValues.section))
                  ? 'user-info-input-field input-error'
                  : 'user-info-input-field'
              }
            >
              <select
                onChange={(e) => this.props.handleChangeInputUniversity(e)}
                value={initialValues.section}
                style={{ width: '100%' }}
                name="section"
                id=""
              >
                <option value="الإختصاص">الإختصاص</option>
                <option value="الإختصاص">الإختصاص</option>
                <option value="الإختصاص">الإختصاص</option>
              </select>
            </div>
          </div>

          <div className="user-personal-info-form-body-row">
            <div
              style={{ width: '100%' }}
              className={
                (hasError && !initialValues.date_from) ||
                (initialValues.date_from && moment().isBefore(initialValues.date_from)) ||
                (initialValues.date_from &&
                  initialValues.date_to &&
                  moment(initialValues.date_to).isBefore(initialValues.date_from))
                  ? 'user-info-input-field input-error'
                  : 'user-info-input-field'
              }
            >
              <div className="input-text-cont">
                <span>من تاريخ</span>
                <input
                  onChange={(e) => this.props.handleChangeInputUniversity(e)}
                  value={initialValues.date_from}
                  type="date"
                  placeholder="DD/MM/YY"
                  name="date_from"
                  id=""
                />
              </div>
            </div>
            <div
              className={
                (hasError && !initialValues.date_to) ||
                (initialValues.date_from &&
                  initialValues.date_to &&
                  moment(initialValues.date_to).isBefore(initialValues.date_from))
                  ? 'user-info-input-field input-error'
                  : 'user-info-input-field'
              }
            >
              <div style={{ width: '100%' }} className="input-text-cont">
                <span>إلى تاريخ</span>
                <input
                  onChange={(e) => this.props.handleChangeInputUniversity(e)}
                  value={initialValues.date_to}
                  type="date"
                  placeholder="DD/MM/YY"
                  name="date_to"
                  id=""
                />
              </div>
            </div>
          </div>

          <div className="user-personal-info-form-body-row details-txt-area">
            <div
              className={
                hasError && !initialValues.description
                  ? 'user-info-input-field input-error'
                  : 'user-info-input-field'
              }
            >
              <textarea
                onChange={(e) => this.props.handleChangeInputUniversity(e)}
                maxLength={1000}
                value={initialValues.description}
                placeholder="أكثر التفاصيل"
                name="description"
              ></textarea>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20.541"
                height="4.108"
                viewBox="0 0 20.541 4.108"
              >
                <g
                  id="Group_10"
                  data-name="Group 10"
                  transform="translate(-1065.1 -1781.336)"
                >
                  <circle
                    id="Ellipse_15"
                    data-name="Ellipse 15"
                    cx="2.054"
                    cy="2.054"
                    r="2.054"
                    transform="translate(1065.1 1781.336)"
                    fill="#dee0e6"
                  ></circle>
                  <circle
                    id="Ellipse_16"
                    data-name="Ellipse 16"
                    cx="2.054"
                    cy="2.054"
                    r="2.054"
                    transform="translate(1073.316 1781.336)"
                    fill="#dee0e6"
                  ></circle>
                  <circle
                    id="Ellipse_17"
                    data-name="Ellipse 17"
                    cx="2.054"
                    cy="2.054"
                    r="2.054"
                    transform="translate(1081.533 1781.336)"
                    fill="#dee0e6"
                  ></circle>
                </g>
              </svg>
            </div>
          </div>
        </div>
        <div style={{marginBottom:'15%'}}></div>
      </div>
    );
  }
}

export default FormAutobiography;
