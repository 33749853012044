import React, { Component } from 'react';
import actions from '../../../../js/actions';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import HeadersAuth from '../../../../layouts/auth/headers';
import Profile from '../../../users/pages/profile/containers/profiles';
import SidebarAdmin from '../../layouts/sidebar';

const mapStateToProps = (state) => {
  return {
    account: state.AppReducer.account,
    isLoadingUser: state.AppReducer.isLoadingUser,
    user: state.AppReducer.user,
    isLoadingSavedInfo: state.AppReducer.isLoadingSavedInfo,
    savedInfo: state.AppReducer.savedInfo,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getUser: (id) => dispatch(actions.getUser(id)),
  getSavedInformationUser: () => dispatch(actions.getSavedInformationUser()),
});

class User extends Component {
  constructor(props) {
    super(props);
    document.title = 'دوراتي';
    props.getUser(props.account.id);
    props.getSavedInformationUser();
  }
  render() {
    const {
      user,
      account,
      savedInfo,
      activeItemSideBarAdmin,
      isLoadingSavedInfo,
      isLoadingUser,
    } = this.props;
    if (isLoadingUser) {
      return (
        <section className="body-section dashboard-body">
          <div className="bs-main-container">
            <div className="bs-main-content">
              <CircularProgress
                style={{
                  textAlign: 'center',
                  marginTop: '25%',
                  width: '40px',
                  color: '#d85555',
                  marginRight: '50%',
                }}
              />
            </div>
          </div>
        </section>
      );
    }
    if (isLoadingSavedInfo) {
      return (
        <section className="body-section dashboard-body">
          <div className="bs-main-container">
            <div className="bs-main-content">
              <CircularProgress
                style={{
                  textAlign: 'center',
                  marginTop: '25%',
                  width: '40px',
                  color: '#d85555',
                  marginRight: '50%',
                }}
              />
            </div>
          </div>
        </section>
      );
    }
    return (
      <>
        <HeadersAuth type={true} />
        <section className="sidebar-body-container registered-user-view-main-cont">
          <SidebarAdmin
            user={account}
            activeItemSideBarAdmin={activeItemSideBarAdmin}
            changeActiveItemSideBarAdmin={this.props.changeActiveItemSideBarAdmin}
          />
          <Profile savedInfo={savedInfo} user={user} />
        </section>
      </>
    );
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(User));
