/* eslint-disable jsx-a11y/iframe-has-title */
import React, { Component } from 'react';
import update from 'immutability-helper';
import { host } from '../../../../../utils/constants';
import NextArrow from '../../../../../assets/img/next-arrow.png';
import Group2350 from '../../../../../assets/img/Group 2350@2x.png';
import Victory01 from '../../../../../assets/img/victory-01@2x.png';
import Group573 from '../../../../../assets/img/Group 573@2x.png';
import actions from '../../../../../js/actions';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { questionsStatic } from '../../../../../utils/constants';
import moment from 'moment';
import { toast } from 'react-toastify';
import ToastAction from '../../../../../componenets/ToastAction';
import ReactPlayer from 'react-player';
import Vimeo from '@u-wave/react-vimeo';
const mapStateToProps = (state) => ({
  score: state.AppReducer.score,
  account: state.AppReducer.account,
});
const mapDispatchToProps = (dispatch) => ({
  changeScoreSideBar: (score) => dispatch(actions.changeScoreSideBar(score)),
  submitUserTraining: (valuse) => dispatch(actions.submitUserTraining(valuse)),
  submitUpdatedUserTraining: (valuse) =>
    dispatch(actions.submitUpdatedUserTraining(valuse)),
  getMe: () => dispatch(actions.getMe()),
});

class Content extends Component {
  constructor(props) {
    super(props);
    let user_course = props.account.user_smart_trainings.find(
      (item) => item.course_id === props.training.id,
    );
    let hideBtn = false;
    let content = false;
    let endVideo = false;
    if (props.training) {
      this.props.changeScoreSideBar(props.training.point);
      if (props.training.section_pause === props.sections.length) {
        content = true;
        hideBtn = true;
        endVideo = false;
        toast(
          ({ closeToast }) => (
            <ToastAction type="success" message={`لقد تحصلت على الشهادة`} />
          ),
          {
            hideProgressBar: true,
          },
        );
      }
      if (props.training.section_pause < props.sections.length)
        if (
          props.training.section_pause > 0 &&
          props.sections[props.training.section_pause].section_items[0].doc_name !==
            'عناصر التلعيب على منصتنا'
        ) {
          content = true;
          hideBtn = true;
          endVideo = false;
        } else {
          if (
            props.sections[props.training.section_pause].section_items[0].doc_name !==
            'عناصر التلعيب على منصتنا'
          ) {
            content = true;
            hideBtn = true;
            endVideo = true;
          }
        }
      else {
      }
    } else {
      if (props.sections[0].section_items[0].doc_name !== 'عناصر التلعيب على منصتنا') {
        content = true;
        hideBtn = true;
      }
    }
    this.state = {
      index:
        props.training && props.training.section_pause < props.sections.length
          ? props.training.section_pause
          : 0,
      indexItemSection: 0,
      percent: 0,
      somme: 0,
      score:
        props.training && props.training.section_pause < props.sections.length
          ? props.training.point
          : 0,
      content: content,
      endVideo: endVideo,
      diploma: false,
      hideBtn: hideBtn,
      user_course: props.training ? props.training.section_pause : 1,
      questions:
        props.training &&
        props.training.section_pause &&
        props.training.section_pause < props.sections.length
          ? props.sections[props.training.section_pause].section_items[0].questions
          : props.training.section_pause > 0 &&
            props.sections[props.training.section_pause - 1].section_items[0].doc_name ===
              'استبيان تقيم الدورة'
          ? questionsStatic
          : props.sections[0].section_items[0].questions,
      initialValues: {
        question_1: '',
        sections: props.sections,
        section_items:
          props.training && props.training.section_pause < props.sections.length
            ? props.sections[props.training.section_pause].section_items[0]
            : props.sections[0].section_items[0],
        question_2: '',
        question_3: '',
        test_valid: false,
        question_2_valid: false,
        question_3_valid: false,
      },
    };
    this.handleValidateQuestion = this.handleValidateQuestion.bind(this);
    this.handleChangeQuestion = this.handleChangeQuestion.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleNextCourse = this.handleNextCourse.bind(this);
  }

  handleInputChange(value, id, index) {
    const { initialValues } = this.state;
    this.setState((prevState) => {
      const data = [...prevState.questions];
      var commentIndex = data.findIndex(function (c) {
        return c.id === id;
      });
      const updatedComment = update(data[commentIndex], {
        answer_user: { $set: value },
      });
      data[commentIndex] = updatedComment;
      this.setState({
        questions: data,
        initialValues: {
          ...initialValues,
        },
      });
    });
  }

  handleChangeQuestion(id, index, firstExam, endExam) {
    const { initialValues, user_course, score, somme } = this.state;
    this.setState((prevState) => {
      const data = [...prevState.questions];

      var commentIndex = data.findIndex(function (c) {
        return c.id === id;
      });
      if (data[commentIndex].answer_user === 'موافق تماماً')
        this.setState({
          somme: somme + 4,
        });
      if (data[commentIndex].answer_user === 'موافق')
        this.setState({
          somme: somme + 3,
        });
      if (data[commentIndex].answer_user === 'غير موافق')
        this.setState({
          somme: somme + 2,
        });
      if (data[commentIndex].answer_user === 'غير موافق تماماً')
        this.setState({
          somme: somme + 1,
        });
      if (
        data[commentIndex].answer_user === data[commentIndex].answer &&
        !firstExam &&
        !endExam
      ) {
        if (user_course < initialValues.sections.length) {
          this.setState({
            score: score + data[commentIndex].score,
          });
          toast(
            ({ closeToast }) => (
              <ToastAction
                message={` رائع ! تمت إضافة ${data[commentIndex].score} نقاط`}
                type="success"
              />
            ),
            {
              hideProgressBar: true,
            },
          );
          this.props.changeScoreSideBar(score + data[commentIndex].score);
        }
        // this.props.changeScoreSideBar({ ...data[commentIndex].score });
      }
      if (data[commentIndex + 1]) {
        if (data[commentIndex].answer_user) {
          const updatedComment1 = update(data[commentIndex], {
            answer_type: { $set: true },
            type: { $set: 0 },
          });
          data[commentIndex] = updatedComment1;
          const updatedComment = update(data[commentIndex + 1], {
            type: { $set: 1 },
          });
          data[commentIndex + 1] = updatedComment;
          this.setState({
            questions: data,
            initialValues: {
              ...initialValues,
            },
          });
        }
      } else {
        //test_valid
        this.setState({
          initialValues: {
            ...initialValues,
            test_valid: true,
          },
        });
      }
    });
  }

  async handleNextCourse(index, indexItemSection) {
    const { initialValues, questions, user_course, score, somme } = this.state;
    const { training } = this.props;
    if (indexItemSection + 1 >= initialValues.sections[index].section_items.length) {
      if (index + 1 === initialValues.sections.length - 1) {
        const countTypes = questions.filter(
          (question) => question.answer === question.answer_user,
        );
        let percent = (countTypes.length / questions.length) * 100;
        if (percent > 70) {
          if (user_course < initialValues.sections.length) {
            toast(
              ({ closeToast }) => (
                <ToastAction
                  message={` ! لقد حصلت على ${percent / 10}/${questions.length} `}
                  type="success"
                />
              ),
              {
                hideProgressBar: true,
              },
            );
            this.props.submitUserTraining({
              point: score + 100,
              status: 'finish',
              course_id: training.id,
              somme: Math.round(somme / 13),
              section_pause: initialValues.sections.length,
            });
          }
          this.setState({
            percent: percent,
            diploma: true,
            index: index + 1,
            indexItemSection: 0,
            initialValues: {
              ...initialValues,
              test_valid: false,
            },
            questions:
              initialValues.sections[index].section_items[indexItemSection].questions,
          });
        } else {
          if (user_course < initialValues.sections.length) {
            toast(
              ({ closeToast }) => (
                <ToastAction
                  type="error"
                  message={`عذرًا! لقد حصلت على ${percent / 10}/${questions.length} `}
                />
              ),
              {
                hideProgressBar: true,
              },
            );

            this.props.submitUpdatedUserTraining({
              course_id: training.id,
              point: 0,
              section_pause: 0,
            });
          }
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      } else {
        const countTypes = questions.filter(
          (question) => question.answer === question.answer_user,
        );
        if (
          initialValues.sections[index].section_items[indexItemSection].doc_name ===
          'الاختبار القبلي'
        ) {
          if (user_course < initialValues.sections.length)
            toast(
              ({ closeToast }) => (
                <ToastAction
                  message={` ${countTypes.length} / ${questions.length}`}
                  type="result"
                />
              ),
              {
                hideProgressBar: true,
              },
            );
        }
        let percent = (countTypes.length / questions.length) * 100;
        if (
          percent < 50 &&
          initialValues.sections[index].section_items[indexItemSection].doc_name !==
            'الاختبار القبلي'
        ) {
          this.setState({
            content: true,
            initialValues: {
              ...initialValues,
              test_valid: false,
            },
          });
          if (user_course < initialValues.sections.length)
            toast(
              ({ closeToast }) => (
                <ToastAction message="إجابة خاطئة ! أعد المشاهدة" type="error" />
              ),
              {
                hideProgressBar: true,
              },
            );
        } else {
          this.props.handleNextSection(
            initialValues.sections[index + 1].section_items[0].id,
          );
          if (user_course < initialValues.sections.length)
            this.props.submitUpdatedUserTraining({
              course_id: training.id,
              point: score,
              section_pause: index + 1,
            });
          this.setState({
            diploma: false,
            index: index + 1,
            indexItemSection: 0,
            initialValues: {
              ...initialValues,
              test_valid: false,
            },
            questions: initialValues.sections[index + 1].section_items[0].questions,
          });
        }
      }
    } else {
      if (
        initialValues.sections[index].section_items[indexItemSection].doc_name ===
        'استبيان تقيم الدورة'
      ) {
        //questionsStatic
        if (user_course < initialValues.sections.length)
          toast(
            ({ closeToast }) => (
              <ToastAction message="نشكرك على تقديم الاستبيان" type="success" />
            ),
            {
              hideProgressBar: true,
            },
          );
      }
      const countTypes = questions.filter(
        (question) => question.answer === question.answer_user,
      );
      let percent = (countTypes.length / questions.length) * 100;
      if (
        initialValues.sections[index].section_items[indexItemSection].doc_name ===
        'الاختبار القبلي'
      ) {
        if (user_course < initialValues.sections.length)
          toast(
            ({ closeToast }) => (
              <ToastAction
                message={` ${countTypes.length} / ${questions.length}`}
                type="result"
              />
            ),
            {
              hideProgressBar: true,
            },
          );
      }
      if (
        percent < 50 &&
        initialValues.sections[index].section_items[indexItemSection].doc_name !==
          'الاختبار القبلي' &&
        initialValues.sections[index].section_items[indexItemSection].doc_name !==
          'استبيان تقيم الدورة'
      ) {
        this.setState({
          content: true,
          initialValues: {
            ...initialValues,
            test_valid: false,
          },
        });
        if (user_course < initialValues.sections.length)
          toast(
            ({ closeToast }) => (
              <ToastAction message="إجابة خاطئة ! أعد المشاهدة" type="error" />
            ),
            {
              hideProgressBar: true,
            },
          );
      } else {
        this.props.handleNextSection(
          initialValues.sections[index].section_items[indexItemSection + 1].id,
        );
        if (
          initialValues.sections[index].section_items[indexItemSection + 1].doc_name ===
          'استبيان تقيم الدورة'
        ) {
          this.setState({
            index: index,
            indexItemSection: indexItemSection + 1,
            initialValues: {
              ...initialValues,
              test_valid: false,
            },
            questions: questionsStatic,
          });
        } else
          this.setState({
            index: index,
            indexItemSection: indexItemSection + 1,
            initialValues: {
              ...initialValues,
              test_valid: false,
            },
            questions:
              initialValues.sections[index].section_items[indexItemSection + 1].questions,
          });
      }
    }
  }

  handleValidateQuestion(question) {
    const { initialValues } = this.state;
    this.setState({
      initialValues: {
        ...initialValues,
        [question]: true,
      },
    });
  }
  render() {
    const {
      initialValues,
      hideBtn,
      questions,
      indexItemSection,
      index,
      content,
      diploma,
      percent,
      endVideo,
    } = this.state;
    const { training, account } = this.props;
    let i = 0;
    return (
      <div className="gamification-sys-container">
        <div className="gamification-sys-content">
          {diploma ? (
            <>
              <div className="knowledge-certif-img-cont">
                <img src={Victory01} alt="" />
              </div>
              <div className="knowledge-certif" style={{ width: '100%' }}>
                <img src={Group573} alt="" />
                <div className="knowledge-certif-content">
                  <div className="knowledge-certif-content-header">
                    {/* <span style={{fontSize:'10px'}} className="certif-title">شهادة</span>
                  <span style={{fontSize:'17px'}} className="certif-subtitle">مشاركة</span>
                  <div className="horz-spr"></div> */}
                  </div>
                  <div className="knowledge-certif-content-body">
                    <span
                      style={{
                        fontSize: '15px',
                        marginTop: '50px',
                      }}
                      className="knowledge-certif-content-body-head"
                    >
                      {/* >هذه الشهادة مقدمة إلى */}
                    </span>
                    <span style={{ fontSize: '28px' }} className="certif-desc">
                      تتقدم الأمانة العامة للمجلس التنفيذي بالشكر<br></br>
                      للسيد(ة) /{' '}
                      <strong>
                        {' '}
                        {`${account.first_name} ${account.last_name}`}
                      </strong>{' '}
                      <br></br>
                      لمشاركتكم في دورة <strong>{training.name}</strong> بأكاديمية المجلس
                      التنفيذي الذكية لإمارة أم القيوين
                    </span>
                  </div>
                  <div className="knowledge-certif-content-footer">
                    <div className="certif-date"></div>
                    <div className="certif-award-badge">
                      <div className="certif-badge-cont"></div>
                    </div>
                    <div
                      className="certif-manager-signature"
                      style={{ marginRight: '65px', marginTop: '10px' }}
                    >
                      <span
                        style={{
                          marginLeft: '-60px',
                          fontSize: '15px',
                          fontWeight: '700',
                        }}
                      >
                        {moment().format('DD-MM-YYYY')}
                      </span>
                      {/* <div className="certif-manager-signature-spr"></div>
                    <span>المدير العام</span> */}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : initialValues.sections[index].section_items[indexItemSection].doc_name !==
              'الاختبار القبلي' &&
            initialValues.sections[index].section_items[indexItemSection].doc_name !==
              'استبيان تقيم الدورة' &&
            initialValues.test_valid ? (
            <div className="knowledge-test-sys-content">
              <div className="knowledge-test-sys-header">
                <span>النتيجة</span>
              </div>
              <div className="knowledge-test-sys-body">
                <div className="knowledge-test-sys-body-content">
                  {questions.map((question) => {
                    i++;
                    return (
                      <div
                        key={question.id}
                        className={
                          question.answer_user === question.answer
                            ? 'knowledge-test-sys-body-elem correct-answer'
                            : 'knowledge-test-sys-body-elem wrong-answer'
                        }
                      >
                        <div className="knowledge-test-sys-question-container">
                          <div className="knowledge-test-sys-question-content">
                            <div className="knowledge-test-sys-question-title">
                              <div className="knowledge-test-sys-question-title-content">
                                <span>
                                  السؤال {i} من {questions.length}
                                </span>
                              </div>
                            </div>
                            <div className="knowledge-test-sys-question-body">
                              <div className="question-res">
                                <span>
                                  {question.answer_user === question.answer
                                    ? 'إجابة صحيحة !'
                                    : 'إجابة خاطئة !'}
                                </span>
                              </div>
                              <div className="question-multiple-choice-res">
                                <div className="actual-question">
                                  <span>{question.question}</span>
                                </div>
                                <div className="question-multiple-choice-res-row">
                                  {question.propositions.map((content) => {
                                    if (content.content === question.answer) {
                                      return (
                                        <div
                                          key={content.id}
                                          className="question-multiple-choice-res-elem MCwrong-answer"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="18"
                                            height="18"
                                            viewBox="0 0 18 18"
                                          >
                                            <g
                                              id="Group_116"
                                              data-name="Group 116"
                                              transform="translate(-1037 -1886)"
                                            >
                                              <g
                                                id="Ellipse_118"
                                                data-name="Ellipse 118"
                                                transform="translate(1037 1886)"
                                                fill="#00732f"
                                                stroke="#00732f"
                                                strokeWidth="1.5"
                                              >
                                                <circle
                                                  cx="9"
                                                  cy="9"
                                                  r="9"
                                                  stroke="none"
                                                ></circle>
                                                <circle
                                                  cx="9"
                                                  cy="9"
                                                  r="8.25"
                                                  fill="none"
                                                ></circle>
                                              </g>
                                              <path
                                                id="Path_312"
                                                data-name="Path 312"
                                                d="M-7901.059-861.254l-5.814,5.167L-7910-860.27"
                                                transform="translate(8951.499 2754.754)"
                                                fill="none"
                                                stroke="#fff"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="1.5"
                                              ></path>
                                            </g>
                                          </svg>
                                          <span>{content.content}</span>
                                        </div>
                                      );
                                    } else
                                      return (
                                        <div
                                          key={content.id}
                                          className="question-multiple-choice-res-elem MCcorrect-answer"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                            width="18"
                                            height="18"
                                            viewBox="0 0 18 18"
                                          >
                                            <defs>
                                              <clipPath id="clip-path">
                                                <rect
                                                  id="Rectangle_475"
                                                  data-name="Rectangle 475"
                                                  width="18"
                                                  height="12"
                                                  transform="translate(1037 729)"
                                                  fill="#"
                                                />
                                              </clipPath>
                                            </defs>
                                            <g
                                              id="Group_113"
                                              data-name="Group 113"
                                              transform="translate(-1037 -1402)"
                                            >
                                              <g
                                                id="Group_102"
                                                data-name="Group 102"
                                                transform="translate(0 676)"
                                              >
                                                <g
                                                  id="Ellipse_114"
                                                  data-name="Ellipse 114"
                                                  transform="translate(1037 726)"
                                                  fill="#ea0029"
                                                  stroke="#ea0029"
                                                  strokeWidth="1.5"
                                                >
                                                  <circle
                                                    cx="9"
                                                    cy="9"
                                                    r="9"
                                                    stroke="none"
                                                  />
                                                  <circle
                                                    cx="9"
                                                    cy="9"
                                                    r="8.25"
                                                    fill="none"
                                                  />
                                                </g>
                                                <g
                                                  id="Mask_Group_1"
                                                  data-name="Mask Group 1"
                                                  clipPath="url(#clip-path)"
                                                >
                                                  <circle
                                                    id="Ellipse_117"
                                                    data-name="Ellipse 117"
                                                    cx="6"
                                                    cy="6"
                                                    r="6"
                                                    transform="translate(1040 743)"
                                                    fill="#ea0029"
                                                  />
                                                </g>
                                              </g>
                                              <line
                                                id="Line_7"
                                                data-name="Line 7"
                                                x2="6.233"
                                                y2="7.366"
                                                transform="translate(1042.884 1407.317)"
                                                fill="none"
                                                stroke="#fff"
                                                strokeLinecap="round"
                                                strokeWidth="1.5"
                                              />
                                              <line
                                                id="Line_8"
                                                data-name="Line 8"
                                                x1="6.232"
                                                y2="7.366"
                                                transform="translate(1042.884 1407.317)"
                                                fill="none"
                                                stroke="#fff"
                                                strokeLinecap="round"
                                                strokeWidth="1.5"
                                              />
                                            </g>
                                          </svg>
                                          <span>{content.content}</span>
                                        </div>
                                      );
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="knowledge-test-sys-question-btn-cont">
                <button
                  onClick={() => {
                    this.handleNextCourse(index, indexItemSection);
                    this.setState({
                      hideBtn: true,
                      /*endVideo:
                        initialValues.sections[index].section_items[indexItemSection + 1]
                          .type === 'video'
                          ? false
                          : true,*/
                      /*initialValues: {
                          ...initialValues,
                          test_valid: false,
                        },*/
                    });
                  }}
                  className="btn btn-green"
                >
                  <span>التالي</span>
                  <img src={NextArrow} alt="" />
                </button>
              </div>
            </div>
          ) : !initialValues.sections[index].section_items[indexItemSection].type &&
            content ? (
            <>
              <div className="gamification-sys-header">
                <span>
                  {initialValues.sections[index].section_items[indexItemSection].doc_name}
                </span>
              </div>
              <div className="knowledge-test-sys-body">
                <div className="knowledge-test-sys-body-content">
                  {questions &&
                    questions.length > 0 &&
                    questions.map((question) => {
                      i++;
                      if (question.type === 1)
                        return (
                          <div key={question.id} className="knowledge-test-sys-body-elem">
                            <div className="knowledge-test-sys-question-container">
                              <div className="knowledge-test-sys-question-content">
                                <div className="knowledge-test-sys-question-title">
                                  <div className="knowledge-test-sys-question-title-content">
                                    <span>
                                      السؤال {i} من {questions.length}
                                    </span>
                                  </div>
                                </div>
                                <div className="knowledge-test-sys-question-body">
                                  <div className="question-multiple-choice">
                                    <div className="actual-question">
                                      <span>{question.question}</span>
                                    </div>
                                    <form id="question1" action="">
                                      {question.propositions.map((content) => {
                                        return (
                                          <div
                                            key={content.id}
                                            className="question-multiple-choice-row"
                                          >
                                            <input
                                              onChange={(e) =>
                                                this.handleInputChange(
                                                  content.content,
                                                  question.id,
                                                  index,
                                                )
                                              }
                                              type="radio"
                                              name="question_1"
                                              id="option1"
                                              value={content.content}
                                            />
                                            <label htmlFor="option1">
                                              {content.content}
                                            </label>
                                          </div>
                                        );
                                      })}
                                    </form>
                                  </div>
                                </div>
                                {!question.answer_type ? (
                                  <div className="knowledge-test-sys-question-btn-cont">
                                    <button
                                      onClick={() => {
                                        if (
                                          question.id ===
                                            questions[questions.length - 1].id &&
                                          //initialValues.test_valid &&
                                          (initialValues.sections[index].section_items[
                                            indexItemSection
                                          ].doc_name === 'استبيان تقيم الدورة' ||
                                            initialValues.sections[index].section_items[
                                              indexItemSection
                                            ].doc_name === 'الاختبار القبلي')
                                        ) {
                                          this.setState({
                                            hideBtn: true,
                                            endVideo: false,
                                            /*initialValues: {
                                                ...initialValues,
                                                test_valid: false,
                                              },*/
                                          });
                                          this.handleNextCourse(index, indexItemSection);
                                        } else
                                          this.handleChangeQuestion(
                                            question.id,
                                            index,
                                            initialValues.sections[index].section_items[
                                              indexItemSection
                                            ].doc_name === 'الاختبار القبلي'
                                              ? true
                                              : false,
                                            initialValues.sections[index].section_items[
                                              indexItemSection
                                            ].doc_name === 'استبيان تقيم الدورة'
                                              ? true
                                              : false,
                                          );
                                      }}
                                      type="button"
                                      className="btn btn-green"
                                      form="question1"
                                    >
                                      تأكيد الاجابة
                                    </button>
                                  </div>
                                ) : (
                                  ''
                                )}
                              </div>
                            </div>
                          </div>
                        );
                    })}
                </div>
              </div>
            </>
          ) : content ? (
            <div className="gamification-sys-content">
              <div className="gamification-sys-header">
                <span>
                  {initialValues.sections[index].section_items[indexItemSection].doc_name}
                </span>
              </div>
              {initialValues.sections[index].type !== 0 ? (
                <>
                  {initialValues.sections[index].section_items[indexItemSection].type ===
                  'text' ? (
                    <div className="gamification-sys-body">
                      <div className="gamification-sys-body-content">
                        <div className="gamification-sys-body-elem">
                          <span>
                            {initialValues.sections[index].section_items[
                              indexItemSection
                            ].doc_url
                              .split('N__L')
                              .map((i) => (
                                <span>
                                  {i}
                                  <br></br>
                                </span>
                              ))}
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : initialValues.sections[index].section_items[indexItemSection]
                      .type === 'video' ? (
                    <div className="course-video-body">
                      <div className="course-video-body-content">
                         <iframe
                          ref={(el) => (this.videoElement = el)}
                          class="sproutvideo-player"
                          src={`${initialValues.sections[index].section_items[indexItemSection].doc_url}`}
                          width="100%"
                          height="420px"
                          frameborder="0"
                          allowfullscreen
                        ></iframe>
                        {/*<Vimeo
                          src={`${initialValues.sections[index].section_items[indexItemSection].doc_url}`}
                          autoplay
                        />*/}
                        {/*<ReactPlayer
                          ///controls
                          onSeek={() => console.log(455555)}
                          config={{
                            youtube: {
                              playerVars: { showinfo: 1 },
                            },
                          }}
                          playsinline
                          playing
                          url={`${initialValues.sections[index].section_items[indexItemSection].doc_url}`}
                          onEnded={() =>
                            this.setState({
                              hideBtn: true,
                              endVideo: true,
                            })
                          }
                        />*/}
                      </div>
                    </div>
                  ) : initialValues.sections[index].section_items[indexItemSection]
                      .type === 'pdf' ? (
                    <div className="gamification-sys-body">
                      <div className="gamification-sys-body-content">
                        <div className="gamification-sys-body-elem">
                          <iframe
                            src={`${host}/public/uploads/trainings/${training.id}/docs/${initialValues.sections[index].section_items[indexItemSection].id}/${initialValues.sections[index].section_items[indexItemSection].doc_url}`}
                            frameBorder="0"
                            height="420px"
                            width="100%"
                          ></iframe>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="gamification-sys-body">
                      <div className="gamification-sys-body-content">
                        <div className="gamification-sys-body-elem">
                          <span>
                            كيف ستقم منصتنا بتحفيزك عن طريق التلعيب أثناء رحتلك التعليمية
                          </span>
                          <a
                            className="btn btn-green"
                            target="_blank"
                            href={`${host}/public/uploads/trainings/${training.id}/doc/${initialValues.sections[index].section_items[indexItemSection].id}/${initialValues.sections[index].section_items[indexItemSection].doc_url}`}
                          >
                            عرض المحتوى
                          </a>
                        </div>
                      </div>
                    </div>
                  )}{/*&&
                  initialValues.sections[index].section_items[indexItemSection].type !==
                    'video'*/}
                  {hideBtn ? (
                    <div className="next-btn">
                      <button
                        onClick={() => {
                          if (
                            initialValues.sections[index].section_items[indexItemSection]
                              .questions.length === 0
                          )
                            this.handleNextCourse(index, indexItemSection);
                          this.setState({
                            hideBtn:
                              initialValues.sections[index].section_items[
                                indexItemSection
                              ].questions.length === 0
                                ? true
                                : !hideBtn,
                            // content: !content,
                            initialValues: {
                              ...initialValues,
                              test_valid: false,
                            },
                          });
                        }}
                        className="btn btn-green"
                      >
                        <span>التالي</span>
                        <img src={NextArrow} alt="" />
                      </button>
                    </div>/* &&
                    endVideo &&
                    initialValues.sections[index].section_items[indexItemSection].type ===
                      'video'*/
                  ) : hideBtn ? (
                    <div className="next-btn">
                      <button
                        onClick={() => {
                          if (
                            initialValues.sections[index].section_items[indexItemSection]
                              .questions.length === 0
                          )
                            this.handleNextCourse(index, indexItemSection);
                          this.setState({
                            hideBtn:
                              initialValues.sections[index].section_items[
                                indexItemSection
                              ].questions.length === 0
                                ? true
                                : !hideBtn,
                            endVideo: false,
                            initialValues: {
                              ...initialValues,
                              test_valid: false,
                            },
                          });
                        }}
                        className="btn btn-green"
                      >
                        <span>التالي</span>
                        <img src={NextArrow} alt="" />
                      </button>
                    </div>
                  ) : (
                    ''
                  )}
                </>
              ) : (
                ''
              )}
              {!hideBtn ? (
                <div className="knowledge-test-sys-body">
                  <div className="knowledge-test-sys-body-content">
                    {questions &&
                      questions.length > 0 &&
                      questions.map((question) => {
                        i++;
                        if (question.type === 1)
                          return (
                            <div
                              key={question.id}
                              className="knowledge-test-sys-body-elem"
                            >
                              <div className="knowledge-test-sys-question-container">
                                <div className="knowledge-test-sys-question-content">
                                  <div className="knowledge-test-sys-question-title">
                                    <div className="knowledge-test-sys-question-title-content">
                                      <span>
                                        السؤال {i} من {questions.length}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="knowledge-test-sys-question-body">
                                    <div className="question-multiple-choice">
                                      <div className="actual-question">
                                        <span>{question.question}</span>
                                      </div>
                                      <form id="question1" action="">
                                        {question.propositions.map((content) => {
                                          return (
                                            <div
                                              key={content.id}
                                              className="question-multiple-choice-row"
                                            >
                                              <input
                                                onChange={(e) =>
                                                  this.handleInputChange(
                                                    content.content,
                                                    question.id,
                                                    index,
                                                  )
                                                }
                                                type="radio"
                                                name="question_1"
                                                id="option1"
                                                value={content.content}
                                              />
                                              <label htmlFor="option1">
                                                {content.content}
                                              </label>
                                            </div>
                                          );
                                        })}
                                      </form>
                                    </div>
                                  </div>
                                  {!question.answer_type ? (
                                    <div className="knowledge-test-sys-question-btn-cont">
                                      <button
                                        onClick={() =>
                                          this.handleChangeQuestion(
                                            question.id,
                                            index,
                                            initialValues.sections[index].section_items[
                                              indexItemSection
                                            ].doc_name === 'الاختبار القبلي'
                                              ? true
                                              : false,
                                            initialValues.sections[index].section_items[
                                              indexItemSection
                                            ].doc_name === 'استبيان تقيم الدورة'
                                              ? true
                                              : false,
                                          )
                                        }
                                        type="button"
                                        className="btn btn-green"
                                        form="question1"
                                      >
                                        تأكيد الاجابة
                                      </button>
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                      })}
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          ) : (
            <>
              <div className="gamification-sys-header">
                <span>{initialValues.section_items.doc_name}</span>
              </div>
              <div className="gamification-sys-body">
                <div className="gamification-sys-body-content">
                  <div className="gamification-sys-body-elem">
                    <span>
                      كيف ستقم منصتنا بتحفيزك عن طريق التلعيب أثناء رحتلك التعليمية
                    </span>
                    <a
                      className="btn btn-green"
                      target="_blank"
                      href={`${host}/public/uploads/trainings/${training.id}/${training.main_doc}`}
                    >
                      عرض المحتوى
                    </a>
                  </div>
                  <div className="gamification-sys-body-elem">
                    <div className="gamification-sys-test-container">
                      <div className="gamification-sys-test-content">
                        <div className="gamification-sys-test-title">
                          <span>
                            {
                              initialValues.sections[index].section_items[
                                indexItemSection
                              ].doc_name
                            }
                          </span>
                        </div>
                        <div className="gamification-sys-test-desc">
                          <span>
                            تهدف هذه الاسئلة الى مراجعه ماتعرفه من معلومات بالفعل عن{' '}
                            {training.name}
                          </span>
                          <button
                            onClick={() => {
                              this.handleNextCourse(index, indexItemSection);
                              this.setState({
                                content: !content,
                                hideBtn: false,
                              });
                            }}
                            className="btn btn-green"
                          >
                            <span>التالي</span>
                            <img src={NextArrow} alt="" />
                          </button>
                        </div>
                        <div className="gamification-sys-test-img-cont">
                          <img src={Group2350} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Content));
