import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { routerMiddleware, routerReducer } from 'react-router-redux';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { reducer as formReducer } from 'redux-form';
import './index.css';
import App from './App';
/*Reducers*/
import AppReducer from './js/reducers';
import LibraryReducer from './pages/libraries/store/reducers';
import CertifiedMemberReducer from './pages/members/store/reducers';
import MemberReducer from './pages/registered-members/store/reducers';
import RegisterReducer from './pages/authentification/register/store/reducers';
import ResetPasswordReducer from './pages/authentification/resetPassword/store/reducers';
import AdvancementUserCourseReducer from './pages/admin/store/reducers';

const history = createBrowserHistory();
const middlewares = [thunk, routerMiddleware(history)];
const store = createStore(
  combineReducers({
    AppReducer,
    LibraryReducer,
    RegisterReducer,
    CertifiedMemberReducer,
    MemberReducer,
    AdvancementUserCourseReducer,
    ResetPasswordReducer,
    router: routerReducer,
    form: formReducer,
  }),
  applyMiddleware(...middlewares),
);

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter basename="/">
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
