import React, { Component } from 'react';
export default class ToastAction extends Component {
  render() {
    const { message, type } = this.props;
    return (
      <div className="ui_elems_toasts">
        <div
          className={`select_container_item ${
            type === 'success'
              ? 'toast_succ'
              : type === 'error'
              ? 'toast_error'
              : type === 'warning'
              ? 'toast_warning'
              : 'toast_info'
          }`}
          style={{
            marginTop: '-7%',
            height: '153px',
            marginLeft: '-7%',
            width: '320px',
          }}
        >
          <div className="left_section">
            {type === 'success' ? (
              <svg
                width="17"
                height="13"
                viewBox="0 0 17 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.25 9.82422L14.9609 0.113281L16.25 1.40234L5.25 12.4023L0.136719 7.28906L1.42578 6L5.25 9.82422Z"
                  fill="white"
                />
              </svg>
            ) : type === 'error' ? (
              <svg
                width="14"
                height="18"
                viewBox="0 0 14 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.4023 1.65234V3.5H0.597656V1.65234H3.77734L4.72266 0.75H9.27734L10.2227 1.65234H13.4023ZM1.5 15.4023V4.40234H12.5V15.4023C12.5 15.8893 12.3138 16.319 11.9414 16.6914C11.569 17.0638 11.1393 17.25 10.6523 17.25H3.34766C2.86068 17.25 2.43099 17.0638 2.05859 16.6914C1.6862 16.319 1.5 15.8893 1.5 15.4023Z"
                  fill="white"
                />
              </svg>
            ) : type === 'warning' ? (
              <svg
                width="22"
                height="19"
                viewBox="0 0 22 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.9023 11.8477V8.15234H10.0977V11.8477H11.9023ZM11.9023 15.5V13.6523H10.0977V15.5H11.9023ZM0.902344 18.25L11 0.847656L21.0977 18.25H0.902344Z"
                  fill="white"
                />
              </svg>
            ) : (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.9023 7.25V5.40234H9.09766V7.25H10.9023ZM10.9023 14.5977V9.09766H9.09766V14.5977H10.9023ZM3.51172 3.55469C5.31641 1.75 7.47917 0.847656 10 0.847656C12.5208 0.847656 14.6693 1.75 16.4453 3.55469C18.25 5.33073 19.1523 7.47917 19.1523 10C19.1523 12.5208 18.25 14.6836 16.4453 16.4883C14.6693 18.2643 12.5208 19.1523 10 19.1523C7.47917 19.1523 5.31641 18.2643 3.51172 16.4883C1.73568 14.6836 0.847656 12.5208 0.847656 10C0.847656 7.47917 1.73568 5.33073 3.51172 3.55469Z"
                  fill="white"
                />
              </svg>
            )}
          </div>
          <div className="right_section">
            <span className="toast_title" style={{fontSize:'30px'}}>
              {type === 'result'
                ? 'النتيجة'
                : type === 'success'
                ? 'نجاح'
                : type === 'error'
                ? 'خطأ'
                : type === 'warning'
                ? 'إشعار'
                : 'تنبيه هام'}
            </span>
            <span className="sub_desc"  style={{fontSize:'25px', marginTop:'15px'}}>{message}</span>
          </div>
        </div>
      </div>
    );
  }
}
