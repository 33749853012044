import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import imgTraining4 from '../../assets/img/sebastian-herrmann-NbtIDoFKGO8-unsplash.png';
import actions from '../../js/actions';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { MemoryRouter, Route } from 'react-router';
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import moment from 'moment';
import { host } from '../../utils/constants';
import Headers from '../../layouts/headers';

const mapStateToProps = (state) => ({
  isLoadingTrainings: state.AppReducer.isLoadingTrainings,
  trainings: state.AppReducer.trainings,
  params: state.AppReducer.params,
  isAuthorized: state.AppReducer.isAuthorized,
});

const mapDispatchToProps = (dispatch) => ({
  changeSearchedString: (params, type) => {
    dispatch(actions.changeSearchedString(params));
    dispatch(actions.getAllTrainings(params, type));
  },
  getAllTrainings: (params, type) => dispatch(actions.getAllTrainings(params, type)),
});

class Trainings extends Component {
  constructor(props) {
    super(props);
    document.title = 'التدريب الذكي';
    props.getAllTrainings(props.params, 2);
    this.makeHttpRequestWithPage = this.makeHttpRequestWithPage.bind(this);
  }
  makeHttpRequestWithPage = (event, page) => {
    this.props.changeSearchedString(
      {
        ...this.props.params,
        currentPage: page ? page : this.props.params.currentPage - 1,
        limit: this.props.params.limit,
      },
      2,
    );
  };
  render() {
    const { isLoadingTrainings, trainings, isAuthorized } = this.props;
    return (
      <section className="body-section">
        <Headers isAuthorized={isAuthorized} />
        <div
          style={{ backgroundColor: '#EDEDED' }}
          className="bs-main-container  zoom-training-body"
        >
          <div className="bs-main-content">
            <div className="bs-main-content-header-sm-tr">
              <div className="bs-main-content-header-title-sm-tr">
                <span>التدريب الذكي</span>
              </div>
              <div className="training-program-section-header-desc">
                <span>
                  {' '}
                  <p>
                    {' '}
                    نقدم مجموعة متنوعة من البرامج التدريبية بنظام التعلم عن بعد والمسجلة
                    بتقنية ثلاثي الابعاد ...
                  </p>
                  <p>
                    عزيزي الموظف هذه الدورات متاحة للدخول فور ضغطك على زر التسجيل ويمكنك
                    التمتع بالتعلم واجتياز الدورات بالوقت الذي يتناسب معك بعيدا عن ضغوط
                    العمل ...
                  </p>
                  <span>
                    كما تؤهلك هذه الدورات للحصول على نقاط وحوافز تقديرية من حكومة أم
                    القيوين حين اجتيازك لكل برنامج تدريبي بنجاح.{' '}
                  </span>
                </span>
              </div>
            </div>
            {isLoadingTrainings ? (
              <div className="cards-container">
                <div className="cards-wrapper">
                  <div className="cardes-wrapper-content">isLoading</div>
                </div>
              </div>
            ) : (
              <>
                <div className="cards-container">
                  <div className="cards-wrapper">
                    <div className="cardes-wrapper-content">
                      {trainings &&
                        trainings.data &&
                        trainings.data.length > 0 &&
                        trainings.data
                          .sort((a, b) =>
                            a.sections.length < b.sections.length
                              ? 1
                              : b.sections.length < a.sections.length
                              ? -1
                              : 0,
                          )
                          .map((training) => {
                            return (
                              <a
                                key={training.id}
                                style={{ color: 'black', textDecoration: 'none' }}
                                href={
                                  training.sections && training.sections.length > 0
                                    ? `/intelligent/training/${training.id}`
                                    : '#'
                                }
                              >
                                <div className="">
                                  <div
                                    style={{
                                      border:
                                        training.sections.length > 0
                                          ? '5px solid green'
                                          : '',
                                      borderRadius:
                                        training.sections.length > 0 ? '18px' : '',
                                    }}
                                    className="training-program-card"
                                  >
                                    <div className="training-program-card-content">
                                      <div className="training-program-card-miniature">
                                        <img src={`${host}${training.picture}`} alt="" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </a>
                            );
                          })}
                    </div>
                  </div>
                </div>
                <div className="pagination-container"></div>
              </>
            )}
          </div>
        </div>
      </section>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Trainings));
