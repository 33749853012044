import React, { Component } from 'react';
import { validate, validatePhone } from '../../../../../utils/helpers';
import validator from 'validator';
import { host } from '../../../../../utils/constants';
import DefaultImg from '../../../../../assets/img/Group 2350@2x (2).png';

class FormPersonnelInfo extends Component {
  handleClick(fileUploader) {
    this.refs[fileUploader].click();
  }
  render() {
    const { initialValues, hasError } = this.props;
    return (
      <div className="user-personal-info-form-container">
        <div className="user-personal-info-form-content">
          <div className="user-personal-info-form-header">
            <span>المعلومات الشخصية</span>
          </div>
          <div className="user-personal-info-form-body">
            <div className="user-personal-info-form-body-row">
              <div
                className={
                  (hasError && !initialValues.first_name) ||
                  (initialValues.first_name && !validate(initialValues.first_name))
                    ? 'user-info-input-field input-error'
                    : 'user-info-input-field'
                }
              >
                <input
                  onChange={(e) => this.props.handleChangeInput(e)}
                  value={initialValues.first_name}
                  type="text"
                  placeholder="الأسم"
                  name="first_name"
                  id=""
                />
              </div>
              <div
                className={
                  (hasError && !initialValues.last_name) ||
                  (initialValues.last_name && !validate(initialValues.last_name))
                    ? 'user-info-input-field input-error'
                    : 'user-info-input-field'
                }
              >
                <input
                  onChange={(e) => this.props.handleChangeInput(e)}
                  value={initialValues.last_name}
                  type="text"
                  placeholder="الأسم الثاني"
                  name="last_name"
                  id=""
                />
              </div>
            </div>

            <div className="user-personal-info-form-body-row">
              <div
                className={
                  (hasError && !initialValues.job_title) ||
                  (initialValues.job_title && !validate(initialValues.job_title))
                    ? 'user-info-input-field input-error'
                    : 'user-info-input-field'
                }
              >
                <input
                  onChange={(e) => this.props.handleChangeInput(e)}
                  value={initialValues.job_title}
                  type="text"
                  placeholder="المسمى الوظيفي"
                  name="job_title"
                  id=""
                />
              </div>
              <div
                className={
                  (hasError && !initialValues.phone) ||
                  (initialValues.phone && !validatePhone(initialValues.phone))
                    ? 'user-info-input-field input-error'
                    : 'user-info-input-field'
                }
              >
                <input
                  maxLength={20}
                  onChange={(e) => this.props.handleChangeInput(e)}
                  value={initialValues.phone}
                  type="text"
                  placeholder="9719323466552+"
                  name="phone"
                  id=""
                />
              </div>
            </div>
            <div className="user-personal-info-form-body-row">
              <div
                className={
                  hasError && !initialValues.circle
                    ? 'user-info-input-field input-error'
                    : 'user-info-input-field'
                }
              >
                <select
                  onChange={(e) => this.props.handleChangeInput(e)}
                  name="circle"
                  id=""
                  value={initialValues.circle}
                >
                  <option value="دائرة بلدية أم القيوين">دائرة بلدية أم القيوين</option>
                  <option value="دائرة التخطيط العمراني">دائرة التخطيط العمراني</option>
                  <option value="دائرة التنمية الاقتصادية">
                    دائرة التنمية الاقتصادية
                  </option>
                  <option value="دائرة ام القيوين الذكية">دائرة ام القيوين الذكية</option>
                  <option value="دائرة المالية">دائرة المالية</option>
                  <option value="دائرة السياحة والآثار">دائرة السياحة والآثار</option>
                  <option value="المجلس التنفيذي">المجلس التنفيذي</option>
                  <option value="الديوان الأميري">الديوان الأميري</option>
                  <option value="آخرى">آخرى</option>
                </select>
                {/*<input
                  onChange={(e) => this.props.handleChangeInput(e)}
                  value={initialValues.address_city}
                  type="text"
                  placeholder="الدائرة "
                  name="address_city"
                  id=""
                />*/}
              </div>
            </div>
            <div className="user-personal-info-form-body-row">
              <div
                className={
                  (hasError && !initialValues.email) ||
                  (initialValues.email && !validator.isEmail(initialValues.email))
                    ? 'user-info-input-field input-error'
                    : 'user-info-input-field'
                }
              >
                <input
                  onChange={(e) => this.props.handleChangeInput(e)}
                  value={initialValues.email}
                  type="text"
                  placeholder="demo2@demo.ae"
                  name="email"
                  id=""
                  style={{ width: '100%' }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="user-personal-info-form-art">
          <div className="user-personal-info-form-art-container">
            {' '}
            {initialValues.file ? (
              <img
                style={{ width: '100%', borderRadius: '9%' }}
                src={
                  typeof initialValues.file === 'string'
                    ? `${host}${initialValues.file}`
                    : URL.createObjectURL(initialValues.file)
                }
                alt=""
              />
            ) : (
              <img
                style={{ width: '100%', borderRadius: '9%' }}
                src={DefaultImg}
                alt=""
              />
            )}
            <input
              onChange={(e) => this.props.handleChangeFiles(e, 'file')}
              type="file"
              ref="fileUploaderDoctor"
              name="file"
              hidden
              accept=".png, .jpg,.jpeg"
            />
            <div className="download-user-personal-info-btn">
              <button onClick={() => this.handleClick('fileUploaderDoctor')}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="48.961"
                  height="48.973"
                  viewBox="0 0 48.961 48.973"
                >
                  <g
                    id="Group_1489"
                    data-name="Group 1489"
                    transform="translate(-641.645 -477.721)"
                  >
                    <g
                      id="Group_1488"
                      data-name="Group 1488"
                      transform="translate(641.645 477.721)"
                    >
                      <path
                        id="Path_286"
                        data-name="Path 286"
                        d="M629.606,1946.226h0a24.549,24.549,0,0,1-24.494,24.468h0a24.543,24.543,0,0,1-24.467-24.468h0a24.559,24.559,0,0,1,24.467-24.505h0a24.565,24.565,0,0,1,24.494,24.505Z"
                        transform="translate(-580.645 -1921.721)"
                        fill="#ea0029"
                        fillRule="evenodd"
                      />
                    </g>
                    <path
                      id="Path_287"
                      data-name="Path 287"
                      d="M600.1,1950.291l-3.662-1.359,6,8.068,5.992-8.068-3.651,1.359-2.341-18.351-2.341,18.351Z"
                      transform="translate(63.685 -1442.263)"
                      fill="#fff"
                      fillRule="evenodd"
                    />
                  </g>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FormPersonnelInfo;
