import React, { Component } from 'react';
import { CircularProgress } from '@material-ui/core';
import { host } from '../../../../../utils/constants';
import { Link } from 'react-router-dom';

class List extends Component {
  render() {
    const { isLoadingTrainings, trainings, type } = this.props;
    return (
      <>
        <div className="dashboard-row">
          <Link to="/admin/content/add">
            <button className="btn btn-red">
              <span>+</span>
              <span>إضافة دورة </span>
            </button>
          </Link>
          <div className="header-search-box">
            <div className="header-search-box-content">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19.323"
                height="19.32"
                viewBox="0 0 19.323 19.32"
              >
                <g
                  id="Group_1076"
                  data-name="Group 1076"
                  transform="translate(-434.555 -185.983)"
                >
                  <g id="Group_1075" data-name="Group 1075">
                    <g id="Group_1074" data-name="Group 1074">
                      <g id="Group_1072" data-name="Group 1072">
                        <path
                          id="Path_802"
                          data-name="Path 802"
                          d="M442.343,201.556a7.789,7.789,0,1,1,5.509-13.3h0a7.787,7.787,0,0,1-5.509,13.3Zm0-12.543a4.756,4.756,0,1,0,3.365,1.391A4.747,4.747,0,0,0,442.343,189.013Z"
                          fill="#ea0029"
                        ></path>
                      </g>
                      <g id="Group_1073" data-name="Group 1073">
                        <rect
                          id="Rectangle_128"
                          data-name="Rectangle 128"
                          width="3.032"
                          height="8.521"
                          transform="translate(445.708 199.277) rotate(-44.994)"
                          fill="#ea0029"
                        ></rect>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
              <div className="search-input-container">
                <input type="text" placeholder="إكتب للبحث في الدورات" />
              </div>
            </div>
          </div>
        </div>
        <div className="dashboard-row">
          {isLoadingTrainings ? (
            <CircularProgress
              style={{
                textAlign: 'center',
                marginTop: '14%',
                width: '40px',
                color: '#d85555',
                marginRight: '50%',
              }}
            />
          ) : (
            <div className="cards-container">
              <div className="cards-wrapper">
                <div className="cardes-wrapper-content">
                  {trainings &&
                    trainings.data &&
                    trainings.data.length > 0 &&
                    trainings.data
                      .sort((a, b) =>
                        a.sections.length < b.sections.length
                          ? 1
                          : b.sections.length < a.sections.length
                          ? -1
                          : 0,
                      )
                      .map((training) => {
                        return (
                          <div
                            onClick={() => {
                              this.props.handleChangeAddCourse(false);
                              this.props.handleClickChangeTrainings(training.id);
                              this.props.handleClick(5, training.id);
                            }}
                            style={{ cursor: 'pointer' }}
                            className="training-program-card"
                          >
                            <div className="training-program-card-content">
                              <div className="training-program-card-miniature">
                                <img src={`${host}${training.picture}`} alt="" />
                              </div>
                            </div>
                          </div>
                          /* <div
                            
                            className="cards-wrapper-elem"
                          >
                            <div className="card-wrapper-elem-content">
                              <div className="cards-wrapper-img">
                                
                              </div>
                            </div>
                          </div>*/
                        );
                      })}
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default List;
