import React, { Component } from 'react';
import UserPng from '../../../assets/img/Path 126.png';
import actions from '../store/actions';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import ContactProfile from '../components/contact-profile';
import Headers from '../../../layouts/headers';
const mapStateToProps = (state) => ({
  isLoadingMember: state.MemberReducer.isLoadingMember,
  member: state.MemberReducer.member,
  isAuthorized: state.MemberReducer.isAuthorized,
});

const mapDispatchToProps = (dispatch) => ({
  getMember: (id) => dispatch(actions.getMember(id)),
});
class MemberProfil extends Component {
  constructor(props) {
    super(props);
    props.getMember(props.match.params.id);
  }
  render() {
    const { member, isAuthorized } = this.props;
    if (!member) {
      return (
        <div className="bs-main-container  e-lib-body">
          <div className="bs-main-content">
            <div className="profil-container">
              <CircularProgress
                style={{
                  textAlign: 'center',
                  marginTop: '9%',
                  marginBottom: '18%',
                  width: '40px',
                  color: '#d85555',
                  marginRight: '47%',
                }}
              />
            </div>
          </div>
        </div>
      );
    }
    return (
      <section className="body-section">
        <Headers isAuthorized={isAuthorized} />
        <div className="bs-main-container  e-lib-body">
          <div className="bs-main-content">
            <div className="profil-container">
              <div className="profil-content">
                <div className="profil-column">
                  <div className="profil-column-elem">
                    <div className="profil-column-elem-content">
                      <div className="profil-img-placeholder-container">
                        <div className="profil-img-placeholder">
                          <img src={UserPng} alt="" />
                        </div>
                      </div>
                      <div className="profil-user-info-text-container">
                        <div className="profil-user-info-text-content">
                          <span>{member.name}</span>
                          <span>نوع العضوية : {member.certified_member_ship.name}</span>
                          <span>رقم العضوية : {member.certified_member_ship.code}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="profil-column-elem">
                    <div className="user-profil-account-link-container">
                      <div className="user-profil-account-link-content">
                        <div className="user-profil-account-link-title">
                          <span>رابط مختصر</span>
                        </div>
                        <div className="user-profil-account-link-body">
                          <span>http://www.uaqacademy</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="profil-column-elem">
                    <div className="user-profil-cv-container">
                      <div className="user-profil-cv-content">
                        <div className="user-profil-cv-content-title">
                          <span>السيرة الذاتية</span>
                        </div>
                        <div className="user-profil-cv-content-body">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="70.713"
                            height="72.116"
                            viewBox="0 0 70.713 72.116"
                          >
                            <g
                              id="Group_2182"
                              data-name="Group 2182"
                              transform="translate(-1390.025 -1182)"
                            >
                              <g
                                id="Group_1083"
                                data-name="Group 1083"
                                transform="translate(1390.025 1182)"
                              >
                                <path
                                  id="Path_807"
                                  data-name="Path 807"
                                  d="M1876.695,357.29h-61.783a4.463,4.463,0,0,1-4.465-4.465v-62.8a4.806,4.806,0,0,1,4.465-4.848h37.654a4.459,4.459,0,0,1,3.184,1.333l24.127,24.513a4.478,4.478,0,0,1,1.283,3.13v38.676A4.462,4.462,0,0,1,1876.695,357.29Zm-57.318-8.93h52.853V315.982L1850.7,294.1h-31.323Z"
                                  transform="translate(-1810.447 -285.174)"
                                  fill="#ea0029"
                                />
                              </g>
                              <g
                                id="Group_1084"
                                data-name="Group 1084"
                                transform="translate(1427.681 1182.388)"
                              >
                                <path
                                  id="Path_808"
                                  data-name="Path 808"
                                  d="M1858.5,318.429h-24.129a4.463,4.463,0,0,1-4.463-4.467V289.825a4.465,4.465,0,0,1,2.755-4.124,4.519,4.519,0,0,1,4.865.969l24.129,24.137a4.465,4.465,0,0,1-3.157,7.622ZM1838.84,309.5h8.887l-8.887-8.893Z"
                                  transform="translate(-1829.912 -285.374)"
                                  fill="#ea0029"
                                />
                              </g>
                            </g>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="profil-column-elem">
                    <div className="user-profil-account-link-container">
                      <div className="user-profil-account-link-content">
                        <div className="user-profil-account-link-title">
                          <span>رابط مختصر</span>
                        </div>
                        <div className="user-profil-account-link-body-error">
                          <span>تنويه ! الحساب غير مدخل</span>
                          <div className="close-user-profil-account-link-body-error">
                            <span>x</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="profil-column">
                  <div className="profil-column-elem">
                    <div className="profil-column-elem-content">
                      <div className="profil-column-elem-title">
                        <span>جديد المنشورات</span>
                      </div>
                      <div className="user-profil-new-pubs-container">
                        <div className="user-profil-new-pubs-content">
                          <div className="user-profil-new-pub">
                            <div className="user-profil-new-pub-body">
                              <span>تنويه ! لا يوجد منشورات حالية ليتم عرضها </span>
                              <div className="close-user-profil-account-link-body-error">
                                <span>x</span>
                              </div>
                            </div>
                            <div className="user-profil-new-pub-close-btn"></div>
                          </div>
                          <div className="user-profil-new-pub"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="profil-column-elem">
                    <div className="profil-column-elem-content">
                      <div className="profil-column-elem-title">
                        <span>الحقائب التدريبية المعتمدة</span>
                      </div>
                      <div className="user-profil-courses-certif">
                        <div className="user-profil-courses-certif-content">
                          <div className="crs-certif">
                            <div className="crs-certif-name">
                              <span>دورة الذكاء الاصطناعي </span>
                            </div>
                            <div className="crs-certif-info">
                              <span>شهادة الإعتماد</span>
                              <div className="vert-sepet"></div>
                              <span className="course-certif-ID">CT01983CRS04011</span>
                            </div>
                          </div>
                          <div className="crs-certif">
                            <div className="crs-certif-name">
                              <span>فن التفاوض الناجح</span>
                            </div>
                            <div className="crs-certif-info">
                              <span>شهادة الإعتماد</span>
                              <div className="vert-sepet"></div>
                              <span className="course-certif-ID">CT01983CRS04011</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="profil-column-elem">
                    <div className="profil-column-elem-content">
                      <div className="profil-column-elem-title">
                        <span>المؤهلات العلمية والتدريبية</span>
                      </div>
                      <div className="user-profil-scientific-qualifications-container">
                        <div className="user-profil-scientific-qualifications-content">
                          <div className="user-profil-scientific-qualifications-title">
                            <span>المؤهلات العلمية</span>
                          </div>
                          <div className="user-profil-scientific-qualifications-body">
                            <ol>
                              <li>
                                {' '}
                                بكالوريوس [ التخصص : طفولة مبكره ، الكلية : التربية ]
                              </li>
                              <li>
                                {' '}
                                بكالوريوس [ التخصص : طفولة مبكره ، الكلية : التربية ]
                              </li>
                              <li>
                                {' '}
                                بكالوريوس [ التخصص : طفولة مبكره ، الكلية : التربية ]
                              </li>
                            </ol>
                          </div>
                        </div>
                      </div>
                      <div className="user-profil-Academic-qualifications-container">
                        <div className="user-profil-Academic-qualifications-content">
                          <div className="user-profil-Academic-qualifications-title">
                            <span>المؤهلات التدريبية </span>
                          </div>
                          <div className="user-profil-Academic-qualifications-body">
                            <ol>
                              <li>
                                {' '}
                                بكالوريوس [ التخصص : طفولة مبكره ، الكلية : التربية ]
                              </li>
                              <li>
                                {' '}
                                بكالوريوس [ التخصص : طفولة مبكره ، الكلية : التربية ]
                              </li>
                              <li>
                                {' '}
                                بكالوريوس [ التخصص : طفولة مبكره ، الكلية : التربية ]
                              </li>
                            </ol>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="profil-column-elem">
                    <div className="profil-column-elem-content">
                      <div className="profil-column-elem-title">
                        <span>المؤهلات العلمية والتدريبية</span>
                      </div>
                      <ContactProfile />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MemberProfil));
