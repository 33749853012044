import React, { Component } from 'react';
import { host, itemCourses } from '../../../../../utils/constants';
import Modal from 'react-modal';
import { CircularProgress } from '@material-ui/core';
import { getTimeFromMins } from '../../../../../utils/helpers';

const customStyles = {
  content: {
    width: '50%',
    height: '50%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    padding: '20px',
    overflow: 'hidden',
    borderRadius: '15px',
  },
};

class Details extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addCourse: props.addCourse || false,
      isOpenModalDelete: false,
      isOpenModalTraining: false,
      isOpenModal: false,
      hasError: false,
      section_item_id: '',
      section_item_name: '',
    };
    this.isOpenModal = this.isOpenModal.bind(this);
    this.isOpenModalDelete = this.isOpenModalDelete.bind(this);
    this.isOpenModalTraining = this.isOpenModalTraining.bind(this);
    this.submitForm = this.submitForm.bind(this);
  } //submitSectionItem
  submitForm(initialValues) {
    if (!initialValues.section_item_name) {
      this.setState({
        ...initialValues,
        hasError: true,
      });
    } else {
      this.setState({
        ...initialValues,
        hasError: false,
      });
      this.props.submitSectionItem(initialValues);
    }
  }
  isOpenModal(section_id, section_item_id) {
    const { isOpenModal } = this.state;
    if (!isOpenModal) {
      const dataSections = [...this.props.training.sections];
      var commentIndexSections = dataSections.findIndex(function (c) {
        return c.id === section_id;
      });
      //const dataSectionItem[commentIndexSections] = [...dataSections.section_items];
      var commentIndexSectionItems = dataSections[
        commentIndexSections
      ].section_items.findIndex(function (c) {
        return c.id === section_item_id;
      });
      let section_items =
        dataSections[commentIndexSections].section_items[commentIndexSectionItems];
      this.setState({
        section_item_id: section_items.id,
        section_item_name: section_items.doc_name,
      });
    }

    this.setState({
      isOpenModal: !isOpenModal,
    });
  }
  isOpenModalDelete(id) {
    const { isOpenModalDelete } = this.state;
    this.setState({
      isOpenModalDelete: !isOpenModalDelete,
      section_item_id: id,
    });
  }
  isOpenModalTraining(training) {
    const { isOpenModalTraining } = this.state;
    if (!isOpenModalTraining)
      this.setState({
        training_name: training.name,
      });
    this.setState({
      isOpenModalTraining: !isOpenModalTraining,
    });
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { updateSectionItem } = this.props;
    if (updateSectionItem !== prevProps.updateSectionItem && updateSectionItem.id) {
      this.isOpenModal();
      this.setState({
        section_item_id: null,
        section_item_name: '',
      });
    }
  }

  render() {
    const { training, addCourse } = this.props;
    const {
      section_item_name,
      isOpenModalDelete,
      isOpenModal,
      section_item_id,
      isOpenModalTraining,
      hasError,
      isLoadingTraining,
      training_name,
    } = this.state;
    let i = -2;
    if (isLoadingTraining) {
      return (
        <CircularProgress
          style={{
            textAlign: 'center',
            marginTop: '14%',
            width: '40px',
            color: '#d85555',
            marginRight: '50%',
          }}
        />
      );
    }
    return (
      <div className="admin-dshb-user-container">
        <Modal
          isOpen={isOpenModal}
          onRequestClose={this.isOpenModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div
            style={{
              justifyContent: 'flex-end',
            }}
            className="modal-header"
          >
            <button
              onClick={() => this.isOpenModal()}
              style={{ border: '0', backgroundColor: 'white' }}
              className=""
            >
              X
            </button>
          </div>
          <div className="modal-body">
            <div
              className="contact-us-form-row"
              style={{ alignItems: 'center', height: '140px' }}
            >
              <div className="input-field-label-container">
                <div className="input-field-label-content">
                  <label htmlFor="">الأسم </label>
                </div>
              </div>
              <div
                className={
                  !section_item_name
                    ? 'input-field-container input-error'
                    : 'input-field-container'
                }
              >
                <div className="input-field-content" style={{ height: '3rem' }}>
                  <input
                    name="section_item_name"
                    onChange={(e) =>
                      this.setState({
                        section_item_name: e.target.value,
                      })
                    }
                    value={section_item_name}
                    placeholder="الأسم"
                    type="text"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              justifyContent: 'flex-start',
            }}
            className="modal-footer"
          >
            <button
              onClick={() =>
                this.submitForm({
                  section_item_name,
                  section_item_id,
                  training_id: training.id,
                })
              }
              className="btn btn-green"
            >
              تسجيل
            </button>
          </div>
        </Modal>
        <Modal
          isOpen={isOpenModalTraining}
          onRequestClose={this.isOpenModalTraining}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div
            style={{
              justifyContent: 'flex-end',
            }}
            className="modal-header"
          >
            <button
              onClick={() => this.isOpenModalTraining()}
              style={{ border: '0', backgroundColor: 'white' }}
              className=""
            >
              X
            </button>
          </div>
          <div className="modal-body">
            <div
              className="contact-us-form-row"
              style={{ alignItems: 'center', height: '140px' }}
            >
              <div className="input-field-label-container">
                <div className="input-field-label-content">
                  <label htmlFor="">الأسم </label>
                </div>
              </div>
              <div
                className={
                  !training_name
                    ? 'input-field-container input-error'
                    : 'input-field-container'
                }
              >
                <div className="input-field-content" style={{ height: '3rem' }}>
                  <input
                    name="section_item_name"
                    onChange={(e) =>
                      this.setState({
                        training_name: e.target.value,
                      })
                    }
                    value={training_name}
                    placeholder="الأسم"
                    type="text"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              justifyContent: 'flex-start',
            }}
            className="modal-footer"
          >
            <button
              onClick={() => {
                this.props.submitUpdateTraining({
                  name: training_name,
                  id: training.id,
                });
                this.isOpenModalTraining();
              }}
              className="btn btn-green"
            >
              تسجيل
            </button>
          </div>
        </Modal>
        <Modal
          isOpen={isOpenModalDelete}
          onRequestClose={this.isOpenModalDelete}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div
            style={{
              justifyContent: 'flex-end',
            }}
            className="modal-header"
          >
            <button
              onClick={() => this.isOpenModalDelete()}
              style={{ border: '0', backgroundColor: 'white' }}
              className=""
            >
              X
            </button>
          </div>
          <div className="modal-body">
            <div className="contact-us-form-row" style={{ alignItems: 'center' }}>
              <span>أنت بصدد حذف</span>
            </div>
          </div>
          <div
            style={{
              justifyContent: 'flex-start',
            }}
            className="modal-footer"
          >
            <button
              onClick={() => {
                this.props.submitDeleteSectionItem({
                  section_item_id,
                  id: training.id,
                });
                this.isOpenModalDelete();
              }}
              className="btn btn-green"
            >
              حذف
            </button>
          </div>
        </Modal>
        <div
          style={{ justifyContent: 'center' }}
          className="dashboard-row admin-dshb-general-stats"
        >
          <div className="course-catalogue-container">
            <div className="course-catalogue-content">
              <div className="course-catalogue-elem">
                <div className="course-info-desc">
                  <div className="course-general-info">
                    <div className="course-general-info-content">
                      <div className="course-min-edit-btn">
                        <button onClick={() => this.isOpenModalTraining(training)}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="30"
                            height="30"
                            viewBox="0 0 30 30"
                          >
                            <g
                              id="Group_1717"
                              data-name="Group 1717"
                              transform="translate(-0.251 -0.078)"
                            >
                              <g
                                id="Group_1668"
                                data-name="Group 1668"
                                transform="matrix(0.899, 0.438, -0.438, 0.899, 16.806, 6.209)"
                              >
                                <g
                                  id="Union_1"
                                  data-name="Union 1"
                                  fill="none"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                >
                                  <path
                                    d="M0,14.484H4.359L2.179,18.1Zm0,0V0H4.359V14.484Z"
                                    stroke="none"
                                  />
                                  <path
                                    d="M 2.179219245910645 16.16572189331055 L 2.589521408081055 15.48404884338379 L 1.768965840339661 15.48404884338379 L 2.179219245910645 16.16572189331055 M 3.358598232269287 13.48404884338379 L 3.358598232269287 0.9999992251396179 L 0.999998152256012 0.9999992251396179 L 0.999998152256012 13.48404884338379 L 3.358598232269287 13.48404884338379 M 2.179168224334717 18.10493850708008 L -1.837539684856893e-06 14.48404884338379 L 4.358598232269287 14.48404884338379 L 2.179168224334717 18.10493850708008 Z M 4.358598232269287 14.48404884338379 L -1.837539684856893e-06 14.48404884338379 L -1.837539684856893e-06 -7.464599889317469e-07 L 4.358598232269287 -7.464599889317469e-07 L 4.358598232269287 14.48404884338379 Z"
                                    stroke="none"
                                    fill="#ea0029"
                                  />
                                </g>
                                <line
                                  id="Line_25"
                                  data-name="Line 25"
                                  x2="3.639"
                                  transform="translate(0.36 1.942)"
                                  fill="none"
                                  stroke="#ea0029"
                                  stroke-linecap="round"
                                  stroke-width="1"
                                />
                              </g>
                              <g
                                id="Ellipse_128"
                                data-name="Ellipse 128"
                                transform="translate(0.251 0.078)"
                                fill="none"
                                stroke="#ea0029"
                                stroke-width="2"
                              >
                                <circle cx="15" cy="15" r="15" stroke="none" />
                                <circle cx="15" cy="15" r="14" fill="none" />
                              </g>
                            </g>
                          </svg>
                        </button>
                      </div>
                      <div style={{ height: '50%' }} className="course-miniature">
                        <img src={`${host}${training.picture}`} alt="" />
                      </div>
                      <div className="course-info-regist-container">
                        <div className="course-info-regist-content">
                          <div className="course-info-ratings">
                            <div className="course-title">
                              <span>{training.name}</span>
                            </div>
                            {training.period ? (
                              <div className="course-length">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 22 22"
                                >
                                  <g
                                    id="Group_4"
                                    data-name="Group 4"
                                    transform="translate(-1703 -517.519)"
                                  >
                                    <g
                                      id="Ellipse_40"
                                      data-name="Ellipse 40"
                                      transform="translate(1703 517.519)"
                                      fill="none"
                                      stroke="#000"
                                      strokeWidth="2"
                                    >
                                      <circle cx="11" cy="11" r="11" stroke="none" />
                                      <circle cx="11" cy="11" r="10" fill="none" />
                                    </g>
                                    <path
                                      id="Path_6"
                                      data-name="Path 6"
                                      d="M-6296-7514.979v6.927h5.139"
                                      transform="translate(8009.593 8036.893)"
                                      fill="#fff"
                                      stroke="#000"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="1"
                                    />
                                  </g>
                                </svg>
                                <span>{getTimeFromMins(training.period)}</span>
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="course-description-container">
                    <div className="course-description-content">
                      <span>
                        {training.description
                          ? (training.description + '').split('N__L').map((i) => (
                              <span>
                                {i}
                                <br></br>
                              </span>
                            ))
                          : '...قيد الانجاز'}
                      </span>
                      {/*
                      <button className="btn btn-green">اقرا المزيد</button>*/}
                    </div>
                  </div>
                </div>
              </div>
              <div className="course-catalogue-elem">
                <div className="course-summary-container">
                  {training.type !== 3 ? (
                    <div className="course-summary-content">
                      <div className="course-summary-header">
                        <div className="course-summary-header-elem course-summary-header-title">
                          <span>المحتوى</span>
                        </div>
                        {training &&
                          training.sections &&
                          training.sections
                            .sort((a, b) =>
                              a.type > b.type ? 1 : b.type > a.type ? -1 : 0,
                            )
                            .map((item) => {
                              if (item.type === 0) {
                                return item.section_items
                                  .sort((a, b) =>
                                    a.order > b.order ? 1 : b.order > a.order ? -1 : 0,
                                  )
                                  .map((section_item) => {
                                    return (
                                      <div className="course-summary-header-elem">
                                        <div className="course-summary-elem-content">
                                          <i
                                            style={{
                                              marginRight: '2%',
                                              marginLeft: '2%',
                                              color: 'gray',
                                            }}
                                            className="far fa-file-alt"
                                          ></i>
                                          <span
                                            style={{
                                              width: '52rem',
                                            }}
                                          >
                                            {section_item.doc_name}
                                          </span>
                                          <div className="course-summary-header-elem-edit-sect">
                                            <button
                                              onClick={() =>
                                                this.isOpenModalDelete(section_item.id)
                                              }
                                              className="course-summary-elem-del-btn"
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="30"
                                                height="30"
                                                viewBox="0 0 30 30"
                                              >
                                                <g
                                                  id="Group_1670"
                                                  data-name="Group 1670"
                                                  transform="translate(0.096 -0.078)"
                                                >
                                                  <g
                                                    id="Ellipse_128"
                                                    data-name="Ellipse 128"
                                                    transform="translate(-0.096 0.078)"
                                                    fill="none"
                                                    stroke="#ea0029"
                                                    stroke-width="2"
                                                  >
                                                    <circle
                                                      cx="15"
                                                      cy="15"
                                                      r="15"
                                                      stroke="none"
                                                    />
                                                    <circle
                                                      cx="15"
                                                      cy="15"
                                                      r="14"
                                                      fill="none"
                                                    />
                                                  </g>
                                                  <g
                                                    id="Group_1671"
                                                    data-name="Group 1671"
                                                    transform="translate(9.756 9.756)"
                                                  >
                                                    <line
                                                      id="Line_26"
                                                      data-name="Line 26"
                                                      x2="10"
                                                      y2="10"
                                                      transform="translate(0.148 0.322)"
                                                      fill="none"
                                                      stroke="#ea0029"
                                                      stroke-width="1"
                                                    />
                                                    <line
                                                      id="Line_27"
                                                      data-name="Line 27"
                                                      x1="10"
                                                      y2="10"
                                                      transform="translate(0.148 0.322)"
                                                      fill="none"
                                                      stroke="#ea0029"
                                                      stroke-width="1"
                                                    />
                                                  </g>
                                                </g>
                                              </svg>
                                            </button>
                                            <button
                                              onClick={() =>
                                                this.isOpenModal(item.id, section_item.id)
                                              }
                                              className="course-summary-elem-edit-btn"
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="30"
                                                height="30"
                                                viewBox="0 0 30 30"
                                              >
                                                <g
                                                  id="Group_1669"
                                                  data-name="Group 1669"
                                                  transform="translate(-0.251 -0.078)"
                                                >
                                                  <g
                                                    id="Group_1668"
                                                    data-name="Group 1668"
                                                    transform="matrix(0.899, 0.438, -0.438, 0.899, 16.806, 6.209)"
                                                  >
                                                    <g
                                                      id="Union_1"
                                                      data-name="Union 1"
                                                      fill="none"
                                                      stroke-linecap="round"
                                                      stroke-linejoin="round"
                                                    >
                                                      <path
                                                        d="M0,14.484H4.359L2.179,18.1Zm0,0V0H4.359V14.484Z"
                                                        stroke="none"
                                                      />
                                                      <path
                                                        d="M 2.179219245910645 16.16572189331055 L 2.589521408081055 15.48404884338379 L 1.768965840339661 15.48404884338379 L 2.179219245910645 16.16572189331055 M 3.358598232269287 13.48404884338379 L 3.358598232269287 0.9999992251396179 L 0.999998152256012 0.9999992251396179 L 0.999998152256012 13.48404884338379 L 3.358598232269287 13.48404884338379 M 2.179168224334717 18.10493850708008 L -1.837539684856893e-06 14.48404884338379 L 4.358598232269287 14.48404884338379 L 2.179168224334717 18.10493850708008 Z M 4.358598232269287 14.48404884338379 L -1.837539684856893e-06 14.48404884338379 L -1.837539684856893e-06 -7.464599889317469e-07 L 4.358598232269287 -7.464599889317469e-07 L 4.358598232269287 14.48404884338379 Z"
                                                        stroke="none"
                                                        fill="#ea0029"
                                                      />
                                                    </g>
                                                    <line
                                                      id="Line_25"
                                                      data-name="Line 25"
                                                      x2="3.639"
                                                      transform="translate(0.36 1.942)"
                                                      fill="none"
                                                      stroke="#ea0029"
                                                      stroke-linecap="round"
                                                      stroke-width="1"
                                                    />
                                                  </g>
                                                  <g
                                                    id="Ellipse_128"
                                                    data-name="Ellipse 128"
                                                    transform="translate(0.251 0.078)"
                                                    fill="none"
                                                    stroke="#ea0029"
                                                    stroke-width="2"
                                                  >
                                                    <circle
                                                      cx="15"
                                                      cy="15"
                                                      r="15"
                                                      stroke="none"
                                                    />
                                                    <circle
                                                      cx="15"
                                                      cy="15"
                                                      r="14"
                                                      fill="none"
                                                    />
                                                  </g>
                                                </g>
                                              </svg>
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  });
                              }
                            })}
                      </div>
                      <div className="course-summary-body">
                        <div className="course-summary-body-content">
                          {training &&
                            training.sections &&
                            training.sections
                              .sort((a, b) =>
                                a.type > b.type ? 1 : b.type > a.type ? -1 : 0,
                              )
                              .map((item) => {
                                i++;
                                if (item.type !== 0) {
                                  return (
                                    <div className="course-summary-body-elem">
                                      <div className="course-summary-body-elem-title">
                                        <div className="course-summary-body-elem-title-content">
                                          <span>{itemCourses[i]}</span>
                                        </div>
                                      </div>
                                      <div className="course-summary-body-elem-body">
                                        <div className="course-summary-subsections-elem">
                                          <span>{item.name}</span>
                                        </div>
                                        {item.section_items &&
                                          item.section_items.map((section_item) => {
                                            if (
                                              section_item.doc_name !== 'تأكيد استيعاب' &&
                                              item.name !== 'الشهادة' &&
                                              item.name !== 'ملخص الدورة التدريبية'
                                            )
                                              return (
                                                <>
                                                  {section_item.type === 'video' ? (
                                                    <div className="course-summary-header-elem">
                                                      <div className="course-summary-elem-content">
                                                        <i
                                                          style={{
                                                            marginRight: '2%',
                                                            color: 'gray',
                                                            marginLeft: '2%',
                                                          }}
                                                          className={
                                                            section_item.type === 'video'
                                                              ? 'fas fa-play'
                                                              : 'far fa-file-pdf'
                                                          }
                                                        ></i>
                                                        <span
                                                          style={{
                                                            width: '46rem',
                                                            color: 'black',
                                                          }}
                                                        >
                                                          {section_item.doc_name}
                                                        </span>
                                                        <div className="course-summary-header-elem-edit-sect">
                                                          <button
                                                            onClick={() =>
                                                              this.isOpenModalDelete()
                                                            }
                                                            className="course-summary-elem-del-btn"
                                                          >
                                                            <svg
                                                              xmlns="http://www.w3.org/2000/svg"
                                                              width="30"
                                                              height="30"
                                                              viewBox="0 0 30 30"
                                                            >
                                                              <g
                                                                id="Group_1670"
                                                                data-name="Group 1670"
                                                                transform="translate(0.096 -0.078)"
                                                              >
                                                                <g
                                                                  id="Ellipse_128"
                                                                  data-name="Ellipse 128"
                                                                  transform="translate(-0.096 0.078)"
                                                                  fill="none"
                                                                  stroke="#ea0029"
                                                                  stroke-width="2"
                                                                >
                                                                  <circle
                                                                    cx="15"
                                                                    cy="15"
                                                                    r="15"
                                                                    stroke="none"
                                                                  />
                                                                  <circle
                                                                    cx="15"
                                                                    cy="15"
                                                                    r="14"
                                                                    fill="none"
                                                                  />
                                                                </g>
                                                                <g
                                                                  id="Group_1671"
                                                                  data-name="Group 1671"
                                                                  transform="translate(9.756 9.756)"
                                                                >
                                                                  <line
                                                                    id="Line_26"
                                                                    data-name="Line 26"
                                                                    x2="10"
                                                                    y2="10"
                                                                    transform="translate(0.148 0.322)"
                                                                    fill="none"
                                                                    stroke="#ea0029"
                                                                    stroke-width="1"
                                                                  />
                                                                  <line
                                                                    id="Line_27"
                                                                    data-name="Line 27"
                                                                    x1="10"
                                                                    y2="10"
                                                                    transform="translate(0.148 0.322)"
                                                                    fill="none"
                                                                    stroke="#ea0029"
                                                                    stroke-width="1"
                                                                  />
                                                                </g>
                                                              </g>
                                                            </svg>
                                                          </button>
                                                          <button
                                                            onClick={() =>
                                                              this.isOpenModal(
                                                                item.id,
                                                                section_item.id,
                                                              )
                                                            }
                                                            className="course-summary-elem-edit-btn"
                                                          >
                                                            <svg
                                                              xmlns="http://www.w3.org/2000/svg"
                                                              width="30"
                                                              height="30"
                                                              viewBox="0 0 30 30"
                                                            >
                                                              <g
                                                                id="Group_1669"
                                                                data-name="Group 1669"
                                                                transform="translate(-0.251 -0.078)"
                                                              >
                                                                <g
                                                                  id="Group_1668"
                                                                  data-name="Group 1668"
                                                                  transform="matrix(0.899, 0.438, -0.438, 0.899, 16.806, 6.209)"
                                                                >
                                                                  <g
                                                                    id="Union_1"
                                                                    data-name="Union 1"
                                                                    fill="none"
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                  >
                                                                    <path
                                                                      d="M0,14.484H4.359L2.179,18.1Zm0,0V0H4.359V14.484Z"
                                                                      stroke="none"
                                                                    />
                                                                    <path
                                                                      d="M 2.179219245910645 16.16572189331055 L 2.589521408081055 15.48404884338379 L 1.768965840339661 15.48404884338379 L 2.179219245910645 16.16572189331055 M 3.358598232269287 13.48404884338379 L 3.358598232269287 0.9999992251396179 L 0.999998152256012 0.9999992251396179 L 0.999998152256012 13.48404884338379 L 3.358598232269287 13.48404884338379 M 2.179168224334717 18.10493850708008 L -1.837539684856893e-06 14.48404884338379 L 4.358598232269287 14.48404884338379 L 2.179168224334717 18.10493850708008 Z M 4.358598232269287 14.48404884338379 L -1.837539684856893e-06 14.48404884338379 L -1.837539684856893e-06 -7.464599889317469e-07 L 4.358598232269287 -7.464599889317469e-07 L 4.358598232269287 14.48404884338379 Z"
                                                                      stroke="none"
                                                                      fill="#ea0029"
                                                                    />
                                                                  </g>
                                                                  <line
                                                                    id="Line_25"
                                                                    data-name="Line 25"
                                                                    x2="3.639"
                                                                    transform="translate(0.36 1.942)"
                                                                    fill="none"
                                                                    stroke="#ea0029"
                                                                    stroke-linecap="round"
                                                                    stroke-width="1"
                                                                  />
                                                                </g>
                                                                <g
                                                                  id="Ellipse_128"
                                                                  data-name="Ellipse 128"
                                                                  transform="translate(0.251 0.078)"
                                                                  fill="none"
                                                                  stroke="#ea0029"
                                                                  stroke-width="2"
                                                                >
                                                                  <circle
                                                                    cx="15"
                                                                    cy="15"
                                                                    r="15"
                                                                    stroke="none"
                                                                  />
                                                                  <circle
                                                                    cx="15"
                                                                    cy="15"
                                                                    r="14"
                                                                    fill="none"
                                                                  />
                                                                </g>
                                                              </g>
                                                            </svg>
                                                          </button>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  ) : (
                                                    <div className="course-summary-header-elem">
                                                      <div className="course-summary-elem-content">
                                                        <i
                                                          style={{
                                                            marginRight: '2%',
                                                            color: 'gray',
                                                            marginLeft: '2%',
                                                          }}
                                                          className={
                                                            section_item.type === 'video'
                                                              ? 'fas fa-play'
                                                              : 'far fa-file-pdf'
                                                          }
                                                        ></i>
                                                        <span
                                                          style={{
                                                            width: '46rem',
                                                            color: 'black',
                                                          }}
                                                        >
                                                          {section_item.doc_name}
                                                        </span>
                                                        <div className="course-summary-header-elem-edit-sect">
                                                          <button
                                                            onClick={() =>
                                                              this.isOpenModalDelete()
                                                            }
                                                            className="course-summary-elem-del-btn"
                                                          >
                                                            <svg
                                                              xmlns="http://www.w3.org/2000/svg"
                                                              width="30"
                                                              height="30"
                                                              viewBox="0 0 30 30"
                                                            >
                                                              <g
                                                                id="Group_1670"
                                                                data-name="Group 1670"
                                                                transform="translate(0.096 -0.078)"
                                                              >
                                                                <g
                                                                  id="Ellipse_128"
                                                                  data-name="Ellipse 128"
                                                                  transform="translate(-0.096 0.078)"
                                                                  fill="none"
                                                                  stroke="#ea0029"
                                                                  stroke-width="2"
                                                                >
                                                                  <circle
                                                                    cx="15"
                                                                    cy="15"
                                                                    r="15"
                                                                    stroke="none"
                                                                  />
                                                                  <circle
                                                                    cx="15"
                                                                    cy="15"
                                                                    r="14"
                                                                    fill="none"
                                                                  />
                                                                </g>
                                                                <g
                                                                  id="Group_1671"
                                                                  data-name="Group 1671"
                                                                  transform="translate(9.756 9.756)"
                                                                >
                                                                  <line
                                                                    id="Line_26"
                                                                    data-name="Line 26"
                                                                    x2="10"
                                                                    y2="10"
                                                                    transform="translate(0.148 0.322)"
                                                                    fill="none"
                                                                    stroke="#ea0029"
                                                                    stroke-width="1"
                                                                  />
                                                                  <line
                                                                    id="Line_27"
                                                                    data-name="Line 27"
                                                                    x1="10"
                                                                    y2="10"
                                                                    transform="translate(0.148 0.322)"
                                                                    fill="none"
                                                                    stroke="#ea0029"
                                                                    stroke-width="1"
                                                                  />
                                                                </g>
                                                              </g>
                                                            </svg>
                                                          </button>
                                                          <button
                                                            onClick={() =>
                                                              this.isOpenModal(
                                                                item.id,
                                                                section_item.id,
                                                              )
                                                            }
                                                            className="course-summary-elem-edit-btn"
                                                          >
                                                            <svg
                                                              xmlns="http://www.w3.org/2000/svg"
                                                              width="30"
                                                              height="30"
                                                              viewBox="0 0 30 30"
                                                            >
                                                              <g
                                                                id="Group_1669"
                                                                data-name="Group 1669"
                                                                transform="translate(-0.251 -0.078)"
                                                              >
                                                                <g
                                                                  id="Group_1668"
                                                                  data-name="Group 1668"
                                                                  transform="matrix(0.899, 0.438, -0.438, 0.899, 16.806, 6.209)"
                                                                >
                                                                  <g
                                                                    id="Union_1"
                                                                    data-name="Union 1"
                                                                    fill="none"
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                  >
                                                                    <path
                                                                      d="M0,14.484H4.359L2.179,18.1Zm0,0V0H4.359V14.484Z"
                                                                      stroke="none"
                                                                    />
                                                                    <path
                                                                      d="M 2.179219245910645 16.16572189331055 L 2.589521408081055 15.48404884338379 L 1.768965840339661 15.48404884338379 L 2.179219245910645 16.16572189331055 M 3.358598232269287 13.48404884338379 L 3.358598232269287 0.9999992251396179 L 0.999998152256012 0.9999992251396179 L 0.999998152256012 13.48404884338379 L 3.358598232269287 13.48404884338379 M 2.179168224334717 18.10493850708008 L -1.837539684856893e-06 14.48404884338379 L 4.358598232269287 14.48404884338379 L 2.179168224334717 18.10493850708008 Z M 4.358598232269287 14.48404884338379 L -1.837539684856893e-06 14.48404884338379 L -1.837539684856893e-06 -7.464599889317469e-07 L 4.358598232269287 -7.464599889317469e-07 L 4.358598232269287 14.48404884338379 Z"
                                                                      stroke="none"
                                                                      fill="#ea0029"
                                                                    />
                                                                  </g>
                                                                  <line
                                                                    id="Line_25"
                                                                    data-name="Line 25"
                                                                    x2="3.639"
                                                                    transform="translate(0.36 1.942)"
                                                                    fill="none"
                                                                    stroke="#ea0029"
                                                                    stroke-linecap="round"
                                                                    stroke-width="1"
                                                                  />
                                                                </g>
                                                                <g
                                                                  id="Ellipse_128"
                                                                  data-name="Ellipse 128"
                                                                  transform="translate(0.251 0.078)"
                                                                  fill="none"
                                                                  stroke="#ea0029"
                                                                  stroke-width="2"
                                                                >
                                                                  <circle
                                                                    cx="15"
                                                                    cy="15"
                                                                    r="15"
                                                                    stroke="none"
                                                                  />
                                                                  <circle
                                                                    cx="15"
                                                                    cy="15"
                                                                    r="14"
                                                                    fill="none"
                                                                  />
                                                                </g>
                                                              </g>
                                                            </svg>
                                                          </button>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )}
                                                </>
                                              );
                                            if (
                                              item.name !== 'الشهادة' &&
                                              item.name !== 'ملخص الدورة التدريبية'
                                            )
                                              return (
                                                <div className="course-summary-subsections-elem">
                                                  <span
                                                    style={{
                                                      width: '52rem',
                                                    }}
                                                  >
                                                    {section_item.doc_name}
                                                  </span>
                                                  <div className="course-summary-header-elem-edit-sect">
                                                    <button
                                                      onClick={() =>
                                                        this.isOpenModalDelete()
                                                      }
                                                      className="course-summary-elem-del-btn"
                                                    >
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="30"
                                                        height="30"
                                                        viewBox="0 0 30 30"
                                                      >
                                                        <g
                                                          id="Group_1670"
                                                          data-name="Group 1670"
                                                          transform="translate(0.096 -0.078)"
                                                        >
                                                          <g
                                                            id="Ellipse_128"
                                                            data-name="Ellipse 128"
                                                            transform="translate(-0.096 0.078)"
                                                            fill="none"
                                                            stroke="#ea0029"
                                                            stroke-width="2"
                                                          >
                                                            <circle
                                                              cx="15"
                                                              cy="15"
                                                              r="15"
                                                              stroke="none"
                                                            />
                                                            <circle
                                                              cx="15"
                                                              cy="15"
                                                              r="14"
                                                              fill="none"
                                                            />
                                                          </g>
                                                          <g
                                                            id="Group_1671"
                                                            data-name="Group 1671"
                                                            transform="translate(9.756 9.756)"
                                                          >
                                                            <line
                                                              id="Line_26"
                                                              data-name="Line 26"
                                                              x2="10"
                                                              y2="10"
                                                              transform="translate(0.148 0.322)"
                                                              fill="none"
                                                              stroke="#ea0029"
                                                              stroke-width="1"
                                                            />
                                                            <line
                                                              id="Line_27"
                                                              data-name="Line 27"
                                                              x1="10"
                                                              y2="10"
                                                              transform="translate(0.148 0.322)"
                                                              fill="none"
                                                              stroke="#ea0029"
                                                              stroke-width="1"
                                                            />
                                                          </g>
                                                        </g>
                                                      </svg>
                                                    </button>
                                                    <button
                                                      onClick={() =>
                                                        this.isOpenModal(
                                                          item.id,
                                                          section_item.id,
                                                        )
                                                      }
                                                      className="course-summary-elem-edit-btn"
                                                    >
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="30"
                                                        height="30"
                                                        viewBox="0 0 30 30"
                                                      >
                                                        <g
                                                          id="Group_1669"
                                                          data-name="Group 1669"
                                                          transform="translate(-0.251 -0.078)"
                                                        >
                                                          <g
                                                            id="Group_1668"
                                                            data-name="Group 1668"
                                                            transform="matrix(0.899, 0.438, -0.438, 0.899, 16.806, 6.209)"
                                                          >
                                                            <g
                                                              id="Union_1"
                                                              data-name="Union 1"
                                                              fill="none"
                                                              stroke-linecap="round"
                                                              stroke-linejoin="round"
                                                            >
                                                              <path
                                                                d="M0,14.484H4.359L2.179,18.1Zm0,0V0H4.359V14.484Z"
                                                                stroke="none"
                                                              />
                                                              <path
                                                                d="M 2.179219245910645 16.16572189331055 L 2.589521408081055 15.48404884338379 L 1.768965840339661 15.48404884338379 L 2.179219245910645 16.16572189331055 M 3.358598232269287 13.48404884338379 L 3.358598232269287 0.9999992251396179 L 0.999998152256012 0.9999992251396179 L 0.999998152256012 13.48404884338379 L 3.358598232269287 13.48404884338379 M 2.179168224334717 18.10493850708008 L -1.837539684856893e-06 14.48404884338379 L 4.358598232269287 14.48404884338379 L 2.179168224334717 18.10493850708008 Z M 4.358598232269287 14.48404884338379 L -1.837539684856893e-06 14.48404884338379 L -1.837539684856893e-06 -7.464599889317469e-07 L 4.358598232269287 -7.464599889317469e-07 L 4.358598232269287 14.48404884338379 Z"
                                                                stroke="none"
                                                                fill="#ea0029"
                                                              />
                                                            </g>
                                                            <line
                                                              id="Line_25"
                                                              data-name="Line 25"
                                                              x2="3.639"
                                                              transform="translate(0.36 1.942)"
                                                              fill="none"
                                                              stroke="#ea0029"
                                                              stroke-linecap="round"
                                                              stroke-width="1"
                                                            />
                                                          </g>
                                                          <g
                                                            id="Ellipse_128"
                                                            data-name="Ellipse 128"
                                                            transform="translate(0.251 0.078)"
                                                            fill="none"
                                                            stroke="#ea0029"
                                                            stroke-width="2"
                                                          >
                                                            <circle
                                                              cx="15"
                                                              cy="15"
                                                              r="15"
                                                              stroke="none"
                                                            />
                                                            <circle
                                                              cx="15"
                                                              cy="15"
                                                              r="14"
                                                              fill="none"
                                                            />
                                                          </g>
                                                        </g>
                                                      </svg>
                                                    </button>
                                                  </div>
                                                </div>
                                              );
                                            else
                                              return (
                                                <div className="course-summary-header-elem">
                                                  <div className="course-summary-elem-content">
                                                    <span
                                                      style={{
                                                        width: '46rem',
                                                        color: 'black',
                                                      }}
                                                    >
                                                      {section_item.doc_name}
                                                    </span>
                                                    <div className="course-summary-header-elem-edit-sect">
                                                      <button
                                                        onClick={() =>
                                                          this.isOpenModalDelete()
                                                        }
                                                        className="course-summary-elem-del-btn"
                                                      >
                                                        <svg
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          width="30"
                                                          height="30"
                                                          viewBox="0 0 30 30"
                                                        >
                                                          <g
                                                            id="Group_1670"
                                                            data-name="Group 1670"
                                                            transform="translate(0.096 -0.078)"
                                                          >
                                                            <g
                                                              id="Ellipse_128"
                                                              data-name="Ellipse 128"
                                                              transform="translate(-0.096 0.078)"
                                                              fill="none"
                                                              stroke="#ea0029"
                                                              stroke-width="2"
                                                            >
                                                              <circle
                                                                cx="15"
                                                                cy="15"
                                                                r="15"
                                                                stroke="none"
                                                              />
                                                              <circle
                                                                cx="15"
                                                                cy="15"
                                                                r="14"
                                                                fill="none"
                                                              />
                                                            </g>
                                                            <g
                                                              id="Group_1671"
                                                              data-name="Group 1671"
                                                              transform="translate(9.756 9.756)"
                                                            >
                                                              <line
                                                                id="Line_26"
                                                                data-name="Line 26"
                                                                x2="10"
                                                                y2="10"
                                                                transform="translate(0.148 0.322)"
                                                                fill="none"
                                                                stroke="#ea0029"
                                                                stroke-width="1"
                                                              />
                                                              <line
                                                                id="Line_27"
                                                                data-name="Line 27"
                                                                x1="10"
                                                                y2="10"
                                                                transform="translate(0.148 0.322)"
                                                                fill="none"
                                                                stroke="#ea0029"
                                                                stroke-width="1"
                                                              />
                                                            </g>
                                                          </g>
                                                        </svg>
                                                      </button>
                                                      <button
                                                        onClick={() =>
                                                          this.isOpenModal(
                                                            item.id,
                                                            section_item.id,
                                                          )
                                                        }
                                                        className="course-summary-elem-edit-btn"
                                                      >
                                                        <svg
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          width="30"
                                                          height="30"
                                                          viewBox="0 0 30 30"
                                                        >
                                                          <g
                                                            id="Group_1669"
                                                            data-name="Group 1669"
                                                            transform="translate(-0.251 -0.078)"
                                                          >
                                                            <g
                                                              id="Group_1668"
                                                              data-name="Group 1668"
                                                              transform="matrix(0.899, 0.438, -0.438, 0.899, 16.806, 6.209)"
                                                            >
                                                              <g
                                                                id="Union_1"
                                                                data-name="Union 1"
                                                                fill="none"
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                              >
                                                                <path
                                                                  d="M0,14.484H4.359L2.179,18.1Zm0,0V0H4.359V14.484Z"
                                                                  stroke="none"
                                                                />
                                                                <path
                                                                  d="M 2.179219245910645 16.16572189331055 L 2.589521408081055 15.48404884338379 L 1.768965840339661 15.48404884338379 L 2.179219245910645 16.16572189331055 M 3.358598232269287 13.48404884338379 L 3.358598232269287 0.9999992251396179 L 0.999998152256012 0.9999992251396179 L 0.999998152256012 13.48404884338379 L 3.358598232269287 13.48404884338379 M 2.179168224334717 18.10493850708008 L -1.837539684856893e-06 14.48404884338379 L 4.358598232269287 14.48404884338379 L 2.179168224334717 18.10493850708008 Z M 4.358598232269287 14.48404884338379 L -1.837539684856893e-06 14.48404884338379 L -1.837539684856893e-06 -7.464599889317469e-07 L 4.358598232269287 -7.464599889317469e-07 L 4.358598232269287 14.48404884338379 Z"
                                                                  stroke="none"
                                                                  fill="#ea0029"
                                                                />
                                                              </g>
                                                              <line
                                                                id="Line_25"
                                                                data-name="Line 25"
                                                                x2="3.639"
                                                                transform="translate(0.36 1.942)"
                                                                fill="none"
                                                                stroke="#ea0029"
                                                                stroke-linecap="round"
                                                                stroke-width="1"
                                                              />
                                                            </g>
                                                            <g
                                                              id="Ellipse_128"
                                                              data-name="Ellipse 128"
                                                              transform="translate(0.251 0.078)"
                                                              fill="none"
                                                              stroke="#ea0029"
                                                              stroke-width="2"
                                                            >
                                                              <circle
                                                                cx="15"
                                                                cy="15"
                                                                r="15"
                                                                stroke="none"
                                                              />
                                                              <circle
                                                                cx="15"
                                                                cy="15"
                                                                r="14"
                                                                fill="none"
                                                              />
                                                            </g>
                                                          </g>
                                                        </svg>
                                                      </button>
                                                    </div>
                                                  </div>
                                                </div>
                                              );
                                          })}
                                      </div>
                                    </div>
                                  );
                                }
                              })}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Details;
