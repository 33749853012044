import React, { Component } from 'react';

class ConditionMember extends Component {
  render() {
    return (
      <div className="membership-benefits-conditions-container">
        <div className="membership-benefits-conditions-content">
          <div className="membership-benefits-conditions-header">
            <span>طلب عضو معتمد</span>
          </div>
          <div className="membership-benefits-conditions-body">
            <ul>
              <li>الحصول على خصم 30% على جميع البرامج والدورات التدريبية</li>
              <li>إتاحة الفرصة للمشاركة في جميع أنشطة المركز والملتقيات السنوية</li>
              <li>مدة العضوية عام ميلادي تجدد في حال الرغبة في ذلك وسداد الرسوم</li>
              <li>يمنح العضو بطاقة عضوية كعضو معتمد من المركز</li>
              <li>
                شروط العضوية : متاحة للجميع – نسخة عن بطاقة الهوية – نسخة عن جواز السفر –
                صورة شخصية
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default ConditionMember;
