const initialAppState = {
  isLoadingMembers: false,
  members: [],
  isLoadingMember: false,
  member: null,
  newMember: null,
  isLoadingNewMember: false,
  params: {
    currentPage: 1,
    limit: 10,
    search: '',
  },
};
const MemberReducer = (state = initialAppState, action) => {
  switch (action.type) {
    case 'CREATE_NEW_MEMBER_REQUESTING':
      return {
        ...state,
        isLoadingNewMember: true,
      };
    case 'CREATE_NEW_MEMBER_SUCCESS':
      return {
        ...state,
        isLoadingMember: false,
        newMember: action.data,
      };
    case 'CREATE_NEW_MEMBER_FAILURE':
      return {
        ...state,
        isLoadingMember: false,
        newMember: null,
      };

    case 'GET_MEMBER_REQUESTING':
      return {
        ...state,
        isLoadingMember: true,
      };
    case 'GET_MEMBER_SUCCESS':
      return {
        ...state,
        isLoadingMember: false,
        member: action.data,
      };
    case 'GET_MEMBER_FAILURE':
      return {
        ...state,
        isLoadingMember: false,
        member: null,
      };

    case 'GET_ALL_MEMBERS_REQUESTING':
      return {
        ...state,
        isLoadingMembers: true,
      };
    case 'GET_ALL_MEMBERS_SUCCESS':
      return {
        ...state,
        isLoadingMembers: false,
        members: action.data,
      };
    case 'GET_ALL_MEMBERS_FAILURE':
      return {
        ...state,
        isLoadingMembers: false,
        members: [],
      };
    case 'PAGINATION_MEMBERS_PARAMS': {
      return {
        ...state,
        params: action.params,
      };
    }
    default: {
      return state;
    }
  }
};

export default MemberReducer;
