import React, { Component } from 'react';
import HeadersAuth from '../../../../layouts/auth/headers';
import SidebarAdmin from '../../layouts/sidebar';
import actions from '../../../../js/actions';
import actionsAdmin from '../../store/actions';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import { Link } from 'react-router-dom';
import List from './pages/list';
import Details from './pages/details';
import Members from './pages/member';
import Reports from './pages/reports';

const mapStateToProps = (state) => ({
  isLoadingTrainings: state.AppReducer.isLoadingTrainings,
  trainings: state.AppReducer.trainings,
  params: state.AppReducer.params,
  isAuthorized: state.AppReducer.isAuthorized,
  training: state.AppReducer.training,
  isLoadingTraining: state.AppReducer.isLoadingTraining,
  isLoadingUpdateSectionItem: state.AppReducer.isLoadingUpdateSectionItem,
  updateSectionItem: state.AppReducer.updateSectionItem,
  isLoadingAdvancementUserCourse:
    state.AdvancementUserCourseReducer.isLoadingAdvancementUserCourse,
  advancementUserCourse: state.AdvancementUserCourseReducer.advancementUserCourse,
  paramsAdvancementUserCourse: state.AdvancementUserCourseReducer.params,
  account: state.AppReducer.account,
  countCourse: state.AdvancementUserCourseReducer.countCourse,
  isLoadingCountCourse: state.AdvancementUserCourseReducer.isLoadingCountCourse,
  isLoadingCourseRating: state.AdvancementUserCourseReducer.isLoadingCourseRating,
  courseRating: state.AdvancementUserCourseReducer.courseRating,
  countUser: state.AdvancementUserCourseReducer.countUser,
  isLoadingCountUser: state.AdvancementUserCourseReducer.isLoadingCountUser,
  ratingUser: state.AdvancementUserCourseReducer.ratingUser,
  isLoadingRatingUser: state.AdvancementUserCourseReducer.isLoadingRatingUser,
});

const mapDispatchToProps = (dispatch) => ({
  changeSearchedString: (params, type) => {
    dispatch(actions.changeSearchedString(params));
    dispatch(actions.getAllTrainings(params, type));
  },
  submitSectionItem: async (values) => {
    await dispatch(actions.submitSectionItem(values));
    dispatch(actions.getTrainingById(values.training_id));
  },
  submitUpdateTraining: async (values) => {
    await dispatch(actions.submitUpdateTraining(values));
    dispatch(actions.getTrainingById(values.id));
  },
  submitDeleteSectionItem: async (values) => {
    await dispatch(actions.submitDeleteSectionItem(values.section_id));
    dispatch(actions.getTrainingById(values.id));
  },
  getTrainingById: (id) => dispatch(actions.getTrainingById(id)),
  getCountCourse: () => dispatch(actionsAdmin.getCountCourse()),
  getAllTrainings: (params, type) => dispatch(actions.getAllTrainings(params, type)),
  getCoursesRatings: () => dispatch(actionsAdmin.getCoursesRatings()),
  getCountUser: () => dispatch(actionsAdmin.getCountUser()),
  getRatingByUser: () => dispatch(actionsAdmin.getRatingByUser()),
  getListAdvancementUserCourse: (params) =>
    dispatch(actionsAdmin.getListAdvancementUserCourse(params)),
});

class TrainingsAdmin extends Component {
  constructor(props) {
    super(props);
    props.getAllTrainings(props.params, 2);
    //props.getCoursesRatings();
    props.getListAdvancementUserCourse(props.paramsAdvancementUserCourse);
    this.state = {
      is_active_item: 0,
      addCourse: false,
    };
    this.makeHttpRequestWithPage = this.makeHttpRequestWithPage.bind(this);
    this.handleClickChangeTrainings = this.handleClickChangeTrainings.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleChangeAddCourse = this.handleChangeAddCourse.bind(this);
  }
  makeHttpRequestWithPage = (event, page) => {
    this.props.changeSearchedString(
      {
        ...this.props.params,
        currentPage: page ? page : this.props.params.currentPage - 1,
        limit: this.props.params.limit,
      },
      2,
    );
  };
  handleChangeAddCourse(addCourse) {
    this.setState({
      addCourse: addCourse,
    });
  }
  handleClickChangeTrainings(id) {
    this.props.getTrainingById(id);
    this.setState({
      is_active_item: 1,
    });
  }
  handleClick = (id, course_id) => {
    if (id === 1) {
      this.props.getAllTrainings(this.props.params, 1);
    }
    if (id === 4) {
      this.props.getCoursesRatings();
      this.props.getCountCourse();
      this.props.getCountUser();
    }
    if (id === 2) {
      this.props.getAllTrainings(this.props.params, 3);
    }
    if (id === 0) {
      this.props.getAllTrainings(this.props.params, 2);
    }
    if (course_id) {
      this.props.getListAdvancementUserCourse(
        this.props.paramsAdvancementUserCourse,
        course_id,
      );
      this.props.getTrainingById(course_id);
    }
    this.setState({
      is_active_item: id,
    });
  };
  render() {
    const {
      countCourse,
      isLoadingTrainings,
      training,
      account,
      isLoadingTraining,
      trainings,
      activeItemSideBarAdmin,
      isLoadingUpdateSectionItem,
      updateSectionItem,
      advancementUserCourse,
      paramsAdvancementUserCourse,
      isLoadingAdvancementUserCourse,
      isLoadingCourseRating,
      courseRating,
      countUser,
      isLoadingCountUser,
    } = this.props;
    const { is_active_item, addCourse } = this.state;
    return (
      <>
        <HeadersAuth type={true} />
        <section className="sidebar-body-container admin-view-dshbd-main-sect">
          <SidebarAdmin
            user={account}
            activeItemSideBarAdmin={activeItemSideBarAdmin}
            changeActiveItemSideBarAdmin={this.props.changeActiveItemSideBarAdmin}
          />
          <section className="body-section dashboard-body admin-view-dashboard">
            <div className="bs-main-container">
              <div className="bs-main-content">
                <div className="dashboard-container">
                  <div className="SideNavMenu-btn--Mob">
                    <div className="SideNavMenu-btn--Mob-content">
                      <button
                        type="button"
                        data-toggle="modal"
                        data-target=".sidenavbar-admin-modal"
                      >
                        <div className="SideNavMenu-btn--Mob-content">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="60.171"
                            height="60.171"
                            viewBox="0 0 60.171 60.171"
                          >
                            <g
                              id="Group_1515"
                              data-name="Group 1515"
                              transform="translate(-1777.986 -170.986)"
                            >
                              <path
                                id="Path_799"
                                data-name="Path 799"
                                d="M1808.072,166.157h0a30.086,30.086,0,0,1-30.086-30.085h0a30.086,30.086,0,0,1,30.086-30.085h0a30.085,30.085,0,0,1,30.085,30.085h0A30.085,30.085,0,0,1,1808.072,166.157Z"
                                transform="translate(0 65)"
                                fill="#f0f0f0"
                                style={{ mixBlendMode: 'multiply', isolation: 'isolate' }}
                              />
                              <path
                                id="Path_800"
                                data-name="Path 800"
                                d="M1812.421,163.205h0a22.785,22.785,0,0,1-22.785-22.785h0a22.785,22.785,0,0,1,22.785-22.785h0a22.785,22.785,0,0,1,22.785,22.785h0A22.785,22.785,0,0,1,1812.421,163.205Z"
                                transform="translate(-4.349 60.651)"
                                fill="#fff"
                              />
                              <g
                                id="Group_1082"
                                data-name="Group 1082"
                                transform="translate(1796.957 189.169)"
                              >
                                <g
                                  id="Group_1080"
                                  data-name="Group 1080"
                                  transform="translate(4.41)"
                                >
                                  <path
                                    id="Path_805"
                                    data-name="Path 805"
                                    d="M1821.8,147.965a6.46,6.46,0,0,1-4.308-1.641,6.338,6.338,0,0,1-2.2-4.819,6.506,6.506,0,0,1,13.012,0,6.338,6.338,0,0,1-2.208,4.824A6.443,6.443,0,0,1,1821.8,147.965Zm0-10.315a3.858,3.858,0,0,0-3.855,3.855,3.718,3.718,0,0,0,1.3,2.831,3.9,3.9,0,0,0,5.092.01,3.736,3.736,0,0,0,1.315-2.841A3.906,3.906,0,0,0,1821.8,137.65Z"
                                    transform="translate(-1815.294 -135)"
                                    fill="#ea0029"
                                  />
                                </g>
                                <g
                                  id="Group_1081"
                                  data-name="Group 1081"
                                  transform="translate(0 9.015)"
                                >
                                  <path
                                    id="Path_806"
                                    data-name="Path 806"
                                    d="M1824.17,162.931h-9.943a5.862,5.862,0,0,1-5.475-3.618,5.943,5.943,0,0,1,1.208-6.542,12.739,12.739,0,0,1,5.4-3.333,1.373,1.373,0,0,1,1.276.28,3.9,3.9,0,0,0,5.079,0,1.37,1.37,0,0,1,1.273-.28,12.7,12.7,0,0,1,5.9,3.892,5.586,5.586,0,0,1,.784,6.04A6.1,6.1,0,0,1,1824.17,162.931Zm-8.669-10.74a10.14,10.14,0,0,0-3.631,2.417,3.342,3.342,0,0,0-.681,3.669,3.214,3.214,0,0,0,3.038,2h9.943a3.414,3.414,0,0,0,3.1-2.024,2.935,2.935,0,0,0-.393-3.209,10.121,10.121,0,0,0-4.032-2.857A6.591,6.591,0,0,1,1815.5,152.192Z"
                                    transform="translate(-1808.258 -149.386)"
                                    fill="#ea0029"
                                  />
                                </g>
                              </g>
                            </g>
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="29"
                            height="40"
                            viewBox="0 0 29 40"
                          >
                            <defs>
                              <filter
                                id="Polygon_2"
                                x="0"
                                y="0"
                                width="29"
                                height="40"
                                filterUnits="userSpaceOnUse"
                              >
                                <feOffset dy="3" input="SourceAlpha" />
                                <feGaussianBlur stdDeviation="3" result="blur" />
                                <feFlood flood-opacity="0.161" />
                                <feComposite operator="in" in2="blur" />
                                <feComposite in="SourceGraphic" />
                              </filter>
                            </defs>
                            <g
                              transform="matrix(1, 0, 0, 1, 0, 0)"
                              filter="url(#Polygon_2)"
                            >
                              <path
                                id="Polygon_2-2"
                                data-name="Polygon 2"
                                d="M10.293.707a1,1,0,0,1,1.414,0l8.586,8.586A1,1,0,0,1,19.586,11H2.414a1,1,0,0,1-.707-1.707Z"
                                transform="translate(9 28) rotate(-90)"
                                fill="#fff"
                              />
                            </g>
                          </svg>
                        </div>
                      </button>
                    </div>
                  </div>
                  <div className="dashboard-content admin-dashboard-view-content admin-dashboard-view-MultiRow">
                    <div className="dashboard-row">
                      <div className="admin-dashboard--nav">
                        <div className="admin-dashboard--nav-content">
                          <Link onClick={() => this.handleClick(0)}>
                            <div
                              className={
                                is_active_item === 0
                                  ? 'admin-dashboard--nav-elem active-dshb-nav-elem'
                                  : 'admin-dashboard--nav-elem'
                              }
                            >
                              <div className="icon-cont">
                                <svg
                                  id="Group_1632"
                                  data-name="Group 1632"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="34"
                                  height="34"
                                  viewBox="0 0 34 34"
                                >
                                  <g
                                    id="Rectangle_584"
                                    data-name="Rectangle 584"
                                    fill="none"
                                    stroke="#000"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    strokeWidth="3"
                                  >
                                    <rect width="34" height="34" rx="11" stroke="none" />
                                    <rect
                                      x="1.5"
                                      y="1.5"
                                      width="31"
                                      height="31"
                                      rx="9.5"
                                      fill="none"
                                    />
                                  </g>
                                  <g
                                    id="Group_1631"
                                    data-name="Group 1631"
                                    transform="translate(9 9)"
                                  >
                                    <g
                                      id="Rectangle_585"
                                      data-name="Rectangle 585"
                                      fill="none"
                                      stroke="#000"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      strokeWidth="2"
                                    >
                                      <rect width="8" height="8" rx="1" stroke="none" />
                                      <rect
                                        x="1"
                                        y="1"
                                        width="6"
                                        height="6"
                                        fill="none"
                                      />
                                    </g>
                                    <g
                                      id="Rectangle_588"
                                      data-name="Rectangle 588"
                                      transform="translate(0 9)"
                                      fill="none"
                                      stroke="#000"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      strokeWidth="2"
                                    >
                                      <rect width="8" height="8" rx="1" stroke="none" />
                                      <rect
                                        x="1"
                                        y="1"
                                        width="6"
                                        height="6"
                                        fill="none"
                                      />
                                    </g>
                                    <g
                                      id="Rectangle_586"
                                      data-name="Rectangle 586"
                                      transform="translate(9)"
                                      fill="none"
                                      stroke="#000"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      strokeWidth="2"
                                    >
                                      <rect width="8" height="8" rx="1" stroke="none" />
                                      <rect
                                        x="1"
                                        y="1"
                                        width="6"
                                        height="6"
                                        fill="none"
                                      />
                                    </g>
                                    <g
                                      id="Rectangle_587"
                                      data-name="Rectangle 587"
                                      transform="translate(9 9)"
                                      fill="none"
                                      stroke="#000"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      strokeWidth="2"
                                    >
                                      <rect width="8" height="8" rx="1" stroke="none" />
                                      <rect
                                        x="1"
                                        y="1"
                                        width="6"
                                        height="6"
                                        fill="none"
                                      />
                                    </g>
                                  </g>
                                </svg>
                              </div>
                              <span>دورات التدريب الذكي</span>
                            </div>
                          </Link>
                          <Link onClick={() => this.handleClick(1)}>
                            <div
                              className={
                                is_active_item === 1
                                  ? 'admin-dashboard--nav-elem active-dshb-nav-elem'
                                  : 'admin-dashboard--nav-elem'
                              }
                            >
                              <div className="icon-cont">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="23"
                                  height="23"
                                  viewBox="0 0 34 34"
                                >
                                  <path
                                    id="content"
                                    d="M6.64,7.5A1.328,1.328,0,0,1,7.968,6.176H26.03a1.328,1.328,0,0,1,0,2.656H7.968A1.328,1.328,0,0,1,6.64,7.5Zm20.719,5.312a1.328,1.328,0,0,0-1.328-1.328H7.968a1.328,1.328,0,0,0,0,2.656H26.03A1.328,1.328,0,0,0,27.359,12.817ZM7.968,16.8a1.328,1.328,0,1,0,0,2.656h6.109a1.328,1.328,0,1,0,0-2.656ZM32.833,27.2A3.984,3.984,0,0,1,27.2,32.833l-7.263-7.276a1.326,1.326,0,0,1-.34-.584l-1.485-5.36a1.328,1.328,0,0,1,1.666-1.625L25,19.575a1.326,1.326,0,0,1,.554.333ZM22.061,23.928l4.882,4.891,1.879-1.879-4.909-4.92-2.6-.789Zm8.892,5.149L30.7,28.82,28.82,30.7l.257.257a1.328,1.328,0,0,0,1.877-1.879ZM28.687,0H5.312A5.318,5.318,0,0,0,0,5.313V28.688A5.318,5.318,0,0,0,5.312,34H21.581a1.328,1.328,0,1,0,0-2.656H5.312a2.659,2.659,0,0,1-2.656-2.656V5.313A2.659,2.659,0,0,1,5.312,2.657H28.687a2.659,2.659,0,0,1,2.656,2.656V21.649a1.328,1.328,0,0,0,2.656,0V5.313A5.318,5.318,0,0,0,28.687,0Zm0,0"
                                    transform="translate(0.001 -0.001)"
                                  />
                                </svg>
                              </div>
                              <span>مقترحات برامج تدريبية</span>
                            </div>
                          </Link>
                          <Link onClick={() => this.handleClick(2)}>
                            <div
                              className={
                                is_active_item === 2
                                  ? 'admin-dashboard--nav-elem active-dshb-nav-elem'
                                  : 'admin-dashboard--nav-elem'
                              }
                            >
                              <div className="icon-cont">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="35.009"
                                  height="25.933"
                                  viewBox="0 0 35.009 25.933"
                                >
                                  <g
                                    id="Group_1638"
                                    data-name="Group 1638"
                                    transform="translate(0.5 0.5)"
                                  >
                                    <g id="Group_1650" data-name="Group 1650">
                                      <path
                                        id="Path_983"
                                        data-name="Path 983"
                                        d="M81.872,154.912a.757.757,0,0,0-.628-.335H76.711v-6.8a.755.755,0,0,0-.756-.756H61.434l-.614-2.45a.754.754,0,0,0-.732-.572H52.533a.756.756,0,0,0-.733.572l-.613,2.45H48.756a.755.755,0,0,0-.756.756v20.4c0,.009,0,.017,0,.027a.768.768,0,0,0,.04.23.044.044,0,0,0,0,.008.754.754,0,0,0,.079.156c.009.013.021.023.031.035s.027.032.042.048a.782.782,0,0,0,.137.119c.014.01.027.021.043.03a.754.754,0,0,0,.215.083.116.116,0,0,0,.014.005.741.741,0,0,0,.146.014h27.2a.756.756,0,0,0,.7-.468l5.289-12.844A.756.756,0,0,0,81.872,154.912Zm-32.361-6.379h2.267a.756.756,0,0,0,.733-.572l.613-2.45H59.5l.614,2.45a.754.754,0,0,0,.732.572H75.2V150.8H49.511Zm0,3.778H75.2v2.267H54.044a.757.757,0,0,0-.7.468l-3.834,9.313Zm25.938,15.111H49.884l4.667-11.333H80.116Z"
                                        transform="translate(-48 -144)"
                                        stroke="#000"
                                        strokeWidth="1"
                                      />
                                    </g>
                                  </g>
                                </svg>
                              </div>
                              <span>خطة التدريب السنوية</span>
                            </div>
                          </Link>
                          <Link onClick={() => this.handleClick(3)}>
                            <div
                              className={
                                is_active_item === 3
                                  ? 'admin-dashboard--nav-elem active-dshb-nav-elem'
                                  : 'admin-dashboard--nav-elem'
                              }
                            >
                              <div className="icon-cont">
                                <svg
                                  id="Group_1635"
                                  data-name="Group 1635"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 25.15 25.835"
                                >
                                  <g
                                    id="Group_1080"
                                    data-name="Group 1080"
                                    transform="translate(5.05)"
                                  >
                                    <path
                                      id="Path_805"
                                      data-name="Path 805"
                                      d="M1822.744,149.846a7.4,7.4,0,0,1-4.934-1.879,7.257,7.257,0,0,1-2.516-5.518,7.45,7.45,0,0,1,14.9,0,7.257,7.257,0,0,1-2.528,5.524A7.378,7.378,0,0,1,1822.744,149.846Zm0-11.812a4.418,4.418,0,0,0-4.415,4.415,4.257,4.257,0,0,0,1.493,3.242,4.461,4.461,0,0,0,5.832.011,4.278,4.278,0,0,0,1.505-3.254A4.473,4.473,0,0,0,1822.744,138.035Z"
                                      transform="translate(-1815.294 -135)"
                                    />
                                  </g>
                                  <g
                                    id="Group_1081"
                                    data-name="Group 1081"
                                    transform="translate(0 10.324)"
                                  >
                                    <path
                                      id="Path_806"
                                      data-name="Path 806"
                                      d="M1826.479,164.9h-11.385a6.713,6.713,0,0,1-6.27-4.143,6.805,6.805,0,0,1,1.384-7.491,14.588,14.588,0,0,1,6.182-3.817,1.572,1.572,0,0,1,1.461.32,4.468,4.468,0,0,0,5.816,0,1.568,1.568,0,0,1,1.458-.32,14.547,14.547,0,0,1,6.759,4.457,6.4,6.4,0,0,1,.9,6.917A6.979,6.979,0,0,1,1826.479,164.9Zm-9.927-12.3a11.614,11.614,0,0,0-4.158,2.768,3.827,3.827,0,0,0-.779,4.2,3.68,3.68,0,0,0,3.479,2.294h11.385a3.909,3.909,0,0,0,3.55-2.317,3.361,3.361,0,0,0-.45-3.674,11.589,11.589,0,0,0-4.617-3.272A7.547,7.547,0,0,1,1816.552,152.6Z"
                                      transform="translate(-1808.258 -149.386)"
                                    />
                                  </g>
                                </svg>
                              </div>
                              <span>المشاركون في البرامج</span>
                            </div>
                          </Link>
                          <Link onClick={() => this.handleClick(4)}>
                            <div
                              className={
                                is_active_item === 4
                                  ? 'admin-dashboard--nav-elem active-dshb-nav-elem'
                                  : 'admin-dashboard--nav-elem'
                              }
                            >
                              <div className="icon-cont">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20.307"
                                  height="26.909"
                                  viewBox="0 0 20.307 26.909"
                                >
                                  <g id="shopping-list" transform="translate(0.25 0.25)">
                                    <g
                                      id="Group_1578"
                                      data-name="Group 1578"
                                      transform="translate(3.302 9.904)"
                                    >
                                      <g id="Group_1577" data-name="Group 1577">
                                        <path
                                          id="Path_973"
                                          data-name="Path 973"
                                          d="M134.384,192.25a.828.828,0,0,0-1.169,0l-2.717,2.719-1.066-1.068a.825.825,0,0,0-1.167,1.167l1.651,1.651a.817.817,0,0,0,.583.243.829.829,0,0,0,.584-.241l3.3-3.3A.826.826,0,0,0,134.384,192.25Z"
                                          transform="translate(-128.024 -192.008)"
                                          stroke="#000"
                                          strokeWidth="0.5"
                                        />
                                      </g>
                                    </g>
                                    <g
                                      id="Group_1580"
                                      data-name="Group 1580"
                                      transform="translate(3.302 16.506)"
                                    >
                                      <g id="Group_1579" data-name="Group 1579">
                                        <path
                                          id="Path_974"
                                          data-name="Path 974"
                                          d="M134.384,320.25a.828.828,0,0,0-1.169,0l-2.717,2.719-1.066-1.066a.825.825,0,0,0-1.167,1.167l1.651,1.651a.827.827,0,0,0,1.167,0l3.3-3.3A.826.826,0,0,0,134.384,320.25Z"
                                          transform="translate(-128.024 -320.008)"
                                          stroke="#000"
                                          strokeWidth="0.5"
                                        />
                                      </g>
                                    </g>
                                    <g
                                      id="Group_1582"
                                      data-name="Group 1582"
                                      transform="translate(11.554 11.554)"
                                    >
                                      <g
                                        id="Group_1581"
                                        data-name="Group 1581"
                                        transform="translate(0)"
                                      >
                                        <path
                                          id="Path_975"
                                          data-name="Path 975"
                                          d="M292.126,224h-3.3a.825.825,0,0,0,0,1.651h3.3a.825.825,0,0,0,0-1.651Z"
                                          transform="translate(-288 -224)"
                                          stroke="#000"
                                          strokeWidth="0.5"
                                        />
                                      </g>
                                    </g>
                                    <g
                                      id="Group_1584"
                                      data-name="Group 1584"
                                      transform="translate(11.554 18.156)"
                                    >
                                      <g
                                        id="Group_1583"
                                        data-name="Group 1583"
                                        transform="translate(0)"
                                      >
                                        <path
                                          id="Path_976"
                                          data-name="Path 976"
                                          d="M292.126,352h-3.3a.825.825,0,0,0,0,1.651h3.3a.825.825,0,1,0,0-1.651Z"
                                          transform="translate(-288 -352)"
                                          stroke="#000"
                                          strokeWidth="0.5"
                                        />
                                      </g>
                                    </g>
                                    <g
                                      id="Group_1586"
                                      data-name="Group 1586"
                                      transform="translate(0)"
                                    >
                                      <g id="Group_1585" data-name="Group 1585">
                                        <path
                                          id="Path_977"
                                          data-name="Path 977"
                                          d="M82.156,3.3h-3.3V2.476a.826.826,0,0,0-.825-.825H76.239a2.478,2.478,0,0,0-4.671,0H69.777a.826.826,0,0,0-.825.825V3.3h-3.3A1.653,1.653,0,0,0,64,4.952V24.759a1.653,1.653,0,0,0,1.651,1.651H82.156a1.653,1.653,0,0,0,1.651-1.651V4.952A1.653,1.653,0,0,0,82.156,3.3ZM70.6,3.3h1.651a.826.826,0,0,0,.825-.825.825.825,0,1,1,1.651,0,.826.826,0,0,0,.825.825H77.2V4.952H70.6ZM82.156,24.759H65.651V4.952h3.3v.825a.826.826,0,0,0,.825.825H78.03a.826.826,0,0,0,.825-.825V4.952h3.3Z"
                                          transform="translate(-64)"
                                          stroke="#000"
                                          strokeWidth="0.5"
                                        />
                                      </g>
                                    </g>
                                  </g>
                                </svg>
                              </div>

                              <span>التقارير</span>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                    {is_active_item === 0 ? (
                      <List
                        handleClick={this.handleClick}
                        handleChangeAddCourse={this.handleChangeAddCourse}
                        handleClickChangeTrainings={this.handleClickChangeTrainings}
                        trainings={trainings}
                        type={2}
                        isLoadingTrainings={isLoadingTrainings}
                      />
                    ) : is_active_item === 1 ? (
                      <List
                        handleClick={this.handleClick}
                        handleChangeAddCourse={this.handleChangeAddCourse}
                        handleClickChangeTrainings={this.handleClickChangeTrainings}
                        trainings={trainings}
                        type={1}
                        isLoadingTrainings={isLoadingTrainings}
                      />
                    ) : is_active_item === 5 ? (
                      addCourse ? (
                        <Details
                          submitDeleteSectionItem={this.props.submitDeleteSectionItem}
                          submitSectionItem={this.props.submitSectionItem}
                          submitUpdateTraining={this.props.submitUpdateTraining}
                          isLoadingUpdateSectionItem={isLoadingUpdateSectionItem}
                          updateSectionItem={updateSectionItem}
                          training={null}
                          addCourse={addCourse}
                          isLoadingTraining={isLoadingTraining}
                        />
                      ) : isLoadingTraining ? (
                        <CircularProgress
                          style={{
                            textAlign: 'center',
                            marginTop: '14%',
                            width: '40px',
                            color: '#d85555',
                            marginRight: '50%',
                          }}
                        />
                      ) : training && training.id ? (
                        <Details
                          submitDeleteSectionItem={this.props.submitDeleteSectionItem}
                          submitSectionItem={this.props.submitSectionItem}
                          submitUpdateTraining={this.props.submitUpdateTraining}
                          isLoadingUpdateSectionItem={isLoadingUpdateSectionItem}
                          updateSectionItem={updateSectionItem}
                          training={training}
                          addCourse={addCourse}
                          isLoadingTraining={isLoadingTraining}
                        />
                      ) : (
                        'اختر دورة'
                      )
                    ) : is_active_item === 2 ? (
                      <List
                        handleClick={this.handleClick}
                        handleClickChangeTrainings={this.handleClickChangeTrainings}
                        trainings={trainings}
                        type={3}
                        handleChangeAddCourse={this.handleChangeAddCourse}
                        isLoadingTrainings={isLoadingTrainings}
                      />
                    ) : is_active_item === 3 ? (
                      isLoadingAdvancementUserCourse ? (
                        <CircularProgress
                          style={{
                            textAlign: 'center',
                            marginTop: '14%',
                            width: '40px',
                            color: '#d85555',
                            marginRight: '50%',
                          }}
                        />
                      ) : advancementUserCourse &&
                        advancementUserCourse.data &&
                        advancementUserCourse.data.length > 0 ? (
                        <Members
                          params={paramsAdvancementUserCourse}
                          advancementUserCourse={advancementUserCourse}
                        />
                      ) : (
                        'اختر دورة'
                      )
                    ) : is_active_item === 4 ? (
                      isLoadingCourseRating || isLoadingCountUser ? (
                        <CircularProgress
                          style={{
                            textAlign: 'center',
                            marginTop: '14%',
                            width: '40px',
                            color: '#d85555',
                            marginRight: '50%',
                          }}
                        />
                      ) : (
                        <Reports
                          countUser={countUser}
                          countCourse={countCourse}
                          courseRating={courseRating}
                        />
                      )
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
      </>
    );
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TrainingsAdmin));
