import React, { Component } from 'react';
import actions from '../../js/actions';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import Headers from '../../layouts/headers';
import Modal from '../../componenets/modal';
import ModalS from 'react-modal';
const mapStateToProps = (state) => ({
  isLoadingTraining: state.AppReducer.isLoadingTraining,
  training: state.AppReducer.training,
  isAuthorized: state.AppReducer.isAuthorized,
});

const mapDispatchToProps = (dispatch) => ({
  getTrainingById: (id) => dispatch(actions.getTrainingById(id)),
  submitUserProgramTraining: (values) =>
    dispatch(actions.submitUserProgramTraining(values)),
  submitSendEmailToAdmin: (values) => dispatch(actions.submitSendEmailToAdmin(values)),
});
const customStyles = {
  content: {
    width: '40%',
    height: '40%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    padding: '20px',
    overflow: 'hidden',
    borderRadius: '15px',
  },
};
class TrainingDetails extends Component {
  constructor(props) {
    super(props);
    props.getTrainingById(props.match.params.id);
    this.state = {
      section_id: null,
      name: '',
      hasError: false,
      isOpenModal: false,
    };
    this.isOpenModal = this.isOpenModal.bind(this);
  }
  isOpenModal(isOpenModal) {
    this.setState({
      isOpenModal: !isOpenModal,
    });
  }
  render() {
    const { training, isAuthorized } = this.props;
    const { section_id, isOpenModal, name, hasError } = this.state;
    let i = 0;
    let ir = 0;

    if (!training) return <div>isLoading</div>;
    return (
      <>
        <Modal
          id={`confirmation-${section_id}`}
          size="modal-md"
          data={
            <>
              <div className="modal-header">
                <h5 style={{ color: 'black' }} className="modal-title">
                  تسجيل في الدورة
                </h5>
              </div>
              <div className="modal-body">
                <p style={{ fontSize: '14px', color: 'black' }}>
                  هل ترغب فعلاً في التسجيل في هذه الدورة ؟
                </p>
              </div>
              <div className="modal-footer" style={{ display: 'block' }}>
                <button
                  onClick={() => {
                    document.getElementById('close-modal-confirmation').click();
                    this.props.submitUserProgramTraining({
                      section_id: section_id,
                    });
                  }}
                  style={{ width: '50%', fontSize: '15px' }}
                  type="button"
                  className="btn btn-success"
                >
                  نعم
                </button>
                <button
                  id="close-modal-confirmation"
                  style={{ width: '40%', fontSize: '15px' }}
                  type="button"
                  className="btn btn-info"
                  data-dismiss="modal"
                >
                  لا
                </button>
              </div>
            </>
          }
        />
        <ModalS
          isOpen={isOpenModal}
          onRequestClose={this.isOpenModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div className="modal-header">
            <h5 style={{ color: 'black' }} className="modal-title">
              هل تريد فعلا ارسال هذا المقترح
            </h5>
            <button
              onClick={() => this.isOpenModal(isOpenModal)}
              style={{
                justifyContent: 'flex-start',
                border: '0',
                backgroundColor: 'white',
              }}
              className=""
            >
              X
            </button>
          </div>

          <div className="modal-body">
            <div
              className="contact-us-form-row"
              style={{ alignItems: 'center' }}
            >
              <div className="input-field-label-container">
                <div className="input-field-label-content">
                  <label htmlFor="">الاسم المقترح</label>
                </div>
              </div>
              <div
                className={
                  hasError && !name
                    ? 'input-field-container input-error'
                    : 'input-field-container'
                }
              >
                <div className="input-field-content" style={{ height: '3rem' }}>
                  <input
                    name="name"
                    onChange={(e) =>
                      this.setState({
                        name: e.target.value,
                      })
                    }
                    value={name}
                    placeholder="الأسم"
                    type="text"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer" style={{ display: 'block' }}>
            <button
              onClick={async () => {
                if (!name) this.setState({ hasError: true });
                else {
                  this.setState({ hasError: false });
                  await this.props.submitSendEmailToAdmin({
                    training_name: training.name,
                    new_name: name,
                  });
                  this.setState({ isOpenModal: false });
                }
              }}
              style={{ width: '50%', fontSize: '15px' }}
              type="button"
              className="btn btn-success"
            >
              نعم
            </button>
            <button
              id="close-modal-confirmation"
              style={{ width: '40%', fontSize: '15px' }}
              type="button"
              className="btn btn-info"
              onClick={() => this.isOpenModal(isOpenModal)}
            >
              لا
            </button>
          </div>
        </ModalS>
        <Headers isAuthorized={isAuthorized} />
        <section className="body-section courses-body">
          <div style={{ backgroundColor: '#EDEDED' }} className="bs-main-container">
            <div className="bs-main-content">
              <div
                style={{ width: '100%' }}
                className="training-program-section-container"
              >
                <div className="training-program-section-content">
                  <div className="training-program-section-content">
                    <div className="training-program-section-elem header--subSect">
                      <div className="training-program-section-header-title">
                        <span>{training.name}</span>
                      </div>
                      <button
                        onClick={() => {
                          this.isOpenModal(isOpenModal);
                        }}
                        style={{ cursor: 'pointer' }}
                        className="btn btn-info"
                      >
                        اقترح دورة أخرى
                      </button>
                    </div>

                    <div className="courses-section-elem courses-section--mob-ver">
                      <div className="courses-section-container">
                        <div className="courses-section-content">
                          <div className="courses-section-header">
                            <div className="courses-section-header-content">
                              <span>كود الدورة و اسمها</span>
                            </div>
                          </div>
                          <div className="courses-section-body">
                            {training &&
                              training.sections &&
                              training.sections.length > 0 &&
                              training.sections.map((section) => {
                                ir++;
                                return (
                                  <div
                                    key={training.id}
                                    className="courses-section-body-elem"
                                  >
                                    <div className="course-name-code--cont">
                                      <div className="course-code-container">
                                        <span className="bold-sp">كود الدورة: </span>
                                        <span id="course-code">
                                          {ir < 10 ? `0${ir}` : ir}
                                        </span>
                                      </div>
                                      <div className="course-name-container">
                                        <span className="bold-sp">اسم الدورة: </span>
                                        <span id="course-name">{section.name}</span>
                                      </div>
                                    </div>
                                    <div className="course-name-enroll-btn">
                                      <button
                                        onClick={() => {
                                          this.setState({
                                            section_id: section.id,
                                          });
                                        }}
                                        style={{ cursor: 'pointer' }}
                                        data-toggle="modal"
                                        data-target={`#confirmation-${section.id}`}
                                        className="btn btn-red"
                                      >
                                        إشترك
                                      </button>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="training-program-section-elem courses-section--desk-ver">
                      <div className="courses-section--desk-ver-container">
                        <div className="courses-section--desk-ver-content">
                          <div className="courses-section--desk-ver-header">
                            <div className="courses-section--desk-ver-header-content">
                              <div className="course-code-column-name">
                                <span>الرقم</span>
                              </div>
                              <div className="course-name-column-name">
                                <span>{training.name}</span>
                              </div>
                              <div className="course-enroll-btn-column-name">
                                <span>الإشتراك</span>
                              </div>
                            </div>
                          </div>
                          <div className="courses-section--desk-ver-body-content">
                            {training &&
                              training.sections &&
                              training.sections.length > 0 &&
                              training.sections.map((section) => {
                                i++;
                                return (
                                  <div
                                    key={i}
                                    className="courses-section--desk-ver-body-content-elem"
                                  >
                                    <div className="course-code--des-ver">
                                      <div
                                        style={{
                                          width: '100%',
                                        }}
                                        className="course-code--des-ver-content"
                                      >
                                        <span>{i < 10 ? `0${i}` : i}</span>
                                      </div>
                                    </div>
                                    <div className="course-name--des-ver">
                                      <span>{section.name}</span>
                                    </div>
                                    <div
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => {
                                        this.setState({
                                          section_id: section.id,
                                        });
                                      }}
                                      data-toggle="modal"
                                      data-target={`#confirmation-${section.id}`}
                                      className="course-enroll-btn--des-ver"
                                    >
                                      <span>إشترك</span>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TrainingDetails));
