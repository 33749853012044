import React, { Component } from 'react';
import path126 from '../../../assets/img/Path 126.png';
import SimpleReactValidator from 'simple-react-validator';
import actions from '../../../js/actions';
import { connect } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import { Link } from 'react-router-dom';
import headerImageDark from '../../../assets/img/umAlQuwain_main_logo_dark.png';

const mapStateToProps = (state) => ({
  isLoadingNewAccount: state.AppReducer.isLoadingNewAccount,
  message: state.AppReducer.message,
  error: state.AppReducer.error,
  authLogin: state.AppReducer.authLogin,
  account: state.AppReducer.account,
  isLoadingLogin: state.AppReducer.isLoadingLogin,
  isLoading: state.AppReducer.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  //InitialState: () => dispatch(actions.InitialState()),
  login: (values) => dispatch(actions.login(values)),
});

class Login extends Component {
  constructor(props) {
    super(props);
    //this.props.InitialState();
    document.title = 'Register';
    this.state = {
      initialState: {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        confirm_password: '',
      },
      onFocus: false,
      hasError: false,
      showPassword: false,
      showConfirmPassword: false,
    };
    this.validator = new SimpleReactValidator({
      messages: {
        email: 'Adresse e-mail invalide',
        min: 'Saisissez un mot de passe comprenant au moins 6 caractères.',
        max: 'Le mot de passe doit comporter de 6 à 20 caractères. ',
        // OR
        default: 'Champ requise.', // will override all messages
      },
    });
  }
  handleShowPassword() {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  }
  handleShowConfirmPassword() {
    this.setState({
      showConfirmPassword: !this.state.showConfirmPassword,
    });
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { account, authLogin } = this.props;
    if (authLogin !== prevProps.authLogin && account !== prevProps.account) {
      window.location.href = `/`;
    }
  }
  submitForm(initialState) {
    const course_id = this.props.location.state
      ? this.props.location.state.course_id
      : null;
    if (!initialState.email || !initialState.password) {
      this.setState({
        ...initialState,
        hasError: true,
      });
    } else if (this.validator.allValid()) {
      if (course_id) {
        initialState.course_id = course_id;
      }
      this.props.login(initialState);
    } else {
      this.setState({
        ...initialState,
        hasError: true,
      });
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  }
  render() {
    const { initialState, hasError, showConfirmPassword, showPassword } = this.state;
    const { isLoadingLogin } = this.props;
    return (
      <section className="auth-main-section">
        <div className="auth-main-section-content">
          <div className="signin-form-container">
            <div className="signin-form-content">
              <div className="form-header">
                <div>
                  <div style={{ textAlign: 'end' }}>
                    <img src={headerImageDark} style={{ width: '20%' }} />
                  </div>
                  <div
                    style={{ marginRight: '38%' }}
                    className="-form-header--icon-container"
                  >
                    <img src={path126} alt="" />
                  </div>
                </div>
                <div className="-form-header--text-container">
                  <div className="-form-header--title">
                    <span>تسجيل الدخول</span>
                  </div>
                </div>
              </div>
              <form action="">
                <div className="input-container">
                  <div
                    style={{ justifyContent: 'flex-end', marginRight: '2%' }}
                    className="-user-email-header"
                  >
                    <span>حساب مسؤول</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="13.886"
                      height="15.148"
                      viewBox="0 0 13.886 15.148"
                    >
                      <path
                        id="Path_1007"
                        data-name="Path 1007"
                        d="M153.315,6.736a3.787,3.787,0,1,0-4.745,0A6.939,6.939,0,0,0,144,13.255v1.894h13.886V13.255A6.939,6.939,0,0,0,153.315,6.736Z"
                        transform="translate(-144)"
                        fill="#ea0029"
                      />
                    </svg>
                  </div>
                  <div className="input-field-container">
                    <input
                      className={
                        (hasError && !initialState.email) ||
                        (this.validator.message(
                          'email',
                          initialState.email,
                          'required|email',
                        ) &&
                          this.validator.message(
                            'email',
                            initialState.email,
                            'required|email',
                          ).type &&
                          initialState.email)
                          ? 'input-error'
                          : ''
                      }
                      required={this.validator.message(
                        'email',
                        initialState.email,
                        'required|email',
                      )}
                      name="email"
                      onChange={(e) => {
                        this.setState({
                          initialState: {
                            ...initialState,
                            email: e.target.value,
                          },
                        });
                      }}
                      type="text"
                      placeholder="البريد الإلكتروني"
                    />
                  </div>
                  <div className="input-field-container">
                    <input
                      className={hasError && !initialState.password ? 'input-error' : ''}
                      name="password"
                      onChange={(e) => {
                        this.setState({
                          initialState: {
                            ...initialState,
                            password: e.target.value,
                          },
                        });
                      }}
                      type="password"
                      placeholder="كلمة المرور"
                    />
                  </div>
                </div>
                <div className="btns-links">
                  <div className="remember-me-btn-container">
                    <Link to="/reset-password">
                      <span>إستعادة كلمة المرور</span>
                    </Link>
                  </div>
                  <div className="-signIn--link">
                    <Link to="/register"> ليس لديك حساب؟ </Link>
                  </div>
                </div>

                <button
                  onClick={() => this.submitForm(initialState)}
                  type="button"
                  className="btn btn-red"
                >
                  {isLoadingLogin ? (
                    <CircularProgress
                      style={{
                        textAlign: 'center',
                        width: '30px',
                        height: '30px',
                        color: 'white',
                      }}
                    />
                  ) : (
                    'تسجيل الدخول'
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Login);
