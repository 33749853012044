const initialAppState = {
  isLoadingNewAccount: false,
  addAccount: null,
  newUser: {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
  },
  message: '',
};
const RegisterReducer = (state = initialAppState, action) => {
  switch (action.type) {
    case 'INITIAL_STATE_SIGN_UP': {
      return {
        ...state,
        isLoading: false,
        uploadNewFile: false,
        addAccount: null,
      };
    }

    case 'CREATE_NEW_ACCOUNT_REQUESTING': {
      return {
        ...state,
        isLoadingNewAccount: true,
        addAccount: false,
      };
    }
    case 'CREATE_NEW_ACCOUNT_SUCCESS': {
      return {
        ...state,
        isLoadingNewAccount: false,
        addAccount: action.data,
      };
    }
    case 'CREATE_NEW_ACCOUNT_FAILURE': {
      return {
        ...state,
        isLoadingNewAccount: false,
        addAccount: false,
        message: action.err,
        ErrorMessage: action.ErrorMessage,
      };
    }

    default: {
      return state;
    }
  }
};

export default RegisterReducer;
