const initialAppState = {
  isLoadingAdvancementUserCourse: false,
  advancementUserCourse: [],
  isLoadingMostAttendedCourses: false,
  mostAttendedCourses: [],
  params: {
    currentPage: 1,
    limit: 10,
  },
  isLoadingCourseRating: false,
  courseRating: [],
  isLoadingCountCourse: false,
  countCourse: null,
  isLoadingCountUser: false,
  countUser: [],
  isLoadingDetailUser: false,
  detailUser: [],
  paramsDetailsUser: {
    currentPage: 1,
    limit: 10,
  },
  isLoadingRatingUser: false,
  ratingUser: [],
};
const AdvancementUserCourseReducer = (state = initialAppState, action) => {
  switch (action.type) {
    case 'GET_RATING_COURSE_USER_REQUESTING':
      return {
        ...state,
        isLoadingRatingUser: true,
      };
    case 'GET_RATING_COURSE_USER_SUCCESS':
      return {
        ...state,
        isLoadingRatingUser: false,
        ratingUser: action.data,
      };
    case 'GET_RATING_COURSE_USER_FAILURE':
      return {
        ...state,
        isLoadingRatingUser: false,
        ratingUser: [],
      };
    case 'GET_DETAIL_USER_REQUESTING':
      return {
        ...state,
        isLoadingDetailUser: true,
      };
    case 'GET_DETAIL_USER_SUCCESS':
      return {
        ...state,
        isLoadingDetailUser: false,
        detailUser: action.data,
      };
    case 'GET_DETAIL_USER_FAILURE':
      return {
        ...state,
        isLoadingDetailUser: false,
        detailUser: [],
      };
    case 'GET_COUNT_USER_REQUESTING':
      return {
        ...state,
        isLoadingCountUser: true,
      };
    case 'GET_COUNT_USER_SUCCESS':
      return {
        ...state,
        isLoadingCountUser: false,
        countUser: action.data,
      };
    case 'GET_COUNT_USER_FAILURE':
      return {
        ...state,
        isLoadingCountUser: false,
        countUser: null,
      };
    case 'GET_COUNT_TRAININGS_REQUESTING':
      return {
        ...state,
        isLoadingCountCourse: true,
      };
    case 'GET_COUNT_TRAININGS_SUCCESS':
      return {
        ...state,
        isLoadingCountCourse: false,
        countCourse: action.data,
      };
    case 'GET_COUNT_TRAININGS_FAILURE':
      return {
        ...state,
        isLoadingCountCourse: false,
        countCourse: null,
      };
    case 'GET_COURSE_RATINGS_REQUESTING':
      return {
        ...state,
        isLoadingCourseRating: true,
      };
    case 'GET_COURSE_RATINGS_SUCCESS':
      return {
        ...state,
        isLoadingCourseRating: false,
        courseRating: action.data,
      };
    case 'GET_COURSE_RATINGS_FAILURE':
      return {
        ...state,
        isLoadingCourseRating: false,
        courseRating: null,
      };
    case 'GET_MOST_ATTENDED_COURSE_REQUESTING':
      return {
        ...state,
        isLoadingMostAttendedCourses: true,
      };
    case 'GET_MOST_ATTENDED_COURSE_SUCCESS':
      return {
        ...state,
        isLoadingMostAttendedCourses: false,
        mostAttendedCourses: action.data,
      };
    case 'GET_MOST_ATTENDED_COURSE_FAILURE':
      return {
        ...state,
        isLoadingMostAttendedCourses: false,
        mostAttendedCourses: null,
      };
    case 'GET_ALL_ADVANCEMENT_USER_COURSE_REQUESTING':
      return {
        ...state,
        isLoadingAdvancementUserCourse: true,
      };
    case 'GET_ALL_ADVANCEMENT_USER_COURSE_SUCCESS':
      return {
        ...state,
        isLoadingAdvancementUserCourse: false,
        advancementUserCourse: action.data,
      };
    case 'GET_ALL_ADVANCEMENT_USER_COURSE_FAILURE':
      return {
        ...state,
        isLoadingAdvancementUserCourse: false,
        advancementUserCourse: null,
      };
    case 'PAGINATION_ADVANCEMENT_USER_COURSE_PARAMS':
      return {
        ...state,
        params: action.params,
      };
    case 'PAGINATION_DETAIL_USER_PARAMS':
      return {
        ...state,
        paramsDetailsUser: action.paramsDetailsUser,
      };
    default: {
      return state;
    }
  }
};

export default AdvancementUserCourseReducer;
