import React, { Component } from 'react';
import path126 from '../../../../assets/img/Path 126.png';
import SimpleReactValidator from 'simple-react-validator';
import { validate, validatePhone } from '../../../../utils/helpers';
import actions from '../../../authentification/register/store/actions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import HeadersAuth from '../../../../layouts/auth/headers';
import SidebarAdmin from '../../layouts/sidebar';

const mapStateToProps = (state) => ({
  isLoadingNewAccount: state.RegisterReducer.isLoadingNewAccount,
  message: state.RegisterReducer.message,
  error: state.RegisterReducer.error,
  addAccount: state.RegisterReducer.addAccount,
  isLoading: state.RegisterReducer.isLoading,
  account: state.AppReducer.account,
});

const mapDispatchToProps = (dispatch) => ({
  InitialState: () => dispatch(actions.InitialState()),
  submitAccountForm: (values) => dispatch(actions.submitAccountForm(values)),
});

class Register extends Component {
  constructor(props) {
    super(props);
    //this.props.InitialState();
    document.title = 'Register';
    this.state = {
      initialState: {
        admin: true,
        first_name: '',
        last_name: '',
        email: '',
        address_country: '',
        password: '',
        phone: '',
        job_title: '',
        circle: '',
        confirm_password: '',
      },
      onFocus: false,
      hasError: false,
      showPassword: false,
      showConfirmPassword: false,
    };
    this.validator = new SimpleReactValidator({
      messages: {
        email: 'Adresse e-mail invalide',
        min: 'Saisissez un mot de passe comprenant au moins 6 caractères.',
        max: 'Le mot de passe doit comporter de 6 à 20 caractères. ',
        // OR
        default: 'Champ requise.', // will override all messages
      },
    });
  }
  handleShowPassword() {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  }

  handleShowConfirmPassword() {
    this.setState({
      showConfirmPassword: !this.state.showConfirmPassword,
    });
  }

  submitForm(initialState) {
    const course_id = this.props.location.state
      ? this.props.location.state.course_id
      : null;
    if (
      !initialState.first_name ||
      !initialState.last_name ||
      !initialState.email ||
      !initialState.phone ||
      !initialState.password ||
      !initialState.circle ||
      !initialState.job_title ||
      !validate(initialState.first_name) ||
      !validatePhone(initialState.phone) ||
      //!validPassword(initialState.password) ||
      !validate(initialState.last_name)
    ) {
      this.setState({
        ...initialState,
        hasError: true,
      });
    } else if (this.validator.allValid()) {
      if (course_id) {
        initialState.course_id = course_id;
      }
      this.props.submitAccountForm(initialState);
    } else {
      this.setState({
        ...initialState,
        hasError: true,
      });
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { addAccount } = this.props;
    if (addAccount !== prevProps.addAccount && addAccount.id) {
      window.location.href = `/admin/users`;
    }
  }

  render() {
    const { initialState, hasError, showConfirmPassword, showPassword } = this.state;
    const { activeItemSideBarAdmin, account } = this.props;
    return (
      <>
        <HeadersAuth type={true} />
        <section className="sidebar-body-container">
          <SidebarAdmin
            user={account}
            activeItemSideBarAdmin={activeItemSideBarAdmin}
            changeActiveItemSideBarAdmin={this.props.changeActiveItemSideBarAdmin}
          />
          <section className="body-section dashboard-body admin-view-dashboard">
            <div className="bs-main-container auth-main-section">
              <div className="bs-main-content auth-main-section-content">
                <div className="dashboard-container signup-form-container">
                  <div className="SideNavMenu-btn--Mob">
                    <div className="SideNavMenu-btn--Mob-content">
                      <button
                        type="button"
                        data-toggle="modal"
                        data-target=".sidenavbar-admin-modal"
                      >
                        <div className="SideNavMenu-btn--Mob-content">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="60.171"
                            height="60.171"
                            viewBox="0 0 60.171 60.171"
                          >
                            <g
                              id="Group_1515"
                              data-name="Group 1515"
                              transform="translate(-1777.986 -170.986)"
                            >
                              <path
                                id="Path_799"
                                data-name="Path 799"
                                d="M1808.072,166.157h0a30.086,30.086,0,0,1-30.086-30.085h0a30.086,30.086,0,0,1,30.086-30.085h0a30.085,30.085,0,0,1,30.085,30.085h0A30.085,30.085,0,0,1,1808.072,166.157Z"
                                transform="translate(0 65)"
                                fill="#f0f0f0"
                                style={{
                                  mixBlendMode: 'multiply',
                                  isolation: 'isolate',
                                }}
                              />
                              <path
                                id="Path_800"
                                data-name="Path 800"
                                d="M1812.421,163.205h0a22.785,22.785,0,0,1-22.785-22.785h0a22.785,22.785,0,0,1,22.785-22.785h0a22.785,22.785,0,0,1,22.785,22.785h0A22.785,22.785,0,0,1,1812.421,163.205Z"
                                transform="translate(-4.349 60.651)"
                                fill="#fff"
                              />
                              <g
                                id="Group_1082"
                                data-name="Group 1082"
                                transform="translate(1796.957 189.169)"
                              >
                                <g
                                  id="Group_1080"
                                  data-name="Group 1080"
                                  transform="translate(4.41)"
                                >
                                  <path
                                    id="Path_805"
                                    data-name="Path 805"
                                    d="M1821.8,147.965a6.46,6.46,0,0,1-4.308-1.641,6.338,6.338,0,0,1-2.2-4.819,6.506,6.506,0,0,1,13.012,0,6.338,6.338,0,0,1-2.208,4.824A6.443,6.443,0,0,1,1821.8,147.965Zm0-10.315a3.858,3.858,0,0,0-3.855,3.855,3.718,3.718,0,0,0,1.3,2.831,3.9,3.9,0,0,0,5.092.01,3.736,3.736,0,0,0,1.315-2.841A3.906,3.906,0,0,0,1821.8,137.65Z"
                                    transform="translate(-1815.294 -135)"
                                    fill="#ea0029"
                                  />
                                </g>
                                <g
                                  id="Group_1081"
                                  data-name="Group 1081"
                                  transform="translate(0 9.015)"
                                >
                                  <path
                                    id="Path_806"
                                    data-name="Path 806"
                                    d="M1824.17,162.931h-9.943a5.862,5.862,0,0,1-5.475-3.618,5.943,5.943,0,0,1,1.208-6.542,12.739,12.739,0,0,1,5.4-3.333,1.373,1.373,0,0,1,1.276.28,3.9,3.9,0,0,0,5.079,0,1.37,1.37,0,0,1,1.273-.28,12.7,12.7,0,0,1,5.9,3.892,5.586,5.586,0,0,1,.784,6.04A6.1,6.1,0,0,1,1824.17,162.931Zm-8.669-10.74a10.14,10.14,0,0,0-3.631,2.417,3.342,3.342,0,0,0-.681,3.669,3.214,3.214,0,0,0,3.038,2h9.943a3.414,3.414,0,0,0,3.1-2.024,2.935,2.935,0,0,0-.393-3.209,10.121,10.121,0,0,0-4.032-2.857A6.591,6.591,0,0,1,1815.5,152.192Z"
                                    transform="translate(-1808.258 -149.386)"
                                    fill="#ea0029"
                                  />
                                </g>
                              </g>
                            </g>
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="29"
                            height="40"
                            viewBox="0 0 29 40"
                          >
                            <defs>
                              <filter
                                id="Polygon_2"
                                x="0"
                                y="0"
                                width="29"
                                height="40"
                                filterUnits="userSpaceOnUse"
                              >
                                <feOffset dy="3" input="SourceAlpha" />
                                <feGaussianBlur stdDeviation="3" result="blur" />
                                <feFlood flood-opacity="0.161" />
                                <feComposite operator="in" in2="blur" />
                                <feComposite in="SourceGraphic" />
                              </filter>
                            </defs>
                            <g
                              transform="matrix(1, 0, 0, 1, 0, 0)"
                              filter="url(#Polygon_2)"
                            >
                              <path
                                id="Polygon_2-2"
                                data-name="Polygon 2"
                                d="M10.293.707a1,1,0,0,1,1.414,0l8.586,8.586A1,1,0,0,1,19.586,11H2.414a1,1,0,0,1-.707-1.707Z"
                                transform="translate(9 28) rotate(-90)"
                                fill="#fff"
                              />
                            </g>
                          </svg>
                        </div>
                      </button>
                    </div>
                  </div>
                  <div className="dashboard-content admin-dashboard-view-content signup-form-content">
                    <div className="form-header">
                      <div className="-form-header--icon-container">
                        <img src={path126} alt="" />
                      </div>
                      <div className="-form-header--text-container">
                        <div className="-form-header--title">
                          <span>تسجيل </span>
                        </div>
                      </div>
                    </div>
                    <form>
                      <div className="input-container">
                        <input hidden name="admin" value={true} />
                        <div style={{ display: 'flex' }}>
                          <div className="input-field-container">
                            <input
                              className={
                                (hasError && !initialState.first_name) ||
                                (hasError &&
                                  initialState.first_name &&
                                  !validate(initialState.first_name))
                                  ? 'input-error'
                                  : ''
                              }
                              name="first_name"
                              onChange={(e) => {
                                this.setState({
                                  initialState: {
                                    ...initialState,
                                    first_name: e.target.value,
                                  },
                                });
                              }}
                              required={this.validator.message(
                                'first_name',
                                initialState.first_name,
                                'required|string',
                              )}
                              type="text"
                              placeholder="الأسم الأول"
                            />
                          </div>
                          <div className="input-field-container">
                            <input
                              className={
                                (hasError && !initialState.last_name) ||
                                (hasError &&
                                  initialState.last_name &&
                                  !validate(initialState.last_name))
                                  ? 'input-error'
                                  : ''
                              }
                              required={this.validator.message(
                                'last_name',
                                initialState.last_name,
                                'required|string',
                              )}
                              name="last_name"
                              onChange={(e) => {
                                this.setState({
                                  initialState: {
                                    ...initialState,
                                    last_name: e.target.value,
                                  },
                                });
                              }}
                              type="text"
                              placeholder="الأسم الثاني"
                            />
                          </div>
                        </div>
                        <div style={{ display: 'flex' }}>
                          <div className="input-field-container">
                            <input
                              className={
                                (hasError && !initialState.job_title) ||
                                (hasError &&
                                  initialState.job_title &&
                                  !validate(initialState.job_title))
                                  ? 'input-error'
                                  : ''
                              }
                              required={this.validator.message(
                                'job_title',
                                initialState.job_title,
                                'required|string',
                              )}
                              name="job_title"
                              onChange={(e) => {
                                this.setState({
                                  initialState: {
                                    ...initialState,
                                    job_title: e.target.value,
                                  },
                                });
                              }}
                              type="text"
                              placeholder="المسمى الوظيفي"
                            />
                          </div>
                          <div className="input-field-container">
                            <input
                              maxLength={20}
                              minLength={8}
                              className={
                                (hasError && !initialState.phone) ||
                                (hasError &&
                                  initialState.phone &&
                                  !validatePhone(initialState.phone))
                                  ? 'input-error'
                                  : ''
                              }
                              /*required={this.validator.message(
                        'phone',
                        initialState.phone,
                        'required|max20|min:8',
                      )}*/
                              name="phone"
                              onChange={(e) => {
                                this.setState({
                                  initialState: {
                                    ...initialState,
                                    phone: e.target.value,
                                  },
                                });
                              }}
                              type="text"
                              placeholder="الهاتف"
                            />
                          </div>
                        </div>
                        <div
                          className={
                            hasError && !initialState.circle
                              ? 'user-info-input-field input-error'
                              : 'user-info-input-field'
                          }
                        >
                          <select
                            onChange={(e) => {
                              this.setState({
                                initialState: {
                                  ...initialState,
                                  circle: e.target.value,
                                },
                              });
                            }}
                            name="circle"
                            id=""
                          >
                            <option selected value="">
                              اختر دائرة
                            </option>
                            <option value="دائرة بلدية أم القيوين">
                              دائرة بلدية أم القيوين
                            </option>
                            <option value="دائرة التخطيط العمراني">
                              دائرة التخطيط العمراني
                            </option>
                            <option value="دائرة التنمية الاقتصادية">
                              دائرة التنمية الاقتصادية
                            </option>
                            <option value="دائرة ام القيوين الذكية">
                              دائرة ام القيوين الذكية
                            </option>
                            <option value="دائرة المالية">دائرة المالية</option>
                            <option value="دائرة السياحة والآثار">
                              دائرة السياحة والآثار
                            </option>
                            <option value="المجلس التنفيذي">المجلس التنفيذي</option>
                            <option value="الديوان الأميري">الديوان الأميري</option>
                            <option value="آخرى">آخرى</option>
                          </select>
                          {/*<input
                        className={
                          (hasError && !initialState.address_country) ||
                          (hasError &&
                            initialState.address_country &&
                            !validate(initialState.address_country))
                            ? 'input-error'
                            : ''
                        }
                        required={this.validator.message(
                          'address_country',
                          initialState.address_country,
                          'required|string',
                        )}
                        name="address_country"
                        onChange={(e) => {
                          this.setState({
                            initialState: {
                              ...initialState,
                              address_country: e.target.value,
                            },
                          });
                        }}
                        type="text"
                        placeholder="الجهة"
                      />*/}
                        </div>
                        <div className="input-field-container">
                          <input
                            className={
                              (hasError && !initialState.email) ||
                              (this.validator.message(
                                'email',
                                initialState.email,
                                'required|email',
                              ) &&
                                this.validator.message(
                                  'email',
                                  initialState.email,
                                  'required|email',
                                ).type &&
                                initialState.email)
                                ? 'input-error'
                                : ''
                            }
                            required={this.validator.message(
                              'email',
                              initialState.email,
                              'required|email',
                            )}
                            name="email"
                            onChange={(e) => {
                              this.setState({
                                initialState: {
                                  ...initialState,
                                  email: e.target.value,
                                },
                              });
                            }}
                            type="text"
                            placeholder="البريد الإلكتروني"
                          />
                        </div>
                        <div className="input-field-container">
                          <input
                            className={
                              hasError && !initialState.password ? 'input-error' : ''
                            }
                            name="password"
                            onChange={(e) => {
                              this.setState({
                                initialState: {
                                  ...initialState,
                                  password: e.target.value,
                                },
                              });
                            }}
                            type="password"
                            placeholder="كلمة المرور"
                          />
                        </div>
                        <div className="input-field-container">
                          <input
                            className={
                              hasError && !initialState.confirm_password
                                ? 'input-error'
                                : ''
                            }
                            name="confirm_password"
                            onChange={(e) => {
                              if (initialState.password.localeCompare(e.target.value)) {
                                this.setState({
                                  ...initialState,
                                  hasError: true,
                                });
                              }
                              this.setState({
                                initialState: {
                                  ...initialState,
                                  confirm_password: e.target.value,
                                },
                              });
                              /*this.setState({
                          initialState: {
                            ...initialState,
                            confirm_password: e.target.value,
                          },
                        });*/
                            }}
                            type="password"
                            placeholder="تاكيد كلمة المرور"
                          />
                        </div>
                      </div>
                      <button
                        onClick={() => this.submitForm(initialState)}
                        type="button"
                        className="btn btn-red"
                      >
                        تسجيل
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
      </>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Register);
