import { apiCall } from '../../../js/actions';

const getListAdvancementUserCourse = (params) => async (dispatch) => {
  dispatch({ type: 'GET_ALL_ADVANCEMENT_USER_COURSE_REQUESTING' });
  try {
    const { currentPage } = params || {
      currentPage: 1,
    };
    const { limit } = params || {
      limit: '',
    };

    //let url = `${host}/api/admin/course/${id}?&limit=${limit}&current_page=${currentPage}`;
    const { data } = await apiCall({
      method: 'get',
      url: `/api/admin/course?&limit=${limit}&current_page=${currentPage}`,
    });
    //const { data } = await axios.get(url);
    dispatch({ type: 'GET_ALL_ADVANCEMENT_USER_COURSE_SUCCESS', data: data });
  } catch (err) {
    const { response } = err;
    if (response)
      dispatch({
        type: 'GET_ALL_ADVANCEMENT_USER_COURSE_FAILURE',
        err: err.message,
      });
  }
};

const getMostAttendedCourses = () => async (dispatch) => {
  dispatch({ type: 'GET_MOST_ATTENDED_COURSE_REQUESTING' });
  try {
    //let url = `${host}/api/admin/course/${id}?&limit=${limit}&current_page=${currentPage}`;
    const { data } = await apiCall({
      method: 'get',
      url: `/api/admin/most/attended/courses`,
    });
    //const { data } = await axios.get(url);
    dispatch({ type: 'GET_MOST_ATTENDED_COURSE_SUCCESS', data: data });
  } catch (err) {
    const { response } = err;
    if (response)
      dispatch({
        type: 'GET_MOST_ATTENDED_COURSE_FAILURE',
        err: err.message,
      });
  }
};
const getCoursesRatings = () => async (dispatch) => {
  dispatch({ type: 'GET_COURSE_RATINGS_REQUESTING' });
  try {
    //let url = `${host}/api/admin/course/${id}?&limit=${limit}&current_page=${currentPage}`;
    const { data } = await apiCall({
      method: 'get',
      url: `/api/admin/course/rating`,
    });
    //const { data } = await axios.get(url);
    dispatch({ type: 'GET_COURSE_RATINGS_SUCCESS', data: data });
  } catch (err) {
    const { response } = err;
    if (response)
      dispatch({
        type: 'GET_COURSE_RATINGS_FAILURE',
        err: err.message,
      });
  }
};

const changeSearchedString = (params) => ({
  type: 'PAGINATION_ADVANCEMENT_USER_COURSE_PARAMS',
  params: params,
});
const changeSearchedStringDetailsUser = (paramsDetailsUser) => ({
  type: 'PAGINATION_DETAIL_USER_PARAMS',
  paramsDetailsUser: paramsDetailsUser,
});

const getCountCourse = () => async (dispatch) => {
  dispatch({ type: 'GET_COUNT_TRAININGS_REQUESTING' });
  try {
    const url = `/api/admin/count/course`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({ type: 'GET_COUNT_TRAININGS_SUCCESS', data: data });
  } catch (err) {
    const { response } = err;
    dispatch({
      type: 'GET_COUNT_TRAININGS_FAILURE',
      err: response && response.data && response.data.error,
    });
  }
};
const getCountUser = () => async (dispatch) => {
  dispatch({ type: 'GET_COUNT_USER_REQUESTING' });
  try {
    //let url = `${host}/api/admin/course/${id}?&limit=${limit}&current_page=${currentPage}`;
    const { data } = await apiCall({
      method: 'get',
      url: `/api/admin/count/user`,
    });
    //const { data } = await axios.get(url);
    dispatch({ type: 'GET_COUNT_USER_SUCCESS', data: data });
  } catch (err) {
    const { response } = err;
    if (response)
      dispatch({
        type: 'GET_COUNT_USER_FAILURE',
        err: err.message,
      });
  }
};
const getDetailsUserCourse = () => async (dispatch) => {
  dispatch({ type: 'GET_DETAIL_USER_REQUESTING' });
  try {
    //let url = `${host}/api/admin/course/${id}?&limit=${limit}&current_page=${currentPage}`;
    const { data } = await apiCall({
      method: 'get',
      url: `/api/admin/count/details/user`,
    });
    //const { data } = await axios.get(url);
    dispatch({ type: 'GET_DETAIL_USER_SUCCESS', data: data });
  } catch (err) {
    const { response } = err;
    if (response)
      dispatch({
        type: 'GET_DETAIL_USER_FAILURE',
        err: err.message,
      });
  }
};

const getRatingByUser = (id) => async (dispatch) => {
  dispatch({ type: 'GET_RATING_COURSE_USER_REQUESTING' });
  try {
    //let url = `${host}/api/admin/course/${id}?&limit=${limit}&current_page=${currentPage}`;
    const { data } = await apiCall({
      method: 'get',
      url: `/api/admin/count/rating/course/user/${id}`,
    });
    //const { data } = await axios.get(url);
    dispatch({ type: 'GET_RATING_COURSE_USER_SUCCESS', data: data });
  } catch (err) {
    const { response } = err;
    if (response)
      dispatch({
        type: 'GET_RATING_COURSE_USER_FAILURE',
        err: err.message,
      });
  }
};
export default {
  getRatingByUser,
  getDetailsUserCourse,
  getCountCourse,
  getListAdvancementUserCourse,
  changeSearchedString,
  getMostAttendedCourses,
  getCoursesRatings,
  getCountUser,
  changeSearchedStringDetailsUser,
};
