import React, { Component } from 'react';
import Group1490 from '../../../../assets/img/Group 1490@2x.png';
import FormPersonnelInfo from './composents/form-personnel-info';
import FormAutobiography from './composents/form-autobiography';
import actions from '../../../../js/actions';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import { validate } from '../../../../utils/helpers';
import validator from 'validator';
import moment from 'moment';
import { CircularProgress } from '@material-ui/core';
import HeadersAuth from '../../../../layouts/auth/headers';
import SideBar from '../../../../layouts/auth/sidebar';
import Modal from 'react-modal';

const mapStateToProps = (state, ownProps) => {
  const isLoadingUser = state.AppReducer.isLoadingUser;
  const user = state.AppReducer.user;
  let first_name;
  let last_name;
  let email;
  let phone;
  let address_country;
  let address_city;
  let language;
  let id;
  let university_id;
  let university;
  let section;
  let description;
  let date_from;
  let date_to;
  let graduated;
  let job_title;
  let circle;
  let file;
  if (!isLoadingUser && user) {
    university_id = user.university ? user.university.id : '';
    graduated = user.university ? user.university.graduated : false;
    section = user.university ? user.university.section : '';
    date_to = user.university ? moment(user.university.date_to).format('YYYY-MM-DD') : '';
    description = user.university ? user.university.description : '';
    date_from = user.university
      ? moment(user.university.date_from).format('YYYY-MM-DD')
      : '';
    university = user.university ? user.university.university : '';
    job_title = user.job_title ? user.job_title : '';
    circle = user.circle ? user.circle : '';
    id = user.id ? user.id : null;
    language = user.language ? user.language : '';
    last_name = user.last_name ? user.last_name : '';
    file = user.picture ? user.picture : null;
    first_name = user.first_name ? user.first_name : '';
    email = user.email ? user.email : '';
    phone = user.phone ? user.phone : '';
    address_country = user.address_country ? user.address_country : '';
    address_city = user.address_city ? user.address_city : '';
  }
  return {
    isLoadingUpdateUser: state.AppReducer.isLoadingUpdateUser,
    updateUser: state.AppReducer.updateUser,
    account: state.AppReducer.account,
    isLoadingUser: state.AppReducer.isLoadingUser,
    user: state.AppReducer.user,
    countCertification: state.AppReducer.countCertification,
    isLoadingSavedInfo: state.AppReducer.isLoadingSavedInfo,
    savedInfo: state.AppReducer.savedInfo,
    changePassword: state.AppReducer.changePassword,
    isLoadingChangePassword: state.AppReducer.isLoadingChangePassword,
    initialValues: {
      id,
      first_name,
      last_name,
      email,
      phone,
      address_country,
      address_city,
      language,
      file,
      circle,
      job_title,
      university: {
        graduated,
        university_id,
        university,
        section,
        date_from,
        date_to,
        description,
      },
    },
  };
};

const mapDispatchToProps = (dispatch) => ({
  getUser: (id) => dispatch(actions.getUser(id)),
  getSavedInformationUser: () => dispatch(actions.getSavedInformationUser()),
  submitUserForm: async (values) => {
    await dispatch(actions.submitUserForm(values));
    dispatch(actions.getUser(values.id));
  },
  changePassword: async (values) => dispatch(actions.changePassword(values)),
});
const customStyles = {
  content: {
    width: '50%',
    height: '50%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    padding: '20px',
    overflow: 'hidden',
    borderRadius: '15px',
  },
};
class Profile extends Component {
  constructor(props) {
    super(props);
    document.title = 'دوراتي';
    props.getUser(props.account.id);
    props.getSavedInformationUser();
    this.state = {
      initialValues: {
        ...props.initialValues,
        old_password: '',
        new_password: '',
      },
      hasError: false,
      isOpenModal: false,
    };
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.handleChangeFiles = this.handleChangeFiles.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleChangeInputUniversity = this.handleChangeInputUniversity.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.validator = new SimpleReactValidator({
      messages: {
        email: 'Adresse e-mail invalide',
        min: 'Saisissez un mot de passe comprenant au moins 6 caractères.',
        max: 'Le mot de passe doit comporter de 6 à 20 caractères. ',
        // OR
        default: 'Champ requise.', // will override all messages
      },
    });
  }
  submitForm(initialValues) {
    if (
      !initialValues.first_name ||
      !initialValues.last_name ||
      !initialValues.email ||
      !initialValues.university.university ||
      !initialValues.university.section ||
      !initialValues.university.date_from ||
      !initialValues.university.description ||
      !initialValues.university.date_to ||
      !validate(initialValues.first_name) ||
      !validator.isEmail(initialValues.email) ||
      (initialValues.university.date_from &&
        initialValues.university.date_to &&
        moment(initialValues.university.date_to).isBefore(
          initialValues.university.date_from,
        )) ||
      (initialValues.university.date_from &&
        initialValues.university.date_to &&
        moment(initialValues.university.date_to).isBefore(
          initialValues.university.date_from,
        )) ||
      //!validPassword(initialState.password) ||
      !validate(initialValues.last_name)
    ) {
      this.setState({
        ...initialValues,
        hasError: true,
      });
    } else {
      this.props.submitUserForm(initialValues);
    }
  }
  handleClick(fileUploader) {
    this.refs[fileUploader].click();
  }
  handleChangeFiles = (event, name) => {
    const { initialValues } = this.state;
    const fileUploaded = event.target.files[0];
    this.setState({
      initialValues: {
        ...initialValues,
        [name]: fileUploaded,
      },
    });
  };
  handleChangeInput(event) {
    const { initialValues } = this.state;
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      initialValues: {
        ...initialValues,
        [name]: value,
      },
    });
  }
  handleChangeInputUniversity(event) {
    const { initialValues } = this.state;
    const target = event.target;
    const value =
      target.type === 'checkbox' ? (target.checked === true ? 1 : 0) : target.value;
    const name = target.name;
    this.setState({
      initialValues: {
        ...initialValues,
        university: {
          ...initialValues.university,
          [name]: value,
        },
      },
    });
  }
  isOpenModal() {
    const { isOpenModal } = this.state;
    this.setState({
      isOpenModal: !isOpenModal,
      hasError: false,
    });
  }
  render() {
    const {
      isLoadingUpdateUser,
      savedInfo,
      isLoadingSavedInfo,
      isLoadingUser,
    } = this.props;
    const { initialValues, hasError, isOpenModal } = this.state;
    if (isLoadingUser && initialValues.id) {
      return (
        <section className="body-section dashboard-body">
          <div className="bs-main-container">
            <div className="bs-main-content">
              <CircularProgress
                style={{
                  textAlign: 'center',
                  marginTop: '25%',
                  width: '40px',
                  color: '#d85555',
                  marginRight: '50%',
                }}
              />
            </div>
          </div>
        </section>
      );
    }
    if (isLoadingSavedInfo) {
      return (
        <section className="body-section dashboard-body">
          <div className="bs-main-container">
            <div className="bs-main-content">
              <CircularProgress
                style={{
                  textAlign: 'center',
                  marginTop: '25%',
                  width: '40px',
                  color: '#d85555',
                  marginRight: '50%',
                }}
              />
            </div>
          </div>
        </section>
      );
    }
    let score;
    if (savedInfo)
      if (savedInfo.info === 30) score = 400;
      else if (savedInfo.info > 30 && savedInfo.info < 40) score = 360;
      else if (savedInfo.info >= 40 && savedInfo.info < 50) score = 330;
      else if (savedInfo.info === 50) score = 280;
      else if (savedInfo.info > 50 && savedInfo.info <= 65) score = 220;
      else if (savedInfo.info > 65 && savedInfo.info < 75) score = 160;
      else if (savedInfo.info === 75) score = 145;
      else if (savedInfo.info > 75 && savedInfo.info <= 85) score = 90;
      else if (savedInfo.info > 85 && savedInfo.info <= 94) score = 65;
      else if (savedInfo.info >= 95 && savedInfo.info <= 98) score = 50;
      else score = 0;
    return (
      <>
        <Modal
          isOpen={isOpenModal}
          onRequestClose={this.isOpenModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div
            style={{
              justifyContent: 'flex-end',
            }}
            className="modal-header"
          >
            <button
              onClick={() => this.isOpenModal()}
              style={{ border: '0', backgroundColor: 'white' }}
              className=""
            >
              X
            </button>
          </div>
          <div className="modal-body">
            <div
              className="contact-us-form-row"
              style={{ alignItems: 'center', height: '80px' }}
            >
              <div className="input-field-label-container">
                <div className="input-field-label-content">
                  <label htmlFor="">كلمة السر القديمة </label>
                </div>
              </div>
              <div
                className={
                  hasError && !initialValues.old_password
                    ? 'input-field-container input-error'
                    : 'input-field-container'
                }
              >
                <div className="input-field-content" style={{ height: '3rem' }}>
                  <input
                    name="section_item_name"
                    onChange={(e) =>
                      this.setState({
                        initialValues: {
                          ...initialValues,
                          old_password: e.target.value,
                        },
                      })
                    }
                    value={initialValues.old_password}
                    placeholder="كلمة السر القديمة "
                    type="password"
                  />
                </div>
              </div>
            </div>
            <div
              className="contact-us-form-row"
              style={{ alignItems: 'center', height: '80px' }}
            >
              <div className="input-field-label-container">
                <div className="input-field-label-content">
                  <label htmlFor="">كلمة السر الجديدة </label>
                </div>
              </div>
              <div
                className={
                  hasError && !initialValues.new_password
                    ? 'input-field-container input-error'
                    : 'input-field-container'
                }
              >
                <div className="input-field-content" style={{ height: '3rem' }}>
                  <input
                    name="section_item_name"
                    onChange={(e) =>
                      this.setState({
                        initialValues: {
                          ...initialValues,
                          new_password: e.target.value,
                        },
                      })
                    }
                    value={initialValues.new_password}
                    placeholder="كلمة السر الجديدة"
                    type="password"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              justifyContent: 'flex-start',
            }}
            className="modal-footer"
          >
            <button
              onClick={() => {
                if (!initialValues.old_password || !initialValues.new_password) {
                  this.setState({
                    hasError: true,
                  });
                } else {
                  this.setState({
                    hasError: false,
                  });
                  this.props.changePassword({
                    old_password: initialValues.old_password,
                    new_password: initialValues.new_password,
                  });
                  this.isOpenModal();
                }
              }}
              className="btn btn-green"
            >
              تسجيل
            </button>
          </div>
        </Modal>
        <HeadersAuth />
        <section className="sidebar-body-container registered-user-view-main-cont">
          <SideBar
            path={this.props.location.pathname}
            changeActiveItemSideBar={this.props.changeActiveItemSideBar}
            activeItemSideBar={this.props.activeItemSideBar}
            account={this.props.account}
          />
          <section className="user-personal-info-section">
            <section className="body-section dashboard-body user-personal-info">
              <div className="bs-main-container">
                <div className="bs-main-content">
                  <div className="dashboard-container">
                    <div className="SideNavMenu-btn--Mob">
                      <div className="SideNavMenu-btn--Mob-content">
                        <button
                          type="button"
                          data-toggle="modal"
                          data-target=".sidenavbar-modal"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="60.171"
                            height="60.171"
                            viewBox="0 0 60.171 60.171"
                          >
                            <g
                              id="Group_1515"
                              data-name="Group 1515"
                              transform="translate(-1777.986 -170.986)"
                            >
                              <path
                                id="Path_799"
                                data-name="Path 799"
                                d="M1808.072,166.157h0a30.086,30.086,0,0,1-30.086-30.085h0a30.086,30.086,0,0,1,30.086-30.085h0a30.085,30.085,0,0,1,30.085,30.085h0A30.085,30.085,0,0,1,1808.072,166.157Z"
                                transform="translate(0 65)"
                                fill="#f0f0f0"
                                style={{
                                  mixBlendMode: 'multiply',
                                  isolation: 'isolate',
                                }}
                              ></path>
                              <path
                                id="Path_800"
                                data-name="Path 800"
                                d="M1812.421,163.205h0a22.785,22.785,0,0,1-22.785-22.785h0a22.785,22.785,0,0,1,22.785-22.785h0a22.785,22.785,0,0,1,22.785,22.785h0A22.785,22.785,0,0,1,1812.421,163.205Z"
                                transform="translate(-4.349 60.651)"
                                fill="#fff"
                              ></path>
                              <g
                                id="Group_1082"
                                data-name="Group 1082"
                                transform="translate(1796.957 189.169)"
                              >
                                <g
                                  id="Group_1080"
                                  data-name="Group 1080"
                                  transform="translate(4.41)"
                                >
                                  <path
                                    id="Path_805"
                                    data-name="Path 805"
                                    d="M1821.8,147.965a6.46,6.46,0,0,1-4.308-1.641,6.338,6.338,0,0,1-2.2-4.819,6.506,6.506,0,0,1,13.012,0,6.338,6.338,0,0,1-2.208,4.824A6.443,6.443,0,0,1,1821.8,147.965Zm0-10.315a3.858,3.858,0,0,0-3.855,3.855,3.718,3.718,0,0,0,1.3,2.831,3.9,3.9,0,0,0,5.092.01,3.736,3.736,0,0,0,1.315-2.841A3.906,3.906,0,0,0,1821.8,137.65Z"
                                    transform="translate(-1815.294 -135)"
                                    fill="#ea0029"
                                  ></path>
                                </g>
                                <g
                                  id="Group_1081"
                                  data-name="Group 1081"
                                  transform="translate(0 9.015)"
                                >
                                  <path
                                    id="Path_806"
                                    data-name="Path 806"
                                    d="M1824.17,162.931h-9.943a5.862,5.862,0,0,1-5.475-3.618,5.943,5.943,0,0,1,1.208-6.542,12.739,12.739,0,0,1,5.4-3.333,1.373,1.373,0,0,1,1.276.28,3.9,3.9,0,0,0,5.079,0,1.37,1.37,0,0,1,1.273-.28,12.7,12.7,0,0,1,5.9,3.892,5.586,5.586,0,0,1,.784,6.04A6.1,6.1,0,0,1,1824.17,162.931Zm-8.669-10.74a10.14,10.14,0,0,0-3.631,2.417,3.342,3.342,0,0,0-.681,3.669,3.214,3.214,0,0,0,3.038,2h9.943a3.414,3.414,0,0,0,3.1-2.024,2.935,2.935,0,0,0-.393-3.209,10.121,10.121,0,0,0-4.032-2.857A6.591,6.591,0,0,1,1815.5,152.192Z"
                                    transform="translate(-1808.258 -149.386)"
                                    fill="#ea0029"
                                  ></path>
                                </g>
                              </g>
                            </g>
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="29"
                            height="40"
                            viewBox="0 0 29 40"
                          >
                            <defs>
                              <filter
                                id="Polygon_2"
                                x="0"
                                y="0"
                                width="29"
                                height="40"
                                filterUnits="userSpaceOnUse"
                              >
                                <feOffset dy="3" input="SourceAlpha"></feOffset>
                                <feGaussianBlur
                                  stdDeviation="3"
                                  result="blur"
                                ></feGaussianBlur>
                                <feFlood flood-opacity="0.161"></feFlood>
                                <feComposite operator="in" in2="blur"></feComposite>
                                <feComposite in="SourceGraphic"></feComposite>
                              </filter>
                            </defs>
                            <g
                              transform="matrix(1, 0, 0, 1, 0, 0)"
                              filter="url(#Polygon_2)"
                            >
                              <path
                                id="Polygon_2-2"
                                data-name="Polygon 2"
                                d="M10.293.707a1,1,0,0,1,1.414,0l8.586,8.586A1,1,0,0,1,19.586,11H2.414a1,1,0,0,1-.707-1.707Z"
                                transform="translate(9 28) rotate(-90)"
                                fill="#fff"
                              ></path>
                            </g>
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div className="dashboard-content">
                      <div className="dashboard-column">
                        <div className="dashboard-column-content">
                          <div className="dashboard-column-elem">
                            <div className="dashboard-column-elem-content -user--info-S1">
                              <div className="dashboard-stats-content">
                                <div className="dashboard-user-personal-info-row">
                                  <div className="profil-completion-perc">
                                    <div className="overall-progress-stats-elem -white-stats--bar">
                                      <div className="overall-progress-stat-perc-ring">
                                        <div className="overall-progress-stat-perc-ring-content">
                                          <svg
                                            className="pie"
                                            viewBox="0 0 200 200"
                                            data-percent="60"
                                          >
                                            <circle r="90" cx="100" cy="100"></circle>
                                            <circle
                                              className="bar"
                                              r="90"
                                              cx="100"
                                              cy="100"
                                              style={{
                                                strokeDashoffset: `${score}px`,
                                                strokeDasharray: '565.487px',
                                              }}
                                            ></circle>
                                          </svg>
                                        </div>
                                        <div className="perc-in-number">
                                          <span>{savedInfo && savedInfo.info}%</span>
                                        </div>
                                      </div>
                                      <div className="profil-completion-txt">
                                        <span>المعلومات المحفوظة</span>
                                      </div>
                                    </div>
                                    <div className="change-user-profil-pwd">
                                      <div
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => this.isOpenModal()}
                                        className="change-user-profil-pwd-content"
                                      >
                                        <span>تحديث كلمة المرور</span>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20.541"
                                          height="4.108"
                                          viewBox="0 0 20.541 4.108"
                                        >
                                          <g
                                            id="Group_12"
                                            data-name="Group 12"
                                            transform="translate(-106.839 -1976.04)"
                                          >
                                            <circle
                                              id="Ellipse_21"
                                              data-name="Ellipse 21"
                                              cx="2.054"
                                              cy="2.054"
                                              r="2.054"
                                              transform="translate(106.839 1976.04)"
                                              fill="#5a5a5a"
                                            />
                                            <circle
                                              id="Ellipse_22"
                                              data-name="Ellipse 22"
                                              cx="2.054"
                                              cy="2.054"
                                              r="2.054"
                                              transform="translate(115.056 1976.04)"
                                              fill="#5a5a5a"
                                            />
                                            <circle
                                              id="Ellipse_23"
                                              data-name="Ellipse 23"
                                              cx="2.054"
                                              cy="2.054"
                                              r="2.054"
                                              transform="translate(123.272 1976.04)"
                                              fill="#5a5a5a"
                                            />
                                          </g>
                                        </svg>
                                      </div>
                                    </div>
                                  </div>
                                  <FormPersonnelInfo
                                    handleChangeFiles={this.handleChangeFiles}
                                    handleClick={this.handleClick}
                                    handleChangeInput={this.handleChangeInput}
                                    initialValues={initialValues}
                                    hasError={hasError}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="dashboard-column-elem stats-graph-container">
                            <div className="user-personal-info-form-container">
                              <FormAutobiography
                                handleChangeInputUniversity={
                                  this.handleChangeInputUniversity
                                }
                                initialValues={initialValues.university}
                                hasError={hasError}
                              />
                              <div className="user-academic-info-form-art">
                                <div className="user-academic-info">
                                  <img src={Group1490} alt="" />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="dashboard-column-elem">
                            <div className="dashboard-column-elem-content">
                              <div className="dashboard-stats-content">
                                <div className="dashboard-user-personal-info-row language-and-save-btn">
                                  <div className="change-app-language"></div>
                                  <div className="save-btn-container">
                                    <button
                                      onClick={() =>
                                        this.submitForm(initialValues, 'user')
                                      }
                                      className="btn btn-green"
                                    >
                                      {isLoadingUpdateUser ? (
                                        <CircularProgress
                                          style={{
                                            textAlign: 'center',
                                            width: '30px',
                                            height: '30px',
                                            color: 'white',
                                          }}
                                        />
                                      ) : (
                                        <span>حفظ المعلومات</span>
                                      )}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </section>
        </section>
      </>
    );
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Profile));
