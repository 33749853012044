import React, { Component } from 'react';
import actions from '../../../../js/actions';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import Training from './composents/training';
import { CircularProgress } from '@material-ui/core';
import SideBar from '../../../../layouts/auth/sidebar';
import HeadersAuth from '../../../../layouts/auth/headers';
import ProgressChart from './composents/progress-chart';
const mapStateToProps = (state) => ({
  isLoadingUser: state.AppReducer.isLoadingUser,
  user: state.AppReducer.user,
  account: state.AppReducer.account,
  isLoadingTrainingUser: state.AppReducer.isLoadingTrainingUser,
  trainingsUser: state.AppReducer.trainingsUser,
});

const mapDispatchToProps = (dispatch) => ({
  getUser: (id) => dispatch(actions.getUser(id)),
  getAllTrainingsByUser: () => dispatch(actions.getAllTrainingsByUser()),
});
class Courses extends Component {
  constructor(props) {
    super(props);
    document.title = 'دوراتي';
    props.getUser(props.account.id);
    props.getAllTrainingsByUser();
  }

  render() {
    const {
      user,
      countTrainingIsStart,
      countTrainingIsFinish,
      countTrainingIsProgress,
      isLoadingTrainingUser,
      trainingsUser,
    } = this.props;
    let i = 0;
    if (isLoadingTrainingUser) {
      return (
        <section className="body-section dashboard-body">
          <div className="bs-main-container">
            <div className="bs-main-content">
              <CircularProgress
                style={{
                  textAlign: 'center',
                  marginTop: '25%',
                  width: '40px',
                  color: '#d85555',
                  marginRight: '50%',
                }}
              />
            </div>
          </div>
        </section>
      );
    }
    return (
      <>
        <HeadersAuth />
        <section className="sidebar-body-container registered-user-view-main-cont">
          <SideBar
            path={this.props.location.pathname}
            changeActiveItemSideBar={this.props.changeActiveItemSideBar}
            activeItemSideBar={this.props.activeItemSideBar}
            account={this.props.account}
          />
          <section className="body-section dashboard-body">
            <div className="bs-main-container">
              <div className="bs-main-content">
                <div className="dashboard-container">
                  <div className="SideNavMenu-btn--Mob">
                    <div className="SideNavMenu-btn--Mob-content">
                      <button
                        type="button"
                        data-toggle="modal"
                        data-target=".sidenavbar-modal"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="60.171"
                          height="60.171"
                          viewBox="0 0 60.171 60.171"
                        >
                          <g
                            id="Group_1515"
                            data-name="Group 1515"
                            transform="translate(-1777.986 -170.986)"
                          >
                            <path
                              id="Path_799"
                              data-name="Path 799"
                              d="M1808.072,166.157h0a30.086,30.086,0,0,1-30.086-30.085h0a30.086,30.086,0,0,1,30.086-30.085h0a30.085,30.085,0,0,1,30.085,30.085h0A30.085,30.085,0,0,1,1808.072,166.157Z"
                              transform="translate(0 65)"
                              fill="#f0f0f0"
                              style={{ mixBlendMode: 'multiply', isolation: 'isolate' }}
                            ></path>
                            <path
                              id="Path_800"
                              data-name="Path 800"
                              d="M1812.421,163.205h0a22.785,22.785,0,0,1-22.785-22.785h0a22.785,22.785,0,0,1,22.785-22.785h0a22.785,22.785,0,0,1,22.785,22.785h0A22.785,22.785,0,0,1,1812.421,163.205Z"
                              transform="translate(-4.349 60.651)"
                              fill="#fff"
                            ></path>
                            <g
                              id="Group_1082"
                              data-name="Group 1082"
                              transform="translate(1796.957 189.169)"
                            >
                              <g
                                id="Group_1080"
                                data-name="Group 1080"
                                transform="translate(4.41)"
                              >
                                <path
                                  id="Path_805"
                                  data-name="Path 805"
                                  d="M1821.8,147.965a6.46,6.46,0,0,1-4.308-1.641,6.338,6.338,0,0,1-2.2-4.819,6.506,6.506,0,0,1,13.012,0,6.338,6.338,0,0,1-2.208,4.824A6.443,6.443,0,0,1,1821.8,147.965Zm0-10.315a3.858,3.858,0,0,0-3.855,3.855,3.718,3.718,0,0,0,1.3,2.831,3.9,3.9,0,0,0,5.092.01,3.736,3.736,0,0,0,1.315-2.841A3.906,3.906,0,0,0,1821.8,137.65Z"
                                  transform="translate(-1815.294 -135)"
                                  fill="#ea0029"
                                ></path>
                              </g>
                              <g
                                id="Group_1081"
                                data-name="Group 1081"
                                transform="translate(0 9.015)"
                              >
                                <path
                                  id="Path_806"
                                  data-name="Path 806"
                                  d="M1824.17,162.931h-9.943a5.862,5.862,0,0,1-5.475-3.618,5.943,5.943,0,0,1,1.208-6.542,12.739,12.739,0,0,1,5.4-3.333,1.373,1.373,0,0,1,1.276.28,3.9,3.9,0,0,0,5.079,0,1.37,1.37,0,0,1,1.273-.28,12.7,12.7,0,0,1,5.9,3.892,5.586,5.586,0,0,1,.784,6.04A6.1,6.1,0,0,1,1824.17,162.931Zm-8.669-10.74a10.14,10.14,0,0,0-3.631,2.417,3.342,3.342,0,0,0-.681,3.669,3.214,3.214,0,0,0,3.038,2h9.943a3.414,3.414,0,0,0,3.1-2.024,2.935,2.935,0,0,0-.393-3.209,10.121,10.121,0,0,0-4.032-2.857A6.591,6.591,0,0,1,1815.5,152.192Z"
                                  transform="translate(-1808.258 -149.386)"
                                  fill="#ea0029"
                                ></path>
                              </g>
                            </g>
                          </g>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="29"
                          height="40"
                          viewBox="0 0 29 40"
                        >
                          <defs>
                            <filter
                              id="Polygon_2"
                              x="0"
                              y="0"
                              width="29"
                              height="40"
                              filterUnits="userSpaceOnUse"
                            >
                              <feOffset dy="3" input="SourceAlpha"></feOffset>
                              <feGaussianBlur
                                stdDeviation="3"
                                result="blur"
                              ></feGaussianBlur>
                              <feFlood flood-opacity="0.161"></feFlood>
                              <feComposite operator="in" in2="blur"></feComposite>
                              <feComposite in="SourceGraphic"></feComposite>
                            </filter>
                          </defs>
                          <g
                            transform="matrix(1, 0, 0, 1, 0, 0)"
                            filter="url(#Polygon_2)"
                          >
                            <path
                              id="Polygon_2-2"
                              data-name="Polygon 2"
                              d="M10.293.707a1,1,0,0,1,1.414,0l8.586,8.586A1,1,0,0,1,19.586,11H2.414a1,1,0,0,1-.707-1.707Z"
                              transform="translate(9 28) rotate(-90)"
                              fill="#fff"
                            ></path>
                          </g>
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div className="dashboard-content">
$                    <div style={{ width: '100%' }}>
                      <div className="dashboard-column-content">
                        <div className="dashboard-column-elem">
                          <div className="dashboard-stats-container">
                            <div className="dashboard-stats-content">
                              <div className="courses-stats">
                                <div className="courses-stats-header">
                                  <span>نسبة التقدم</span>
                                </div>
                                <div className="courses-stats-content">
                                  {/* <div className="stat-pie-chart">
                                <div className="stat-pie-chart-content">
                                  <span></span>
                                </div>
                              </div>*/}
                                  <div className="stat-pie-chart-legend">
                                    <div className="stat-pie-chart-legend-content">
                                      <div className="completed-courses">
                                        <div className="key-color"></div>
                                        <span>الدورات المنجزة</span>
                                        <span>
                                          {(countTrainingIsFinish * 100) / 100}%
                                        </span>
                                      </div>
                                      <div className="courses-not-started">
                                        <div className="key-color"></div>
                                        <span>دورات لم تبدأ</span>
                                        <span>{(countTrainingIsStart * 100) / 100}%</span>
                                      </div>
                                      <div className="courses-in-progress">
                                        <div className="key-color"></div>
                                        <span>دورات في قيد الإنجاز</span>
                                        <span>
                                          {(countTrainingIsProgress * 100) / 100}%
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="courses-progress-stats">
                                <div className="courses-progress-stats-header">
                                  <span>نسبة التقدم</span>
                                </div>
                                <div className="courses-progress-stats-content">
                                  {trainingsUser && trainingsUser.length > 0
                                    ? trainingsUser.map((training) => {
                                        i++;
                                        return (
                                          <ProgressChart training={training} i={i} />
                                        );
                                      })
                                    : ''}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="dashboard-column-elem"></div>
                        </div>
                        <div className="dashboard-column-elem dashboard-carousel-cont">
                          <div className="bsSliderInter">
                            <div className="carousel-header">
                              <span>دوراتي</span>
                            </div>
                            {user && user.courses && user.courses.length > 0 ? (
                              <div
                                id="bsSliderInter"
                                className="carousel slide"
                                data-ride="carousel"
                              >
                                <a
                                  className="carousel-control-next"
                                  href="#bsSliderInter"
                                  role="button"
                                  data-slide="next"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    width="29.437"
                                    height="50.216"
                                    viewBox="0 0 29.437 50.216"
                                  >
                                    <defs>
                                      <filter
                                        id="Path_162"
                                        x="0"
                                        y="0"
                                        width="29.437"
                                        height="50.216"
                                        filterUnits="userSpaceOnUse"
                                      >
                                        <feOffset dy="3" input="SourceAlpha" />
                                        <feGaussianBlur stdDeviation="3" result="blur" />
                                        <feFlood flood-opacity="0.161" />
                                        <feComposite operator="in" in2="blur" />
                                        <feComposite in="SourceGraphic" />
                                      </filter>
                                    </defs>
                                    <g
                                      transform="matrix(1, 0, 0, 1, 0, 0)"
                                      filter="url(#Path_162)"
                                    >
                                      <path
                                        id="Path_162-2"
                                        data-name="Path 162"
                                        d="M-12300.5-6205.5l9.059,14.366-8,15.12"
                                        transform="translate(12310.88 6212.88)"
                                        fill="none"
                                        stroke="#ea0029"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                      />
                                    </g>
                                  </svg>
                                </a>
                                <div className="carousel-inner">
                                  <div className="carousel-item active">
                                    <div className="container">
                                      {user.courses.slice(0, 4).map((course) => {
                                        if (course.type === 2) {
                                          return (
                                            <Training
                                              title={true}
                                              key={course.id}
                                              user_course={user.user_smart_trainings.find(
                                                (item) => item.course_id === course.id,
                                              )}
                                              training={course}
                                            />
                                          );
                                        }
                                      })}
                                    </div>
                                  </div>
                                  <div className="carousel-item">
                                    <div className="container">
                                      {user.courses.slice(4, 100).map((course) => {
                                        if (course.type === 2) {
                                          return (
                                            <Training
                                              title={true}
                                              key={course.id}
                                              user_course={user.user_smart_trainings.find(
                                                (item) => item.course_id === course.id,
                                              )}
                                              training={course}
                                            />
                                          );
                                        }
                                      })}
                                    </div>
                                  </div>
                                </div>
                                <a
                                  className="carousel-control-prev"
                                  href="#bsSliderInter"
                                  role="button"
                                  data-slide="prev"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    width="29.437"
                                    height="50.216"
                                    viewBox="0 0 29.437 50.216"
                                  >
                                    <defs>
                                      <filter
                                        id="Path_2"
                                        x="0"
                                        y="0"
                                        width="29.437"
                                        height="50.216"
                                        filterUnits="userSpaceOnUse"
                                      >
                                        <feOffset dy="3" input="SourceAlpha"></feOffset>
                                        <feGaussianBlur
                                          stdDeviation="3"
                                          result="blur"
                                        ></feGaussianBlur>
                                        <feFlood flood-opacity="0.161"></feFlood>
                                        <feComposite
                                          operator="in"
                                          in2="blur"
                                        ></feComposite>
                                        <feComposite in="SourceGraphic"></feComposite>
                                      </filter>
                                    </defs>
                                    <g
                                      transform="matrix(1, 0, 0, 1, 0, 0)"
                                      filter="url(#Path_2)"
                                    >
                                      <path
                                        id="Path_2-2"
                                        data-name="Path 2"
                                        d="M-12300.5-6205.5l9.059,14.366-8,15.12"
                                        transform="translate(-12281.44 -6168.66) rotate(180)"
                                        fill="none"
                                        stroke="#ea0029"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                      ></path>
                                    </g>
                                  </svg>
                                </a>
                              </div>
                            ) : (
                              <div
                                style={{
                                  textAlign: 'center',
                                  padding: '10%',
                                }}
                              >
                                <span>لا توجد دورات بعد. الرجاء الاشتراك في واحدة</span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
      </>
    );
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Courses));
