import React, { Component } from 'react';
import actions from './store/actions';
import { MemoryRouter, Route, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Modal from '../../componenets/modal';
import Path2 from '../../assets/img/Path 2.png';
import stack from '../../assets/img/video icons/stack.png';
import Path1 from '../../assets/img/Path 1.png';
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import { host } from '../../utils/constants';
import ReactPlayer from 'react-player/youtube';
import { CircularProgress } from '@material-ui/core';
import Headers from '../../layouts/headers';

const mapStateToProps = (state) => ({
  isLoadingLibrary: state.LibraryReducer.isLoadingLibrary,
  library: state.LibraryReducer.library,
  params: state.LibraryReducer.params,
  isAuthorized: state.AppReducer.isAuthorized,
});

const mapDispatchToProps = (dispatch) => ({
  changeSearchedString: (params, type) => {
    dispatch(actions.changeSearchedString(params));
    dispatch(actions.getAllLibraries(params, type));
  },
  getLibrary: (id) => dispatch(actions.getLibrary(id)),
});
class Libraries extends Component {
  constructor(props) {
    super(props);
    document.title = 'المكتبة الالكترونية';
    props.getLibrary(props.match.params.id);
    this.state = {
      items: [],
      selectedIndex: 0,
    };
    this._TogglePrev = this._TogglePrev.bind(this);
    this._ToggleNext = this._ToggleNext.bind(this);
  }

  _ToggleNext(selectedIndex) {
    if (selectedIndex === this.state.items.length - 1) return;

    this.setState((prevState) => ({
      selectedIndex: selectedIndex + 1,
    }));
  }

  _TogglePrev(selectedIndex) {
    if (selectedIndex === 0) return;

    this.setState((prevState) => ({
      selectedIndex: selectedIndex - 1,
    }));
  }
  makeHttpRequestWithPage = (event, page) => {
    this.props.changeSearchedString(
      {
        ...this.props.params,
        currentPage: page ? page : this.props.params.currentPage - 1,
        limit: this.props.params.limit,
      },
      this.props.match.params.type,
    );
  };
  download(file) {
    // fake server request, getting the file url as response
    var link = document.createElement('a');
    link.href = file;
    link.download = file.substr(file.lastIndexOf('/') + 1);
    link.click();
  }
  render() {
    const { isLoadingLibrary, isAuthorized, library, params } = this.props;
    const { items, selectedIndex } = this.state;
    if (!library) {
      return (
        <CircularProgress
          style={{
            textAlign: 'center',
            marginTop: '17%',
            marginBottom: '25%',
            width: '40px',
            color: '#d85555',
            marginRight: '50%',
          }}
        />
      );
    }
    return (
      <section className="body-section">
        <Headers isAuthorized={isAuthorized} />
        <div
          className={
            library.type === 'videos'
              ? 'bs-main-container videos-ressources-body'
              : library.type === 'photos'
              ? 'bs-main-container photos-ressources-body'
              : 'bs-main-container books-ressources-body'
          }
        >
          <Modal
            id={
              library.type === 'videos'
                ? 'videoPlayerModal'
                : library.type === 'photos'
                ? 'photoPlayerModal'
                : 'booksPlayerModal'
            }
            data={
              items && items.length > 0 ? (
                library.type === 'videos' ? (
                  <div className="modal-body">
                    <div className="video-container">
                    <iframe
                        class="sproutvideo-player"
                        src={items[selectedIndex].path}
                        width="100%"
                        height="100%"
                        frameborder="0"
                        allowfullScreen
                      ></iframe>
                    </div>
                  </div>
                ) : library.type === 'photos' ? (
                  <>
                    <a
                      onClick={() => this._TogglePrev(selectedIndex)}
                      className="carousel-control-prev prev"
                      role="button"
                    >
                      <img src={Path2} alt="" />
                    </a>
                    <div className="modal-body">
                      <div className="photo-container">
                        <div className="photo-content">
                          <div className="carousel slide">
                            <div className="carousel-inner">
                              {items && items.length > 0 ? (
                                <div className="carousel-item active">
                                  <div className="photo-icon">
                                    <img
                                      style={{
                                        maxHeight: '585px',
                                        width: '100%',
                                      }}
                                      src={`${host}/public/uploads/libraries/${items[selectedIndex].library_id}/${items[selectedIndex].path}`}
                                      alt=""
                                    />
                                  </div>
                                </div>
                              ) : (
                                <div className="carousel-item">
                                  <div className="photo-icon">
                                    <img src={stack} alt="" />
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <a
                      onClick={() => this._ToggleNext(selectedIndex)}
                      className="carousel-control-next next"
                      role="button"
                    >
                      <img src={Path1} alt="" />
                    </a>
                  </>
                ) : library.type === 'books' ? (
                  <div className="modal-body">
                    <div className="modal-body-content">
                      <div className="pdf-icon-container">
                        <div className="pdf-icon-content">
                          <img
                            style={{
                              width: '90%',
                              marginRight: '4%',
                            }}
                            src={`${host}/public/uploads/libraries/${items[selectedIndex].library_id}/${items[selectedIndex].preview_image}`}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="bks-options-btns-container">
                        <a
                          className="btn btn-green"
                          target="_blank"
                          href={`${host}/public/uploads/libraries/${items[selectedIndex].library_id}/${items[selectedIndex].path}`}
                        >
                          معاينة الملف
                        </a>
                        <button
                          onClick={() =>
                            this.download(
                              `${host}/public/uploads/libraries/${items[selectedIndex].library_id}/${items[selectedIndex].path}`,
                            )
                          }
                          className="btn btn-green"
                        >
                          تنزيل الملف
                        </button>
                        {/*<a
                        download
                        className="btn btn-green"
                        //target="_blank"
                        href={`${host}/public/uploads/libraries/${items[selectedIndex].library_id}/${items[selectedIndex].path}`}
                      >
                        تنزيل الملف
                      </a>*/}
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )
              ) : (
                ''
              )
            }
          />
          <div className="bs-main-content">
            <div className="bs-main-content-header-sm-tr">
              <div className="bs-main-content-header-title-sm-tr">
                <span>{library.title}</span>
              </div>
            </div>
            <div className="cards-container">
              <div className="cards-wrapper">
                <div className="cardes-wrapper-content">
                  {isLoadingLibrary ? (
                    <div>isLoading</div>
                  ) : (
                    library &&
                    library.item_libraries &&
                    library.item_libraries.length > 0 &&
                    library.item_libraries.map((item) => {
                      return (
                        <div className="cards-wrapper-elem">
                          <button
                            onClick={() => {
                              this.setState({
                                items: library.item_libraries,
                                selectedIndex: 0,
                              });
                            }}
                            data-toggle="modal"
                            data-target={
                              library.type === 'videos'
                                ? '#videoPlayerModal'
                                : library.type === 'photos'
                                ? '#photoPlayerModal'
                                : '#booksPlayerModal'
                            }
                          >
                            <div className="training-program-card">
                              <div className="training-program-card-content">
                                <div className="training-program-card-miniature">
                                  {library.type === 'videos' ? (
                                    <img
                                      src={`${host}/public/uploads/libraries/${library.id}/${item.preview_image}`}
                                      alt=""
                                    />
                                  ) : library.type === 'photos' ? (
                                    <img
                                      src={`${host}/public/uploads/libraries/${library.id}/${item.path}`}
                                      alt=""
                                    />
                                  ) : (
                                    <img
                                      src={`${host}/public/uploads/libraries/${library.id}/${item.preview_image}`}
                                      alt=""
                                    />
                                  )}{' '}
                                </div>
                                <div className="training-program-card-info">
                                  <div className="training-program-card-info-elem course-card-title">
                                    <span style={{ fontSize: '18px' }}>
                                      {item.name && item.name.length > 55
                                        ? `${item.name.substring(0, 55)} ...`
                                        : item.name}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </button>
                        </div>
                      );
                    })
                  )}
                </div>
              </div>
            </div>
            <div className="pagination-container"></div>
          </div>
        </div>
      </section>
    );
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Libraries));
