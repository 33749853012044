import React, { Component } from 'react';
import actions from './store/actions';
import actionsJs from '../../js/actions';
import { MemoryRouter, Route, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Modal from '../../componenets/modal';
import Path2 from '../../assets/img/Path 2.png';
import stack from '../../assets/img/video icons/stack.png';
import Path1 from '../../assets/img/Path 1.png';
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import { host } from '../../utils/constants';
import ReactPlayer from 'react-player/youtube';
import { CircularProgress } from '@material-ui/core';
import Headers from '../../layouts/headers';
import ModalS from 'react-modal';

const mapStateToProps = (state) => ({
  isLoadingLibraries: state.LibraryReducer.isLoadingLibraries,
  libraries: state.LibraryReducer.libraries,
  params: state.LibraryReducer.params,
  isAuthorized: state.AppReducer.isAuthorized,
});

const mapDispatchToProps = (dispatch) => ({
  changeSearchedString: (params, type) => {
    dispatch(actions.changeSearchedString(params));
    dispatch(actions.getAllLibraries(params, type));
  },
  getAllLibraries: (params, type) => dispatch(actions.getAllLibraries(params, type)),
  submitSendEmailToAdmin: (values) => dispatch(actionsJs.submitSendEmailToAdmin(values)),
});
const customStyles = {
  content: {
    width: '40%',
    height: '40%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    padding: '20px',
    overflow: 'hidden',
    borderRadius: '15px',
  },
};
class Libraries extends Component {
  constructor(props) {
    super(props);
    document.title = 'المكتبة الالكترونية';
    props.getAllLibraries(props.params, props.match.params.type);
    this.state = {
      items: [],
      selectedIndex: 0,
      nameVideo: '',
      isOpenModal: false,
    };
    this._TogglePrev = this._TogglePrev.bind(this);
    this._ToggleNext = this._ToggleNext.bind(this);
    this.isOpenModal = this.isOpenModal.bind(this);
  }
  isOpenModal(isOpenModal) {
    this.setState({
      isOpenModal: !isOpenModal,
    });
  }

  _ToggleNext(selectedIndex) {
    if (selectedIndex === this.state.items.length - 1) return;

    this.setState((prevState) => ({
      selectedIndex: selectedIndex + 1,
    }));
  }

  _TogglePrev(selectedIndex) {
    if (selectedIndex === 0) return;

    this.setState((prevState) => ({
      selectedIndex: selectedIndex - 1,
    }));
  }
  makeHttpRequestWithPage = (event, page) => {
    this.props.changeSearchedString(
      {
        ...this.props.params,
        currentPage: page ? page : this.props.params.currentPage - 1,
        limit: this.props.params.limit,
      },
      this.props.match.params.type,
    );
  };
  download(file) {
    // fake server request, getting the file url as response
    var link = document.createElement('a');
    link.href = file;
    link.download = file.substr(file.lastIndexOf('/') + 1);
    link.click();
  }
  render() {
    const { isLoadingLibraries, isAuthorized, libraries, params } = this.props;
    const { items, selectedIndex, nameVideo, isOpenModal } = this.state;
    if (isLoadingLibraries) {
      return (
        <CircularProgress
          style={{
            textAlign: 'center',
            marginTop: '17%',
            marginBottom: '25%',
            width: '40px',
            color: '#d85555',
            marginRight: '50%',
          }}
        />
      );
    }
    return (
      <section className="body-section">
        <ModalS
          isOpen={isOpenModal}
          onRequestClose={this.isOpenModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div className="modal-header">
          <h5 style={{ color: 'black' }} className="modal-title">
          طلب فيديو من المسؤول
            </h5>
            <button
              onClick={() => this.isOpenModal(isOpenModal)}
              style={{
                justifyContent: 'flex-start',
                border: '0',
                backgroundColor: 'white',
              }}
              className=""
            >
              X
            </button>
          </div>

          <div className="modal-body">
            <div
              className="contact-us-form-row"
              style={{ alignItems: 'center'}}
            >
              <h5 style={{ color: 'black' }} className="modal-title">
                هل تريد فعلا طلب هذا الفيديو ؟
              </h5>
            </div>
          </div>
          <div className="modal-footer" style={{ display: 'block' }}>
            <button
              onClick={async () => {
                await this.props.submitSendEmailToAdmin({
                  training_name: null,
                  new_name: nameVideo,
                });
              }}
              style={{ width: '50%', fontSize: '15px' }}
              type="button"
              className="btn btn-success"
            >
              نعم
            </button>
            <button
              id="close-modal-confirmation"
              style={{ width: '40%', fontSize: '15px' }}
              type="button"
              className="btn btn-info"
              onClick={() => this.isOpenModal(isOpenModal)}
            >
              لا
            </button>
          </div>
        </ModalS>
        <Headers isAuthorized={isAuthorized} />
        <div
          className={
            this.props.match.params.type === 'videos'
              ? 'bs-main-container videos-ressources-body'
              : this.props.match.params.type === 'photos'
              ? 'bs-main-container photos-ressources-body'
              : 'bs-main-container books-ressources-body'
          }
        >
          <Modal
            id={
              this.props.match.params.type === 'videos'
                ? 'videoPlayerModal'
                : this.props.match.params.type === 'photos'
                ? 'photoPlayerModal'
                : 'booksPlayerModal'
            }
            data={
              items && items.length > 0 ? (
                this.props.match.params.type === 'videos' ? (
                  <div className="modal-body">
                    <div className="video-container">
                      <iframe
                        class="sproutvideo-player"
                        src={items[selectedIndex].path}
                        width="100%"
                        height="100%"
                        frameborder="0"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </div>
                ) : this.props.match.params.type === 'photos' ? (
                  <>
                    <a
                      onClick={() => this._TogglePrev(selectedIndex)}
                      className="carousel-control-prev prev"
                      role="button"
                    >
                      <img src={Path2} alt="" />
                    </a>
                    <div className="modal-body">
                      <div className="photo-container">
                        <div className="photo-content">
                          <div className="carousel slide">
                            <div className="carousel-inner">
                              {items && items.length > 0 ? (
                                <div className="carousel-item active">
                                  <div className="photo-icon">
                                    <img
                                      style={{
                                        maxHeight: '585px',
                                        width: '100%',
                                      }}
                                      src={`${host}/public/uploads/libraries/${items[selectedIndex].library_id}/${items[selectedIndex].path}`}
                                      alt=""
                                    />
                                  </div>
                                </div>
                              ) : (
                                <div className="carousel-item">
                                  <div className="photo-icon">
                                    <img src={stack} alt="" />
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <a
                      onClick={() => this._ToggleNext(selectedIndex)}
                      className="carousel-control-next next"
                      role="button"
                    >
                      <img src={Path1} alt="" />
                    </a>
                  </>
                ) : this.props.match.params.type === 'books' ? (
                  <div className="modal-body">
                    <div className="modal-body-content">
                      <div className="pdf-icon-container">
                        <div className="pdf-icon-content">
                          <img
                            style={{
                              width: '90%',
                              marginRight: '4%',
                            }}
                            src={`${host}/public/uploads/libraries/${items[selectedIndex].library_id}/${items[selectedIndex].preview_image}`}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="bks-options-btns-container">
                        <a
                          className="btn btn-green"
                          target="_blank"
                          href={`${host}/public/uploads/libraries/${items[selectedIndex].library_id}/${items[selectedIndex].path}`}
                        >
                          معاينة الملف
                        </a>
                        <button
                          onClick={() =>
                            this.download(
                              `${host}/public/uploads/libraries/${items[selectedIndex].library_id}/${items[selectedIndex].path}`,
                            )
                          }
                          className="btn btn-green"
                        >
                          تنزيل الملف
                        </button>
                        <a
                        download
                        className="btn btn-green"
                        //target="_blank"
                        href={`${host}/public/uploads/libraries/${items[selectedIndex].library_id}/${items[selectedIndex].path}`}
                      >
                        تنزيل الملف
                      </a>
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )
              ) : (
                ''
              )
            }
          />
          <div className="bs-main-content">
            <div className="bs-main-content-header-sm-tr">
              <div className="bs-main-content-header-title-sm-tr">
                <span>
                  {this.props.match.params.type === 'videos'
                    ? 'فيديوهات'
                    : this.props.match.params.type === 'photos'
                    ? 'بانوراما'
                    : 'المواد العلمية للدورات'}
                </span>
              </div>
              {this.props.match.params.type === 'videos' ? (
                <div
                  className="bs-main-content-header-desc-sm-tr"
                  style={{
                    textAlign: 'center',
                    padding: '0px 15%',
                  }}
                >
                  <span>
                    للاطلاع على المزيد من فيديوهات البرامج المسجلة.. يرجى التواصل معنا عبر
                    البريد الالكتروني{' '}
                    <a style={{ color: '#3a7eef' }} href="mailto:support@academyuaq.com">
                      support@academyuaq.com
                    </a>
                  </span>
                </div>
              ) : (
                ''
              )}
            </div>
            <div className="cards-container">
              <div className="cards-wrapper">
                <div className="cardes-wrapper-content">
                  {isLoadingLibraries ? (
                    <div>isLoading</div>
                  ) : (
                    libraries &&
                    libraries.data &&
                    libraries.data.length > 0 &&
                    libraries.data.map((item) => {
                      if (item.item_libraries && item.item_libraries.length > 0)
                        return (
                          <Link to={`/library-item/${item.id}`}>
                            <div className="training-program-card">
                              <div className="training-program-card-content">
                                <div className="training-program-card-miniature">
                                  {item.type === 'videos' ? (
                                    <img
                                      src={`${host}/public/uploads/libraries/${item.id}/${item.item_libraries[0].preview_image}`}
                                      alt=""
                                    />
                                  ) : item.type === 'photos' ? (
                                    <img
                                      src={`${host}/public/uploads/libraries/${item.id}/${item.item_libraries[0].path}`}
                                      alt=""
                                    />
                                  ) : (
                                    <img
                                      src={`${host}/public/uploads/libraries/${item.id}/${item.item_libraries[0].preview_image}`}
                                      alt=""
                                    />
                                  )}
                                </div>
                                <div className="training-program-card-info">
                                  <div className="training-program-card-info-elem course-card-title">
                                    <span style={{ fontSize: '18px' }}>
                                      {item.title && item.title.length > 55
                                        ? `${item.title.substring(0, 55)} ...`
                                        : item.title}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        );
                      else
                        return (
                          <div
                            onClick={() => {
                              this.setState({
                                nameVideo: item.title,
                              });
                              this.isOpenModal(isOpenModal);
                            }}
                            className="training-program-card"
                          >
                            <div className="training-program-card-content">
                              <div className="training-program-card-miniature">
                                {item.type === 'videos' ? (
                                  <img
                                    src={`${host}/public/uploads/libraries/placeholder_preview_image.jpg`}
                                    alt=""
                                  />
                                ) : item.type === 'photos' ? (
                                  <img
                                    src={`${host}/public/uploads/libraries/${item.id}/${item.item_libraries[0].path}`}
                                    alt=""
                                  />
                                ) : (
                                  <img
                                    src={`${host}/public/uploads/libraries/${item.id}/${item.item_libraries[0].preview_image}`}
                                    alt=""
                                  />
                                )}
                              </div>
                              <div className="training-program-card-info">
                                <div className="training-program-card-info-elem course-card-title">
                                  <span style={{ fontSize: '18px' }}>
                                    {item.title && item.title.length > 55
                                      ? `${item.title.substring(0, 55)} ...`
                                      : item.title}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                    })
                  )}
                </div>
              </div>
            </div>
            {/* {libraries.total > 70 ? (
              <div className="pagination-container">
                <div className="pagination-content">
                  <MemoryRouter initialEntries={['/inbox']}>
                    <Route>
                      {() => {
                        const page = parseInt(
                          libraries.current_page || 1,
                          Math.ceil(libraries.total / libraries.per_page),
                        );
                        return (
                          <Pagination
                            // color="secondary"
                            page={page}
                            //defaultPage={trainings.current_page}
                            // borderCount={5}
                            hidePrevButton
                            hideNextButton
                            count={Math.ceil(libraries.total / libraries.per_page)}
                            onChange={this.makeHttpRequestWithPage}
                            renderItem={(item) => (
                              <PaginationItem
                                className="pagination-content-elem last-pagination-number"
                                component={Link}
                                page={libraries.current_page}
                                to={`/inbox${
                                  item.page === 1 ? '' : `?page=${item.page}`
                                }`}
                                {...item}
                              />
                            )}
                          />
                        );
                      }}
                    </Route>
                  </MemoryRouter>
                </div>
              </div>
            ) : (
              <div className="pagination-container"></div>
            )} */}
          </div>
        </div>
      </section>
    );
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Libraries));
