import React, { Component } from 'react';
import actions from '../store/actions';
import SimpleReactValidator from 'simple-react-validator';
import { connect } from 'react-redux';
import { validate, validatePhone } from '../../../utils/helpers';

const mapStateToProps = (state, ownProps) => {
  const { certified_member } = ownProps;
  return {
    isLoadingNewMember: state.MemberReducer.isLoadingNewMember,
    newMember: state.MemberReducer.newMember,
    isLoading: state.MemberReducer.isLoading,
    initialValues: {
      certified_member_ship_id: certified_member.id,
      certified_member_type: certified_member.type,
    },
  };
};

const mapDispatchToProps = (dispatch) => ({
  submitMemberForm: (values) => dispatch(actions.submitMemberForm(values)),
});

class FormMember extends Component {
  constructor(props) {
    super(props);
    document.title = 'طلب مدرب معتمد';
    this.state = {
      initialValues: {
        ...props.initialValues,
        first_name: '',
        last_name: '',
        subject: '',
        phone: '',
        email: '',
        copy_identity: null,
        personal_photo: null,
        copy_passport: null,
        copy_institution_profile: null,
      },
      hasError: false,
    };
    this.validator = new SimpleReactValidator({
      messages: {
        email: 'Adresse e-mail invalide',
        min: 'Saisissez un mot de passe comprenant au moins 6 caractères.',
        max: 'Le mot de passe doit comporter de 6 à 20 caractères. ',
        // OR
        default: 'Champ requise.', // will override all messenger
      },
    });
  }

  handleChangeFilesElem = (event, name) => {
    const { initialValues } = this.state;
    event.target.files[0].name_file = name;
    this.setState((prevState) => {
      this.setState({
        initialValues: {
          ...initialValues,
          [name]: event.target.files[0],
        },
      });
    });
  };

  submitForm(initialValues) {
    if (
      !initialValues.first_name ||
      !initialValues.last_name ||
      !initialValues.subject ||
      !initialValues.email ||
      !initialValues.phone ||
      !validate(initialValues.first_name) ||
      !validatePhone(initialValues.phone) ||
      // !validate(initialValues.subject) ||
      !validate(initialValues.last_name)
    ) {
      this.setState({
        ...initialValues,
        hasError: true,
      });
    } else if (this.validator.allValid()) {
      this.props.submitMemberForm(initialValues);
    } else {
      this.setState({
        ...initialValues,
        hasError: true,
      });
      this.validator.showMessages();
      // rerender to show messenger for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  }

  render() {
    const { initialValues, hasError } = this.state;
    return (
      <div className="app-form--body">
        <form action="">
          <div className="form-row-group">
            <div className="user-profil-contact-form-row">
              <div className="input-field-label-container">
                <div className="input-field-label-content">
                  <label htmlFor="">الأسم الأول </label>
                </div>
              </div>
              <div
                className={
                  (hasError && !initialValues.first_name) ||
                  (hasError &&
                    initialValues.first_name &&
                    !validate(initialValues.first_name))
                    ? 'input-field-container input-error'
                    : 'input-field-container'
                }
              >
                <div className="input-field-content">
                  <input
                    name="first_name"
                    onChange={(e) => {
                      this.setState({
                        initialValues: {
                          ...initialValues,
                          first_name: e.target.value,
                        },
                      });
                    }}
                    required={this.validator.message(
                      'first_name',
                      initialValues.first_name,
                      'required|string',
                    )}
                    placeholder="الأسم الأول"
                    type="text"
                  />
                </div>
              </div>
            </div>
            <div className="user-profil-contact-form-row">
              <div className="input-field-label-container">
                <div className="input-field-label-content">
                  <label htmlFor="">الأسم الثاني</label>
                </div>
              </div>
              <div
                className={
                  (hasError && !initialValues.last_name) ||
                  (hasError &&
                    initialValues.last_name &&
                    !validate(initialValues.last_name))
                    ? 'input-field-container input-error'
                    : 'input-field-container'
                }
              >
                <div className="input-field-content">
                  <input
                    required={this.validator.message(
                      'last_name',
                      initialValues.last_name,
                      'required|string',
                    )}
                    name="last_name"
                    onChange={(e) => {
                      this.setState({
                        initialValues: {
                          ...initialValues,
                          last_name: e.target.value,
                        },
                      });
                    }}
                    type="text"
                    placeholder="الأسم الثاني"
                  />
                </div>
              </div>
            </div>
            <div className="user-profil-contact-form-row">
              <div className="input-field-label-container">
                <div className="input-field-label-content">
                  <label htmlFor="">البريد الإلكتروني</label>
                </div>
              </div>
              <div
                className={
                  (hasError && !initialValues.email) ||
                  (this.validator.message(
                    'email',
                    initialValues.email,
                    'required|email',
                  ) &&
                    this.validator.message('email', initialValues.email, 'required|email')
                      .type &&
                    initialValues.email)
                    ? 'input-field-container input-error'
                    : 'input-field-container'
                }
              >
                <div className="input-field-content">
                  <input
                    required={this.validator.message(
                      'email',
                      initialValues.email,
                      'required|email',
                    )}
                    name="email"
                    onChange={(e) => {
                      this.setState({
                        initialValues: {
                          ...initialValues,
                          email: e.target.value,
                        },
                      });
                    }}
                    type="text"
                    placeholder="البريد الإلكتروني"
                  />
                </div>
              </div>
            </div>
            <div className="user-profil-contact-form-row">
              <div className="input-field-label-container">
                <div className="input-field-label-content">
                  <label htmlFor="">الهاتف</label>
                </div>
              </div>
              <div
                className={
                  (hasError && !initialValues.phone) ||
                  (initialValues.phone && !validatePhone(initialValues.phone))
                    ? 'input-field-container input-error'
                    : 'input-field-container'
                }
              >
                <div className="input-field-content">
                  <input
                    required
                    name="phone"
                    onChange={(e) => {
                      this.setState({
                        initialValues: {
                          ...initialValues,
                          phone: e.target.value,
                        },
                      });
                    }}
                    type="text"
                    placeholder="الهاتف"
                  />
                </div>
              </div>
            </div>
            <div className="user-profil-contact-form-row">
              <div className="input-field-label-container">
                <div className="input-field-label-content">
                  <label htmlFor="">الموضوع</label>
                </div>
              </div>
              <div
                className={
                  hasError && !initialValues.subject
                    ? 'input-field-container input-error'
                    : 'input-field-container'
                }
              >
                <div className="input-field-content">
                  <input
                    name="subject"
                    onChange={(e) => {
                      this.setState({
                        initialValues: {
                          ...initialValues,
                          subject: e.target.value,
                        },
                      });
                    }}
                    required={this.validator.message(
                      'subject',
                      initialValues.subject,
                      'required|string',
                    )}
                    placeholder="الموضوع"
                    type="text"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="form-row-group">
            <div className="user-profil-contact-form-row">
              <div className="input-field-label-container">
                <div className="input-field-label-content">
                  <label htmlFor="">نسخة عن صورة الهوية</label>
                </div>
              </div>
              <div className="input-field-container">
                <div
                  style={{ display: initialValues.copy_identity ? 'none' : '' }}
                  className="input-field-content"
                >
                  <label
                    className="input-field-content"
                    htmlFor="file-1"
                    style={{
                      cursor: 'pointer',
                      width: '100%',
                      height: '100%',
                      padding: '7px',
                    }}
                  ></label>
                </div>
                <input
                  accept="image/png, image/jpeg, image/png, image/gif"
                  onChange={(e) => this.handleChangeFilesElem(e, 'copy_identity')}
                  type="file"
                  name="copy_identity"
                  id="file-1"
                  hidden
                />
                <div
                  style={{ display: !initialValues.copy_identity ? 'none' : 'flex' }}
                  className="input-field-content"
                >
                  <input
                    disabled={true}
                    value={
                      initialValues.copy_identity ? initialValues.copy_identity.name : ''
                    }
                    type="text"
                  />
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      this.setState({
                        initialValues: {
                          ...initialValues,
                          copy_identity: '',
                        },
                      });
                    }}
                  >
                    X
                  </span>
                </div>
              </div>
            </div>
            <div className="user-profil-contact-form-row">
              <div className="input-field-label-container">
                <div className="input-field-label-content">
                  <label htmlFor="">نسخة عن الصورة الشخصية</label>
                </div>
              </div>
              <div className="input-field-container">
                <div
                  style={{ display: initialValues.personal_photo ? 'none' : '' }}
                  className="input-field-content"
                >
                  <label
                    className="input-field-content"
                    htmlFor="personal_photo-1"
                    style={{
                      cursor: 'pointer',
                      width: '100%',
                      height: '100%',
                      padding: '7px',
                    }}
                  ></label>
                </div>
                <input
                  accept="image/png, image/jpeg, image/png, image/gif"
                  onChange={(e) => {
                    this.handleChangeFilesElem(e, 'personal_photo');
                  }}
                  type="file"
                  name="personal_photo"
                  id="personal_photo-1"
                  hidden
                />
                <div
                  style={{ display: !initialValues.personal_photo ? 'none' : 'flex' }}
                  className="input-field-content"
                >
                  <input
                    disabled={true}
                    value={
                      initialValues.personal_photo
                        ? initialValues.personal_photo.name
                        : ''
                    }
                    type="text"
                  />
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      this.setState({
                        initialValues: {
                          ...initialValues,
                          personal_photo: '',
                        },
                      });
                    }}
                  >
                    X
                  </span>
                </div>
              </div>
            </div>
            <div className="user-profil-contact-form-row">
              <div className="input-field-label-container">
                <div className="input-field-label-content">
                  <label htmlFor="">نسخة عن جواز السفر</label>
                </div>
              </div>
              <div className="input-field-container">
                <div
                  style={{ display: initialValues.copy_passport ? 'none' : '' }}
                  className="input-field-content"
                >
                  <label
                    className="input-field-content"
                    htmlFor="copy_passport-1"
                    style={{
                      cursor: 'pointer',
                      width: '100%',
                      height: '100%',
                      padding: '7px',
                    }}
                  ></label>
                </div>
                <input
                  accept="image/png, image/jpeg, image/png, image/gif"
                  onChange={(e) => this.handleChangeFilesElem(e, 'copy_passport')}
                  type="file"
                  name="copy_passport"
                  id="copy_passport-1"
                  hidden
                />
                <div
                  style={{ display: !initialValues.copy_passport ? 'none' : 'flex' }}
                  className="input-field-content"
                >
                  <input
                    disabled={true}
                    value={
                      initialValues.copy_passport ? initialValues.copy_passport.name : ''
                    }
                    type="text"
                  />
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      this.setState({
                        initialValues: {
                          ...initialValues,
                          copy_passport: '',
                        },
                      });
                    }}
                  >
                    X
                  </span>
                </div>
              </div>
            </div>
            {initialValues.certified_member_type !== 1 ? (
              <div className="user-profil-contact-form-row">
                <div className="input-field-label-container">
                  <div className="input-field-label-content">
                    <label htmlFor="">نسخة عن الملف التعريفي للمؤسسة</label>
                  </div>
                </div>
                <div className="input-field-container">
                  <div
                    style={{
                      display: initialValues.copy_institution_profile ? 'none' : '',
                    }}
                    className="input-field-content"
                  >
                    <label
                      className="input-field-content"
                      htmlFor="copy_institution_profile-1"
                      style={{
                        cursor: 'pointer',
                        width: '100%',
                        height: '100%',
                        padding: '7px',
                      }}
                    ></label>
                  </div>
                  <input
                    accept="image/png, image/jpeg, image/png, image/gif"
                    onChange={(e) =>
                      this.handleChangeFilesElem(e, 'copy_institution_profile')
                    }
                    type="file"
                    name="copy_institution_profile"
                    id="copy_institution_profile-1"
                    hidden
                  />
                  <div
                    style={{
                      display: !initialValues.copy_institution_profile ? 'none' : 'flex',
                    }}
                    className="input-field-content"
                  >
                    <input
                      disabled={true}
                      value={
                        initialValues.copy_institution_profile
                          ? initialValues.copy_institution_profile.name
                          : ''
                      }
                      type="text"
                    />
                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        this.setState({
                          initialValues: {
                            ...initialValues,
                            copy_institution_profile: '',
                          },
                        });
                      }}
                    >
                      X
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>

          <div className="user-profil-contact-form-row">
            <button
              onClick={() => this.submitForm(initialValues)}
              type="button"
              className="btn btn-green"
            >
              تاكيد
            </button>
          </div>
        </form>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(FormMember);
