//export const host = 'https://fakademy.com:8002';
export const host = "https://ootb-api.amitechss.com";
//export const host = "http://212.71.247.250:8100";

export const itemCourses = {
  0: 'الباب الأول',
  1: 'الباب الثاني',
  2: 'الباب الثالث',
  3: 'الباب الرابع',
  4: 'الباب الخامس',
  5: 'الباب  السادس',
  6: 'الباب السابع',
  7: 'الباب الثامن',
  8: 'الباب التاسع',
  9: 'الباب العاشر',
  10: 'الباب الحادي عشر',
  11: 'الباب الثاني عشر',
  12: 'الباب الثالث عشر',
  13: 'الباب الرابع عشر',
  14: 'الباب الخامس عشر',
  15: 'الباب السادس عشر',
  16: 'الباب السابع عشر',
  17: 'الباب الثامن عشر',
};

export const questionsStatic = [
  {
    id: 1,
    content: 'أهداف ومحاور البرنامج التدريبي واضحة بالنسبة لي',
    question: 'أهداف ومحاور البرنامج التدريبي واضحة بالنسبة لي',
    answer: 'موافق تماماً',
    score: 1,
    order: 1,
    type: 1,
    propositions: [
      {
        id: 4,
        content: 'موافق تماماً',
        answer: true,
      },
      {
        id: 2,
        content: 'موافق',
        answer: false,
      },
      {
        id: 3,
        content: 'غير موافق',
        answer: false,
      },
      {
        id: 4,
        content: 'غير موافق تماماً',
        answer: false,
      },
    ],
  },
  {
    type: 0,
    id: 2,
    content: 'كانت مدة التدريب ملائمة لمحتويات البرنامج',
    question: 'كانت مدة التدريب ملائمة لمحتويات البرنامج',
    answer: 'موافق تماما',
    score: 1,
    order: 2,
    propositions: [
      {
        id: 1,
        content: 'موافق تماماً',
        answer: false,
      },
      {
        id: 2,
        content: 'موافق',
        answer: false,
      },
      {
        id: 3,
        content: 'غير موافق',
        answer: false,
      },
      {
        id: 4,
        content: 'غير موافق تماماً',
        answer: true,
      },
    ],
  },
  {
    type: 0,
    id: 3,
    content: 'ترتيب محتويات الدورة والأنشطة ملائماً ومنطقياً',
    question: 'ترتيب محتويات الدورة والأنشطة ملائماً ومنطقياً',
    answer: 'موافق تماما',
    score: 1,
    order: 3,
    propositions: [
      {
        id: 1,
        content: 'موافق تماماً',
        answer: true,
      },
      {
        id: 2,
        content: 'موافق',
        answer: false,
      },
      {
        id: 3,
        content: 'غير موافق',
        answer: false,
      },
      {
        id: 4,
        content: 'غير موافق تماماً',
        answer: false,
      },
    ],
  },
  {
    type: 0,
    id: 4,
    content: 'يمكنني استخدام المادة التعليمية كأداة داعمة للتطبيق في العمل',
    question: 'يمكنني استخدام المادة التعليمية كأداة داعمة للتطبيق في العمل',
    answer: 'موافق تماماً',
    score: 1,
    order: 4,
    propositions: [
      {
        id: 279,
        content: 'موافق تماماً',
        answer: false,
      },
      {
        id: 280,
        content: 'موافق',
        answer: false,
      },
      {
        id: 281,
        content: 'غير موافق',
        answer: false,
      },
      {
        id: 282,
        content: 'غير موافق تماماً',
        answer: true,
      },
    ],
  },
  {
    type: 0,
    id: 5,
    content: 'ساعدتني مادة العرض والإرشادات على فهم محتويات الدورة',
    question: 'ساعدتني مادة العرض والإرشادات على فهم محتويات الدورة',
    answer: 'موافق تماما',
    score: 1,
    order: 5,
    propositions: [
      {
        id: 283,
        content: 'موافق تماماً',
        answer: true,
      },
      {
        id: 284,
        content: 'موافق',
        answer: false,
      },
      {
        id: 285,
        content: 'غير موافق',
        answer: false,
      },
      {
        id: 286,
        content: 'غير موافق تماماً',
        answer: false,
      },
    ],
  },
  {
    type: 0,
    id: 6,
    content: ' مادة العرض واضحة بالنسبة لي',
    question: ' مادة العرض واضحة بالنسبة لي',
    answer: 'موافق تماما',
    score: 1,
    order: 6,
    propositions: [
      {
        id: 287,
        content: 'موافق تماماً',
        answer: false,
      },
      {
        id: 288,
        content: 'موافق',
        answer: false,
      },
      {
        id: 289,
        content: 'غير موافق',
        answer: false,
      },
      {
        id: 290,
        content: 'غير موافق تماماً',
        answer: true,
      },
    ],
  },
  {
    type: 0,
    id: 7,
    content: 'محاور وأهداف الدورة مرتبطة بعملي',
    question: 'محاور وأهداف الدورة مرتبطة بعملي',
    answer: 'موافق تماما',
    score: 1,
    order: 7,
    propositions: [
      {
        id: 291,
        content: 'موافق تماماً',
        answer: true,
      },
      {
        id: 292,
        content: 'موافق',
        answer: false,
      },
      {
        id: 293,
        content: 'غير موافق',
        answer: false,
      },
      {
        id: 294,
        content: 'غير موافق تماماً',
        answer: false,
      },
    ],
  },
  {
    type: 0,
    id: 8,
    content: 'هذا التدريب استثمار جيد لي ومؤسستي',
    question: 'هذا التدريب استثمار جيد لي ومؤسستي',
    answer: 'موافق تماما',
    score: 1,
    order: 8,
    propositions: [
      {
        id: 295,
        content: 'موافق تماماً',
        answer: false,
      },
      {
        id: 296,
        content: 'موافق',
        answer: false,
      },
      {
        id: 297,
        content: 'غير موافق',
        answer: false,
      },
      {
        id: 298,
        content: 'غير موافق تماماً',
        answer: true,
      },
    ],
  },
  {
    type: 0,
    id: 9,
    content: 'هل توافق على وظائف النظام من حيث الأداء والفاعلية',
    question: 'هل توافق على وظائف النظام من حيث الأداء والفاعلية',
    answer: 'موافق تماما',
    score: 1,
    order: 9,
    propositions: [
      {
        id: 307,
        content: 'موافق تماماً',
        answer: true,
      },
      {
        id: 308,
        content: 'موافق',
        answer: false,
      },
      {
        id: 309,
        content: 'غير موافق',
        answer: false,
      },
      {
        id: 310,
        content: 'غير موافق تماماً',
        answer: false,
      },
    ],
  },
  {
    type: 0,
    id: 10,
    content: 'ماهو مدى رضاك على تصميم واجهة المستخدم لهذا النظام',
    question: 'ماهو مدى رضاك على تصميم واجهة المستخدم لهذا النظام',
    answer: 'موافق تماما',
    score: 1,
    order: 10,
    propositions: [
      {
        id: 315,
        content: 'موافق تماماً',
        answer: true,
      },
      {
        id: 316,
        content: 'موافق',
        answer: false,
      },
      {
        id: 317,
        content: 'غير موافق',
        answer: false,
      },
      {
        id: 318,
        content: 'غير موافق تماماً',
        answer: false,
      },
    ],
  },
];
