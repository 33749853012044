import React, { Component } from 'react';
import TrainingTime from '../composents/training-time';
import ApexChart from './chartCircel';
import { CircularProgress } from '@material-ui/core';

class ReportsTest extends Component {
  render() {
    const { mostAttendedCourses, isLoadingCountUser, countCourse } = this.props;
    if (isLoadingCountUser) {
      return (
        <CircularProgress
          style={{
            textAlign: 'center',
            marginTop: '14%',
            width: '40px',
            color: '#d85555',
            marginRight: '50%',
          }}
        />
      );
    }
    return (
      <div className="dashboard-content">
        <div className="dashboard-column left-sect-dshb">
          <div className="dashboard-column-content">
            <div className="dashboard-column-elem admin-dshb-courses-progress-info">
              <div className="admin-dshb-courses-progress-info-content">
                <div className="admin-dshb-courses-progress-info-title">
                  <span>معدل إتمام الدورات</span>
                </div>
                <div className="admin-dshb-courses-progress-info-body">
                  <div className="admin-dashboard-view-stats-pie-chart-body">
                    <div className="admin-dashboard-view-stats-actual-pie-chart-legend">
                      <div className="admin-dashboard-view-stats-actual-pie-chart">
                        {/* <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="196.302"
                          height="196.279"
                          viewBox="0 0 196.302 196.279"
                        >
                          <g
                            id="Group_1760"
                            data-name="Group 1760"
                            transform="translate(0 0.5)"
                          >
                            <g
                              id="Group_438"
                              data-name="Group 438"
                              transform="translate(0 0)"
                            >
                              <g
                                id="Group_436"
                                data-name="Group 436"
                                transform="translate(0 0.002)"
                              >
                                <path
                                  id="Path_242"
                                  data-name="Path 242"
                                  d="M229.87,3258.218a97.889,97.889,0,1,0,97.889,97.889A97.889,97.889,0,0,0,229.87,3258.218Zm0,172.108a74.219,74.219,0,1,1,74.219-74.219A74.219,74.219,0,0,1,229.87,3430.326Z"
                                  transform="translate(-131.981 -3258.218)"
                                ></path>
                              </g>
                              <g
                                id="Group_437"
                                data-name="Group 437"
                                transform="translate(0.109 60.521)"
                              >
                                <path
                                  id="Path_244"
                                  data-name="Path 244"
                                  d="M132.068,3339.751l23.648.839a73.854,73.854,0,0,1,5.621-25.112l-21.974-8.794A97.382,97.382,0,0,0,132.068,3339.751Z"
                                  transform="translate(-132.068 -3306.683)"
                                  fill="#00732f"
                                ></path>
                              </g>
                            </g>
                            <path
                              id="Path_245"
                              data-name="Path 245"
                              d="M137.91,3318.63s20.309-54.165,80.14-59.977l2.164,23.575s-43.463,4.9-60.33,45.2Z"
                              transform="translate(-130.507 -3258.109)"
                              fill="#5a5a5a"
                            ></path>
                          </g>
                        </svg>
                        <span>35%</span>*/}
                        {isLoadingCountUser ? (
                          ''
                        ) : (
                          <ApexChart
                            countTrainingIsFinish={
                              countCourse && countCourse.countTrainingIsFinish
                                ? `${parseInt(
                                    (countCourse.countTrainingIsFinish /
                                      countCourse.countCourseExam) *
                                      100,
                                  )}`
                                : 0
                            }
                            countTrainingIsStart={
                              countCourse && countCourse.countTrainingIsStart
                                ? `${parseInt(
                                    (countCourse.countTrainingIsStart /
                                      countCourse.countCourseExam) *
                                      100,
                                  )}`
                                : 0
                            }
                            countTrainingIsProgress={
                              countCourse && countCourse.countTrainingIsProgress
                                ? `${parseInt(
                                    (countCourse.countTrainingIsProgress /
                                      countCourse.countCourseExam) *
                                      100,
                                  )}`
                                : 0
                            }
                            countCourse={countCourse}
                          />
                        )}
                      </div>
                      <div className="admin-dashboard-view-stats-actual-pie-chart-legend-elem-col">
                        <div className="admin-dashboard-view-stats-actual-pie-chart-legend-elem --black-ver-sect">
                          <div className="pie-chart-legend-color --black-ver"></div>
                          <span>إكمال</span>
                          <span>
                            {countCourse && countCourse.countTrainingIsFinish
                              ? `${parseInt(
                                  (countCourse.countTrainingIsFinish /
                                    countCourse.countCourseExam) *
                                    100,
                                )}%`
                              : 0}
                          </span>
                        </div>
                        <div className="admin-dashboard-view-stats-actual-pie-chart-legend-elem --green-ver-sect">
                          <div className="pie-chart-legend-color --green-ver"></div>
                          <span> لم تبدأ</span>
                          <span>
                            {countCourse && countCourse.countTrainingIsStart
                              ? `${parseInt(
                                  (countCourse.countTrainingIsStart /
                                    countCourse.countCourseExam) *
                                    100,
                                )}%`
                              : 0}
                          </span>
                        </div>
                        <div className="admin-dashboard-view-stats-actual-pie-chart-legend-elem --gray-ver-sect">
                          <div className="pie-chart-legend-color --gray-ver"></div>
                          <span>في تقدم</span>
                          <span>
                            {countCourse && countCourse.countTrainingIsProgress
                              ? `${parseInt(
                                  (countCourse.countTrainingIsProgress /
                                    countCourse.countCourseExam) *
                                    100,
                                )}%`
                              : 0}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="dashboard-column-elem -admin-dshb--ups">
              <div className="admin-dshb-users-progress">
                <div className="admin-dshb-users-progress-content">
                  <div className="admin-dshb-users-progress-sect">
                    <div className="admin-dshb-users-progress-sect-content">
                      <div className="admin-dshb-users-progress-sect-elem">
                        <span> إجمالي عدد التقييم</span>
                        <div className="admin-dshb-users-progress-inNum">
                          <span>
                            {countCourse && countCourse.countRatingCourse
                              ? countCourse.countRatingCourse.total_value
                              : 0}
                          </span>
                        </div>
                      </div>
                      <div className="admin-dshb-users-progress-sect-elem">
                        <span> إجمالي عدد الشهادات</span>
                        <div className="admin-dshb-users-progress-inNum">
                          <span>
                            {countCourse && countCourse.countCertification
                              ? countCourse.countCertification
                              : 0}
                          </span>
                        </div>
                      </div>
                      <div className="admin-dshb-users-progress-sect-elem">
                        <span> إجمالي عدد الاشتراكات</span>
                        <div className="admin-dshb-users-progress-inNum">
                          <span>
                            {countCourse && countCourse.countUserCourse
                              ? countCourse.countUserCourse
                              : 0}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="dashboard-column right-sect-dshbd">
          <div className="dashboard-column-content">
            <div className="dashboard-column-elem">
              <div className="dashboard-column-elem-content">
                <div className="dashboard-stats-content">
                  <div className="user-types-access--stats">
                    <div className="user-types-access--stats-content">
                      <div className="user-types-access--stats-elem">
                        <span>إدارة النظام</span>
                        <div className="user-types-access-actual-stat">
                          <div className="stat-number-label">
                            <span>
                              {countCourse && countCourse.countAdmin
                                ? countCourse.countAdmin
                                : 0}
                            </span>
                          </div>
                          <div className="user-types-access-actual-stat-content">
                            <div className="user-types-access-actual-stat-icon-cont">
                              <svg
                                id="Group_793"
                                data-name="Group 793"
                                xmlns="http://www.w3.org/2000/svg"
                                width="23.566"
                                height="49.168"
                                viewBox="0 0 23.566 49.168"
                              >
                                <path
                                  id="Path_366"
                                  data-name="Path 366"
                                  d="M1259.477,1847.5v-2.551l.9-.044V1829.33l-.43-.118-.176-5.521-.086-.015-1.62,5.4-1.292-.451v1.065h1.72v11.316h-4.273v-11.281h1.594c0-.473.017-.9-.013-1.32-.007-.1-.162-.213-.274-.264-.3-.136-.622-.241-.973-.374.051-.167.089-.316.142-.46q2.383-6.45,4.762-12.9a.682.682,0,0,1,.592-.51c1.118-.192,2.237-.386,3.347-.619a.574.574,0,0,1,.644.245c.763.9,1.54,1.794,2.359,2.744.307-.362.592-.7.874-1.032.493-.589.972-1.189,1.486-1.76a.66.66,0,0,1,.509-.185c.962.154,1.921.331,2.874.537a.8.8,0,0,1,.519.38q2.548,6.465,5.057,12.944c.023.058.035.121.067.232l-3.506,1.322-1.635-5.18-.1.017c0,.614.012,1.228,0,1.842-.028,1.184-.064,2.367-.115,3.549-.005.116-.148.221-.206.342a1.21,1.21,0,0,0-.159.436,26.5,26.5,0,0,0,.42,5.827,15.147,15.147,0,0,1-.183,5.612c-.023.109-.039.22-.051.331a1,1,0,0,0,.02.173l.811.067v2.489h-6.76v-2.458c.034-.021.047-.035.062-.037.916-.118.916-.117,1.136-1.032a2.971,2.971,0,0,1,.113-.465c.731-1.731.249-3.454-.025-5.178-.142-.9-.289-1.8-.46-2.694a.458.458,0,0,0-.3-.307c-.475-.034-.954-.015-1.46-.015v12.891h.9v2.583Z"
                                  transform="translate(-1254.224 -1798.33)"
                                />
                                <path
                                  id="Path_367"
                                  data-name="Path 367"
                                  d="M1266.294,1812.166a6.918,6.918,0,1,1,6.915-6.931A6.91,6.91,0,0,1,1266.294,1812.166Z"
                                  transform="translate(-1254.224 -1798.33)"
                                />
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="user-types-access--stats-elem">
                        <span>الدوائر الحكومية</span>
                        <div className="user-types-access-actual-stat">
                          <div className="stat-number-label">
                            <span>
                              {countCourse && countCourse.countSectors
                                ? countCourse.countSectors
                                : 0}
                            </span>
                          </div>
                          <div className="user-types-access-actual-stat-content">
                            <div className="user-types-access-actual-stat-icon-cont">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="48.944"
                                height="41.988"
                                viewBox="0 0 48.944 41.988"
                              >
                                <g
                                  id="Group_798"
                                  data-name="Group 798"
                                  transform="translate(-21.72 -41.616)"
                                >
                                  <path
                                    id="Path_373"
                                    data-name="Path 373"
                                    d="M934.019,1832.04h1.576v-10.091h6.036v10.034c.378.033.386.033.387-.311,0-.938,0-1.877,0-2.815v-7.348h-2.814v-4.381h7.3v.59q0,6.868,0,13.739a2.091,2.091,0,0,0,.014.427c.013.063.111.131.182.149.037.01.126-.078.145-.137a.989.989,0,0,0,.016-.284q0-7.251,0-14.5c0-.124-.017-.248-.03-.416h-3.419v-3.1h7.273v7.088h-3.07V1832l.1.084a1.264,1.264,0,0,0,.229-.286.934.934,0,0,0,.009-.331v-10.334h6.314c.009.189.026.372.026.555q0,4.937.006,9.875c0,.193-.139.532.329.461V1820.7h-3.474v-7.982h7.494v8.82H956.1v10.486l.162.031a2.328,2.328,0,0,0,.089-.444c.006-1.844,0-3.689,0-5.534v-4.137h6.008v10.093h2.923v1.7H934.638c-.206,0-.413.012-.619.018Z"
                                    transform="translate(-912.299 -1762.173)"
                                  />
                                  <path
                                    id="Path_374"
                                    data-name="Path 374"
                                    d="M982.918,1809.425a6.362,6.362,0,0,1-1.266,2.617,4.961,4.961,0,0,1-7.413-.02,4.943,4.943,0,1,1,7.94-5.819,15.983,15.983,0,0,1,.739,1.886Z"
                                    transform="translate(-912.299 -1762.173)"
                                  />
                                  <path
                                    id="Path_375"
                                    data-name="Path 375"
                                    d="M980.05,1815.981l-3.9-1-1.851,7.331-.145-.007v-7.813h6.955c.008.15.023.317.023.485q0,7.035.009,14.071c0,.379-.062.62-.545.609.036.782.074,1.54.1,2.3.045,1.081.135,2.162.117,3.241a20.847,20.847,0,0,0,1.084,5.392c.358,1.442.7,2.89,1.058,4.4a4.885,4.885,0,0,1-1.612.241c-1.084-.246-1.937.561-2.954.505a2.543,2.543,0,0,0-.4.044l-.246-1.5,1.857-.723-1.817-7h.935l.411-6.667a15.794,15.794,0,0,1-2.944-.29c.418-.024.508-.262.59-.59q1.594-6.4,3.216-12.792C980.013,1816.156,980.024,1816.1,980.05,1815.981Z"
                                    transform="translate(-912.299 -1762.173)"
                                  />
                                  <path
                                    id="Path_376"
                                    data-name="Path 376"
                                    d="M970.246,1829.732l3.118.213c.255-.489-.24-1.26.8-1.427l-.74-.24c1.065-4.223,2.126-8.432,3.194-12.669l2.846.743-3.176,12.625-.447.02-.078,1.075,2.833.184-.333,5.915-8.33-.552Zm4.844-.373-.9-.065-.049.536.929.047Z"
                                    transform="translate(-912.299 -1762.173)"
                                  />
                                  <path
                                    id="Path_377"
                                    data-name="Path 377"
                                    d="M974.295,1845.516a3.63,3.63,0,0,1-1.223-.15c-1.064-.523-2.207-.072-3.276-.362l-.27-.073c.043-.43.067-.856.142-1.273a.442.442,0,0,1,.319-.246c.535-.07,1.074-.1,1.666-.154l1.468-7.019,3.055.221Z"
                                    transform="translate(-912.299 -1762.173)"
                                  />
                                </g>
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="user-types-access--stats-elem">
                        <span>المشاركون في البرامج</span>
                        <div className="user-types-access-actual-stat">
                          <div className="stat-number-label">
                            <span>
                              {' '}
                              {countCourse && countCourse.countUser
                                ? countCourse.countUser
                                : 0}
                            </span>
                          </div>
                          <div className="user-types-access-actual-stat-content">
                            <div className="user-types-access-actual-stat-icon-cont">
                              <svg
                                id="Group_796"
                                data-name="Group 796"
                                xmlns="http://www.w3.org/2000/svg"
                                width="23.118"
                                height="47.256"
                                viewBox="0 0 23.118 47.256"
                              >
                                <path
                                  id="Path_368"
                                  data-name="Path 368"
                                  d="M660.952,1799.432a.987.987,0,0,1,.655,1.082c-.023,1.443-.01,2.887-.006,4.331a.85.85,0,0,1-.481.869.778.778,0,0,1-1.092-.779c-.016-1.121-.005-2.242-.006-3.364v-.531h-.706v3.088h-8.778v-3.134a10.47,10.47,0,0,1-1.542-.022,1.116,1.116,0,0,1-.7-.483.93.93,0,0,1,.094-.717c.073-.153.307-.229.469-.34Z"
                                  transform="translate(-643.761 -1799.432)"
                                />
                                <path
                                  id="Path_369"
                                  data-name="Path 369"
                                  d="M660.836,1822.45q.121,2.415.244,4.831.256,4.905.517,9.811c.027.506.064,1.013.083,1.52.036.922-.29,1.279-1.22,1.355-.058.005-.115.022-.225.044v.5c0,1.245.007,2.491,0,3.737a2.272,2.272,0,1,1-4.531-.03c-.008-1.23,0-2.46,0-3.691V1840h-1.632c-.007.164-.021.327-.021.489-.005,1.353.02,2.707-.021,4.06a2.085,2.085,0,0,1-1.424,1.967,2.145,2.145,0,0,1-2.39-.476,2.264,2.264,0,0,1-.7-1.745q0-1.891,0-3.783v-.518c-.119-.011-.192-.021-.265-.025a1.083,1.083,0,0,1-1.157-1.317c.108-1.981.211-3.961.322-5.941q.211-3.753.434-7.506c.005-.091.015-.181.028-.329.724.146,1.423.284,2.12.429a3.248,3.248,0,0,0,3.495-1.3.961.961,0,0,1,.4-.284c.655-.263,1.321-.5,1.978-.759a2.565,2.565,0,0,0,1.578-3.509q-1.176-3.125-2.387-6.236a2.562,2.562,0,0,0-3.518-1.551c-1.121.419-2.237.854-3.356,1.28-.138.053-.28.1-.535.182.361-.5.641-.919.947-1.316a1.893,1.893,0,0,1,1.537-.721c2.491-.021,4.983-.028,7.474,0a2.024,2.024,0,0,1,1.927,1.323q3.024,6.695,6.1,13.369a1.988,1.988,0,0,1-.02,1.98,1.88,1.88,0,0,1-3.307-.18c-.521-1.095-1.014-2.2-1.519-3.306q-.42-.918-.841-1.835Z"
                                  transform="translate(-643.761 -1799.432)"
                                />
                                <path
                                  id="Path_370"
                                  data-name="Path 370"
                                  d="M655.089,1822a3.417,3.417,0,0,0-3.12-3.216c-.558-.095-1.109-.237-1.663-.36-.39-.085-.786-.151-1.165-.27a.741.741,0,0,1-.419-.338c-.232-.517-.424-1.052-.615-1.587a1.06,1.06,0,0,1,.68-1.512q2.163-.849,4.34-1.66a1.056,1.056,0,0,1,1.51.686q1.2,3.109,2.379,6.231a1.083,1.083,0,0,1-.709,1.574C655.912,1821.709,655.508,1821.848,655.089,1822Z"
                                  transform="translate(-643.761 -1799.432)"
                                />
                                <path
                                  id="Path_371"
                                  data-name="Path 371"
                                  d="M646.505,1816.288c.348.908.674,1.8,1.042,2.679a.778.778,0,0,0,.474.393c1.284.3,2.578.57,3.866.856a1.918,1.918,0,0,1,1.585,2.488,1.828,1.828,0,0,1-2.1,1.239q-2.976-.621-5.946-1.265a1.914,1.914,0,0,1-1.243-3.112c.733-1.08,1.488-2.146,2.235-3.217A.337.337,0,0,1,646.505,1816.288Z"
                                  transform="translate(-643.761 -1799.432)"
                                />
                                <path
                                  id="Path_372"
                                  data-name="Path 372"
                                  d="M659.295,1805.551a4.349,4.349,0,0,1-2.455,4.167,4.182,4.182,0,0,1-4.6-.413,4.262,4.262,0,0,1-1.76-3.754Z"
                                  transform="translate(-643.761 -1799.432)"
                                />
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <TrainingTime mostAttendedCourses={mostAttendedCourses} />
          </div>
        </div>
      </div>
    );
  }
}

export default ReportsTest;
