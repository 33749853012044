import React, { Component } from 'react';
import video from '../../../assets/video_academyuaq.mp4';
import previewImage from "../../../assets/img/slider_video_image.jpeg";
import Modal from '../../../componenets/modal';


class SliderHeader extends Component {
  render() {
    return (
      <>
      <Modal
            id={'videoPlayerModal'}
            data={
              (
                <div className="modal-body">
                    <div className="video-container">
                    <iframe
                        class="sproutvideo-player"
                        src={'https://videos.sproutvideo.com/embed/ea9ddab41e1ee1c263/e3ffad0e73d1662d'}
                        width="100%"
                        height="100%"
                        frameborder="0"
                        allowfullScreen
                      ></iframe>
                    </div>
                  </div>
              )
            }
          />
      <div className="hs-slider-container">
        <div className="hs-slider-content">
          <div className="carousel slide" data-ride="carousel">
            <div className="carousel-inner">
              <div className="carousel-item active">
                    <div
                      style={{
                        width: '100%',
                        height: '100%',
                      }}
                    >
                                <a
                            data-toggle="modal"
                            data-target={'#videoPlayerModal'
                            }
                          ><img src={previewImage} style={{width:'-webkit-fill-available',height:'inherit'}}/>
                          </a>

                    </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
    );
  }
}

export default SliderHeader;
