import React, { Component } from 'react';
import PathUserPng1 from '../../../../../assets/img/memebers-icons/Path 79@2x.png';
import Modal from 'react-modal';

const customStyles = {
  content: {
    width: '50%',
    height: '60%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    padding: '20px',
    overflow: 'hidden',
    borderRadius: '15px',
  },
};

class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenModal: false,
      userSelected: null,
    };
    this.isOpenModal = this.isOpenModal.bind(this);
  }
  isOpenModal(userSelected) {
    const { isOpenModal } = this.state;
    this.setState({
      isOpenModal: !isOpenModal,
      userSelected,
    });
  }
  render() {
    const { user } = this.props;
    const { isOpenModal } = this.state;
    return (
      <>
        <Modal
          isOpen={isOpenModal}
          onRequestClose={this.isOpenModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div
            style={{
              justifyContent: 'flex-end',
            }}
            className="modal-header"
          >
            <button
              onClick={() => this.isOpenModal()}
              style={{ border: '0', backgroundColor: 'white' }}
              className=""
            >
              X
            </button>
          </div>
          <div style={{ height: '86%' }} className="modal-body">
            <div
              className="contact-us-form-row"
              style={{ alignItems: 'center', padding: '6px' }}
            >
              <div className="input-field-label-container">
                <div className="input-field-label-content">
                  <label htmlFor="">الأسم </label>
                </div>
              </div>
              <div style={{ width: '100%' }} className="input-field-label-content">
                <label htmlFor="">{`${user.first_name} ${user.last_name}`} </label>
              </div>
            </div>
            <div
              className="contact-us-form-row"
              style={{ alignItems: 'center', padding: '6px' }}
            >
              <div className="input-field-label-container">
                <div className="input-field-label-content">
                  <label htmlFor="">المسمى الوظيفي </label>
                </div>
              </div>
              <div style={{ width: '100%' }} className="input-field-label-content">
                <label htmlFor="">
                  {user.job_title ? user.job_title : 'لا يوجد مسمى وظيفي'}{' '}
                </label>
              </div>
            </div>
            <div
              className="contact-us-form-row"
              style={{ alignItems: 'center', padding: '6px' }}
            >
              <div className="input-field-label-container">
                <div className="input-field-label-content">
                  <label htmlFor="">الدائرة </label>
                </div>
              </div>
              <div style={{ width: '100%' }} className="input-field-label-content">
                <label htmlFor="">{user.circle ? user.circle : 'لا يوجد دائرة'} </label>
              </div>
            </div>
            <div
              className="contact-us-form-row"
              style={{ alignItems: 'center', padding: '6px' }}
            >
              <div className="input-field-label-container">
                <div className="input-field-label-content">
                  <label htmlFor="">رقم الموبايل </label>
                </div>
              </div>
              <div style={{ width: '100%' }} className="input-field-label-content">
                <label htmlFor="">
                  {user.phone ? user.phone : 'لا يوجد رقم موبايل'}{' '}
                </label>
              </div>
            </div>
            <div
              className="contact-us-form-row"
              style={{ alignItems: 'center', padding: '6px' }}
            >
              <div className="input-field-label-container">
                <div className="input-field-label-content">
                  <label htmlFor="">الايميل</label>
                </div>
              </div>
              <div style={{ width: '100%' }} className="input-field-label-content">
                <label htmlFor="">{user.email} </label>
              </div>
            </div>
          </div>
          <div
            style={{
              justifyContent: 'flex-start',
            }}
            className="modal-footer"
          ></div>
        </Modal>
        <div
          onClick={() => this.isOpenModal()}
          style={{ cursor: 'pointer' }}
          key={user.id}
          className="dashboard-column-content-body-elem"
        >
          <div className="user-icon-container">
            <img src={PathUserPng1} alt="" />
          </div>
          <div className="registered-user-info">
            <span className="dshb-user-name">{`${user.first_name} ${user.last_name}`}</span>
            <span className="dshb-user-status">
              {user.roles.length > 0 && user.roles[0].displayName}
            </span>
          </div>
        </div>
      </>
    );
  }
}

export default User;
