import React, { Component } from 'react';
import path126 from '../../../assets/img/Path 126.png';
import SimpleReactValidator from 'simple-react-validator';
import { validate, validatePhone } from '../../../utils/helpers';
import actions from './store/actions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import headerImageDark from "../../../assets/img/umAlQuwain_main_logo_dark.png";

const mapStateToProps = (state) => ({
  isLoadingNewAccount: state.RegisterReducer.isLoadingNewAccount,
  message: state.RegisterReducer.message,
  error: state.RegisterReducer.error,
  addAccount: state.RegisterReducer.addAccount,
  isLoading: state.RegisterReducer.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  InitialState: () => dispatch(actions.InitialState()),
  submitAccountForm: (values) => dispatch(actions.submitAccountForm(values)),
});

class Register extends Component {
  constructor(props) {
    super(props);
    //this.props.InitialState();
    document.title = 'Register';
    this.state = {
      initialState: {
        first_name: '',
        last_name: '',
        email: '',
        address_country: '',
        password: '',
        phone: '',
        job_title: '',
        circle: '',
        confirm_password: '',
      },
      onFocus: false,
      hasError: false,
      showPassword: false,
      showConfirmPassword: false,
    };
    this.validator = new SimpleReactValidator({
      messages: {
        email: 'Adresse e-mail invalide',
        min: 'Saisissez un mot de passe comprenant au moins 6 caractères.',
        max: 'Le mot de passe doit comporter de 6 à 20 caractères. ',
        // OR
        default: 'Champ requise.', // will override all messages
      },
    });
  }
  handleShowPassword() {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  }

  handleShowConfirmPassword() {
    this.setState({
      showConfirmPassword: !this.state.showConfirmPassword,
    });
  }

  submitForm(initialState) {
    const course_id = this.props.location.state
      ? this.props.location.state.course_id
      : null;
    if (
      !initialState.first_name ||
      !initialState.last_name ||
      !initialState.email ||
      !initialState.phone ||
      !initialState.password ||
      !initialState.circle ||
      !initialState.job_title ||
      !validate(initialState.first_name) ||
      !validatePhone(initialState.phone) ||
      //!validPassword(initialState.password) ||
      !validate(initialState.last_name)
    ) {
      this.setState({
        ...initialState,
        hasError: true,
      });
    } else if (this.validator.allValid()) {
      if (course_id) {
        initialState.course_id = course_id;
      }
      this.props.submitAccountForm(initialState);
    } else {
      this.setState({
        ...initialState,
        hasError: true,
      });
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { addAccount } = this.props;
    if (addAccount !== prevProps.addAccount && addAccount.id) {
      window.location.href = `/login`;
    }
  }

  render() {
    const { initialState, hasError, showConfirmPassword, showPassword } = this.state;
    const course_id = this.props.location.state
      ? this.props.location.state.course_id
      : null;
    return (
      <section className="auth-main-section">
        <div className="auth-main-section-content">
          <div className="signup-form-container">
            <div className="signup-form-content">
              <div className="form-header">
                <div>
                  <div style={{ textAlign: 'end' }}>
                    <img src={headerImageDark} style={{ width: '20%' }} />
                  </div>
                  <div
                    style={{ marginRight: '38%' }}
                    className="-form-header--icon-container"
                  >
                    <img src={path126} alt="" />
                  </div>
                </div>
                <div className="-form-header--text-container">
                  <div className="-form-header--title">
                    <span>تسجيل الدخول</span>
                  </div>
                  {/*<div className="-form-header--text-desc">
                    <span>أو سجل عن طريق البريد الإلكتروني</span>
                  </div>*/}
                </div>
              </div>
              <form>
                <div className="input-container">
                  <div style={{ display: 'flex' }}>
                    <div className="input-field-container">
                      <input
                        className={
                          (hasError && !initialState.first_name) ||
                          (hasError &&
                            initialState.first_name &&
                            !validate(initialState.first_name))
                            ? 'input-error'
                            : ''
                        }
                        name="first_name"
                        onChange={(e) => {
                          this.setState({
                            initialState: {
                              ...initialState,
                              first_name: e.target.value,
                            },
                          });
                        }}
                        required={this.validator.message(
                          'first_name',
                          initialState.first_name,
                          'required|string',
                        )}
                        type="text"
                        placeholder="الأسم الأول"
                      />
                    </div>
                    <div className="input-field-container">
                      <input
                        className={
                          (hasError && !initialState.last_name) ||
                          (hasError &&
                            initialState.last_name &&
                            !validate(initialState.last_name))
                            ? 'input-error'
                            : ''
                        }
                        required={this.validator.message(
                          'last_name',
                          initialState.last_name,
                          'required|string',
                        )}
                        name="last_name"
                        onChange={(e) => {
                          this.setState({
                            initialState: {
                              ...initialState,
                              last_name: e.target.value,
                            },
                          });
                        }}
                        type="text"
                        placeholder="الأسم الثاني"
                      />
                    </div>
                  </div>
                  <div className="input-field-container">
                    <input
                      className={
                        (hasError && !initialState.job_title) ||
                        (hasError &&
                          initialState.job_title &&
                          !validate(initialState.job_title))
                          ? 'input-error'
                          : ''
                      }
                      required={this.validator.message(
                        'job_title',
                        initialState.job_title,
                        'required|string',
                      )}
                      name="job_title"
                      onChange={(e) => {
                        this.setState({
                          initialState: {
                            ...initialState,
                            job_title: e.target.value,
                          },
                        });
                      }}
                      type="text"
                      placeholder="المسمى الوظيفي"
                    />
                  </div>
                  <div
                    className={
                      hasError && !initialState.circle
                        ? 'user-info-input-field input-error'
                        : 'user-info-input-field'
                    }
                  >
                    <select
                      onChange={(e) => {
                        this.setState({
                          initialState: {
                            ...initialState,
                            circle: e.target.value,
                          },
                        });
                      }}
                      name="circle"
                      id=""
                    >
                      <option value="دائرة بلدية أم القيوين">
                        دائرة بلدية أم القيوين
                      </option>
                      <option value="دائرة التخطيط العمراني">
                        دائرة التخطيط العمراني
                      </option>
                      <option value="دائرة التنمية الاقتصادية">
                        دائرة التنمية الاقتصادية
                      </option>
                      <option value="دائرة ام القيوين الذكية">
                        دائرة ام القيوين الذكية
                      </option>
                      <option value="دائرة المالية">دائرة المالية</option>
                      <option value="دائرة السياحة والآثار">دائرة السياحة والآثار</option>
                      <option value="المجلس التنفيذي">المجلس التنفيذي</option>
                      <option value="الديوان الأميري">الديوان الأميري</option>
                      <option value="آخرى">آخرى</option>
                    </select>
                    {/*<input
                        className={
                          (hasError && !initialState.address_country) ||
                          (hasError &&
                            initialState.address_country &&
                            !validate(initialState.address_country))
                            ? 'input-error'
                            : ''
                        }
                        required={this.validator.message(
                          'address_country',
                          initialState.address_country,
                          'required|string',
                        )}
                        name="address_country"
                        onChange={(e) => {
                          this.setState({
                            initialState: {
                              ...initialState,
                              address_country: e.target.value,
                            },
                          });
                        }}
                        type="text"
                        placeholder="الجهة"
                      />*/}
                  </div>
                  <div className="input-field-container">
                    <input
                      maxLength={20}
                      minLength={8}
                      className={
                        (hasError && !initialState.phone) ||
                        (hasError &&
                          initialState.phone &&
                          !validatePhone(initialState.phone))
                          ? 'input-error'
                          : ''
                      }
                      /*required={this.validator.message(
                        'phone',
                        initialState.phone,
                        'required|max20|min:8',
                      )}*/
                      name="phone"
                      onChange={(e) => {
                        this.setState({
                          initialState: {
                            ...initialState,
                            phone: e.target.value,
                          },
                        });
                      }}
                      type="text"
                      placeholder="الهاتف"
                    />
                  </div>
                  <div className="input-field-container">
                    <input
                      className={
                        (hasError && !initialState.email) ||
                        (this.validator.message(
                          'email',
                          initialState.email,
                          'required|email',
                        ) &&
                          this.validator.message(
                            'email',
                            initialState.email,
                            'required|email',
                          ).type &&
                          initialState.email)
                          ? 'input-error'
                          : ''
                      }
                      required={this.validator.message(
                        'email',
                        initialState.email,
                        'required|email',
                      )}
                      name="email"
                      onChange={(e) => {
                        this.setState({
                          initialState: {
                            ...initialState,
                            email: e.target.value,
                          },
                        });
                      }}
                      type="text"
                      placeholder="البريد الإلكتروني"
                    />
                  </div>
                  <div className="input-field-container">
                    <input
                      className={hasError && !initialState.password ? 'input-error' : ''}
                      name="password"
                      onChange={(e) => {
                        this.setState({
                          initialState: {
                            ...initialState,
                            password: e.target.value,
                          },
                        });
                      }}
                      type="password"
                      placeholder="كلمة المرور"
                    />
                  </div>
                  <div className="input-field-container">
                    <input
                      className={
                        hasError && !initialState.confirm_password ? 'input-error' : ''
                      }
                      name="confirm_password"
                      onChange={(e) => {
                        if (initialState.password.localeCompare(e.target.value)) {
                          this.setState({
                            ...initialState,
                            hasError: true,
                          });
                        }
                        this.setState({
                          initialState: {
                            ...initialState,
                            confirm_password: e.target.value,
                          },
                        });
                        /*this.setState({
                          initialState: {
                            ...initialState,
                            confirm_password: e.target.value,
                          },
                        });*/
                      }}
                      type="password"
                      placeholder="تاكيد كلمة المرور"
                    />
                  </div>
                </div>
                <div className="-signIn--link">
                  <Link
                    to={{
                      pathname: '/login',
                      state: {
                        course_id,
                      },
                    }}
                  >
                    لديك حساب؟
                  </Link>
                </div>
                <button
                  onClick={() => this.submitForm(initialState)}
                  type="button"
                  className="btn btn-red"
                >
                  تسجيل الدخول
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Register);
