import React, { Component } from 'react';

class ConditionInstitution extends Component {
  render() {
    return (
      <div className="membership-benefits-conditions-container">
        <div className="membership-benefits-conditions-content">
          <div className="membership-benefits-conditions-header">
            <span>طلب مؤسسة معتمدة</span>
          </div>
          <div className="membership-benefits-conditions-body">
            <ul>
              <li>مدة العضوية عام ميلادي تتجدد في حال الرغبة في ذلك وسداد الرسوم</li>
              <li>تمنح المؤسسة بطاقة عضوية كمؤسسة معتمدة في مركز التدريب</li>
              <li>
                دعم المؤسسة لوجستيّاً، بتنفيذ برامجها المتميزة بالاشتراك مع مركز التدريب
              </li>
              <li>
                اشتراك المؤسسة في الملتقيات التدريبية التي ينفذها المركز ومنحها فرصة تقديم
                برنامج تدريبي في الملتقى
              </li>
              <li>
                تمنح المؤسسة المتميزة، بحسب تقدير مجلس الإدارة وسام المؤسسة المتميزة في
                التدريب
              </li>
              <li>
                يحق للمركز استطلاع عام للمتدربين حول أداء المؤسسة بعد كل برنامج تدريبي
              </li>
              <li>
                يحق للمركز تعليق أو إلغاء عضورة المنتسب، في حال مخالفته لأخلاقيات التدريب
                المعروفة، أو في حال ضعف قدراته العلمية أو المهارية
              </li>
              <li>
                شروط العضوية: الملف التعريفي عن المؤسسة - نسخة عن الرخصة التجارية - نسخة
                عن جواز السفر لصاحب المؤسسة
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default ConditionInstitution;
