import axios from 'axios';
import { host } from '../../../utils/constants';

const getAllLibraries = (params, type) => async (dispatch) => {
  dispatch({ type: 'GET_ALL_LIBRARIES_REQUESTING' });
  try {
    const { currentPage } = params || {
      currentPage: 1,
    };
    const { limit } = params || {
      limit: '',
    };
    const { data } = await axios.get(
      `${host}/api/library/${type}`,
    );
    dispatch({ type: 'GET_ALL_LIBRARIES_SUCCESS', data: data });
  } catch (err) {
    const { response } = err;
    if (response)
      dispatch({
        type: 'GET_ALL_LIBRARIES_FAILURE',
        err: err.message,
      });
  }
};
const getLibrary = (id) => async (dispatch) => {
  dispatch({ type: 'GET_LIBRARY_REQUESTING' });
  try {
    const { data } = await axios.get(`${host}/api/library/items/${id}`);
    dispatch({ type: 'GET_LIBRARY_SUCCESS', data: data.result });
  } catch (err) {
    const { response } = err;
    if (response)
      dispatch({
        type: 'GET_LIBRARY_FAILURE',
        err: err.message,
      });
  }
};
const getAllItemLibraries = (params, id) => async (dispatch) => {
  dispatch({ type: 'GET_ALL_ITEM_LIBRARIES_REQUESTING' });
  try {
    const { currentPage } = params || {
      currentPage: 1,
    };
    const { limit } = params || {
      limit: 8,
    };
    const { data } = await axios.get(
      `${host}/api/item-library/list/${id}?limit=${limit}&current_page=${currentPage}`,
    );
    dispatch({ type: 'GET_ALL_ITEM_LIBRARIES_SUCCESS', data: data });
  } catch (err) {
    const { response } = err;
    if (response)
      dispatch({
        type: 'GET_ALL_ITEM_LIBRARIES_FAILURE',
        err: err.message,
      });
  }
};

const changeSearchedString = (params) => ({
  type: 'PAGINATION_LIBRARIES_PARAMS',
  params: params,
});
export default {
  getAllLibraries,
  changeSearchedString,
  getAllItemLibraries,
  getLibrary,
};
