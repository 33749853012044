import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import actions from './store/actions';
import SimpleReactValidator from 'simple-react-validator';
import { Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import path126 from '../../../assets/img/Path 126.png';
import headerImageDark from '../../../assets/img/umAlQuwain_main_logo_dark.png';

const mapStateToProps = (state) => ({
  emailExist: state.ResetPasswordReducer.emailExist,
  isLoadingGetEmail: state.ResetPasswordReducer.isLoadingGetEmail,
  isLoading: state.ResetPasswordReducer.isLoading,
  message: state.ResetPasswordReducer.message,
  error: state.ResetPasswordReducer.error,
  isLoadingRestPassword: state.ResetPasswordReducer.isLoadingRestPassword,
  updated: state.ResetPasswordReducer.updated,
});

const mapDispatchToProps = (dispatch) => ({
  getEmail: (email) => dispatch(actions.getEmail(email)),
  submitResetPasswordForm: (values) => dispatch(actions.submitResetPasswordForm(values)),
});

class ResetPassword extends PureComponent {
  constructor(props) {
    super(props);
    document.title = 'التحقق من العنوان البريد الإلكتروني';
    this.state = {
      showEmail: true,
      hiddenEmail: false,
      hiddenPassword: true,
      showPassword: false,
      showRepeatPassword: false,
      hasErrorNewPassword: false,
      hasError: false,
      initialState: {
        confirm_password: '',
        code: '',
        email: '',
        password: '',
        showEmail: '',
        showPassword: '',
      },
    };
    this.validator = new SimpleReactValidator({
      messages: {
        email: 'Adresse e-mail invalide',
        min: 'Saisissez un mot de passe comprenant au moins 6 caractères.',
        max: 'Le mot de passe doit comporter de 6 à 20 caractères. ',
        // OR
        default: 'Champ requise.', // will override all messages
      },
    });
    this.handleShowPassword = this.handleShowPassword.bind(this);
    this.handleShowRepeatPassword = this.handleShowRepeatPassword.bind(this);
  }
  handleShowPassword() {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  }

  handleShowRepeatPassword() {
    this.setState({
      showRepeatPassword: !this.state.showRepeatPassword,
    });
  }
  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  keyPressed(event) {
    if (event.key === 'Enter') {
      const { initialState, hasError } = this.state;
      if (!initialState.email)
        this.setState({
          hasError: !hasError,
        });
      if (initialState.email) {
        this.submitFormEmail(initialState.email);
      }
    }
  }
  submitFormResetPassword(initialState) {
    if (this.validator.allValid()) {
      this.props.submitResetPasswordForm(initialState);
    } else if (initialState.password.localeCompare(initialState.confirm_password)) {
      this.setState({
        ...initialState,
        hasErrorNewPassword: true,
      });
    } else {
      this.setState({
        hasError: true,
      });
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const { emailExist, updated } = nextProps;
    const { hiddenPassword } = this.state;
    if (emailExist && emailExist.code && hiddenPassword) {
      this.setState({
        hasError: false,
        showEmail: false,
        showResetPassword: true,
      });
    }
    if (updated && hiddenPassword) {
      nextProps.history.push('/login');
    }
  }

  submitFormEmail(email) {
    if (this.validator.allValid()) {
      this.props.getEmail(email);
    } else {
      this.setState({
        hasError: true,
      });
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  }
  render() {
    const {
      showPassword,
      hasErrorNewPassword,
      showRepeatPassword,
      initialState,
      hasError,
      showEmail,
      hiddenPassword,
      showResetPassword,
    } = this.state;
    //const {  } = initialState;
    const { emailExist, isLoadingGetEmail, isLoadingRestPassword } = this.props;
    return (
      <div className="authentication_view">
        {showEmail ? (
          <section className="auth-main-section">
            <div className="auth-main-section-content">
              <div className="signin-form-container">
                <div className="signin-form-content" style={{ paddingBottom: '60px' }}>
                  <div className="form-header">
                    <div>
                      <div style={{ textAlign: 'end' }}>
                        <img src={headerImageDark} style={{ width: '20%' }} />
                      </div>
                      <div
                        style={{ marginRight: '38%' }}
                        className="-form-header--icon-container"
                      >
                        <img src={path126} alt="" />
                      </div>
                    </div>
                    <div className="-form-header--text-container">
                      <div className="-form-header--title">
                        <span>تغيير كلمة المرور</span>
                      </div>
                      <div className="-form-header--text-desc">
                        <span>أدخل البريد الإلكتروني الخاصة بك للمتابعة</span>
                      </div>
                    </div>
                  </div>
                  <form action="">
                    <div className="input-container">
                      <div className="input-field-container">
                        <input
                          className={
                            (hasError && !initialState.email) ||
                            (this.validator.message(
                              'email',
                              initialState.email,
                              'required|email',
                            ) &&
                              this.validator.message(
                                'email',
                                initialState.email,
                                'required|email',
                              ).type &&
                              initialState.email)
                              ? 'input-error'
                              : ''
                          }
                          required={this.validator.message(
                            'email',
                            initialState.email,
                            'required|email',
                          )}
                          name="email"
                          onChange={(e) => {
                            this.setState({
                              initialState: {
                                ...initialState,
                                email: e.target.value,
                              },
                            });
                          }}
                          type="text"
                          placeholder="البريد الإلكتروني"
                        />
                      </div>
                    </div>
                    <button
                      onClick={() => this.submitFormEmail(initialState)}
                      type="button"
                      className="btn btn-red"
                    >
                      {isLoadingGetEmail ? (
                        <CircularProgress
                          style={{
                            textAlign: 'center',
                            width: '30px',
                            height: '30px',
                            color: 'white',
                          }}
                        />
                      ) : (
                        'تسجيل الدخول'
                      )}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </section>
        ) : (
          ''
        )}
        {showResetPassword ? (
          <section className="auth-main-section">
            <div className="auth-main-section-content">
              <div className="signin-form-container">
                <div className="signin-form-content" style={{ paddingBottom: '60px' }}>
                  <div className="form-header">
                    <div>
                      <div style={{ textAlign: 'end' }}>
                        <img src={headerImageDark} style={{ width: '20%' }} />
                      </div>
                      <div
                        style={{ marginRight: '38%' }}
                        className="-form-header--icon-container"
                      >
                        <img src={path126} alt="" />
                      </div>
                    </div>
                    <div className="-form-header--text-container">
                      <div className="-form-header--title">
                        <span>تغيير كلمة المرور</span>
                      </div>
                      <div className="-form-header--text-desc">
                        <span>أدخل كلمة المرور جديدة</span>
                      </div>
                    </div>
                  </div>
                  <form className="sign_in_form">
                    <div className="input-container">
                      <div className="input-field-container">
                        <input
                          className={
                            hasError &&
                            !initialState.email /*||
                            (this.validator.message(
                              'email',
                              initialState.email,
                              'required|email',
                            ) &&
                              this.validator.message(
                                'email',
                                initialState.email,
                                'required|email',
                              ).type &&
                              initialState.email)*/
                              ? 'input-error'
                              : ''
                          }
                          required={this.validator.message(
                            'code',
                            initialState.code,
                            `${
                              hiddenPassword && emailExist ? `in:${emailExist.code}` : ''
                            }`,
                            { messages: { in: 'رمز التحقق خاطئ.' } },
                          )}
                          name="code"
                          onChange={(e) => {
                            this.setState({
                              initialState: {
                                ...initialState,
                                code: e.target.value,
                              },
                            });
                          }}
                          type="text"
                          placeholder="رمز التحقق"
                        />
                      </div>
                      <div className="input-field-container">
                        <input
                          className={
                            hasError && !initialState.password
                              ? 'input-error'
                              : hasError &&
                                initialState.password &&
                                initialState.confirm_password &&
                                !initialState.password.localeCompare(
                                  initialState.confirm_password,
                                )
                              ? 'input-error'
                              : ''
                          }
                          name="password"
                          onChange={(e) => {
                            this.setState({
                              initialState: {
                                ...initialState,
                                password: e.target.value,
                              },
                            });
                          }}
                          type="password"
                          placeholder="كلمة المرور"
                        />
                      </div>
                      <div className="input-field-container">
                        <input
                          className={
                            hasError && !initialState.confirm_password
                              ? 'input-error'
                              : hasError &&
                                initialState.password &&
                                initialState.confirm_password &&
                                !initialState.password.localeCompare(
                                  initialState.confirm_password,
                                )
                              ? 'input-error'
                              : ''
                          }
                          name="confirm_password"
                          onChange={(e) => {
                            if (initialState.password.localeCompare(e.target.value)) {
                              this.setState({
                                ...initialState,
                                hasError: true,
                              });
                            }
                            this.setState({
                              initialState: {
                                ...initialState,
                                confirm_password: e.target.value,
                              },
                            });
                          }}
                          type="password"
                          placeholder="تاكيد كلمة المرور"
                        />
                      </div>
                    </div>
                    <div className="-signIn--link">
                      <Link
                        to={{
                          pathname: '/login',
                        }}
                      >
                        لديك حساب؟
                      </Link>
                    </div>
                    <button
                      onClick={() => {
                        if (
                          !initialState.confirm_password ||
                          !initialState.password ||
                          !initialState.code
                        )
                          this.setState({
                            ...this.state,
                            hasError: true,
                          });
                        if (
                          initialState.confirm_password &&
                          initialState.password &&
                          initialState.code
                        ) {
                          this.submitFormResetPassword(initialState);
                        }
                      }}
                      type="button"
                      className="btn btn-red"
                    >
                      {isLoadingRestPassword ? (
                        <CircularProgress
                          style={{
                            marginRight: '45%',
                            width: '30px',
                            height: '30px',
                            color: 'white',
                          }}
                        />
                      ) : (
                        'تسجيل'
                      )}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </section>
        ) : (
          ''
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
