import axios from 'axios';
import { host } from '../../../utils/constants';

const getAllCertifiedMembers = (params) => async (dispatch) => {
  dispatch({ type: 'GET_ALL_CERTIFIED_MEMBER_SHIP_REQUESTING' });
  try {
    const { currentPage } = params || {
      currentPage: 1,
    };
    const { limit } = params || {
      limit: '',
    };
    const { data } = await axios.get(
      `${host}/api/certified-member-ship?limit=${limit}&current_page=${currentPage}`,
    );
    dispatch({ type: 'GET_ALL_CERTIFIED_MEMBER_SHIP_SUCCESS', data: data.result });
  } catch (err) {
    const { response } = err;
    if (response)
      dispatch({
        type: 'GET_ALL_CERTIFIED_MEMBER_SHIP_FAILURE',
        err: err.message,
      });
  }
};
const getCertifiedMember = (id) => async (dispatch) => {
  dispatch({ type: 'GET_CERTIFIED_MEMBER_SHIP_REQUESTING' });
  try {
    const { data } = await axios.get(`${host}/api/certified-member-ship/${id}`);
    dispatch({ type: 'GET_CERTIFIED_MEMBER_SHIP_SUCCESS', data: data.result });
  } catch (err) {
    const { response } = err;
    if (response)
      dispatch({
        type: 'GET_CERTIFIED_MEMBER_SHIP_FAILURE',
        err: err.message,
      });
  }
};

const changeSearchedString = (params) => ({
  type: 'PAGINATION_CERTIFIED_MEMBER_SHIP_PARAMS',
  params: params,
});
export default {
  getAllCertifiedMembers,
  getCertifiedMember,
  changeSearchedString,
};
