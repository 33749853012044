import axios from 'axios';
import { host } from '../utils/constants';
import decode from 'jwt-decode';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToastAction from '../componenets/ToastAction';

const ERROR_MAINTENANCE = 503;

const getAllTrainings = (params, type) => async (dispatch) => {
  dispatch({ type: 'GET_TRAININGS_REQUESTING' });
  try {
    const { currentPage } = params || {
      currentPage: 1,
    };
    const { limit } = params || {
      limit: '',
    };
    //&limit=${6}&current_page=${currentPage}
    const { data } = await axios.get(`${host}/api/course?type=${type}`);
    dispatch({ type: 'GET_TRAININGS_SUCCESS', data: data });
  } catch (err) {
    const { response } = err;
    if (response)
      dispatch({
        type: 'GET_TRAININGS_FAILURE',
        err: err.message,
      });
  }
};

const getAllCourses = () => async (dispatch) => {
  dispatch({ type: 'GET_TRAININGS_REQUESTING' });
  try {
    //&limit=${6}&current_page=${currentPage}
    const { data } = await axios.get(`${host}/api/course/course/admin/add`);
    dispatch({ type: 'GET_TRAININGS_SUCCESS', data: data.result });
  } catch (err) {
    const { response } = err;
    if (response)
      dispatch({
        type: 'GET_TRAININGS_FAILURE',
        err: err.message,
      });
  }
};

const getAllTrainingsType = (params, type) => async (dispatch) => {
  dispatch({ type: 'GET_TRAININGS_TYPE_REQUESTING' });
  try {
    const { currentPage } = params || {
      currentPage: 1,
    };
    const { limit } = params || {
      limit: '',
    };
    //&limit=${6}&current_page=${currentPage}
    const { data } = await axios.get(`${host}/api/course?type=${type}`);
    dispatch({ type: 'GET_TRAININGS_TYPE_SUCCESS', data: data });
  } catch (err) {
    const { response } = err;
    if (response)
      dispatch({
        type: 'GET_TRAININGS_TYPE_FAILURE',
        err: err.message,
      });
  }
};

const getAllTrainingsByUser = () => async (dispatch) => {
  dispatch({ type: 'GET_TRAININGS_BY_USER_REQUESTING' });
  try {
    const { data } = await apiCall({
      method: 'get',
      url: '/api/course/user/smart-course',
    });
    dispatch({ type: 'GET_TRAININGS_BY_USER_SUCCESS', data: data });
  } catch (err) {
    const { response } = err;
    if (response)
      dispatch({
        type: 'GET_TRAININGS_BY_USER_FAILURE',
        err: err.message,
      });
  }
};

const getSavedInformationUser = () => async (dispatch) => {
  dispatch({ type: 'GET_SAVED_INFO_USER_REQUESTING' });
  try {
    const { data } = await apiCall({
      method: 'get',
      url: '/api/user/get/saved/information',
    });
    dispatch({ type: 'GET_SAVED_INFO_USER_SUCCESS', data: data });
  } catch (err) {
    const { response } = err;
    if (response)
      dispatch({
        type: 'GET_SAVED_INFO_USER_FAILURE',
        err: err.message,
      });
  }
};

const getTrainingById = (id) => async (dispatch) => {
  dispatch({ type: 'GET_TRAINING_REQUESTING' });
  try {
    const { data } = await axios.get(`${host}/api/course/${id}`);
    dispatch({ type: 'GET_TRAINING_SUCCESS', data: data.result });
  } catch (err) {
    const { response } = err;
    if (response)
      dispatch({
        type: 'GET_TRAINING_FAILURE',
        err: err.message,
      });
  }
};

const getUserTrainingById = (id) => async (dispatch) => {
  dispatch({ type: 'GET_USER_TRAINING_REQUESTING' });
  try {
    const { data } = await apiCall({
      method: 'get',
      url: `/api/course/user-course/${id}`,
    });
    dispatch({ type: 'GET_USER_TRAINING_SUCCESS', data: data.result });
  } catch (err) {
    const { response } = err;
    if (response)
      dispatch({
        type: 'GET_USER_TRAINING_FAILURE',
        err: err.message,
      });
  }
};

const submitContact = (valuse) => async (dispatch) => {
  dispatch({ type: 'CONTACT_REQUESTING' });
  try {
    const { email, name, message, subject } = valuse;
    const { data } = await axios.post(`${host}/api/user/create/add/contact`, {
      email,
      name,
      message,
      subject,
    });
    if (data.error)
      toast(({ closeToast }) => <ToastAction type="error" message={data.message} />, {
        hideProgressBar: true,
      });
    else {
      toast(({ closeToast }) => <ToastAction type="success" message="Contact send" />, {
        hideProgressBar: true,
      });
      dispatch({ type: 'CONTACT_SUCCESS', data: data });
    }
  } catch (err) {
    const { response } = err;
    toast(
      ({ closeToast }) => <ToastAction type="error" message={response.data.message} />,
      {
        hideProgressBar: true,
      },
    );
    dispatch({
      type: 'CONTACT_FAILURE',
      err:
        (response && response.data && response.data.error) || (response && response.data),
    });
  }
};

const submitUserTraining = (valuse) => async (dispatch) => {
  dispatch({ type: 'ADD_USER_COURSE_REQUESTING' });
  try {
    const { data } = await apiCall({
      method: 'post',
      url: `/api/course/create/user-course`,
      data: valuse,
    });
    if (data.error)
      toast(({ closeToast }) => <ToastAction type="error" message={data.message} />, {
        hideProgressBar: true,
      });
    else {
      toast(
        ({ closeToast }) => (
          <ToastAction type="success" message="تم التسجيل في الدورة بنجاح" />
        ),
        {
          hideProgressBar: true,
        },
      );
      dispatch({ type: 'ADD_USER_COURSE_SUCCESS', data: data });
    }
  } catch (err) {
    const { response } = err;
    toast(
      ({ closeToast }) => <ToastAction type="error" message={response.data.message} />,
      {
        hideProgressBar: true,
      },
    );
    dispatch({
      type: 'ADD_USER_COURSE_FAILURE',
      err:
        (response && response.data && response.data.error) || (response && response.data),
    });
  }
};

const submitUpdatedUserTraining = (valuse) => async (dispatch) => {
  dispatch({ type: 'ADD_USER_COURSE_REQUESTING' });
  try {
    const { data } = await apiCall({
      method: 'patch',
      url: `/api/course/update/user-course`,
      data: valuse,
    });
    if (data.error) dispatch({ type: 'ADD_USER_COURSE_FAILURE', data: data });
    /* toast(({ closeToast }) => <ToastAction type="error" message={data.message} />, {
        hideProgressBar: true,
      });*/ else {
      /*toast(({ closeToast }) => <ToastAction type="success" message="Course add" />, {
        hideProgressBar: true,
      });*/
      dispatch({ type: 'ADD_USER_COURSE_SUCCESS', data: data });
    }
  } catch (err) {
    const { response } = err;
    /*toast(
      ({ closeToast }) => <ToastAction type="error" message={response.data.message} />,
      {
        hideProgressBar: true,
      },
    );*/
    dispatch({
      type: 'ADD_USER_COURSE_FAILURE',
      err:
        (response && response.data && response.data.error) || (response && response.data),
    });
  }
};

const submitFirstUserTraining = (valuse) => async (dispatch) => {
  dispatch({ type: 'ADD_USER_COURSE_REQUESTING' });
  try {
    const { data } = await apiCall({
      method: 'post',
      url: `/api/course/create/first/user-course`,
      data: valuse,
    });
    if (data.error)
      toast(({ closeToast }) => <ToastAction type="error" message={data.message} />, {
        hideProgressBar: true,
      });
    else {
      toast(
        ({ closeToast }) => (
          <ToastAction type="success" message="تم التسجيل في الدورة بنجاح" />
        ),
        {
          hideProgressBar: true,
        },
      );
      dispatch({ type: 'ADD_USER_COURSE_SUCCESS', data: data });
    }
  } catch (err) {
    const { response } = err;
    toast(
      ({ closeToast }) => <ToastAction type="error" message={response.data.message} />,
      {
        hideProgressBar: true,
      },
    );
    dispatch({
      type: 'ADD_USER_COURSE_FAILURE',
      err:
        (response && response.data && response.data.error) || (response && response.data),
    });
  }
};

const submitUserDirectTraining = (valuse) => async (dispatch) => {
  dispatch({ type: 'ADD_USER_DIRECT_COURSE_REQUESTING' });
  try {
    const { data } = await apiCall({
      method: 'post',
      url: `/api/create/direct/training`,
      data: valuse,
    });
    if (data.error)
      toast(({ closeToast }) => <ToastAction type="error" message={data.message} />, {
        hideProgressBar: true,
      });
    else {
      toast(
        ({ closeToast }) => (
          <ToastAction type="success" message="تم التسجيل في الدورة بنجاح" />
        ),
        {
          hideProgressBar: true,
        },
      );
      dispatch({ type: 'ADD_USER_DIRECT_COURSE_SUCCESS', data: data });
    }
  } catch (err) {
    const { response } = err;
    toast(
      ({ closeToast }) => <ToastAction type="error" message={response.data.message} />,
      {
        hideProgressBar: true,
      },
    );
    dispatch({
      type: 'ADD_USER_DIRECT_COURSE_FAILURE',
      err:
        (response && response.data && response.data.error) || (response && response.data),
    });
  }
};

const submitUserProgramTraining = (valuse) => async (dispatch) => {
  dispatch({ type: 'ADD_USER_DIRECT_COURSE_REQUESTING' });
  try {
    const { data } = await apiCall({
      method: 'post',
      url: `/api/create/program/training`,
      data: valuse,
    });
    if (data.error)
      toast(({ closeToast }) => <ToastAction type="error" message={data.message} />, {
        hideProgressBar: true,
      });
    else {
      toast(
        ({ closeToast }) => (
          <ToastAction type="success" message="تم التسجيل في الدورة بنجاح" />
        ),
        {
          hideProgressBar: true,
        },
      );
      dispatch({ type: 'ADD_USER_DIRECT_COURSE_SUCCESS', data: data });
    }
  } catch (err) {
    const { response } = err;
    toast(
      ({ closeToast }) => <ToastAction type="error" message={response.data.message} />,
      {
        hideProgressBar: true,
      },
    );
    dispatch({
      type: 'ADD_USER_DIRECT_COURSE_FAILURE',
      err:
        (response && response.data && response.data.error) || (response && response.data),
    });
  }
};

const submitTraining = (values, docs) => async (dispatch) => {
  dispatch({ type: 'ADD_COURSE_REQUESTING' });
  try {
    const { data } = await apiCall({
      method: 'post',
      url: `/api/create/course`,
      data: values,
    });
    if (data.error)
      toast(({ closeToast }) => <ToastAction type="error" message={data.message} />, {
        hideProgressBar: true,
      });
    else {
      if (values.file) {
        let formDataCourse = new FormData();
        formDataCourse.append('file', values.file[0]);
        formDataCourse.append('id', data.course_id);
        await apiCall({
          method: 'post',
          url: `/api/upload/file`,
          data: formDataCourse,
        });
      }
      let formData = new FormData();
      for (let i = 0; i < docs.length; i++) {
        const id = data.ids.find((item) => item.old_id === docs[i].id);
        if (id) {
          formData.append('file', docs[i][`doc_${docs[i].id}`]);
          await apiCall({
            method: 'post',
            url: `/api/create/doc/section/item?id=${id.new_id}&course_id=${data.course_id}`,
            data: formData,
          });
        }
      }

      toast(
        ({ closeToast }) => (
          <ToastAction type="success" message="تمت إضافة الدورة بنجاح" />
        ),
        {
          hideProgressBar: true,
        },
      );
      dispatch({ type: 'ADD_COURSE_SUCCESS', data: data });
    }
  } catch (err) {
    const { response } = err;
    toast(
      ({ closeToast }) => <ToastAction type="error" message={response.data.message} />,
      {
        hideProgressBar: true,
      },
    );
    dispatch({
      type: 'ADD_COURSE_FAILURE',
      err:
        (response && response.data && response.data.error) || (response && response.data),
    });
  }
};

const submitSectionItem = (values) => async (dispatch) => {
  dispatch({ type: 'UPDATE_SECTION_ITEM_REQUESTING' });
  try {
    const { data } = await apiCall({
      method: 'patch',
      url: `/api/section/item/update/${values.section_item_id}`,
      data: {
        doc_name: values.section_item_name,
      },
    });
    if (data.error)
      toast(({ closeToast }) => <ToastAction type="error" message={data.message} />, {
        hideProgressBar: true,
      });
    else {
      toast(({ closeToast }) => <ToastAction type="success" message="تم تعديل بنجاح" />, {
        hideProgressBar: true,
      });
      dispatch({ type: 'UPDATE_SECTION_ITEM_SUCCESS', data: data });
    }
  } catch (err) {
    const { response } = err;
    toast(
      ({ closeToast }) => <ToastAction type="error" message={response.data.message} />,
      {
        hideProgressBar: true,
      },
    );
    dispatch({
      type: 'UPDATE_SECTION_ITEM_FAILURE',
      err:
        (response && response.data && response.data.error) || (response && response.data),
    });
  }
};

const submitDeleteSectionItem = (id) => async (dispatch) => {
  dispatch({ type: 'DELETE_SECTION_ITEM_REQUESTING' });
  try {
    const { data } = await apiCall({
      method: 'delete',
      url: `/api/section/item/delete/${id}`,
    });
    if (data.error)
      toast(({ closeToast }) => <ToastAction type="error" message={data.message} />, {
        hideProgressBar: true,
      });
    else {
      toast(({ closeToast }) => <ToastAction type="success" message="تم تعديل بنجاح" />, {
        hideProgressBar: true,
      });
      dispatch({ type: 'DELETE_SECTION_ITEM_SUCCESS', data: data });
    }
  } catch (err) {
    const { response } = err;
    toast(
      ({ closeToast }) => <ToastAction type="error" message={response.data.message} />,
      {
        hideProgressBar: true,
      },
    );
    dispatch({
      type: 'DELETE_SECTION_ITEM_FAILURE',
      err:
        (response && response.data && response.data.error) || (response && response.data),
    });
  }
};

const submitUpdateTraining = (values) => async (dispatch) => {
  dispatch({ type: 'UPDATE_SECTION_REQUESTING' });
  try {
    const { data } = await apiCall({
      method: 'patch',
      url: `/api/course/update/${values.id}`,
      data: {
        name: values.name,
      },
    });
    if (data.error)
      toast(({ closeToast }) => <ToastAction type="error" message={data.message} />, {
        hideProgressBar: true,
      });
    else {
      toast(({ closeToast }) => <ToastAction type="success" message="تم تعديل بنجاح" />, {
        hideProgressBar: true,
      });
      dispatch({ type: 'UPDATE_SECTION_SUCCESS', data: data });
    }
  } catch (err) {
    const { response } = err;
    toast(
      ({ closeToast }) => <ToastAction type="error" message={response.data.message} />,
      {
        hideProgressBar: true,
      },
    );
    dispatch({
      type: 'UPDATE_SECTION_FAILURE',
      err:
        (response && response.data && response.data.error) || (response && response.data),
    });
  }
};
const submitSendEmailToAdmin = (values) => async (dispatch) => {
  dispatch({ type: 'SEND_EMAIL_TO_ADMIN_REQUESTING' });
  try {
    const { data } = await apiCall({
      method: 'post',
      url: `/api/admin/send-email-to-admin`,
      data: values,
    });
    if (data.error)
      toast(({ closeToast }) => <ToastAction type="error" message={data.message} />, {
        hideProgressBar: true,
      });
    else {
      toast(({ closeToast }) => <ToastAction type="success" message="تم تعديل بنجاح" />, {
        hideProgressBar: true,
      });
      dispatch({ type: 'SEND_EMAIL_TO_ADMIN_SUCCESS', data: data });
    }
  } catch (err) {
    const { response } = err;
    toast(
      ({ closeToast }) => <ToastAction type="error" message={response.data.message} />,
      {
        hideProgressBar: true,
      },
    );
    dispatch({
      type: 'SEND_EMAIL_TO_ADMIN_FAILURE',
      err:
        (response && response.data && response.data.error) || (response && response.data),
    });
  }
};

const login = (valuse) => async (dispatch) => {
  dispatch({ type: 'LOGIN_REQUESTING' });
  try {
    const { email, password, course_id } = valuse;
    const { data } = await axios.post(`${host}/api/login`, {
      email,
      password,
      course_id,
    });
    if (data.error)
      toast(({ closeToast }) => <ToastAction type="error" message={data.message} />, {
        hideProgressBar: true,
      });
    else {
      window.localStorage.setItem('token', data.token);
      window.localStorage.setItem('registrationTimestamp', Date.now());
      window.localStorage.setItem('email', data.user.email);
      window.localStorage.setItem('expiryMilliseconds', decode(data.token).exp);
      dispatch({ type: 'LOGIN_SUCCESS', data: data });
    }
  } catch (err) {
    const { response } = err;
    toast(
      ({ closeToast }) => <ToastAction type="error" message={response.data.message} />,
      {
        hideProgressBar: true,
      },
    );
    dispatch({
      type: 'LOGIN_FAILURE',
      err:
        (response && response.data && response.data.error) || (response && response.data),
    });
  }
};

const getMe = () => async (dispatch) => {
  dispatch({ type: 'GET_ME_REQUESTING' });
  try {
    const url = `/api/user/get-me`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({ type: 'GET_ME_SUCCESS', data });
  } catch (err) {
    const { response } = err;
    dispatch({
      type: 'GET_ME_FAILURE',
      err: response && response.data && response.data.error,
    });
  }
};

const getCountCertification = () => async (dispatch) => {
  dispatch({ type: 'GET_COUNT_CONVERSATION_REQUESTING' });
  try {
    const url = `/api/count/certification`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({ type: 'GET_COUNT_CONVERSATION_SUCCESS', data: data.result });
  } catch (err) {
    const { response } = err;
    dispatch({
      type: 'GET_COUNT_CONVERSATION_FAILURE',
      err: response && response.data && response.data.error,
    });
  }
};

const getCountTraining = () => async (dispatch) => {
  dispatch({ type: 'GET_COUNT_TRAININGS_REQUESTING' });
  try {
    const url = `/api/count/trainings`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({ type: 'GET_COUNT_TRAININGS_SUCCESS', data: data.result });
  } catch (err) {
    const { response } = err;
    dispatch({
      type: 'GET_COUNT_TRAININGS_FAILURE',
      err: response && response.data && response.data.error,
    });
  }
};

const getUser = (id) => async (dispatch) => {
  dispatch({ type: 'GET_USER_REQUESTING' });
  try {
    const url = `/api/user/get-one/${id}`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({ type: 'GET_USER_SUCCESS', data });
  } catch (err) {
    const { response } = err;
    dispatch({
      type: 'GET_USER_FAILURE',
      err: response && response.data && response.data.error,
    });
  }
};

const isTokenExpired = (token) => {
  try {
    const decoded = decode(token);
    if (decoded.exp < Date.now() / 1000) {
      // Checking if token is expired
      return true;
    }
    return false;
  } catch (err) {
    return false;
  }
};

export const apiCall = async (params) => {
  const token = window.localStorage.getItem('token');
  // Clear Authentication cache for IE
  // </AxiosResponse>document.execCommand('ClearAuthenticationCache', false);
  if (!token || isTokenExpired(token)) {
    // try for a new token
    return axios
      .post(
        `${host}/api/refreshToken`,
        { token },
        {
          headers: {
            Authorization: `JWT ${token}`,
          },
        },
      )
      .then(({ data }) => {
        const response = data.data;
        window.localStorage.setItem('user', JSON.stringify(response.user));
        window.localStorage.setItem('token', response.token);
        window.localStorage.setItem('email', response.user.email);
        window.localStorage.setItem('registrationTimestamp', Date.now());
        window.localStorage.setItem('expiryMilliseconds', decode(response.token).exp);
        const newExtendedParams = {
          ...params,
          url: `${host + params.url}`,
          headers: {
            ...params.headers,
            Authorization: `JWT ${response.token}`,
          },
        };
        return newExtendedParams;
      })
      .then((newExtendedParams) => axios(newExtendedParams))
      .catch((apiError) => {
        if (apiError.response && apiError.response.status === ERROR_MAINTENANCE) {
          document.location.reload();
        }
        return Promise.reject(apiError);
      });
  }
  const extendedParams = {
    ...params,
    url: `${host + params.url}`,
    headers: {
      ...params.headers,
      Authorization: `JWT ${token}`,
    },
  };
  return axios(extendedParams).catch((apiError) => {
    if (apiError.response && apiError.response.status === ERROR_MAINTENANCE) {
      document.location.reload();
    }
    return Promise.reject(apiError);
  });
};

const changeSearchedString = (params) => ({
  type: 'PAGINATION_TRAININGS_PARAMS',
  params: params,
});

const changeActiveItemSideBar = (data) => async (dispatch) => {
  dispatch({ type: 'UPDATE_ACTIVE_ITEM_SIDE_BAR', activeItemSideBar: data });
};

const changeActiveItemSideBarAdmin = (data) => async (dispatch) => {
  dispatch({ type: 'UPDATE_ACTIVE_ITEM_SIDE_BAR_ADMIN', activeItemSideBarAdmin: data });
};

const submitUserForm = (values) => async (dispatch) => {
  dispatch({ type: 'UPDATE_USER_REQUESTING' });
  const {
    first_name,
    last_name,
    email,
    phone,
    job_title,
    address_city,
    language,
    university,
    file,
    circle,
    admin,
  } = values;

  let request = {
    first_name,
    last_name,
    email,
    phone,
    job_title,
    address_city,
    language,
    university,
    file,
    circle,
  };
  try {
    let formData = new FormData();
    formData.append('first_name', first_name);
    formData.append('last_name', last_name);
    formData.append('email', email);
    formData.append('phone', phone);
    formData.append('job_title', job_title);
    formData.append('address_city', address_city);
    formData.append('language', language);
    formData.append('file', file);
    formData.append('circle', circle);
    formData.append('admin', admin);
    const { data } = await apiCall({
      method: values.id ? 'patch' : 'post',
      url: `/api/user/update/${values.id || ''}`,
      data: formData,
    });
    if (university.university) {
      await apiCall({
        method: university.university_id ? 'patch' : 'post',
        url: `/api/university/${
          university.university_id ? `update/${university.university_id}` : 'create'
        }`,
        data: university,
      });
    }
    if (!data.error) {
      toast(
        ({ closeToast }) => (
          <ToastAction type="success" message="تم تحديث الملف الشخصي" />
        ),
        {
          hideProgressBar: true,
        },
      );
      dispatch({
        type: 'UPDATE_USER_SUCCESS',
        data: data,
      });
    } else {
      toast(({ closeToast }) => <ToastAction type="error" message={data.message} />, {
        hideProgressBar: true,
      });
      dispatch({
        type: 'UPDATE_USER_FAILURE',
        ErrorMessage: data.message,
        data: values,
        status: true,
      });
    }
  } catch (e) {
    const { response } = e;
    const { data } = response;
    if (data.message) {
      toast(
        ({ closeToast }) => <ToastAction type="error" message={response.data.message} />,
        {
          hideProgressBar: true,
        },
      );
    }
    // toast.error(`Profil non ${!values.type ? values.id ? 'modifié' : 'ajouté' : ''} `, {position: toast.POSITION.TOP_CENTER});
    else
      dispatch({
        type: 'UPDATE_USER_FAILURE',
        msg: 'error server',
        err: data.errors || data.message,
        data: values,
      });
  }
};

const changeScoreSideBar = (data) => async (dispatch) => {
  dispatch({ type: 'UPDATE_SCORE_SIDE_BAR', score: data });
};

const getCountCourseIsStart = () => async (dispatch) => {
  dispatch({ type: 'GET_COUNT_TRAININGS_IS_START_REQUESTING' });
  try {
    const url = `/api/count/course/is-start`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({ type: 'GET_COUNT_TRAININGS_IS_START_SUCCESS', data: data.result });
  } catch (err) {
    const { response } = err;
    dispatch({
      type: 'GET_COUNT_TRAININGS_IS_START_FAILURE',
      err: response && response.data && response.data.error,
    });
  }
};

const getCountCourseInProgress = () => async (dispatch) => {
  dispatch({ type: 'GET_COUNT_TRAININGS_IN_PROGRESS_REQUESTING' });
  try {
    const url = `/api/count/course/in-progress`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({ type: 'GET_COUNT_TRAININGS_IN_PROGRESS_SUCCESS', data: data.result });
  } catch (err) {
    const { response } = err;
    dispatch({
      type: 'GET_COUNT_TRAININGS_IN_PROGRESS_FAILURE',
      err: response && response.data && response.data.error,
    });
  }
};

const getCountCourseIsFinish = () => async (dispatch) => {
  dispatch({ type: 'GET_COUNT_TRAININGS_IS_FINISH_REQUESTING' });
  try {
    const url = `/api/count/course/is-finish`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({ type: 'GET_COUNT_TRAININGS_IS_FINISH_SUCCESS', data: data.result });
  } catch (err) {
    const { response } = err;
    dispatch({
      type: 'GET_COUNT_TRAININGS_IS_FINISH_FAILURE',
      err: response && response.data && response.data.error,
    });
  }
};
const changePassword = (valuse) => async (dispatch) => {
  dispatch({ type: 'CHANGE_PASSWORD_REQUESTING' });
  try {
    const { email, old_password, new_password } = valuse;
    const url = `/api/user/change/password`;
    const { data } = await apiCall({
      method: 'post',
      url,
      data: {
        email,
        old_password,
        new_password,
      },
    });

    if (data.error)
      toast(({ closeToast }) => <ToastAction type="error" message={data.message} />, {
        hideProgressBar: true,
      });
    else {
      toast(
        ({ closeToast }) => <ToastAction type="success" message="تم تحديث كلمة المرور" />,
        {
          hideProgressBar: true,
        },
      );
      dispatch({ type: 'CHANGE_PASSWORD_SUCCESS', data: data });
    }
  } catch (err) {
    const { response } = err;
    toast(
      ({ closeToast }) => <ToastAction type="error" message={response.data.message} />,
      {
        hideProgressBar: true,
      },
    );
    dispatch({
      type: 'CHANGE_PASSWORD_FAILURE',
      err:
        (response && response.data && response.data.error) || (response && response.data),
    });
  }
};

const getAllUsers = (paramsUser) => async (dispatch) => {
  dispatch({ type: 'GET_USERS_REQUESTING', paramsUser });
  try {
    const { limit } = paramsUser || {
      limit: '',
    };
    //&limit=${6}&current_page=${currentPage}
    const { data } = await apiCall({
      method: 'get',
      url: `/api/user?limit=${limit}`,
    });
    dispatch({ type: 'GET_USERS_SUCCESS', data: (data && data.users) || [], paramsUser });
  } catch (err) {
    const { response } = err;
    if (response)
      dispatch({
        type: 'GET_USERS_FAILURE',
        err: err.message,
        paramsUser,
      });
  }
};
const changeUsersSearchedString = (paramsUser) => ({
  type: 'PAGINATION_USERS_PARAMS',
  params: paramsUser,
});

export default {
  changeUsersSearchedString,
  getAllUsers,
  changePassword,
  changeScoreSideBar,
  submitUserForm,
  getAllTrainings,
  changeSearchedString,
  getTrainingById,
  submitContact,
  login,
  getMe,
  getUser,
  getCountCertification,
  getCountTraining,
  changeActiveItemSideBar,
  changeActiveItemSideBarAdmin,
  submitUserTraining,
  submitFirstUserTraining,
  submitUpdatedUserTraining,
  getCountCourseIsStart,
  getCountCourseInProgress,
  getCountCourseIsFinish,
  getAllTrainingsByUser,
  getSavedInformationUser,
  getAllTrainingsType,
  submitUserDirectTraining,
  submitUserProgramTraining,
  getAllCourses,
  submitTraining,
  submitSectionItem,
  submitUpdateTraining,
  submitDeleteSectionItem,
  getUserTrainingById,
  submitSendEmailToAdmin,
};
