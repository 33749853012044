import React, { Component } from 'react';
import actions from '../../../../js/actions';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import Path2 from '../../../../assets/img/Path 2.png';
import Path1 from '../../../../assets/img/Path 1.png';
import { host } from '../../../../utils/constants';
import UserProgressInfo from '../components/user-progress-info';
import UserProgressCprOthers from '../components/user-progress-cpr-others';
//../../../assets/Group 93.png
import Download from '../../../../assets/img/Group 93.png';
import Awards from '../../../../assets/img/diploma/2021_awards.png';
import moment from 'moment';
import HeadersAuth from '../../../../layouts/auth/headers';
import SideBar from '../../../../layouts/auth/sidebar';
import Group573 from '../../../../assets/img/Group 573@2x.png';
import CUSTOMFIRMA from '../../../../assets/img/CUSTOM-FIRMA@2x.png';
const mapStateToProps = (state) => ({
  isLoadingUser: state.AppReducer.isLoadingUser,
  user: state.AppReducer.user,
  account: state.AppReducer.account,
});

const mapDispatchToProps = (dispatch) => ({
  getUser: (id) => dispatch(actions.getUser(id)),
});
class Courses extends Component {
  constructor(props) {
    super(props);
    document.title = ' شهاداتي';
    props.getUser(props.account.id);
    this.state = {
      selectedIndex: 0,
    };
    this._TogglePrev = this._TogglePrev.bind(this);
    this._ToggleNext = this._ToggleNext.bind(this);
  }

  _ToggleNext(selectedIndex) {
    if (selectedIndex === this.props.user.certifications.length - 1) return;

    this.setState((prevState) => ({
      selectedIndex: selectedIndex + 1,
    }));
  }

  _TogglePrev(selectedIndex) {
    if (selectedIndex === 0) return;

    this.setState((prevState) => ({
      selectedIndex: selectedIndex - 1,
    }));
  }
  render() {
    const {
      user,
      isLoadingUser,
      countTrainingIsFinish,
      countCertification,
      countTraining,
    } = this.props;
    const { selectedIndex } = this.state;
    if (isLoadingUser) {
      return (
        <section className="body-section dashboard-body user-progress-dashboard">
          <div className="bs-main-container">
            <div className="bs-main-content">
              <CircularProgress
                style={{
                  textAlign: 'center',
                  marginTop: '25%',
                  width: '40px',
                  color: '#d85555',
                  marginRight: '50%',
                }}
              />
            </div>
          </div>
        </section>
      );
    }
    return (
      <>
        <HeadersAuth />
        <section className="sidebar-body-container registered-user-view-main-cont">
          <SideBar
            path={this.props.location.pathname}
            changeActiveItemSideBar={this.props.changeActiveItemSideBar}
            activeItemSideBar={this.props.activeItemSideBar}
            account={this.props.account}
          />
          <section className="body-section dashboard-body user-progress-dashboard">
            <div className="bs-main-container">
              <div className="bs-main-content">
                <div className="dashboard-container">
                  <div className="SideNavMenu-btn--Mob">
                    <div className="SideNavMenu-btn--Mob-content">
                      <button
                        type="button"
                        data-toggle="modal"
                        data-target=".sidenavbar-modal"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="60.171"
                          height="60.171"
                          viewBox="0 0 60.171 60.171"
                        >
                          <g
                            id="Group_1515"
                            data-name="Group 1515"
                            transform="translate(-1777.986 -170.986)"
                          >
                            <path
                              id="Path_799"
                              data-name="Path 799"
                              d="M1808.072,166.157h0a30.086,30.086,0,0,1-30.086-30.085h0a30.086,30.086,0,0,1,30.086-30.085h0a30.085,30.085,0,0,1,30.085,30.085h0A30.085,30.085,0,0,1,1808.072,166.157Z"
                              transform="translate(0 65)"
                              fill="#f0f0f0"
                              style={{ mixBlendMode: 'multiply', isolation: 'isolate' }}
                            ></path>
                            <path
                              id="Path_800"
                              data-name="Path 800"
                              d="M1812.421,163.205h0a22.785,22.785,0,0,1-22.785-22.785h0a22.785,22.785,0,0,1,22.785-22.785h0a22.785,22.785,0,0,1,22.785,22.785h0A22.785,22.785,0,0,1,1812.421,163.205Z"
                              transform="translate(-4.349 60.651)"
                              fill="#fff"
                            ></path>
                            <g
                              id="Group_1082"
                              data-name="Group 1082"
                              transform="translate(1796.957 189.169)"
                            >
                              <g
                                id="Group_1080"
                                data-name="Group 1080"
                                transform="translate(4.41)"
                              >
                                <path
                                  id="Path_805"
                                  data-name="Path 805"
                                  d="M1821.8,147.965a6.46,6.46,0,0,1-4.308-1.641,6.338,6.338,0,0,1-2.2-4.819,6.506,6.506,0,0,1,13.012,0,6.338,6.338,0,0,1-2.208,4.824A6.443,6.443,0,0,1,1821.8,147.965Zm0-10.315a3.858,3.858,0,0,0-3.855,3.855,3.718,3.718,0,0,0,1.3,2.831,3.9,3.9,0,0,0,5.092.01,3.736,3.736,0,0,0,1.315-2.841A3.906,3.906,0,0,0,1821.8,137.65Z"
                                  transform="translate(-1815.294 -135)"
                                  fill="#ea0029"
                                ></path>
                              </g>
                              <g
                                id="Group_1081"
                                data-name="Group 1081"
                                transform="translate(0 9.015)"
                              >
                                <path
                                  id="Path_806"
                                  data-name="Path 806"
                                  d="M1824.17,162.931h-9.943a5.862,5.862,0,0,1-5.475-3.618,5.943,5.943,0,0,1,1.208-6.542,12.739,12.739,0,0,1,5.4-3.333,1.373,1.373,0,0,1,1.276.28,3.9,3.9,0,0,0,5.079,0,1.37,1.37,0,0,1,1.273-.28,12.7,12.7,0,0,1,5.9,3.892,5.586,5.586,0,0,1,.784,6.04A6.1,6.1,0,0,1,1824.17,162.931Zm-8.669-10.74a10.14,10.14,0,0,0-3.631,2.417,3.342,3.342,0,0,0-.681,3.669,3.214,3.214,0,0,0,3.038,2h9.943a3.414,3.414,0,0,0,3.1-2.024,2.935,2.935,0,0,0-.393-3.209,10.121,10.121,0,0,0-4.032-2.857A6.591,6.591,0,0,1,1815.5,152.192Z"
                                  transform="translate(-1808.258 -149.386)"
                                  fill="#ea0029"
                                ></path>
                              </g>
                            </g>
                          </g>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="29"
                          height="40"
                          viewBox="0 0 29 40"
                        >
                          <defs>
                            <filter
                              id="Polygon_2"
                              x="0"
                              y="0"
                              width="29"
                              height="40"
                              filterUnits="userSpaceOnUse"
                            >
                              <feOffset dy="3" input="SourceAlpha"></feOffset>
                              <feGaussianBlur
                                stdDeviation="3"
                                result="blur"
                              ></feGaussianBlur>
                              <feFlood flood-opacity="0.161"></feFlood>
                              <feComposite operator="in" in2="blur"></feComposite>
                              <feComposite in="SourceGraphic"></feComposite>
                            </filter>
                          </defs>
                          <g
                            transform="matrix(1, 0, 0, 1, 0, 0)"
                            filter="url(#Polygon_2)"
                          >
                            <path
                              id="Polygon_2-2"
                              data-name="Polygon 2"
                              d="M10.293.707a1,1,0,0,1,1.414,0l8.586,8.586A1,1,0,0,1,19.586,11H2.414a1,1,0,0,1-.707-1.707Z"
                              transform="translate(9 28) rotate(-90)"
                              fill="#fff"
                            ></path>
                          </g>
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div className="dashboard-content">
                    <div className="dashboard-column">
                      <div className="dashboard-column-content">
                        <UserProgressInfo
                          countCertification={countCertification}
                          countTraining={countTrainingIsFinish}
                          score={user ? user.score : 0}
                          badge={user ? user.badge : 0}
                        />
                        <UserProgressCprOthers
                          countCertification={countCertification}
                          countTraining={countTraining}
                          score={user ? user.score : 0}
                          badge={user ? user.badge : 0}
                        />
                      </div>
                    </div>
                    <div className="dashboard-column certif-carousel-container">
                      <div className="dashboard-column-content">
                        <div className="dashboard-column-elem">
                          <div className="dashboard-column-elem-content">
                            <div className="user-certifications-container">
                              {user &&
                              user.certifications &&
                              user &&
                              user.certifications.length > 0 ? (
                                <div className="user-certifications-content">
                                  <div className="bsCertifSlider">
                                    <div
                                      id="certifCarousel"
                                      className="carousel slide"
                                      data-ride="carousel"
                                    >
                                      <a
                                        onClick={() => this._ToggleNext(selectedIndex)}
                                        className="carousel-control-next next"
                                        role="button"
                                      >
                                        <img src={Path1} alt="" />
                                      </a>
                                      <div className="carousel-inner">
                                        {user && user.certifications ? (
                                          <div className="carousel-item active">
                                            <div
                                              className="knowledge-certif"
                                              style={{ width: '100%' }}
                                            >
                                              <img src={Group573} alt="" />
                                              <div className="knowledge-certif-content">
                                                <div className="knowledge-certif-content-header">
                                                  {/* <span style={{fontSize:'10px'}} className="certif-title">شهادة</span>
                                                  <span style={{fontSize:'17px'}} className="certif-subtitle">مشاركة</span>
                                                  <div className="horz-spr"></div> */}
                                                </div>

                                                <div className="knowledge-certif-content-body">
                                                  <span
                                                    style={{
                                                      fontSize: '15px',
                                                      marginTop: '50px',
                                                    }}
                                                    className="knowledge-certif-content-body-head"
                                                  >
                                                    {/* >هذه الشهادة مقدمة إلى */}
                                                  </span>
                                                  <span
                                                    style={{ fontSize: '20px' }}
                                                    className="certif-desc"
                                                  >
                                                    تتقدم الأمانة العامة للمجلس التنفيذي
                                                    بالشكر<br></br>
                                                    للسيد(ة) /{' '}
                                                    <strong>{`${user.first_name} ${user.last_name}`}</strong>{' '}
                                                    <br></br>
                                                    لمشاركتكم في دورة{' '}
                                                    <strong>
                                                      {
                                                        user.certifications[selectedIndex]
                                                          .title
                                                      }
                                                    </strong>{' '}
                                                    بأكاديمية  المجلس التنفيذي الذكية لإمارة أم القيوين 
                                                  </span>
                                                </div>
                                                <div className="knowledge-certif-content-footer">
                                                  <div className="certif-date">
                                                  </div>
                                                  <div className="certif-award-badge">
                                                    <div className="certif-badge-cont">
                                                    </div>
                                                  </div>
                                                  <div className="certif-manager-signature">
                                                    <span style={{ marginLeft: '-60px' }}>
                                                      {moment(
                                                        user.certifications[selectedIndex]
                                                          .date,
                                                      ).format('DD-MM-YYYY')}
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        ) : (
                                          ''
                                        )}
                                      </div>
                                      <a
                                        onClick={() => this._TogglePrev(selectedIndex)}
                                        className="carousel-control-prev prev"
                                        role="button"
                                      >
                                        <img src={Path2} alt="" />
                                      </a>
                                    </div>
                                  </div>
                                  <div className="download-certif-btn-container">
                                    <button>
                                      <div className="dwnld-icon-container">
                                        <img src={Download} alt="" />
                                      </div>
                                      <span>تحميل الشهادة</span>
                                    </button>
                                  </div>
                                </div>
                              ) : (
                                <div
                                  style={{
                                    textAlign: 'center',
                                    padding: '10%',
                                  }}
                                >
                                  <span>
                                    لا توجد شهادات بعد. الرجاء الاشتراك في واحدة
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
      </>
    );
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Courses));
